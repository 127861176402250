prismanoteApp.factory('payNlService', ['$q', '$rootScope', '$http',
  function ($q, $rootScope, $http) {
    function getTerminalStatus (url) {
      return $q(function (resolve, reject) {
        $http({
          method: 'GET',
          url: url,
          cache: false,
          dataType: 'json'
        }).then(function successCallback (response) {
          return resolve(response)
        }).catch(function errorCallback (response) {
          return reject(response)
        })
      })
    }

    function generateQRCode () {}

    function checkVoucher () {}

    function balanceVoucher () {}

    function activateVoucher () {}

    return {
      getTerminalStatus: getTerminalStatus,
      generateQRCode: generateQRCode,
      checkVoucher: checkVoucher,
      balanceVoucher: balanceVoucher,
      activateVoucher: activateVoucher
    }
  }])
