prismanoteApp.factory('$email', ['$q', '$api', 'Upload', '$rootScope', '$uibModal',
  function ($q, $api, Upload, $rootScope, $uibModal) {
    function confirmEmail (email, oldEmail) {
      return $q(function (resolve, reject) {
        $api.post('email/verification', {
          email: email,
          oldEmail: oldEmail || null
        })
          .then(function (result) {
            resolve(result.message)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    return {
      confirmEmail: confirmEmail
    }
  }])
