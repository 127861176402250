prismanoteApp.service('$productConfig', ['$q', '$api', function ($q, $api) {
  var getStarpColors = function (force) {
    return [{ key: 'SILVER_COLORED', hex: '#CACFD2' }, { key: 'GREY', hex: '#4D5656' }, { key: 'BLUE', hex: '#2980B9' }, { key: 'WHITE', hex: '#FFF8DC' }, { key: 'RED', hex: '#922B21' }, { key: 'BLACK', hex: '#000000' }, { key: 'ROSE_GOLD_COLORED', hex: '#FFA07A' }, { key: 'ROSE_SILVER_COLORED', hex: '#FFA07A' }, { key: 'GOLD_COLORED', hex: '#B8860B' }, { key: 'GOLD_SILVER_COLORED', hex: '#B8860B' }, { key: 'BROWN', hex: '#8B4513' }, { key: 'BEIGE', hex: '#CD853F' }, { key: 'GREEN', hex: '#228B22' }, { key: 'PINK', hex: '#FF69B4' }, { key: 'PURPLE', hex: '#663399' }, { key: 'ORANGE', hex: '#FF5733' }, { key: 'YELLOW', hex: '#FFD700' }, { key: 'CREME', hex: '#F5DEB3' }, { key: 'TAUPE', hex: '#D2B48C' }, { key: 'BRASS', hex: '#CD853F' }]
  }

  var getDialColors = function () {
    return [{ key: 'SILVER_COLORED', hex: '#CACFD2' }, { key: 'GREY', hex: '#4D5656' }, { key: 'BLUE', hex: '#2980B9' }, { key: 'WHITE', hex: '#FFF8DC' }, { key: 'RED', hex: '#922B21' }, { key: 'BLACK', hex: '#000000' }, { key: 'ROSE_GOLD_COLORED', hex: '#FFA07A' }, { key: 'ROSE_SILVER_COLORED', hex: '#FFA07A' }, { key: 'GOLD_COLORED', hex: '#B8860B' }, { key: 'MOTHER_OF_PEARL', hex: '#BC8F8F' }, { key: 'BROWN', hex: '#8B4513' }, { key: 'BEIGE', hex: '#CD853F' }, { key: 'MOTHER_OF_PEARL_COLOURED', hex: '#BC8F8F' }, { key: 'GREEN', hex: '#228B22' }, { key: 'PINK', hex: '#FF69B4' }, { key: 'PURPLE', hex: '#663399' }, { key: 'ORANGE', hex: '#FF5733' }, { key: 'YELLOW', hex: '#FFD700' }, { key: 'CREME', hex: '#F5DEB3' }, { key: 'TAUPE', hex: '#D2B48C' }, { key: 'BRASS', hex: '#CD853F' }]
  }

  var variantBases = function () {
    return [{ key: 'product.size', value: 'product.size' },
      { key: 'product.diamonds', value: 'product.diamonds' },
      { key: 'product.totalCaratWeight', value: 'product.totalCaratWeight' },
      { key: 'product.weight', value: 'product.weight' },
      { key: 'case.size', value: 'Case.size' },
      { key: 'case.shape', value: 'Case.shape' },
      { key: 'case.depth', value: 'Case.depth' },
      { key: 'case.material', value: 'Case.material' },
      { key: 'case.color', value: 'Case.color' },
      { key: 'case.glassMaterial', value: 'Case.glassMaterial' },
      { key: 'dial.color', value: 'Dial.color' },
      { key: 'dial.pattern', value: 'Dial.pattern' },
      { key: 'dial.print', value: 'Dial.print' },
      { key: 'dial.index', value: 'Dial.index' },
      { key: 'strap.model', value: 'Strap.model' },
      { key: 'strap.width', value: 'Strap.width' },
      { key: 'strap.material', value: 'Strap.material' },
      { key: 'strap.color', value: 'Strap.color' },
      { key: 'strap.print', value: 'Strap.print' },
      { key: 'strap.studs', value: 'Strap.studs' },
      { key: 'strap.clasp', value: 'Strap.clasp' },
      { key: 'strap.wristPermimeter', value: 'Strap.wristPermimeter' },
      { key: 'jewel.material', value: 'Jewel.material' },
      { key: 'jewel.color', value: 'Jewel.color' },
      { key: 'jewel.type', value: 'Jewel.type' },
      { key: 'jewel.height', value: 'Jewel.height' },
      { key: 'jewel.width', value: 'Jewel.width' },
      { key: 'jewel.depth', value: 'Jewel.depth' },
      { key: 'jewel.diameter', value: 'Jewel.diameter' },
      { key: 'jewel.weight', value: 'Jewel.weight' },
      { key: 'jewel.chain', value: 'Jewel.chain' },
      { key: 'jewel.clasp', value: 'Jewel.clasp' },
      { key: 'jewel.shape', value: 'Jewel.shape' },
      { key: 'jewel.gloss', value: 'Jewel.gloss' },
      { key: 'watch.type', value: 'Watch.type' },
      { key: 'watch.indication', value: 'Watch.indication' },
      { key: 'watch.hasSwissMovement', value: 'Watch.hasSwissMovement' },
      { key: 'watch.hasDateFunction', value: 'Watch.hasDateFunction' },
      { key: 'watch.hasLightFunction', value: 'Watch.hasLightFunction' },
      { key: 'watch.waterproofLevel', value: 'Watch.waterproofLevel' },
      { key: 'watch.isAntiAllergy', value: 'Watch.isAntiAllergy' },
      { key: 'watch.isSmartwatch', value: 'Watch.isSmartwatch' },
      { key: 'watch.smartWatchFunctions', value: 'Watch.smartWatchFunctions' }]
  }

  var variantBaseOptions = function () {
    return ['product.diamonds',
      'product.totalCaratWeight',
      'product.weight',
      'product.size',
      'Case.size',
      'Case.shape',
      'Case.depth',
      'Case.material',
      'Case.color',
      'Case.glassMaterial',
      'Dial.color',
      'Dial.pattern',
      'Dial.print',
      'Dial.index',
      'Strap.model',
      'Strap.width',
      'Strap.material',
      'Strap.color',
      'Strap.print',
      'Strap.studs',
      'Strap.clasp',
      'Strap.wristPermimeter',
      'Jewel.material',
      'Jewel.color',
      'Jewel.type',
      'Jewel.height',
      'Jewel.width',
      'Jewel.depth',
      'Jewel.diameter',
      'Jewel.weight',
      'Jewel.chain',
      'Jewel.clasp',
      'Jewel.shape',
      'Jewel.gloss',
      'Watch.type',
      'Watch.indication',
      'Watch.hasSwissMovement',
      'Watch.hasDateFunction',
      'Watch.waterproofLevel',
      'Watch.isNickelFree',
      'Watch.isAntiAllergy',
      'Watch.hasLightFunction',
      'Watch.isSmartwatch',
      'Watch.smartWatchFunctions']
  }

  var addNewCollection = function () {
    return {
      en: {
        name: ' ➕ Add New Collection'
      },
      nl: {
        name: ' ➕ Voeg nieuwe collectie toe'
      },
      es: {
        name: ' ➕ Añadir colección'
      },
      fr: {
        name: ' ➕ Ajouter une collection'
      },
      de: {
        name: ' ➕ Kollektion hinzufügen'
      }
    }
  }

  var addNewCollectionString = function () {
    return {
      en: {
        name: ' ➕  Add new collection'
      },
      nl: {
        name: ' ➕  Add new collection'
      },
      es: {
        name: ' ➕  Add new collection'
      },
      fr: {
        name: ' ➕  Add new collection'
      },
      de: {
        name: ' ➕  Add new collection'
      }
    }
  }

  var purchasePriceNotInPurchaseOrderNotice = function () {
    return [
      {
        en: {
          notice: 'Notice!',
          message: 'When your puchase price is not showing in this window this price is probably imported and there is no purchase order created for this price. Create a new purchase order or change the price from an existing purchase order.'
        }
      },
      {
        nl: {
          notice: 'Let op!',
          message: 'Wanneer uw puchase prijs niet wordt getoond in dit venster wordt deze prijs waarschijnlijk geïmporteerd en is er geen inkooporder aangemaakt voor deze prijs. Maak een nieuwe inkooporder aan of wijzig de prijs van een bestaande inkooporder.'
        }
      },
      {
        es: {
          notice: 'Cuidado!',
          message: 'Cuando el precio de compra no se muestra en esta ventana, es probable que este precio se importe y no se cree ningún pedido para este precio. Cree un nuevo pedido o modifique el precio de un pedido existente.'
        }
      },
      {

        fr: {
          notice: 'Notez!',
          message: " Lorsque votre prix d'achat n'apparaît pas dans cette fenêtre, ce prix est probablement importé et aucun bon de commande n'est créé pour ce prix. Créez une nouvelle commande ou modifiez le prix à partir d'une commande existante."
        }
      },
      {
        de: {
          notice: 'Hinweis!',
          message: 'Wenn Ihr Einkaufspreis in diesem Fenster nicht angezeigt wird, wird dieser Preis wahrscheinlich importiert und es wurde keine Bestellung für diesen Preis angelegt. Legen Sie eine neue Bestellung an oder ändern Sie den Preis aus einer bestehenden Bestellung.'
        }
      }
    ]
  }

  var makeValidProductNumber = function (productnumber, cbMPN) {
    return $q(function (resolve, reject) {
      var validation = ['.', ',', ' ']
      for (var i = 0; i < validation.length; i++) {
        if (i === 0 && productnumber.includes('.')) {
          productnumber = productnumber.split('.').join('')
        } else if (i === 1 && productnumber.includes(',')) {
          productnumber = productnumber.split(',').join('')
        } else if (i === 2 && productnumber.includes(' ')) {
          productnumber = productnumber.split(' ').join('')
        }
        if (i == (validation.length - 1)) {
          // console.log('=================================> Return Valid Product Number <=============================', productnumber)
          return resolve(productnumber)
        }
      }
    })
  }

  var retrunValidProductNumber = function (productNumber) {
    productNumber = productNumber.toUpperCase().replace(/[^A-Z0-9+\/\\|-]/gi, '')
    var isLastSPC = true;
    var newProductNumber;
    var productNumberLength = productNumber.length
    for (var i = 0; i < productNumberLength; i++) {
      var str = productNumber[i]
      if (/^[a-zA-Z0-9+]*$/.test(str) == false) {
        if (!isLastSPC) {
          isLastSPC = true
          newProductNumber = (newProductNumber) ? newProductNumber + str : str
        }
      } else {
        isLastSPC = false
        newProductNumber = (newProductNumber) ? newProductNumber + str : str
      }
    }
    var char = newProductNumber.charAt((newProductNumber.length - 1))

    if (/^[a-zA-Z0-9+]*$/.test(char) == false) newProductNumber = newProductNumber.slice(0, -1)
    // var charVal = newProductNumber.charAt((newProductNumber.length - 2))
    // if ((/^[a-zA-Z0-9+]*$/.test(char) && /^[a-zA-Z0-9+]*$/.test(charVal) == false)) newProductNumber = newProductNumber.slice(0, -1)
    return newProductNumber
  }

  var productIsValidForUpdate = function (product, canUpdate, images) {
    return $q(function (resolve, reject) {
      try {
        if (!canUpdate) canUpdate = {}

        if (product &&
          ((product.category === 'JEWEL' && product.jewel && product.jewel.type) || product.category === 'OTHER') &&
          product.en && product.nl && product.fr && product.es && product.de &&
          product.en.name && product.nl.name && product.fr.name && product.es.name && product.de.name &&
          product.variants &&
          product.variants.length &&
          product.variants[0].productNumber.length > 3 &&
          (product.female === true || product.female === false || product.male === true || product.male === false || product.kids === true || product.kids === false) &&
          ((product.images &&
            product.images.length) || (images && images.length))
        ) {
          canUpdate.NL = !!(product.nl.shortDescription)
          canUpdate.EN = !!(product.en.shortDescription)
          canUpdate.FR = !!(product.fr.shortDescription)
          canUpdate.ES = !!(product.es.shortDescription)
          canUpdate.DE = !!(product.de.shortDescription)
          return resolve(canUpdate)
        } else if (product &&
          product.category == 'STRAP' &&
          product.en && product.nl && product.fr && product.es && product.de &&
          product.en.name && product.nl.name && product.fr.name && product.es.name && product.de.name &&
          product.variants &&
          product.variants.length &&
          product.variants[0].productNumber.length > 3 &&
          (product.female === true || product.female === false || product.male === true || product.male === false || product.kids === true || product.kids === false) &&
          ((product.images &&
            product.images.length) || (images && images.length)) &&
          product.strap && product.strap.color
        ) {
          canUpdate.NL = !!(product.nl.shortDescription)
          canUpdate.EN = !!(product.en.shortDescription)
          canUpdate.FR = !!(product.fr.shortDescription)
          canUpdate.ES = !!(product.es.shortDescription)
          canUpdate.DE = !!(product.de.shortDescription)
          return resolve(canUpdate)
        } else if (
          product && product.category == 'WATCH' &&
          product.en &&
          product.nl &&
          product.fr &&
          product.es &&
          product.de &&
          product.en.name &&
          product.nl.name &&
          product.fr.name &&
          product.es.name &&
          product.de.name &&
          product.variants &&
          product.variants.length &&
          product.variants[0].productNumber &&
          product.variants[0].productNumber.length > 3 &&
          (product.female === true || product.female === false || product.male === true || product.male === false || product.kids === true || product.kids === false) &&
          ((product.images &&
            product.images.length) || (images && images.length)) &&
          product.watch &&
          product.watch.type &&
          product.watch.strap &&
          product.watch.strap.color &&
          product.watch.case &&
          product.watch.case.size &&
          (
            product.watch.isAntiAllergy === true ||
            product.watch.isAntiAllergy === false
          ) &&
          (
            product.watch.hasDateFunction === true ||
            product.watch.hasDateFunction === false
          )
        ) {
          // console.log('product 2 watch', product)
          canUpdate.NL = !!(product.nl.shortDescription)
          canUpdate.EN = !!(product.en.shortDescription)
          canUpdate.FR = !!(product.fr.shortDescription)
          canUpdate.ES = !!(product.es.shortDescription)
          canUpdate.DE = !!(product.de.shortDescription)

          return resolve(canUpdate)
        } else {
          canUpdate.DE = canUpdate.ES = canUpdate.FR = canUpdate.EN = canUpdate.NL = false
          return resolve(canUpdate)
        }
      } catch (e) {
        console.log('Something went wrong: ', e);
        reject();
      }
    })
  }

  var getJewelRingSize = function (){
    return [
      { key : 11.75, value: 37},
      { key : 12.00, value: 38},
      { key : 12.25, value: 38.5},
      { key : 12.50, value: 39},
      { key : 12.75, value: 40},
      { key : 13.00, value: 41},
      { key : 13.25, value: 42},
      { key : 13.50, value: 42.5},
      { key : 13.75, value: 43},
      { key : 14.00, value: 44},
      { key : 14.25, value: 45},
      { key : 14.50, value: 46},
      { key : 14.75, value: 46.5},
      { key : 15.00, value: 47},
      { key : 15.25, value: 48},
      { key : 15.50, value: 49},
      { key : 15.75, value: 49.5},
      { key : 16.00, value: 50},
      { key : 16.25, value: 51},
      { key : 16.50, value: 52},
      { key : 16.75, value: 52.5},
      { key : 17.00, value: 53},
      { key : 17.25, value: 54},
      { key : 17.50, value: 55},
      { key : 17.75, value: 56},
      { key : 18.00, value: 57},
      { key : 18.25, value: 57.5},
      { key : 18.50, value: 58},
      { key : 18.75, value: 58.5},
      { key : 19.00, value: 59},
      { key : 19.25, value: 60},
      { key : 19.50, value: 61},
      { key : 19.75, value: 62},
      { key : 20.00, value: 63},
      { key : 20.25, value: 64},
      { key : 20.50, value: 65},
      { key : 20.75, value: 65.5},
      { key : 21.00, value: 66},
      { key : 21.25, value: 67},
      { key : 21.50, value: 67.5},
      { key : 21.75, value: 68},
      { key : 22.00, value: 69},
      { key : 22.25, value: 70},
      { key : 22.50, value: 71},
      { key : 22.75, value: 71.5},
      { key : 23.00, value: 72},
      { key : 23.25, value: 73},
      { key : 23.50, value: 74},
      { key : 23.75, value: 74.5},
      { key : 24.00, value: 75},
      { key : 24.25, value: 76},
      { key : 24.50, value: 77},
      { key : 24.75, value: 78},
      { key : 25.00, value: 78.5},
      { key : 25.25, value: 79},
      { key : 25.50, value: 80},
      { key : 25.75, value: 81},
      { key : 26.00, value: 82},
      { key : 26.25, value: 83},
      { key : 26.50, value: 83.5},
      { key : 26.75, value: 84},
      { key : 27.00, value: 85},
      { key : 27.25, value: 85.5},
      { key : 27.50, value: 86},
      { key : 27.75, value: 87},
      { key : 28.00, value: 88},
      { key : 28.25, value: 89},
      { key : 28.50, value: 89.5},
      { key : 28.75, value: 90},
      { key : 29.00, value: 91},
      { key : 29.25, value: 92},
      { key : 29.50, value: 93},
      { key : 29.75, value: 93.5},
      { key : 30.00, value: 94},
      { key : 30.25, value: 95},
      { key : 30.50, value: 96}
    ]
  }

  this.getJewelRingSize = getJewelRingSize
  this.getStarpColors = getStarpColors
  this.getDialColors = getDialColors
  this.variantBaseOptions = variantBaseOptions
  this.addNewCollection = addNewCollection
  this.makeValidProductNumber = makeValidProductNumber
  this.variantBases = variantBases
  this.purchasePriceNotInPurchaseOrderNotice = purchasePriceNotInPurchaseOrderNotice
  this.productIsValidForUpdate = productIsValidForUpdate
  this.retrunValidProductNumber = retrunValidProductNumber
  this.addNewCollectionString = addNewCollectionString
}])
