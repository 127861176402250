prismanoteApp.service('$case', ['$q', '$api', function ($q, $api) {
  var getGemKinds = function (force) {
    return [{ key: 'agate', value: 'agate' },
      { key: 'amazone', value: 'amazone' },
      { key: 'amazonite', value: 'amazonite' },
      { key: 'amber', value: 'amber' },
      { key: 'amethyst', value: 'amethyst' },
      { key: 'aquamarine', value: 'aquamarine' },
      { key: 'aventurine', value: 'aventurine' },
      { key: 'beryll', value: 'beryll' },
      { key: 'bloodcoral', value: 'bloodcoral' },
      { key: 'calcite', value: 'calcite' },
      { key: 'cameo', value: 'cameo' },
      { key: 'carnelean', value: 'carnelean' },
      { key: 'cateye', value: 'cateye' },
      { key: 'ceramics', value: 'ceramics' },
      { key: 'chalcedony', value: 'chalcedony' },
      { key: 'citrine', value: 'citrine' },
      { key: 'corundum', value: 'corundum' },
      { key: 'crystal', value: 'crystal' },
      { key: 'diamond', value: 'diamond' },
      { key: 'emerald', value: 'emerald' },
      { key: 'enamel', value: 'enamel' },
      { key: 'epidote', value: 'epidote' },
      { key: 'firepost', value: 'firepost' },
      { key: 'fluorite', value: 'fluorite' },
      { key: 'garnet', value: 'garnet' },
      { key: 'glass', value: 'glass' },
      { key: 'heliotrope', value: 'heliotrope' },
      { key: 'hematite', value: 'hematite' },
      { key: 'irisquartz', value: 'irisquartz' },
      { key: 'jade', value: 'jade' },
      { key: 'jargon', value: 'jargon' },
      { key: 'jasper', value: 'jasper' },
      { key: 'labradorite', value: 'labradorite' },
      { key: 'lapis', value: 'lapis' },
      { key: 'lazuli', value: 'lazuli' },
      { key: 'lazurite', value: 'lazurite' },
      { key: 'lemonquartz', value: 'lemonquartz' },
      { key: 'madeiracitrine', value: 'madeiracitrine' },
      { key: 'malachite', value: 'malachite' },
      { key: 'moonstone', value: 'moonstone' },
      { key: 'muranoglass', value: 'muranoglass' },
      { key: 'naturalstone', value: 'naturalstone' },
      { key: 'nephrite', value: 'nephrite' },
      { key: 'onyx', value: 'onyx' },
      { key: 'opal', value: 'opal' },
      { key: 'pearl', value: 'pearl' },
      { key: 'pearl akoya', value: 'pearl akoya' },
      { key: 'pearl tahiti', value: 'pearl tahiti' },
      { key: 'pearl sweet water', value: 'pearl sweet water' },
      { key: 'pearl south sea', value: 'pearl south sea' },
      { key: 'pearl synthetical', value: 'pearl synthetical' },
      { key: 'pearl cultive', value: 'pearl cultive' },
      { key: 'peridote', value: 'peridote' },
      { key: 'phosphorite', value: 'phosphorite' },
      { key: 'quartz', value: 'quartz' },
      { key: 'rhinestone', value: 'rhinestone' },
      { key: 'rockcrystal', value: 'rockcrystal' },
      { key: 'rosequartz', value: 'rosequartz' },
      { key: 'ruby', value: 'ruby' },
      { key: 'sapphire', value: 'sapphire' },
      { key: 'slate', value: 'slate' },
      { key: 'smoky quartz', value: 'smoky quartz' },
      { key: 'sodalite', value: 'sodalite' },
      { key: 'spinel', value: 'spinel' },
      { key: 'stratumstone', value: 'stratum stone' },
      { key: 'swarovskicrystal', value: 'swarovskicrystal' },
      { key: 'syntheticstone', value: 'syntheticstone' },
      { key: 'tigereye', value: 'tigereye' },
      { key: 'topaz', value: 'topaz' },
      { key: 'tourmaline', value: 'tourmaline' },
      { key: 'turquoise', value: 'turquoise' },
      { key: 'uvarovite', value: 'uvarovite' },
      { key: 'wildvinite', value: 'wildvinite' },
      { key: 'xylopile', value: 'xylopile' },
      { key: 'zirconia', value: 'zirconia' }]
  }

  var getGemPurities = function () {
    return [{ key: 'LC', value: 'LC' },
      { key: 'VVS1', value: 'VVS1' },
      { key: 'VVS2', value: 'VVS2' },
      { key: 'VS1', value: 'VS1' },
      { key: 'VS2', value: 'VS2' },
      { key: 'SI1', value: 'SI1' },
      { key: 'SI2', value: 'SI2' },
      { key: 'P1', value: 'P1' },
      { key: 'P2', value: 'P2' },
      { key: 'P3', value: 'P3' }]
  }

  var getGemColors = function () {
    return [{ value: 'D', key: 'D (Finest white+ Jager )' },
      { value: 'E', key: 'E (Finest white River )' },
      { value: 'F', key: 'F (Fine white+ River )' },
      { value: 'G', key: 'G (Fine white Top Wesselton )' },
      { value: 'H', key: 'H (White Wesselton )' },
      { value: 'I', key: 'I (Light tinted white+ Crystal)' },
      { value: 'J', key: 'J (Light tinted white )' },
      { value: 'K', key: 'K (Tinted white+ Top )' },
      { value: 'L', key: 'L (Tinted white Top Cape )' },
      { value: 'M', key: 'M (Tinted colour Cape )' },
      { value: 'N', key: 'N (Tinted colour Low Cape )' },
      { value: 'O', key: 'O (Tinted colour Very Light Yellow)' },
      { value: 'P-Z', key: 'P-Z (Tinted colour Light )' },
      { value: 'P', key: 'P (Tinted colour Very Light Yellow)' },
      { value: 'Q', key: 'Q (Tinted colour Very Light Yellow)' },
      { value: 'R', key: 'R (Tinted colour Very Light Yellow)' },
      { value: 'S', key: 'S (Light Yellow)' },
      { value: 'T', key: 'T (Light Yellow)' },
      { value: 'V', key: 'V (Light Yellow)' },
      { value: 'U', key: 'U (Light Yellow)' },
      { value: 'W', key: 'W (Light Yellow)' },
      { value: 'X', key: 'X (Light Yellow)' },
      { value: 'Y', key: 'Y (Light Yellow)' },
      { value: 'Z', key: 'Z (Light Yellow)' },
      { value: 'TRANSPARENT', key: 'TRANSPARENT' },
      { value: 'SILVER', key: 'SILVER' },
      { value: 'GREY', key: 'GREY' },
      { value: 'BLUE', key: 'BLUE' },
      { value: 'WHITE', key: 'WHITE' },
      { value: 'RED', key: 'RED' },
      { value: 'BLACK', key: 'BLACK' },
      { value: 'ROSE_GOLD', key: 'ROSE_GOLD' },
      { value: 'GOLD', key: 'GOLD' },
      { value: 'MOTHER_OF_PEARL', key: 'MOTHER_OF_PEARL' },
      { value: 'BROWN', key: 'BROWN' },
      { value: 'BEIGE', key: 'BEIGE' },
      { value: 'MOTHER_OF_PEARL_COLOURED', key: 'MOTHER_OF_PEARL_COLOURED' },
      { value: 'GOLDEN', key: 'GOLDEN' },
      { value: 'GREEN', key: 'GREEN' },
      { value: 'PINK', key: 'PINK' },
      { value: 'PURPLE', key: 'PURPLE' },
      { value: 'ORANGE', key: 'ORANGE' },
      { value: 'YELLOW', key: 'YELLOW' },
      { value: 'CREME', key: 'CREME' },
      { value: 'TAUPE', key: 'TAUPE' },
      { value: 'BRASS', key: 'BRASS' }]
  }

  var getGemCuts = function () {
    return [{ key: 'asscher', value: 'asscher' },
      { key: 'baguette', value: 'baguette' },
      { key: 'briljant', value: 'briljant' },
      { key: 'buff-top', value: 'buff-top' },
      { key: 'cabochon', value: 'cabochon' },
      { key: 'carre', value: 'carre' },
      { key: 'emerald', value: 'emerald' },
      { key: 'fact', value: 'fact' },
      { key: 'heart', value: 'heart' },
      { key: 'marquise', value: 'marquise' },
      { key: 'mixed', value: 'mixed' },
      { key: 'octagon', value: 'octagon' },
      { key: 'oval fact', value: 'oval fact' },
      { key: 'oval', value: 'oval' },
      { key: 'pear', value: 'pear' },
      { key: 'radiant', value: 'radiant' },
      { key: 'princess', value: 'princess' },
      { key: 'single-cut', value: 'single-cut' },
      { key: 'radiant', value: 'radiant' },
      { key: 'round', value: 'round' },
      { key: 'radiant', value: 'radiant' }]
  }

  this.getGemKinds = getGemKinds
  this.getGemPurities = getGemPurities
  this.getGemColors = getGemColors
  this.getGemCuts = getGemCuts
}])
