prismanoteApp.factory('$goldStockService', ['$api',
  function ($api) {
    function fetchShopGoldStockAndValue (shopId) {
      return $api.get('v2/gold-stock-value-history/' + shopId)
    };

    function updateShopGoldStockAndValue (shopId, updateData) {
      return $api.put('v2/gold-stock-value/' + shopId, updateData)
    };

    return {
      fetchShopGoldStockAndValue: fetchShopGoldStockAndValue,
      updateShopGoldStockAndValue: updateShopGoldStockAndValue
    }
  }
])
