prismanoteApp.service('$brand', ['$q', '$api', '$rootScope', function ($q, $api, $rootScope) {
  function getAllBrands() {
    return $q(function (resolve, reject) {
      $api.get('brands?getAll=true')
        .then(function (response) {
          console.log('Get success resopnse', response.data)
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function updateProductNumberBasedOnBrand(brandId, currentDigit, newDigit) {
    return $q(function (resolve, reject) {
      $api.put('brand/products/update-product-number/' + brandId + '/' + currentDigit + '/' + newDigit)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function addEmployeeToTheCompany(employee) {
    return $q(function (resolve, reject) {
      employee.language = $rootScope.language
      employee.companyId = $rootScope.currentCompany._id;
      $api.post('brand/employee', employee)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function getEmployeesOfCompany(companyId) {
    return $q(function (resolve, reject) {
      $api.get('brand/company-employees/' + companyId)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function editEmployeeByEmployeeId(employee) {
    return $q(function (resolve, reject) {
      employee.companyId = $rootScope.currentCompany._id;
      $api.put('brand/employee', employee)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function getCompanyOwnBrands(companyId) {
    console.log('companyId', companyId)
    return $q(function (resolve, reject) {
      $api.get('v2/company/brands/' + companyId)
        .then(function (response) {
          console.log(response)
          return resolve(response.data)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason.data)
        })
    })
  }

  function getDashboardCounts(companyId) {
    console.log('companyId: ', companyId)
    return $q(function (resolve, reject) {
      $api.get('v2/company/dashboard-counts/' + companyId)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason.data)
        })
    })
  }

  /**
   * its about when company doing then have to send email or not
   * @param {string} companyId company id
   * @param {boolean} isSendEmailNotifications have to send email notification or not
   */
  function changeEmailNotification(companyId, isSendEmailNotifications, emailNotificationlanguage) {
    console.log('companyId: ', companyId, isSendEmailNotifications)
    return $q(function (resolve, reject) {
      var body = {
        companyId: companyId,
        isSendEmailNotifications: isSendEmailNotifications,
        emailNotificationlanguage: emailNotificationlanguage
      }
      $api.post('v2/company/change-email-notification', body)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason.data)
        })
    })
  }

  function changeCompanyDetailByKey(body) {
    return $api.post('v2/brand/change-company-detail-by-key', body)
  }

  /**
   * Its for adding shop to the company
   * @param {object} body companyid, shopId and shop email
   */
  function addShopInCompany(body) {
    console.log('addShopInCompany: ', body)
    return $q(function (resolve, reject) {
      $api.post('v2/company/add-shop-in-company', body)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason.data)
        })
    })
  }

  function addCompanyInShop(body) {
    return $api.post('v2/retailer/add-company-in-shop', body)
  }

  function searchShopForBrandPurchaseOrder(body) {
    return $api.post('v2/brand/seacrch-shop-brand-purchase-order', body)
  }

  /**
   * @param {object} body body of comment
   */
  function getCommentofCompanyOrShop(body) {
    console.log('getCommentofCompanyOrShop: ', body)
    return $q(function (resolve, reject) {
      $api.get('v2/comment-of-shop-or-company', body)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason.data)
        })
    })
  }

  /**
   * @param {object} body body of comment
   */
  function addCommentofCompanyOrShop(body) {
    console.log('addCommentofCompanyOrShop: ', body)
    return $q(function (resolve, reject) {
      $api.post('v2/comment-of-shop-or-company', body)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason.data)
        })
    })
  }

  /**
   * @param {object} body body of comment
   */
  function editCommentofCompanyOrShop(body) {
    console.log('editCommentofCompanyOrShop: ', body)
    return $q(function (resolve, reject) {
      $api.put('v2/comment-of-shop-or-company', body)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason.data)
        })
    })
  }

  /**
   * @param {object} body body of comment
   */
  function addInternalCommentOfSupplier(body) {
    console.log('addInternalCommentOfSupplier: ', body)
    return $q(function (resolve, reject) {
      $api.put('v2/company/comment-of-supplier', body)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          // console.log("Error", reason);
          return reject(reason)
        })
    })
  }

  /**
   * @param {object} body body of comment
   */
  function getInternalCommentOfSupplier(body) {
    console.log('getInternalCommentOfSupplier: ', body)
    return $q(function (resolve, reject) {
      $api.get('v2/company/comment-of-supplier', body)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          // console.log("Error", reason);
          return reject(reason)
        })
    })
  }

  /**
   * @param {object} body body of shopId and companyId
   */
  function removeShopFromTheCompany(body) {
    console.log('removeShopFromTheCompany: ', body)
    return $q(function (resolve, reject) {
      $api.put('v2/company/remove-shop-from-company', body)
        .then(function (response) {
          return resolve(response)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason)
        })
    })
  }

  /**
   * @param {object} body body of searhcMode and SerachText
   */
  function searchShopWithSearchMode(body, companyId) {
    console.log('searchShopWithSearchMode: ', body)
    return $q(function (resolve, reject) {
      $api.get('v2/company/search-shop-with-searchmode?companyId=' + companyId, body)
        .then(function (response) {
          return resolve(response)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason)
        })
    })
  }

  /**
   * @param {object} body shop data
   */
  function createShop(body) {
    return $q(function (resolve, reject) {
      $api.post('v2/company/create-shop', body)
        .then(function (response) {
          return resolve(response)
        }).catch(function (reason) {
          // console.log("Error", reason);
          return reject(reason)
        })
    })
  }

  function changeReportMisUseBrand(body) {
    return $q(function (resolve, reject) {
      $api.post('v2/company/change-report-misuse-brand', body)
        .then(function (response) {
          return resolve(response)
        }).catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function addShopCompanyDeleteSuggestion(body) {
    return $q(function (resolve, reject) {
      $api.post('v2/shop-company-delete-suggestion', body)
        .then(function (response) {
          return resolve(response)
        }).catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function getShopCompanyDeleteSuggestion(body) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop-company-delete-suggestion', body)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function getBrandsProductForAdmin(nameSlug) {
    return $q(function (resolve, reject) {
      $api.get('v2/admin/brand/products/' + nameSlug)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason.data)
        })
    })
  }

  function getBrandsProductAutoForAdmin(nameSlug) {
    return $q(function (resolve, reject) {
      $api.get('v2/admin/brand/products-auto/' + nameSlug)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason.data)
        })
    })
  }

  function getBrandProductSuggestions(nameSlug) {
    return $q(function (resolve, reject) {
      $api.get('v2/brand/product-suggestion-product-number/' + nameSlug)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          console.log('Error', reason)
          return reject(reason.data)
        })
    })
  }

  function getCompanyShopDetail(body) {
    return $q(function (resolve, reject) {
      $api.get('v2/company/company-shop-detail', body)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function editCompanyShopDetail(body) {
    return $q(function (resolve, reject) {
      $api.put('v2/company/company-shop-detail', body)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function applyNewStartingDigitInSelectedProducts(data) {
    return $q(function (resolve, reject) {
      $api.post('v2/brand/products/apply-new-starting-digits', data)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function getTheStockvalueForGraph(data) {
    return $api.post('v2/stockvalue', data)
  }

  function getShopMapDetail(body) {
    return $api.get('v2/brand/shop-map-detail', body)
  }

  function getPurchaseOrderAndRepairWhichHasRemarks(body) {
    return $api.get('v2/brand/purchaseorder-repair-which-has-remarks', body)
  }

  function getServiceOfRetailerAndCompany(body) {
    return $api.get('v2/retailer/service-of-retailer-company', body)
  }

  function getCompanyShopBrands(body) {
    return $api.get('v2/company/brand-of-shop-and-company', body)
  }

  function getShopDetailsForBrandPortalWithSelectedTab(body) {
    return $api.get('v2/company/shopdetail-for-brand-for-selected-tab', body)
  }
  
  function getActivitySelectedTab(body) {
    return $api.get('v2/company/get-shop-activities-for-selected-tab', body)
  }

  function getMergeShopDetailsForBrandPortalWithSelectedTab(body) {
    return $api.get('v2/company/shopdetail-for-brand-for-merged-data', body)
  }

  function getBrands() {
    return $api.get('brands')
  }

  function getBrandCompaniesForShopSettings(body) {
    return $api.get('v2/retailer/brand-companies-for-shop', body)
  }

  function updateBrandInShop(body) {
    return $api.post('v2/retailer/updatebrand-in-shop', body)
  }

  function addBrandInShop(body) {
    return $api.post('v2/retailer/addbrand-in-shop', body)
  }

  function getCompanyEmailAndThereUserEmailAddress(body) {
    return $api.get('v2/brand/companyemail-and-usersemail', body)
  }

  function updateCompanyByKey(body) {
    return $api.put('v2/brand/company-by-key', body)
  }

  function getCompanyInfoForCompanyUser(body) {
    return $api.get('v2/brand/companyinfo', body)
  }

  function updateCompanyInfo(body) {
    return $api.put('v2/brand/companyinfo', body)
  }

  // below routes are not working ad companyId is not proper passed!
  // function getCompanyInfoForCompanyUser(body, companyId) {
  //   return $api.get('v2/brand/companyinfo' + companyId, body)
  // }

  // function updateCompanyInfo(body, companyId) {
  //   return $api.put('v2/brand/companyinfo', + companyId, body)
  // }

  function updateShopStatisticsSettingsForNotification(body) {
    return $api.post('v2/brand/edit-shop-statisticsnotification', body)
  }

  function inviteRetailerForRecommendationOrder(body) {
    return $api.post('v2/brand/invite-retailer-for-recommenation', body)
  }

  function getTaxForCountry(body) {
    return $api.get('tax/country', body)
  }

  function getShopEmails(body) {
    return $api.get('v2/brand/shop-emails', body)
  }

  function getCompanies(body) {
    return $api.get('v2/admin/companies', body)
  }

  function getAllTheCompanies() {
    return $api.get('v2/admin/all_companies')
  }

  function addCustomFilter(body) {
    return $api.post('v2/wholesaler/custom-filter', body)
  }

  function addCustomFilterCategory(body) {
    return $api.post('wholesaler/custom-filter-category', body, null, 2)
  }

  function getCustomFilterInCompany(body) {
    return $api.get('v2/wholesaler/custom-filter', body)
  }

  function updateCustomFilterInCompany(body) {
    return $api.put('v2/wholesaler/custom-filter', body)
  }

  function deleteCustomFilterInCompany(body) {
    return $api.put('v2/wholesaler/delete-custom-filter', body)
  }

  function deleteCustomFilterCategoryInCompany(body) {
    return $api.delete('wholesaler/custom-filter-category/' + body.companyId + '/' + body.categoryId, null, null, 2)
  }

  function deleteCompanyBrandAndItsProduct(body) {
    return $api.delete('company/company-brand/' + body.companyId + '/' + body.brandId, null, null, 2)
  }

  function fetchRecommendationManagementProducts(body) {
    return $api.get('v2/company/recommendation-management-products', body)
  }

  function fetchRecommendationManagementBrandProducts(body) {
    return $api.get('v2/company/recommendation-management-brand-products', body)
  }

  function fetchcompanyclientgroup(body) {
    return $api.get('v2/company/client-group', body)
  }

  function fetchTheClientGroupShopProducts(body) {
    return $api.get('v2/company/clientgroup-shopproducts', body)
  }

  this.getActivitySelectedTab = getActivitySelectedTab
  this.getBrandsProductAutoForAdmin = getBrandsProductAutoForAdmin
  this.getBrandProductSuggestions = getBrandProductSuggestions
  this.getAllBrands = getAllBrands;
  this.updateProductNumberBasedOnBrand = updateProductNumberBasedOnBrand;
  this.getCompanyOwnBrands = getCompanyOwnBrands;
  this.addEmployeeToTheCompany = addEmployeeToTheCompany;
  this.getEmployeesOfCompany = getEmployeesOfCompany;
  this.editEmployeeByEmployeeId = editEmployeeByEmployeeId;
  this.getDashboardCounts = getDashboardCounts;
  this.changeEmailNotification = changeEmailNotification;
  this.changeCompanyDetailByKey = changeCompanyDetailByKey;
  this.addShopInCompany = addShopInCompany;
  this.addCompanyInShop = addCompanyInShop;
  this.addCommentofCompanyOrShop = addCommentofCompanyOrShop;
  this.getCommentofCompanyOrShop = getCommentofCompanyOrShop;
  this.editCommentofCompanyOrShop = editCommentofCompanyOrShop;
  this.addInternalCommentOfSupplier = addInternalCommentOfSupplier;
  this.getInternalCommentOfSupplier = getInternalCommentOfSupplier;
  this.removeShopFromTheCompany = removeShopFromTheCompany;
  this.searchShopWithSearchMode = searchShopWithSearchMode;
  this.createShop = createShop;
  this.changeReportMisUseBrand = changeReportMisUseBrand;
  this.addShopCompanyDeleteSuggestion = addShopCompanyDeleteSuggestion;
  this.getShopCompanyDeleteSuggestion = getShopCompanyDeleteSuggestion;
  this.getBrandsProductForAdmin = getBrandsProductForAdmin;
  this.getCompanyShopDetail = getCompanyShopDetail;
  this.editCompanyShopDetail = editCompanyShopDetail;
  this.applyNewStartingDigitInSelectedProducts = applyNewStartingDigitInSelectedProducts;
  this.getTheStockvalueForGraph = getTheStockvalueForGraph;
  this.getShopMapDetail = getShopMapDetail;
  this.getPurchaseOrderAndRepairWhichHasRemarks = getPurchaseOrderAndRepairWhichHasRemarks;
  this.getServiceOfRetailerAndCompany = getServiceOfRetailerAndCompany;
  this.getShopDetailsForBrandPortalWithSelectedTab = getShopDetailsForBrandPortalWithSelectedTab
  this.getCompanyShopBrands = getCompanyShopBrands
  this.getMergeShopDetailsForBrandPortalWithSelectedTab = getMergeShopDetailsForBrandPortalWithSelectedTab
  this.getBrands = getBrands;
  this.getBrandCompaniesForShopSettings = getBrandCompaniesForShopSettings;
  this.updateBrandInShop = updateBrandInShop;
  this.addBrandInShop = addBrandInShop;
  this.getCompanyEmailAndThereUserEmailAddress = getCompanyEmailAndThereUserEmailAddress;
  this.updateCompanyByKey = updateCompanyByKey;
  this.getCompanyInfoForCompanyUser = getCompanyInfoForCompanyUser;
  this.updateCompanyInfo = updateCompanyInfo;
  this.updateShopStatisticsSettingsForNotification = updateShopStatisticsSettingsForNotification;
  this.inviteRetailerForRecommendationOrder = inviteRetailerForRecommendationOrder;
  this.getTaxForCountry = getTaxForCountry;
  this.getShopEmails = getShopEmails;
  this.getCompanies = getCompanies;
  this.getAllTheCompanies = getAllTheCompanies;
  this.addCustomFilter = addCustomFilter;
  this.addCustomFilterCategory = addCustomFilterCategory
  this.getCustomFilterInCompany = getCustomFilterInCompany;
  this.updateCustomFilterInCompany = updateCustomFilterInCompany;
  this.deleteCustomFilterInCompany = deleteCustomFilterInCompany;
  this.deleteCustomFilterCategoryInCompany = deleteCustomFilterCategoryInCompany;
  this.searchShopForBrandPurchaseOrder = searchShopForBrandPurchaseOrder;
  this.deleteCompanyBrandAndItsProduct = deleteCompanyBrandAndItsProduct;
  this.fetchRecommendationManagementProducts = fetchRecommendationManagementProducts;
  this.fetchRecommendationManagementBrandProducts = fetchRecommendationManagementBrandProducts;
  this.fetchcompanyclientgroup = fetchcompanyclientgroup;
  this.fetchTheClientGroupShopProducts = fetchTheClientGroupShopProducts;
}
])
