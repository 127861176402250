prismanoteApp.factory('$device', ['$q', '$api', '$log', '$rootScope', '$uibModal', 'FileSaver', '$http',
  function ($q, $api, $log, $rootScope, $uibModal, FileSaver, $http) {

    function getDevices(shopId, deviceId) {
      return $q(function (resolve, reject) {
        $api.get('device'+ (shopId ? '/' + shopId : '') + (deviceId ? '/' + deviceId : ''))
          .then(function (res) {
            return resolve(res.data.devices)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function createDevice (deviceName, shopId) {
      return $q(function(resolve, reject) {
        var documents = [
          'REPAIR/SPECIAL',
          'WEBSHOP',
          'TRANSACTION',
          'GIFTCARD',
          'DAYSTATE',
          'PURCHASE_ORDER'
        ]
        $api.post('device', {
          shopId: $rootScope.currentShop._id, //security
          device: {
            name: deviceName,
            shop: shopId,
            documents: documents.map(function(d) {
              return {
                autoPrint: false,
                type: d,
              }
            })
        }})
          .then(function (res) {
            return resolve(res.message)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function updateDevice(device, updateShop) {
      return $q(function (resolve, reject) {
        if(!device) {
          device = $rootScope.currentDevice
        }
        $api.put('device', {
          device: device,
          shopId: $rootScope.currentShop._id,
          updateShop: updateShop
        })
          .then(function (res) {
            return resolve(res.message)
          })
          .catch(function(reason){
            return reject(reason)
          })
      })
    }

    function deleteDevice(id) {
      return $q(function (resolve, reject) {
        $api.delete('device/' + $rootScope.currentShop._id +'/' + id)
          .then(function(){
            return resolve('DEVICE_DELETED')
          })
          .catch(function (reason){
            return reject(reason)
          })
      })
    }

    function getCurrentDevice() {
      return $q(function (resolve, reject) {
        if (!$rootScope.currentShop || !$rootScope.currentShop._id) {
          return reject()
        }
        var currentDevice = localStorage.getItem('currentDevice')
        if (currentDevice) {
          getDevices($rootScope.currentShop._id, currentDevice)
            .then(function (device) {
              if (!device) {
                return setFirstDevice()
                  .then(function (device) {
                    return resolve(device)
                  })
                  .catch(function (reason){
                    $log.error('Error while selecting device', reason)
                    return reject(reason)
                  })
              }
              $rootScope.currentDevice = device
              return resolve(device)
            })
            .catch(function (reason) {
              $log.error('Error while fetching currentDevice', reason)
              return reject(reason)
            })
        } else {
          return setFirstDevice()
            .then(function (device) {
              return resolve(device)
            })
            .catch(function (reason){
              $log.error('Error while selecting device', reason)
              return reject(reason)
            })
        }
      })
    }

    function setCurrentDevice(device) {
      localStorage.setItem('currentDevice', device)
      getCurrentDevice()
    }

    function unsetCurrentDevice() {
      $rootScope.currentDevice = null
      localStorage.removeItem('currentDevice')
    }

    function setFirstDevice () {
      return $q(function (resolve, reject) {
        getDevices($rootScope.currentShop._id)
        .then(function(devices) {
          if(!devices || $rootScope.deviceModalOpen) {
            return reject()
          }
          if(devices.length > 1) {
            var deviceModal = $uibModal.open({
              templateUrl: '../views/modal/retailer-device-modal.html',
              controller: 'retailerDeviceModalController',
              size: 'sm',
              backdrop: 'static',
              keyboard: false,
              resolve: {
                devices: function () {
                  return devices;
                }
              }
            })
            deviceModal.result.then(function (result) {
              $rootScope.currentDevice = result
              setCurrentDevice(result._id)
              $rootScope.deviceModalOpen = false
              return resolve(result)
            }, function (err) {
              //dismissed
            })

          } else {
            $rootScope.currentDevice = devices[0]
            setCurrentDevice(devices[0]._id)
            return resolve(devices[0])
          }
        })
      })
    }

    function getDeviceInfoForPrinter (printerName) {
      return $q(function (resolve, reject) {
        //First we look if the requested printer is in the current device
        //That will be the case for many cased so that will reduce API calls and increase performance

        var pIdx = _.findIndex($rootScope.currentDevice.printers, function (p) {
          return p === printerName
        })

        if(pIdx >= 0 ){
          //Printer is found in the current device, we should return the currentDevice now
          return resolve($rootScope.currentDevice)
        } else {
          //Printer is not found in the current device, we need to let the server search for the right device
          $api.get('device/find?printerName=' + printerName + '&shop=' + $rootScope.currentShop._id)
            .then(function (result) {
              return resolve(result.data.device)
            })
            .catch(function (reason){
              $log.error('Error while fetching device information for printer', reason)
              return reject(reason)
            })
        }
      })
    }

    function downloadCertificate(name, id) {
      $http({
        method: 'GET',
        url: '/api/device/certificate',
        params: {
          currentShopId: $rootScope.currentShop._id,
          deviceId: id
        },
        responseType: 'arraybuffer'
      })
        .then(function (response) {
          var file = new Blob([response.data], {type: 'application/x-x509-ca-cert'})
          var fileName = $rootScope.slugify(name) + '.cer'
          FileSaver.saveAs(file, fileName)
        })
        .catch(function (reason){
          console.error('Error while downloading certificate', reason)
        })
    }

    return {
      getDevices: getDevices,
      createDevice: createDevice,
      updateDevice : updateDevice,
      deleteDevice: deleteDevice,
      getCurrentDevice : getCurrentDevice,
      setCurrentDevice : setCurrentDevice,
      unsetCurrentDevice : unsetCurrentDevice,
      setFirstDevice : setFirstDevice,
      getDeviceInfoForPrinter: getDeviceInfoForPrinter,
      downloadCertificate: downloadCertificate
    }
  }])
