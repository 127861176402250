prismanoteApp.factory('$data', ['$language', '$retailer', '$indexedDB', '$api', '$rootScope', '$q',
  function ($language, $retailer, $indexedDB, $api, $rootScope, $q) {
    // For create a new indexed db connection
    function createIndedDBNewConnection () {
      var tempdb = new Dexie('Prismanote3')

      // Create a customers table
      tempdb.version(1).stores({
        customers: '++_id'
      })

      tempdb.version(1).stores({
        brands: '_id'
      })

      // Create a transactions table
      tempdb.version(1).stores({
        transactions: '_id'
      })

      // Create a products table
      tempdb.version(1).stores({
        products: '_id'
      })

      // Create a orders table
      tempdb.version(1).stores({
        orders: '_id'
      })
      return tempdb
    };

    var db = createIndedDBNewConnection()

    function loadData (show, force) {
      logDataStatus('GETTING_DATA_FROM_SERVER')
      if (show) {
        $rootScope.dataLoading = true
      }
      if (localStorage.getItem('syncData')) {
        var now = moment()

        var syncData = JSON.parse(localStorage.getItem('syncData'))

        // When one of the data is older than 4 minutes, force a full sync
        if ((
        // (now.diff(syncData.products, 'minutes') < 0 || now.diff(syncData.products, 'minutes') > 4) ||
          (now.diff(syncData.customers, 'minutes') < 0 || now.diff(syncData.customers, 'minutes') > 4) ||
                    // (now.diff(syncData.orders, 'minutes') < 0 || now.diff(syncData.orders, 'minutes') > 4) ||
                    (now.diff(syncData.transactions, 'minutes') < 0 || now.diff(syncData.transactions, 'minutes') > 4) ||
                    // (now.diff(syncData.brands, 'minutes') < 0 || now.diff(syncData.brands, 'minutes') > 4) &&
                    force === false) ||
                    force === true
        ) {
          if (force === true) {
            logDataStatus('Full sync forced', false, true)
          } else {
            logDataStatus('Data is older than 4 minutes or is not synced yet', false, true)
          }

          force = true
        } else if (force === false) {
          logDataStatus('No sync needed, completing', false, true)
          return
        }
      } else {
        var syncData = {}
      }

      $api.get('dashboard-data', {
        shop: $rootScope.selectedShop,
        dates: syncData,
        force: force,
        data: null
      })
        .then(function (response) {
          logDataStatus('Data fetched from server, processing now...', false, true)
          // loadOrders(response.data.orders, force)
          //     .then(function () {

          loadCustomers(response.data.customers, force)
            .then(function () {
              // loadBrands(response.data.brands, force)
              //     .then(function () {

              loadTransactions(response.data.transactions, force)
                .then(function () {
                  dataLoadDone(true)
                })
                .catch(function () {
                  // loadTransactions
                  dataLoadDone(false)
                })
              // })
              // .catch(function () {
              //     //loadBrands
              //     dataLoadDone(false);
              // })
            })
            .catch(function () {
              // loadCustomers
              dataLoadDone(false)
            })
          // })
          // .catch(function () {
          //     //loadOrders
          //     dataLoadDone(false)
          // })
        })
        .catch(function (reason) {
          logDataStatus('Error while getting dashboard data ' + reason, true, true)
          dataLoadDone(false)
        })
    }

    function logDataStatus (message, error, hide) {
      if (error) {
        console.error('%cloadData', 'font-style:italic;', new Date().toLocaleTimeString(), '-', message)
      } else {
        console.info('%cloadData', 'font-style:italic;', new Date().toLocaleTimeString(), '-', message)
      }
      if (!hide) {
        $rootScope.dataStatus = $language.translate(message)
      }
    }

    function dataLoadDone (status, data) {
      if (status) {
        logDataStatus('SUCCESFULL_SAVING_LAST_DETAILS')
        if (!data) {
          var syncData = {
            products: new Date(),
            customers: new Date(),
            orders: new Date(),
            transactions: new Date(),
            brands: new Date()
          }
        } else {
          if (localStorage.getItem('syncData')) {
            var syncData = JSON.parse(localStorage.getItem('syncData'))
          } else {
            var syncData = {}
          }
          syncData[data] = new Date()
        }
        localStorage.setItem('syncData', JSON.stringify(syncData))
      }

      $rootScope.dataLoading = false
    }

    function loadOrders (orders, force) {
      return $q(function (resolve, reject) {
        logDataStatus('ORDERS')

        // First check if we have indexedDb connection or not.
        if (!db.isOpen()) {
          db = createIndedDBNewConnection()
        }

        if (force) {
          db.orders.clear()

          db.orders.bulkAdd(orders).then(function (lastKey) {
            return resolve(orders)
          }).catch(Dexie.BulkError, function (e) {
            return reject(e)
          })
        } else {
          db
            .table('orders')
            .toArray()
            .then(function (allOrders) {
              return resolve(allOrders)
            })
            .catch(function (error) {
              return reject(error)
            })
        }
      })
    }

    function loadCustomers (customers, force) {
      return $q(function (resolve, reject) {
        // logDataStatus('CUSTOMERS')
        // // First check if we have indexedDb connection or not.
        // if (!db.isOpen()) {
        //   db = createIndedDBNewConnection()
        // }
        //
        // if (force) {
        //   db.customers.clear()
        //
        //   db.customers.bulkAdd(customers).then(function (lastKey) {
            return resolve(customers)
        //   }).catch(Dexie.BulkError, function (e) {
        //     return reject(e)
        //   })
        // } else {
        //   db
        //     .table('customers')
        //     .toArray()
        //     .then(function (allCustomer) {
        //       return resolve(allCustomer)
        //     })
        //     .catch(function (error) {
        //       return reject(error)
        //     })
        // }
      })
    }

    function loadBrands (brands, force) {
      return $q(function (resolve, reject) {
        logDataStatus('BRANDS')

        // First check if we have indexedDb connection or not.
        if (!db.isOpen()) {
          db = createIndedDBNewConnection()
        }

        if (force) {
          db.brands.clear()

          db.brands.bulkAdd(brands).then(function (lastKey) {
            return resolve(brands)
          }).catch(Dexie.BulkError, function (e) {
            return reject(e)
          })
        } else {
          db
            .table('brands')
            .toArray()
            .then(function (allbrands) {
              return resolve(allbrands)
            })
            .catch(function (error) {
              return reject(error)
            })
        }
      })
    }

    function loadTransactions (transactions, force) {
      return $q(function (resolve, reject) {
        // logDataStatus('TRANSACTIONS')
        //
        // if (force) {
        //   db.transactions.clear()
        //
        //   db.transactions.bulkAdd(transactions).then(function (lastKey) {
            return resolve(transactions)
        //   }).catch(Dexie.BulkError, function (e) {
        //     return reject(e)
        //   })
        // } else {
        //   db
        //     .table('transactions')
        //     .toArray()
        //     .then(function (allTransactions) {
        //       return resolve(allTransactions)
        //     })
        //     .catch(function (error) {
        //       return reject(error)
        //     })
        // }
      })
    }

    function getCustomers (force, onlyGet) {
      return $q(function (resolve, reject) {
        logDataStatus('Get customers', false, true)
        // if (onlyGet) {
        //   if (!db.isOpen()) {
        //     db = createIndedDBNewConnection()
        //   }
        //
        //   db
        //     .table('customers')
        //     .toArray()
        //     .then(function (allCustomer) {
        //       return resolve(allCustomer)
        //     })
        //     .catch(function (error) {
        //       return reject(error)
        //     })
        //   return
        // }
        if (localStorage.getItem('syncData') && force === false) {
          var now = moment()
          var syncData = JSON.parse(localStorage.getItem('syncData'))

          if (now.diff(syncData.customers, 'minutes') > 4) {
            logDataStatus('Need to sync customers since the data is older than 4 minutes', false, true)

            $api.get('dashboard-data', {
              shop: $rootScope.selectedShop,
              dates: syncData,
              data: 'customers'
            })
              .then(function (response) {
                loadCustomers(response.data.customers, true)
                  .then(function (customers) {
                    dataLoadDone(true, 'customers')
                    return resolve(customers)
                  })
              })
              .catch(function (reason) {
                logDataStatus('Error while getting customers ' + reason, true, false)
                dataLoadDone(false)
                return reject(reason)
              })
          } else {
            logDataStatus('Data is not old, no need to get them again', false, true)

            if (!db.isOpen()) {
              db = createIndedDBNewConnection()
            }

            db
              .table('customers')
              .toArray()
              .then(function (allCustomer) {
                return resolve(allCustomer)
              })
              .catch(function (error) {
                return reject(error)
              })
          }
        } else {
          logDataStatus('No syncdata found, or force sync requested', false, true)
          $api.get('dashboard-data', {
            shop: $rootScope.selectedShop,
            dates: {},
            force: true,
            data: 'customers'
          })
            .then(function (response) {
              loadCustomers(response.data.customers, true)
                .then(function (customers) {
                  dataLoadDone(true, 'customers')
                  return resolve(customers)
                })
                .catch(function (reason) {
                  logDataStatus('Error while getting customers ' + reason, true, true)
                  return reject(reason)
                })
            })
        }
      })
    }

    function getTransactions (force) {
      return $q(function (resolve, reject) {
        logDataStatus('Get transactions', false, true)

        if (localStorage.getItem('syncData') && force === false) {
          var now = moment()
          var syncData = JSON.parse(localStorage.getItem('syncData'))

          if (now.diff(syncData.transactions, 'minutes') > 4) {
            logDataStatus('Need to sync transactions since the data is older than 4 minutes', false, true)

            $api.get('dashboard-data', {
              shop: $rootScope.selectedShop,
              dates: syncData,
              data: 'transactions'
            })
              .then(function (response) {
                loadTransactions(response.data.transactions, true)
                  .then(function (transactions) {
                    dataLoadDone(true, 'transactions')

                    return resolve(transactions)
                  })
              })
              .catch(function (reason) {
                logDataStatus('Error while getting transactions ' + reason, true, true)
                dataLoadDone(false)
                return reject(reason)
              })
          } else {
            logDataStatus('Data is not old, no need to get them again', false, true)

            if (!db.isOpen()) {
              db = createIndedDBNewConnection()
            }

            db
              .table('transactions')
              .toArray()
              .then(function (allTransactions) {
                return resolve(allTransactions)
              })
              .catch(function (error) {
                return reject(error)
              })
          }
        } else {
          logDataStatus('No syncdata found, or force sync requested', false, true)
          $api.get('dashboard-data', {
            shop: $rootScope.selectedShop,
            dates: {},
            force: true,
            data: 'transactions'
          })
            .then(function (response) {
              loadTransactions(response.data.transactions, true)
                .then(function (transactions) {
                  dataLoadDone(true, 'transactions')
                  return resolve(transactions)
                })
                .catch(function (reason) {
                  logDataStatus('Error while getting transactions ' + reason, true, true)
                  return reject(reason)
                })
            })
        }
      })
    }

    return {
      loadData: loadData,
      logDataStatus: logDataStatus,
      getCustomers: getCustomers,
      getTransactions: getTransactions
    }
  }])
