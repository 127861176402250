prismanoteApp.factory('campaignFact', ['$http', 'constantFact', function ($http, constantFact) {
  return {
    isinstagramPrev: false,
    currentWholesaler: '',
    campObj: {},
    updateCampaign: {},
    campBudgetCheckout: 0,
    welcome_camp: false,
    selectedBrandCampaign: [],
    changeLanguage: {
      images: [],
      pageName: '',
      description: '',
      url: '',
      headLine: '',
      newsFeed: '',
      callToAction: '',
      goal: '',
      minAge: '',
      maxAge: '',
      gender: '',
      budget: '',
      startDate: '',
      enddate: ''
    },
    postCampData: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook-ad-campaign',
        method: 'POST',
        data: obj
      })
        .then(function (addData) {
          // campArr = addData.data;
          return addData.data
        }, function (err) {
          return err
        })
    },
    postAdsetData: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook-adset',
        method: 'POST',
        data: obj
      })
        .then(function (addData) {
          // campArr = addData.data;
          return addData.data
        }, function (err) {
          return err
        })
    },
    getRegion: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook-region-code',
        method: 'POST',
        data: { country_code: obj }
      })
        .then(function (addData) {
          // campArr = addData.data;
          return addData.data
        }, function (err) {
          return err
        })
    },
    getCity: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook-city-code',
        method: 'POST',
        data: {
          query: obj.query,
          regionID: obj.regionID
        }
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    getAdInterest: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook-adInterests',
        method: 'POST',
        data: {
          query: obj.query
        }
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },

    uploadImg: function (obj, accountId) {
      return $http({
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined },
        url: constantFact.Url + accountId + '/adimages',
        method: 'POST',
        data: obj
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    getAdcreative: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook-adcreative',
        method: 'POST',
        data: obj
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    getAdReach: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook-adreach',
        method: 'POST',
        data: obj
      }).then(function (addData) {
        return addData.data
      }, function (err) {
        return err
      })
    },
    getAd: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook-ad',
        method: 'POST',
        data: obj
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    createfbOrder: function (obj) {
      return $http({
        url: '/api/create-fborder',
        data: obj,
        method: 'POST'
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    fbInsightsDB: function (obj) {
      return $http({
        url: '/api/save-fbInsights',
        data: obj,
        method: 'POST'
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    getDBinsight: function (obj) {
      return $http({
        url: '/api/getData-fbInsights',
        method: 'GET'
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    getWholesalers: function (obj) {
      return $http({
        url: '/api/fbCtrlWholesaler',
        method: 'GET'
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    createPayment: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/create-payment',
        method: 'POST',
        data: obj
      })
        .then(function (payment) {
          return payment.data
        }, function (err) {
          return err
        })
    },
    savePaymentID: function (paymentId, payId, amount) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/save-fbpaymentId',
        data: { paymentid: paymentId, payID: payId, amount: amount },
        method: 'POST'
      })
        .then(function (payment) {
          return payment.data
        }, function (err) {
          return err
        })
    },
    // not used at this time
    paymentWebhook: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/fbpayment-webhook',
        method: 'POST',
        data: { payment_id: obj }
      })
        .then(function (payment) {
          return payment.data
        }, function (err) {
          return err
        })
    },

    uploadImageByHash: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook-adimage',
        method: 'POST',
        data: obj
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    getFacebookPixel: function (actId, obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        // url: constantFact.Url + actId + '/adspixels',
        url: '/api/facebook/adspixels',
        method: 'POST',
        data: obj
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    getPageLikesFunc: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook-insights',
        method: 'POST',
        data: obj
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },

    deleteFbCampaign: function (campid, accessToken) {
      return $http({
        url: constantFact.Url + campid + '?access_token=' + accessToken,
        method: 'DELETE'
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },

    getCountry: function () {
      return $http({
        url: '/api/facebook-country-code',
        method: 'GET'
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },

    getAdminData: function () {
      return $http({
        url: '/api/facebook-admin-id',
        method: 'GET'
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },

    getadCreativePrevDesktop: function (createId, accessToken) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/prev',
        method: 'POST',
        data: { createId: createId, format: 'DESKTOP_FEED_STANDARD' }
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    getadCreativePrevMobile: function (createId, accessToken) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/prev',
        method: 'POST',
        data: { createId: createId, format: 'MOBILE_FEED_STANDARD' }
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    getadPrevRightColumn: function (createId, accessToken) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/prev',
        method: 'POST',
        data: { createId: createId, format: 'RIGHT_COLUMN_STANDARD' }
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    getadPrevInstagram: function (createId, accessToken) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/prev',
        method: 'POST',
        data: { createId: createId, format: 'INSTAGRAM_STANDARD' }
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },

    checkPayment: function (payId) {
      return $http({
        url: '/api/check-fbpayment/' + payId,
        method: 'GET'
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },

    getCustomLocation: function (pageId, accessToken) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/customlocation',
        method: 'POST',
        data: { pageId: pageId }
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    getInstaAccount: function (pageId, accessToken) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/intagram-account',
        method: 'POST',
        data: { pageId: pageId, pageToken: accessToken }
      })
        .then(function (addData) {
          if (addData.data) {
            return addData.data
          } else {
          }
        }, function (err) {
          return err
        })
    },
    getPageToken: function (pageId, accessToken) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/getpage-token',
        method: 'POST',
        data: { pageId: pageId }
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },

    readAdCampaign: function (actId, accessToken) {
      return $http({
        url: '/api/facebook/read-adcamp',
        method: 'GET'
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    campaignInsightss: function (actId, accessToken) {
      return $http({
        url: '/api/facebook/camp-insightss',
        method: 'GET'
      }).then(function (res) {
        return res.data
      }, function (err) {
        return err
      })
    },
    readAdsets: function (actId, accessToken) {
      return $http({
        url: '/api/facebook/read-adset',
        method: 'GET'
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    readAds: function (actId, accessToken) {
      return $http({
        url: '/api/facebook/read-ads',
        method: 'GET'
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    readInsight: function (actId, accessToken, date_preset) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/read-insight',
        method: 'POST',
        data: { date_preset: date_preset }
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    getCustomAudience: function () {
      return $http({
        url: '/api/facebook-audience',
        method: 'GET'
      })
        .then(function (addData) {
          return addData.data
        }, function (err) {
          return err
        })
    },
    adAccountInsights: function (actId, accessToken, date_preset) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/adaccount-insights',
        method: 'POST',
        data: { date_preset: date_preset }
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    campaignInsights: function (actId, accessToken, start_date, stop_date) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/camp-insights',
        method: 'POST',
        data: { start_date: start_date, stop_date: stop_date }
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    getcampaignDB: function (actId, accessToken, date_preset) {
      return $http({
        url: '/api/getData-campaignDB',
        method: 'GET'
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    getCampaignExecute: function (actId, accessToken, date_preset) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/campaign-execute',
        method: 'POST',
        data: { actId: actId }
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    getsocialPortal: function (actId, accessToken) {
      return $http({
        url: '/api/get-social-portalDB',
        method: 'GET'
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    totalSpent: function (actId, accessToken) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/totalspent',
        method: 'POST',
        data: { actId: actId }
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    getCampaignName: function (campId, accessToken) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/facebook/campaign-name',
        method: 'POST',
        data: { actId: actId }
      })
        .then(function (addData) {
          return addData
        }, function (err) {
          return err
        })
    },
    getPortalUserData: function (name) {
      return $http({
        url: '/api/get-portal-userdata',
        method: 'POST',
        data: { brand: name }
      })
        .then(function (data) {
          return data
        }, function (err) {
          return err
        })
    },
    fbAllowAccess: function (data) {
      return $http({
        url: '/api/fb-grant-access-brand',
        method: 'POST',
        data: data
      })
        .then(function (data) {
          return data
        }, function (err) {
          return err
        })
    },
    saveCreditInBrands: function (data, name) {
      return $http({
        url: '/api/fb-save-credit-brand',
        method: 'POST',
        data: { credit: data, brandName: name }
      })
        .then(function (data) {
          return data
        }, function (err) {
          return err
        })
    },
    postCreditInCampaign: function (brandname, email) {
      return $http({
        url: '/api/fb-credit-campaign',
        method: 'POST',
        data: { brandName: brandname, email: email }
      })
        .then(function (data) {
          return data
        }, function (err) {
          return err
        })
    },
    updateMarketingUser: function (data) {
      return $http({
        url: '/api/verify-marketing-user',
        method: 'POST',
        data: { data: data }
      })
        .then(function (data) {
          return data
        }, function (err) {
          return err
        })
    },
    isRegistered: function (data) {
      return $http({
        url: '/api/isregister-marketinguser',
        method: 'POST',
        data: { data: data }
      })
        .then(function (data) {
          return data
        }, function (err) {
          return err
        })
    },
    unLinkFacebook: function () {
      return $http({
        url: '/api/facebook/unlink-facebook',
        method: 'GET'
      })
        .then(function (data) {
          return data
        }, function (err) {
          return err
        })
    },
    getFbCurrency: function () {
      return $http({
        url: '/api/facebook/get-currency',
        method: 'GET'
      }).then(function (response) {
        return response
      }, function (err) {
        return err
      })
    },
    removeMarketingUser: function (id) {
      return $http({
        url: '/api/remove-marketinguser',
        method: 'POST',
        data: { id: id }
      })
        .then(function (data) {
          return data
        }, function (err) {
          return err
        })
    },
    updatePermission: function () {
      return $http({
        url: '/api/facebook/get-permission',
        method: 'GET'
      }).then(function (response) {
        return response
      }, function (err) {
        return err
      })
    },
    getFacebookInfo: function () {
      return $http({
        url: '/api/facebook/get-fbinfo',
        method: 'GET'
      }).then(function (response) {
        return response
      }, function (err) {
        return err
      })
    },
    getImgUrl: function (obj) {
      return $http({
        url: '/api/image-url',
        method: 'POST',
        data: { url: obj }
      })
        .then(function (data) {
          return data
        }, function (err) {
          return err
        })
    }

  }
}])
