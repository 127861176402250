prismanoteApp.service('$chat', ['$api', '$rootScope',
  function ($api, $rootScope) {
    function getChatDetail (body) {
      return $api.get('v2/chat-detail', body)
    }

    function addChatIntoRootScope (chatresponse) {
      if ($(window).width() > 1500) {
        $rootScope.openChats.unshift(chatresponse)
        $rootScope.openChats.splice(4)
      } else if ($(window).width() > 1024) {
        $rootScope.openChats.unshift(chatresponse)
        $rootScope.openChats.splice(3)
      } else if ($(window).width() > 767) {
        $rootScope.openChats.unshift(chatresponse)
        $rootScope.openChats.splice(2)
      } else if ($(window).width() > 370) {
        $rootScope.openChats.unshift(chatresponse)
        $rootScope.openChats.splice(1)
      }

      setTimeout(function(){
        //check if the window needs to be moved to the left due to an open help window
        //this needs to be a little bit later because the element needs to be added to the document
        var iframe = document.getElementById('jsd-widget')
        var idoc = iframe.contentDocument || iframe.contentWindow.document;
        var elem = idoc.getElementById('search-container')

        setTimeout(function(){
          var chatwindow = $('.single-chat')
          if (elem) {
            if (chatwindow) {
              chatwindow.css('margin-right', '375px')
            }
          } else {
            if (chatwindow) {
              chatwindow.css('margin-right', '75px')
            }
          }
        }, 50)
      }, 50)
    }

    function isChatAlreadyOpen (clickedremarkId) {
      try {
        if (!clickedremarkId) return true // If not remarkId avaialble then bad request so we are not opening the chat window

        var isChatAlreadyOpen = false
        if (!$rootScope.openChats || !$rootScope.openChats.length) $rootScope.openChats = []
        if ($rootScope.openChats.length) {
          for (var i = 0; i < $rootScope.openChats.length; i++) {
            var remarkId = $rootScope.openChats[i]._id
            if (remarkId === clickedremarkId) {
              isChatAlreadyOpen = true
              break
            }
          }
        }

        return isChatAlreadyOpen
      } catch (catherr) {
        console.error('catherr: ', catherr)
        return true
      }
    }

    function getCurrentUserDetail () {
      var currentUser = $rootScope.user
      if ($rootScope.currentEmployee) currentUser = $rootScope.currentEmployee
      var userDetail = {
        fullName: (currentUser.firstName || '') + ' ' + (currentUser.lastNamePrefix || '') + ' ' + (currentUser.lastName || ''),
        profileImage: currentUser.profileImage
      }

      return userDetail
    }

    function closeChatWindow (currentRemarkId) {
      for (var i = 0; i < $rootScope.openChats.length; i++) {
        var remarkId = $rootScope.openChats[i]._id
        if (remarkId === currentRemarkId) {
          $rootScope.openChats.splice(i, 1)
          break
        }
      }
    }

    return {
      getChatDetail: getChatDetail,
      addChatIntoRootScope: addChatIntoRootScope,
      isChatAlreadyOpen: isChatAlreadyOpen,
      getCurrentUserDetail: getCurrentUserDetail,
      closeChatWindow: closeChatWindow
    }
  }])
