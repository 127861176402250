prismanoteApp.service('$newCompany', ['$q', '$api', 'Upload', function ($q, $api, Upload) {
  function getCompanyBrands (companyId) {
    return $q(function (resolve, reject) {
      $api.get('v2/company/brands/' + companyId)
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  return {
    getCompanyBrands: getCompanyBrands
  }
}])
