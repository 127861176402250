prismanoteApp.service('$brandCampaign', ['$q', '$api', 'Upload', function ($q, $api, Upload) {
  function createCampaign (camapigndata, companyId) {
    return $q(function (resolve, reject) {
      var body = {
        name: camapigndata.name,
        startDate: camapigndata.startDate,
        endDate: camapigndata.endDate,
        brandId: camapigndata.brand._id,
        companyId: companyId,
        tasks: [
          {
            resultsBrand: {
              companyId: companyId
            },
            fbPromotionSettings: {
              facebook: {
                feeds: true,
                instantArticle: true,
                inStreamVideos: true,
                rightColumn: true,
                suggestedVideos: true,
                marketPlace: true,
                stories: false
              },
              instagram: {
                feeds: true,
                stories: false
              },
              audienceNetwork: {
                nativeBannerInterstitial: true,
                inStreamVideos: true,
                rewardedVideos: false
              },
              messenger: {
                inbox: true,
                stories: false
              }
            }
          }
        ]
      }

      $api.post('newcampaigns', body)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject({ showError: true, errorMessage: 'Something went wrong while creating campaign' })
        })
    })
  }

  function contentForConsumerUploadImage (media, campaignName) {
    return $q(function (resolve, reject) {
      var data = isValidMediaFn(media)
      console.log('media: ', media)
      if (data.isValidMedia) {
        Upload.upload({
          url: '/api/upload-consumer-images',
          data: {
            files: media
          }
        }).then(function (res) {
          // console.log('then', res);
          if (!res.data || !res.data.data || !res.data.data.length) {
            reject({ showError: true, errorMessage: 'Something went wrong while uploading media' })
          } else {
            console.log('res.data.data: ', res.data.data)
            var mediaToSend = []
            for (var i = 0; i < media.length; i++) {
              for (var j = 0; j < res.data.data.length; j++) {
                if (res.data.data[j] && res.data.data[j].name && media[i] && media[i].name) {
                  var originalmedianame = media[i].name
                  var mediaSplit = originalmedianame.split('.')
                  var extensionname = mediaSplit.pop().toLowerCase()
                  var medianame = mediaSplit.join('.')
                  console.log('checked: ', i, (slugify(medianame).toLowerCase() + '.' + extensionname) == (res.data.data[j].name).toLowerCase(), (slugify(medianame).toLowerCase() + '.' + extensionname), res.data.data[j].name)
                  // console.log('url matching: ', slugify(media[i].name).toLowerCase(), (res.data.data[j].name).toLowerCase());
                  if ((slugify(medianame).toLowerCase() + '.' + extensionname) == (res.data.data[j].name).toLowerCase()) {
                    media[i].name = originalmedianame
                    mediaToSend[i] = {
                      src: res.data.data[j].path,
                      alt: campaignName,
                      headline: media[i].heading,
                      description: media[i].description
                    }
                    break
                  }
                }
              }
            }
            console.log('finla: ', mediaToSend)
            resolve(mediaToSend)
          }
        }).catch(function (reason) {
          console.log('reason: ', reason)
          reject({ showError: true, errorMessage: 'Something went wrong while uploading media' })
        })
      } else {
        reject({ showError: true, errorMessage: data.message })
      }
    })
  }

  function contentForConsumer (body) {
    return $q(function (resolve, reject) {
      $api.put('newcampaigns', body)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (reason) {
          console.log('content for comsumer: ', reason)
          reject({ showError: true, errorMessage: 'Something went wrong while updaing contnet for consumer' })
        })
    })
  }

  function addTargetGroupSettings (body) {
    return $q(function (resolve, reject) {
      $api.put('add-target-group-settings', body)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (reason) {
          console.log('content for comsumer: ', reason)
          reject({ showError: true, errorMessage: 'Something went wrong while updaing contnet for consumer' })
        })
    })
  }

  function getItems (type) {
    return $q(function (resolve, reject) {
      $api.get(type)
        .then(function (response) {
          resolve(response.data[type])
        })
        .catch(function (reason) {
          console.log(reason)
          reject({ showError: true, errorMessage: 'Something went wrong while fetching' + type })
        })
    })
  }

  function searchItems (str, brands) {
    var matches = []
    brands.forEach(function (item) {
      if ((item.name && item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                (item.nameSlug && item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
        matches.push(item)
      }
    })
    return matches
  };

  function LandingPageUploadImage (media, newsname) {
    // console.log('LandingPageUploadImage: ', media);
    return $q(function (resolve, reject) {
      var data = isValidMediaFn(media)
      // console.log(data);
      if (data.isValidMedia) {
        Upload.upload({
          url: '/api/upload-consumer-images',
          data: {
            files: media
          }
        }).then(function (res) {
          console.log('res: ', res)
          if (!res.data || !res.data.data || !res.data.data.length) {
            reject({ showError: true, errorMessage: 'Something went wrong while uploading media' })
          } else {
            var mediaToSend = []
            for (var i = 0; i < media.length; i++) {
              var imageType = 'common'
              if (i == 2) imageType = 'shop'
              else if (i == 3) imageType = 'brand'

              for (var j = 0; j < res.data.data.length; j++) {
                // console.log('checked: ', i, (media[i].name.replace(/\s+/g, '-').toLowerCase()) == (res.data.data[j].name).toLowerCase(), (media[i].name.replace(/\s+/g, '-').toLowerCase()), res.data.data[j].name)
                // if (res.data.data[j] && res.data.data[j].name && media[i] && media[i].name && (slugify(media[i].name).toLowerCase()) == (res.data.data[j].name).toLowerCase()) {
                //     // if (res.data.data[j] && res.data.data[j].name && media[i] && media[i].name && (media[i].name.replace(/\s+/g, '-').toLowerCase()) == (res.data.data[j].name).toLowerCase()) {
                //     mediaToSend[i] = {
                //         src: res.data.data[j].path,
                //         alt: newsname,
                //         imageType: imageType
                //     }
                //     break;
                // }

                if (res.data.data[j] && res.data.data[j].name && media[i] && media[i].name) {
                  var originalmedianame = media[i].name
                  var mediaSplit = originalmedianame.split('.')
                  var extensionname = mediaSplit.pop().toLowerCase()
                  var medianame = mediaSplit.join('.')
                  console.log('checked: ', i, (slugify(medianame).toLowerCase() + '.' + extensionname) == (res.data.data[j].name).toLowerCase(), (slugify(medianame).toLowerCase() + '.' + extensionname), res.data.data[j].name)
                  // console.log('url matching: ', slugify(media[i].name).toLowerCase(), (res.data.data[j].name).toLowerCase());
                  if ((slugify(medianame).toLowerCase() + '.' + extensionname) == (res.data.data[j].name).toLowerCase()) {
                    media[i].name = originalmedianame
                    mediaToSend[i] = {
                      src: res.data.data[j].path,
                      alt: newsname,
                      imageType: imageType
                    }
                    break
                  }
                }
              }
            }
            // console.log('medaiTOsend: ', mediaToSend);
            resolve(mediaToSend)
          }
        }).catch(function (reason) {
          console.log('reason: ', reason)
          reject({ showError: true, errorMessage: 'Something went wrong while uploading media' })
        })
      } else {
        reject({ showError: true, errorMessage: data.message })
      }
    })
  }

  function AddNewsUsingLandignPage (newsItem) {
    return $q(function (resolve, reject) {
      $api.post('news', { newsItem: newsItem })
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          console.log('add news suign langin apge: ', reason)
          reject({ showError: true, errorMessage: 'Something went wrong while creating news' })
        })
    })
  }

  function saveRetailerEmail (body) {
    return $q(function (resolve, reject) {
      console.log('body: ', body.emailReceivers)
      var emailArray = body.emailReceivers.split(/[ , ; \n]+/)
      var isValiEmails = isValidEmailAddresses(emailArray)
      if (isValiEmails) {
        var bodyToSend = {
          campaignId: body._id,
          campaignStep: 'saveRetailerEmail',
          emailReceivers: {
            added: emailArray
          }
        }
        $api.put('newcampaigns', bodyToSend)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (reason) {
            console.log('content for comsumer: ', reason)
            reject({ showError: true, errorMessage: 'Something went wrong while saving retailer email address' })
          })
      } else {
        reject({ showError: true, errorMessage: 'Some emails is not valid. Please insert proper email address' })
      }
    })
  }

  function sentEmailToAdmin (body) {
    return $q(function (resolve, reject) {
      $api.put('newcampaigns', body)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (reason) {
          console.log('content for comsumer: ', reason)
          reject({ showError: true, errorMessage: 'Something went wrong while updaing contnet for consumer' })
        })
    })
  }

  function isValidMediaFn (media) {
    if (!media.length) {
      return { isValidMedia: false, message: 'Please upload media first' }
    } else {
      var isValidMedia = true
      var message = 'Perfect!'
      console.log(media.length)
      for (var i = 0; i < media.length; i++) {
        if (media[i] && media[i].type && !isValidMediaType(media[i].type)) {
          message = 'Please upload valid media'
          isValidMedia = false
          break
        }

        function isValidMediaType (type) {
          var typeArray = type.split('/')
          if (typeArray[0] == 'video' || typeArray[0] == 'image') return true
          else return false
        }
      }
      if (isValidMedia) return { isValidMedia: isValidMedia, message: message }
      else return { isValidMedia: isValidMedia, message: message }
    }
  }

  var isValidMediaTypeUploaded = function (media, type) {
    if (!media.length) {
      return { isValidMedia: false, message: 'Please upload media first' }
    } else if (type == 'carousel' || 'single-image') {
      var isValidMedia = true
      var message = 'Perfect!'
      for (var i = 0; i < media.length; i++) {
        if (!media[i] || !media[i].type || !isValidMediaType(media[i].type)) {
          message = 'Please upload valid media'
          isValidMedia = false
          break
        }

        function isValidMediaType (type) {
          var typeArray = type.split('/')
          if (typeArray[0] == 'image') return true
          else return false
        }
      }
      if (isValidMedia) return { isValidMedia: isValidMedia, message: message }
      else return { isValidMedia: isValidMedia, message: message }
    } else if (type == 'single-video') {
      var isValidMedia = true
      var message = 'Perfect!'
      for (var i = 0; i < media.length; i++) {
        if (!media[i] || !media[i].type || !isValidMediaType(media[i].type)) {
          message = 'Please upload valid media'
          isValidMedia = false
          break
        }

        function isValidMediaType (type) {
          var typeArray = type.split('/')
          if (typeArray[0] == 'video') return true
          else return false
        }
      }
      if (isValidMedia) return { isValidMedia: isValidMedia, message: message }
      else return { isValidMedia: isValidMedia, message: message }
    }
  }

  function isValidEmailAddresses (emailArray) {
    var emailPattern = /^\S+@(([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6})$/
    var isAllValidEmail = true
    for (var i = 0; i < emailArray.length; i++) {
      if (!emailPattern.test(emailArray[i])) {
        isAllValidEmail = false
        break
      }
    }
    return isAllValidEmail
  }

  var getCampaignsByCompanyId = function (companyId) {
    return $q(function (resolve, reject) {
      $api.get('get-campaigns-by-companyid/' + companyId).then(function (response) {
        resolve(response.data.data)
      }).catch(function (reason) {
        reject((reason || 'Something went wrong while fetching the campaign details'))
      })
    })
  }

  var updateCampaignById = function (campaignId, campaign) {
    return $q(function (resolve, reject) {
      $api.put('newcampaigns/' + campaignId, { campaign: campaign })
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (reason) {
          reject((reason || 'Something went wrong while updating campaign'))
        })
    })
  }

  function updateNewsById (body) {
    return $q(function (resolve, reject) {
      $api.post('updatenews', body)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (reason) {
          console.log('content for comsumer: ', reason)
          reject({ showError: true, errorMessage: 'Something went wrong while updaing news' })
        })
    })
  }

  function saveInvitationEmailDate (body) {
    return $q(function (resolve, reject) {
      $api.post('saveinvitation-emaildate', body)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          console.log('saveInvitationEmailDate: ', reason)
          reject({ showError: true, errorMessage: 'Something went wrong while creating news' })
        })
    })
  }

  function changeStatusofJoinigRetailer (body) {
    return $q(function (resolve, reject) {
      $api.post('changestatus-of-joinretailer', body)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          console.log('changeStatusofJoinigRetailer: ', reason)
          reject({ showError: true, errorMessage: 'Something went wrong while changing status' })
        })
    })
  }

  function slugify (text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }

  this.createCampaign = createCampaign
  this.contentForConsumerUploadImage = contentForConsumerUploadImage
  this.contentForConsumer = contentForConsumer
  this.getItems = getItems
  this.searchItems = searchItems
  this.LandingPageUploadImage = LandingPageUploadImage
  this.AddNewsUsingLandignPage = AddNewsUsingLandignPage
  this.addTargetGroupSettings = addTargetGroupSettings
  this.saveRetailerEmail = saveRetailerEmail
  this.sentEmailToAdmin = sentEmailToAdmin
  this.getCampaignsByCompanyId = getCampaignsByCompanyId
  this.updateCampaignById = updateCampaignById
  this.isValidMediaTypeUploaded = isValidMediaTypeUploaded
  this.updateNewsById = updateNewsById
  this.saveInvitationEmailDate = saveInvitationEmailDate
  this.changeStatusofJoinigRetailer = changeStatusofJoinigRetailer
}])
