prismanoteApp.service('$campaign', ['$q', '$api', function ($q, $api) {
  var getCampaignById = function (campaignId, body) {
    return $q(function (resolve, reject) {
      $api.get('newcampaigns/' + campaignId, body).then(function (response) {
        resolve(response.data.data)
      }).catch(function (reason) {
        reject((reason || 'Something went wrong while fetching the campaign details'))
      })
    })
  }

  var getJoiningRetailerListByCampaignId = function (campaignId) {
    return $q(function (resolve, reject) {
      $api.get('get-joiningretailer-list-by-companyid/' + campaignId).then(function (response) {
        resolve(response.data.data)
      }).catch(function (reason) {
        reject((reason || 'Something went wrong while fetching the campaign details'))
      })
    })
  }

  var campaignViewedByRetailer = function (campaignId, shopId) {
    return $q(function (resolve, reject) {
      var body = {
        campaignId: campaignId,
        shopId: shopId
      }
      $api.post('campaign-viewed-by-retailer', body).then(function (response) {
        resolve(response.data)
      }).catch(function (reason) {
        reject((reason || 'Something went wrong while fetching the campaign details'))
      })
    })
  }

  var joinTheCampaign = function (campaignId, taskId, shopId) {
    return $q(function (resolve, reject) {
      var body = {
        campaignId: campaignId,
        taskId: taskId,
        shopId: shopId
      }
      $api.post('join-campaign', body).then(function (response) {
        resolve(response.data)
      }).catch(function (reason) {
        reject((reason || 'Something went wrong while fetching the campaign details'))
      })
    })
  }

  var sendInvitationEmailManually = function (body) {
    return $q(function (resolve, reject) {
      $api.post('v2/shop/send-campaign-invitation-email-manually', body).then(function (response) {
        resolve(response)
      }).catch(function (reason) {
        reject((reason || 'Something went wrong while fetching the campaign details'))
      })
    })
  }

  var validateEmail = function (email) {
    return $q(function (resolve, reject) {
      var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      resolve(regex.test(String(email).toLowerCase()))
    })
  }

  var changeBrandCampaignSubcription = function (body) {
    return $q(function (resolve, reject) {
      $api.post('v2/retailer/change-brand-subscription-campaign', body).then(function (response) {
        resolve(response.data)
      }).catch(function (reason) {
        reject((reason || 'Something went wrong while changing the subscription'))
      })
    })
  }

  this.getCampaignById = getCampaignById
  this.campaignViewedByRetailer = campaignViewedByRetailer
  this.joinTheCampaign = joinTheCampaign
  this.getJoiningRetailerListByCampaignId = getJoiningRetailerListByCampaignId
  this.sendInvitationEmailManually = sendInvitationEmailManually
  this.validateEmail = validateEmail
  this.changeBrandCampaignSubcription = changeBrandCampaignSubcription
}])
