prismanoteApp.factory('adminProductsToMergeService', ['$scope', '$rootScope', '$q', function ($http, $q) {
  var obj = {}
  var serverPath = 'http://localhost:3000/api/'
  obj.searchProducts = function (searchParams) {
    console.log('Inside Service')
    var defer = $q.defer()
    $http.get(serverPath + 'products/search/' + searchParams)
      .then(function (success) {
        $q.response(success)
      }, function (err) {
        $q.reject(err)
      })
    return $q.promise
  }

  return obj
}])
