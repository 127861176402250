prismanoteApp.factory('$label', ['$q', '$api', '$log', '$jspm', '$rootScope', '$diamond', '$timeout', '$language', '$printnode',
  function ($q, $api, $log, $jspm, $rootScope, $diamond, $timeout, $language, $printnode) {

    function getLabels(shopId, labelId, readOnly) {
      return $q(function (resolve, reject) {
        if(!readOnly) {
          readOnly = false
        } else {
          shopId = null
        }
        $api.get('labels/'+ readOnly + (shopId ? '/' + shopId : '') + (labelId ? '/' + labelId : ''))
          .then(function (res) {
            return resolve(res.data.labels)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function getCompanyLabels(companyId, labelId, readOnly) {
      return $q(function (resolve, reject) {
        if (!readOnly) {
          readOnly = false
        } else {
          companyId = null
        }
        $api.get('labels/company/'+ readOnly + (companyId ? '/' + companyId : '') + (labelId ? '/' + labelId : ''))
          .then(function (res) {
            return resolve(res.data.labels)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function createLabel (label) {
      return $q(function(resolve, reject) {
        $api.post('labels', {label: label})
          .then(function (res) {
            return resolve(res.message)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function deleteLabel(id) {
      return $q(function (resolve, reject) {
        $api.delete('labels/false/null/' + id)
          .then(function(){
            return resolve('LABEL_DELETED')
          })
          .catch(function (reason){
            return reject(reason)
          })
      })
    }

    function getExampleDataObject () {
      return {
        '%%PRODUCT_NAME%%':'Ring Diamant',
        '%%SELLING_PRICE%%':'1234',
        '%%PRODUCT_NUMBER%%': 'KA123456',
        '%%ARTICLE_NUMBER%%' : '000001234',
        '%%BRAND_NAME%%': 'Kasius',
        '%%EAN%%':'8718834442003',
        '%%DIAMONDINFO%%': 'DI,SI2,H,0.13',
        '%%PRODUCT_WEIGHT%%':'3.02',
        '%%DESCRIPTION%%' : 'Ring diamant 0.13ct',
        '%%MY_OWN_COLLECTION%%':'Ringen',
        '%%VARIANTS_COLLECTION%%':'Ringen',
        '%%BRAND_COLLECTION1%%':'Ringen',
        '%%BRAND_COLLECTION2%%':'Goud',
        '%%TOTALCARATWEIGHT%%':'0.13',
        '%%LAST_DELIVIERY_DATE%%':'08-05-2020',
        '%%SUPPLIER_NAME%%' : 'Kasius NL',
        '%%SUPPLIER_CODE%%' : 'KAS',
        '%%SUGGESTED_RETAIL_PRICE%%': '5678',
        '%%PRODUCT_CATEGORY%%' : 'RINGEN',
        '%%PRODUCT_SIZE%%' : '20',
        '%%JEWEL_TYPE%%' : 'RING',
        '%%JEWEL_MATERIAL%%' : 'Goud',
        '%%STRAP_WIDTH%%' : '30mm',
        '%%STRAP_MATERIAL%%' : 'Staal'
      }
    }

    function getProductValue (value, product) {
      var name = false
      if (value === 'name') {
        name = true
        value = $rootScope.language + '.' + value
      } else if (value === 'brand.name') {
        // We need to check if their is an alias
        if (!product.brand) {
          return
        }
        var brand = _.get(product, 'brand')
        if (brand.alias) {
          return brand.alias
        }
        if (brand.name) {
          return brand.name
        }
        return ''
      }
      var productValue = _.get(product, value)
      if (value === 'lastPurchaseOrderDeliveryDate' && productValue !== null && typeof productValue !== 'undefined') {
        return new Date(productValue).toISOString()
      }
      if (productValue) {
        if (value === 'category' || value === 'jewel.type' || value === 'jewel.material' || value === 'strap.material') {
          return $language.translate(productValue)
        }
        if (value === 'size') {
          var type = _.get(product, 'jewel.type')
          if(type && (type === 'BRACELET' || type === 'NECKLACE')) {
            var val = parseFloat(productValue) / 10 + 'cm'
            if (val) {
              return val.toString().replace('.', ',')
            } else {
              return ''
            }
          } else {
            if (productValue) {
              return productValue.toString().replace('.', ',') + 'mm'
            } else {
              return ''
            }
          }
        }
		if(value === 'strap.width') {
			productValue = productValue.toString()+'mm'
		}
        return productValue
      } else {
        if (name) {
          return _.get(product, 'name')
        }
        return ''
      }
    }

    function getCollectionInfo(type, index, product) {
      try {
        if (!index) {
          if (!product || !product.collections || !product.collections[type]) {
            return null
          }
          for (var i = 0; i < product.collections[type].length; i++) {
            if ($rootScope.currentShop && $rootScope.currentShop._id) {
              if (product.collections[type][i].uploader.shopId === $rootScope.currentShop._id) {
                index = i
              }
            } else if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
              if (product.collections[type][i].uploader.companyId === $rootScope.currentCompany._id) {
                index = i
              }
            }
          }
        }

        if (!type || !product || !product.collections ||
          !product.collections[type] || product.collections[type].length === 0 ||
          (index > 0 && product.collections[type].length < index)) {
          return null
        }

        return product.collections[type][index]
      } catch (e) {
        return null;
      }
    }

    function getDiamondInfo (product) {
      if (product.diamonds && product.diamonds.length > 0 && product.diamonds[0]) {
        var diamond = product.diamonds[0]
        var gemKindSN = $diamond.getGemKindAbbreviation(diamond.gemKind)
        var diamondInfo = ''
        if (gemKindSN) {
          diamondInfo += gemKindSN + ','
        }
        if (diamond.gemPurity && diamond.gemPurity.code) {
          diamondInfo += diamond.gemPurity.code + ','
        }
        if (diamond.gemColor && diamond.gemColor.code) {

          if(String(diamond.gemColor.code).length === 1) {
            diamondInfo += diamond.gemColor.code + ','
          }
        }
        if (diamond.caratWeight) {
          diamondInfo += diamond.caratWeight
        }
        return diamondInfo
      } else {
        return null
      }
    }

    function getReplaceValue(name, product) {
      try {
        var placeholder = _.find($rootScope.labelPlaceholders, { name: name })
        if (placeholder) {
          if (placeholder.value === 'diamondinfo') {
            var value = getDiamondInfo(product)
          } else if (placeholder.value === 'ownCollection') {
            var collection = getCollectionInfo('privateLabelCollections', null, product)
            if (collection) {
              var value = collection[$rootScope.language].name
            }
          } else if (placeholder.value === 'variantsCollection') {
            var collection = getCollectionInfo('variantsCollection', null, product)
            if (collection) {
              var value = collection[$rootScope.language].name
            }
          } else if (placeholder.value === 'brandCollection1') {
            var collection = getCollectionInfo('brandCollections', null, product)

            if (collection) {
              var value = collection[$rootScope.language].name
            }
          } else if (placeholder.value === 'brandCollection2') {
            var collection = getCollectionInfo('brandCollections', 1, product)

            if (collection) {
              var value = collection[$rootScope.language].name
            }
          } else if (placeholder.value === 'weight') {
            var value = getProductValue(placeholder.value, product)
            if (value) {
              value += 'g'
            }
          } else if (placeholder.value === 'lastDeliveryDate') {
            var value = getProductValue('lastPurchaseOrderDeliveryDate', product)
          } else if (placeholder.value === 'suggestedRetailPrice') {
            var price = getProductValue(placeholder.value, product)
            var tax = getProductValue('suggestedRetailPriceVat', product)
            return price * (1 + (tax / 100))
          } else {
            var value = getProductValue(placeholder.value, product)
          }

          if (!value) {
            return ''
          }
          return value
        } else {
          return ''
        }
      } catch (e) {
        return '';
      }
    }

    function makeDataObjectForProduct(product) {
      try {
        var dataObject = {}
        for (var key in $rootScope.labelPlaceholders) {
          for (var key2 in $rootScope.labelPlaceholders[key]) {
            if (key2 === 'name') {
              var keyName = $rootScope.labelPlaceholders[key].name
              var value = getReplaceValue(keyName, product)
              if (keyName === '%%SELLING_PRICE%%' || keyName === '%%SUGGESTED_RETAIL_PRICE%%') {
                var price = parseFloat(value.toString().replace(',', '.'))
                value = price.toFixed(2).replace('.', ',')
              }

              if (!value || value === 'NaN') {
                value = ''
              }
              dataObject[keyName] = value
            }
          }
        }
        if (product.quantity > -1) {
          dataObject.quantity = product.quantity
        }
        return dataObject
      } catch (e) {
        $log.error('makeDataObjectForProduct error: ', e);
      }
    }

    function isValidJSON(json){
      try {
        JSON.parse(JSON.stringify(json))
        return true
      }catch(e){
        $log.error('Error when parsing label template json', e)
        return false
      }
    }

    function checkTemplateDate(dateCreated) {
      var result = true
      if(dateCreated && dateCreated !== '') {
        var pDate = moment(dateCreated)
        result = pDate.isBefore('2020-08-31')
      }
      return result
    }

    function makeLabel (template, data) {
      return $q(function (resolve, reject){
        //To be sure that the reference is removed we copy the variables
        var cTemplate = angular.copy(template)
        var cData = angular.copy(data)

        if(!isValidJSON(cTemplate)){
          return reject('TEMPLATE_JSON_IS_NOT_VALID')
        }

        var layout = template.layout_name && (template.layout_name.length > 1 && typeof template.layout_name === 'string')
        var label = new ZebraLabel(cTemplate)

        var printCommand = label.generateCommand(cTemplate, cData, checkTemplateDate(template.dateCreated), layout)

        return resolve({
          label: label,
          printCommand: printCommand,
          previewUrl: makePreview(label, template, data),
          quantity: cData.quantity ? cData.quantity : 1,
        })
      })
    }

    function makePreview (label, template, data) {
      var previewCommand = label.generatePreview(template, data)
      var location = window.location.origin
      return location + '/api/labels/preview?dpmm=' + label.dpmm.toString() + '&width=' + label.widthMm + '&height=' + label.heightMm + '&data=' + encodeURI(previewCommand)
    }

    function printRAW (command) {
      return $q(function (resolve, reject) {
          $printnode.printRawContent(command, $rootScope.currentDevice.labelPrinterSettings.printNode.printerId, $rootScope.currentDevice.labelPrinterSettings.printNode.deviceId, 1, {
            title: 'RAW Commando Print'
          })
            .then(function () {
              return resolve()
            })
            .catch(function (reason) {
              $log.error('label printnode error', reason)
              return reject(reason)
            })
      })
    }

    function printLabel (printCommand, quantity, template) {
      return $q(function (resolve, reject){
        if(!quantity) {
          quantity = 1
        }

        if(template) {
          $log.info('Sending layout to printer before printing')
          saveLabelToPrinterMemory(template)
            .then(function () {
              $timeout(function () {
                sendToPrinter()
                updateLabelPrinterSet(template._id)
              }, 1000)
            })
            .catch(function(reason){
              $log.error('Error while saving label to printer before printing', reason)
              return reject(reason)
            })

        } else {
          sendToPrinter()
        }

        function sendToPrinter() {
          printCommand = printCommand.replace('^XA', '^XA^PQ' + quantity)

          var JSPM = false
          var printerId = null
          var deviceId = null
          var company = false

          if($rootScope.currentShop && $rootScope.currentShop._id) {
            JSPM = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false
            if(!JSPM) {
              printerId = $rootScope.currentDevice.labelPrinterSettings.printNode.printerId
              deviceId = $rootScope.currentDevice.labelPrinterSettings.printNode.deviceId
            }
          } else {
            if($rootScope.currentCompany && $rootScope.currentCompany._id) {
              JSPM = !$rootScope.currentCompany.printNode || $rootScope.currentCompany.printNode.enabled === false
              if(!JSPM) {
                printerId = $rootScope.currentCompany.printers.labelPrinter.printerId
                deviceId = $rootScope.currentCompany.printers.labelPrinter.deviceId
                company = true
              }
            }
          }
            $printnode.printRawContent(printCommand, printerId, deviceId, 1, {
              title: 'Product label'
            }, company)
              .then(function () {
                return resolve()
              })
              .catch(function (reason) {
                $log.error('label printnode error', reason)
                return reject(reason)
              })
        }
      })
    }

    function saveLabelToPrinterMemory(template) {
      return $q(function (resolve, reject){
        if(!template.layout_name || template.layout_name === '') {
          return reject('TEMPLATE_HAS_NO_VALID_LAYOUT_NAME')
        }
        var label = new ZebraLabel(template)

        //push layout_name (in template) and layout_command = false to the printer to make a template which can be saved in the printers memory
        var printCommand = label.generateCommand(template, {  }, checkTemplateDate(template.dateCreated), false)

        var JSPM = false
        var printerId = null
        var deviceId = null
        var company = false

        if($rootScope.currentShop && $rootScope.currentShop._id) {
          JSPM = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false
          if(!JSPM) {
            printerId = $rootScope.currentDevice.labelPrinterSettings.printNode.printerId
            deviceId = $rootScope.currentDevice.labelPrinterSettings.printNode.deviceId
          }
        } else {
          if($rootScope.currentCompany && $rootScope.currentCompany._id) {
            JSPM = !$rootScope.currentCompany.printNode || $rootScope.currentCompany.printNode.enabled === false
            if(!JSPM) {
              printerId = $rootScope.currentCompany.printers.labelPrinter.printerId
              deviceId = $rootScope.currentCompany.printers.labelPrinter.deviceId
            }
            company = true
          }
        }

        var JSPM = false
        if($rootScope.currentShop && $rootScope.currentShop._id) {
          JSPM = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false
        }

          $printnode.printRawContent(printCommand, printerId, deviceId, 1, {
            title: 'Template update'
          }, company)
            .then(function () {
              return resolve()
            })
            .catch(function (reason) {
              $log.error('label printnode error', reason)
              return reject(reason)
            })
        
      })
    }

    function bulkPrintLabels (commandsArray) {
      return $q(function (resolve, reject){
        if(!commandsArray) {
          return reject('NO_COMMAND_SEND')
        }
        var cmdArray = []
        var counter = 0
        var command = ''

        var JSPM = false
        if($rootScope.currentShop) {
          JSPM = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false
        }
        for(var i = 0; i < commandsArray.length; i++) {
          var printCommand = commandsArray[i].command.replace('^XA', '^XA^PQ' + commandsArray[i].quantity)
          command += printCommand
          cmdArray.push({
            command: printCommand,
            quantity: 1,
          })
          counter++
          if(counter === commandsArray.length) {
              $printnode.printRawContent(command, $rootScope.currentDevice.labelPrinterSettings.printNode.printerId,  $rootScope.currentDevice.labelPrinterSettings.printNode.deviceId,1, {
                title: 'Label bulk print'
              })
                .then(function(result) {
                  return resolve()
                })
                .catch(function (reason) {
                  $log.error('Error printing bulk labels', reason)
                  return reject(reason)
                })
          }
        }
      })
    }

    function updateLabelPrinterSet (labelId) {
      $api.put('label/update/' + labelId, {})
        .then(function (result) {
          $log.info('Label updated')
        })
        .catch(function (reason){
          $log.error('ERROR_WHILE_UPDATING_LABEL', reason)
        })
    }

    return {
      getLabels: getLabels,
      getCompanyLabels: getCompanyLabels,
      createLabel: createLabel,
      deleteLabel: deleteLabel,
      getExampleDataObject: getExampleDataObject,
      makeLabel: makeLabel,
      printLabel: printLabel,
      bulkPrintLabels: bulkPrintLabels,
      makeDataObjectForProduct: makeDataObjectForProduct,
      saveLabelToPrinterMemory: saveLabelToPrinterMemory,
      checkTemplateDate: checkTemplateDate,
      printRAW: printRAW
    }
  }])
