prismanoteApp.factory('loadTranslations', ['$http', '$q', function ($http, $q) {
  return function (options) {
    return $q(function (resolve, reject) {
      if (!options || !options.url) {
        throw new Error('Couldn\'t use urlLoader since no url is given!')
      }
      var requestParams = { lang: options.key }

      return $http(angular.extend({
        url: options.url,
        params: requestParams,
        method: 'GET'
      }, options.$http))

        .then(function (result) {
          resolve(result.data.translations)
        })

        .catch(function (reason) {
          reject(reason)
        })
    })
  }
}])

prismanoteApp.config(['$translateProvider', function ($translateProvider) {
  $translateProvider.useCookieStorage()
  $translateProvider.useLoader('loadTranslations', { url: '/api/translation' })
  $translateProvider.determinePreferredLanguage()
  $translateProvider.fallbackLanguage(['en'])
  $translateProvider.useSanitizeValueStrategy('escape')
  // $translateProvider.useMissingTranslationHandler('missingTranslationsFactory');
}])

prismanoteApp.factory('missingTranslationsFactory', ['$http', '$q', function ($http, $q) {
  return function (translationID) {
    console.info('missing ' + translationID)

    // $http({
    // 	url: 'api/translation',
    // 	params: {kewyord: translationID},
    // 	method: 'PUT'
    // })
    // .then(function successCallback(result) {
    // 	console.log("RES", result);
    // }, function errorCallback(response){
    // 	console.log("error!", response);
    // });
  }
}])
