prismanoteApp.factory('$product', ['$rootScope', '$q', 'prompt', '$api', '$language',
  function ($rootScope, $q, prompt, $api, $language) {
    function deleteProduct (product) {
      return $q(function (resolve, reject) {
        var brand = $rootScope.currentShop.brands.find(
          function (brand) {
            return brand.nameSlug == product.brand.nameSlug
          }
        ) || null

        if (brand && brand.restricted) {
          prompt({
            title: $language.translate('DELETE_PRODUCT') + '?',
            message: $language.translate('YOU_ARE_OFFICIAL_DEALER_PRODUCT_STAYS_IN_ASSORTMENT')
          }).then(function () {
            product.stock = 0
            product.show = false

            saveProduct(product)
              .then(function (product) {
                resolve(product)
              })
              .catch(function (reason) {
                reject(reason)
              })
          }, function () {
            reject('Cancel')
          })
        } else {
          prompt({
            title: $language.translate('DELETE_PRODUCT') + '?',
            message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
            buttons: [
              { label: $language.translate('DELETE_PRODUCT'), primary: true, id: 0 },
              { label: $language.translate('STOP_SELLING_STOCK_TO_0'), primary: true, id: 1 },
              { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 2 }
            ]
          }).then(function (value) {
            if (value.id === 0) {
              deleteFromShop(function (err, result) {
                if (err) {
                  return reject(err)
                }
                return resolve(result)
              })

              function deleteFromShop (callback) {
                $api.post('shops/delete-product-from-shop', {
                  shop: $rootScope.currentShop._id,
                  product: product._id
                }).then(function (response) {
                  return callback(null, response.data)
                }).catch(function (reason) {
                  return callback(reason.data)
                })
              }
            } else if (value.id === 1) {
              product.stock = 0
              product.show = false
              saveProduct(product)
                .then(function (product) {
                  resolve(product)
                })
                .catch(function (reason) {
                  reject(reason)
                })
            }
          }, function () {
            reject('Cancel')
          })
        }
      })
    }

    function saveProduct (product) {
      return $q(function (resolve, reject) {
        $api.post('shop/save-shop-product', {
          shop: $rootScope.currentShop._id,
          product: product,
          language: $rootScope.language
        })
          .then(function (res) {
            if (!product.stock) {
              product.stock = 0
            }
            if (!product.discount) {
              product.discount = 0
            }

            resolve(product)
          })
          .catch(function (reason) {
            console.error('Error while saveProduct', reason)
            reject(reason.data)
          })
      })
    }

    function addNewProductInMainDatabase (data) {
      return $q(function (resolve, reject) {
        $api.post('product/add-new-product/', data)
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error while saveProduct', reason)
            return reject(reason)
          })
      })
    }

    function eanIsExistsOrNot (ean, productId, shopOrCompanyId) {
      return $q(function (resolve, reject) {
        $api.get('product/ean-number-exists-or-not/' + ean + '/' + productId, { shopId: shopOrCompanyId, companyId: shopOrCompanyId })
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting Ean valid', reason)
            return reject(reason.data)
          })
      })
    }

    function productNumberIsExistsOrNot (productNumber, productId) {
      return $q(function (resolve, reject) {
        $api.get('product/product-number-exists-or-not', { productNumber: productNumber, productId: productId })
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting Ean valid', reason)
            return reject(reason.data)
          })
      })
    }

    function sendDeleteSuggestionToAdmin (productId, currentShopId) {
      return $q(function (resolve, reject) {
        $api.post('product/delete-suggestion', { productId: productId, shopId: currentShopId })
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (err) {
            return reject(err)
          })
      })
    }

    function getMergedRequestProducts (primaryProductId, secondaryProductId) {
      return $q(function (resolve, reject) {
        $api.get('products/merge-products/' + primaryProductId + '/' + secondaryProductId)
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting Ean valid', reason)
            return reject(reason.data)
          })
      })
    }

    function getProductWithSuggestionsNotOwnShop (shopId, productId) {
      return $q(function (resolve, reject) {
        $api.get('shop/products/without-own-suggestions/' + shopId + '/' + productId)
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting Ean valid', reason)
            return reject(reason.data)
          })
      })
    }

    function getValidSuggestionFromSuggestionId (suggestionsId) {
      return $q(function (resolve, reject) {
        $api.post('product/suggestions/', { suggestionsId: suggestionsId })
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting Ean valid', reason)
            return reject(reason.data)
          })
      })
    }

    function updateProduct (nameSlug, product, newCollections, oldCollections, suggester, autoGenerateARNo) {
      return $q(function (resolve, reject) {
        $api.put('product/shop/updateProduct', { product: product, nameSlug: nameSlug, newCollection: newCollections, oldCollections: oldCollections, suggester: suggester, autoGenerateARNo: autoGenerateARNo })
          .then(function (res) {
            return resolve(res)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function getProductsForMerged (primaryProductId, secondaryProductId, currentShopId) {
      return $q(function (resolve, reject) {
        $api.get('products/for-merged/' + primaryProductId + '/' + secondaryProductId + '/' + currentShopId)
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting Ean valid', reason)
            return reject(reason.data)
          })
      })
    }

    function addProductForMergr (params) {
      return $q(function (resolve, reject) {
        $api.put('suggest-merge-product/', params)
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting Ean valid', reason)
            return reject(reason.data)
          })
      })
    }

    function getProducts (body) {
      return $q(function (resolve, reject) {
        $api.get('v2/admin/products', body)
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting ADMIN Products', reason)
            return reject(reason.data)
          })
      })
    }

    function getProductWithSuggestions (body) {
      return $q(function (resolve, reject) {
        $api.get('v2/admin/product', body)
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting ADMIN product', reason)
            return reject(reason.data)
          })
      })
    }

    function updateProductByAdmin (body) {
      console.log('Get Product', body)
      return $q(function (resolve, reject) {
        $api.put('v2/admin/product', { product: body })
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting ADMIN product', reason)
            return reject(reason.data)
          })
      })
    }

    function getProductWithDeleteSuggestions () {
      return $q(function (resolve, reject) {
        $api.get('product/delete-suggestion')
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error While Getting ADMIN products delete Suggestions', reason)
            return reject(reason.data)
          })
      })
    }

    function fetchAdminProductForVerfiy(body) {
      return $api.get('v2/admin/fetch-admiin-product-verify', body)
    }

    function fetchTheDuplicateEanNumber() {
      return $api.get('v2/admin/fetch-duplicate-ean-number')
    }

    function fetchTheDuplicateNameslug() {
      return $api.get('v2/admin/fetch-duplicate-ean-number')
    }

    function fetchTheDuplicateProductNumber() {
      return $api.get('v2/admin/fetch-duplicate-product-number')
    }

    function getProductDetailForAdminMerge(body) {
      return $api.get('v2/admin/product-detail', body)
    }

    function fetchAdminMergeProductDetail(body) {
      return $api.get('v2/admin/fetch-admin-merge-product', body)
    }

    function mergeProductInAllShopProducts(body) {
      return $api.post('v2/admin/merge-shop-product', body)
    }

    function fetchAdminMergeRecords(body) {
      return $api.get('v2/admin/product-merge-record', body)
    }

    function removeBrandFromProduct(body) {
      return $api.post('v2/admin/remove-brand-from-product', body)
    }

    function isProductExistCheckByProductNumber(body) {
      return $api.get('v2/retailer/product-exist-check-by-productnumber', body)
    }

    function robotForAdminProductVerification(body) {
      return $api.post('v2/admin/products-verify', body)
    }

    function fetchDuplicateCollectionsList(body) {
      return $api.get('v2/admin/duplicate-collections-list', body)
    }

    function fetchProductPrivateLableCollectionByShop(body) {
      return $api.post('v2/retailer/fetch-product-privatelable-by-shop', body)
    }

    return {
      deleteProduct: deleteProduct,
      saveProduct: saveProduct,
      addNewProductInMainDatabase: addNewProductInMainDatabase,
      eanIsExistsOrNot: eanIsExistsOrNot,
      productNumberIsExistsOrNot: productNumberIsExistsOrNot,
      sendDeleteSuggestionToAdmin: sendDeleteSuggestionToAdmin,
      getMergedRequestProducts: getMergedRequestProducts,
      getProductWithSuggestionsNotOwnShop: getProductWithSuggestionsNotOwnShop,
      getValidSuggestionFromSuggestionId: getValidSuggestionFromSuggestionId,
      updateProduct: updateProduct,
      getProductsForMerged: getProductsForMerged,
      addProductForMergr: addProductForMergr,
      getProducts: getProducts,
      getProductWithSuggestions: getProductWithSuggestions,
      updateProductByAdmin: updateProductByAdmin,
      getProductWithDeleteSuggestions: getProductWithDeleteSuggestions,
      fetchAdminProductForVerfiy: fetchAdminProductForVerfiy,
      fetchTheDuplicateEanNumber: fetchTheDuplicateEanNumber,
      fetchTheDuplicateProductNumber: fetchTheDuplicateProductNumber,
      fetchTheDuplicateNameslug: fetchTheDuplicateNameslug,
      getProductDetailForAdminMerge: getProductDetailForAdminMerge,
      fetchAdminMergeProductDetail: fetchAdminMergeProductDetail,
      mergeProductInAllShopProducts: mergeProductInAllShopProducts,
      fetchAdminMergeRecords: fetchAdminMergeRecords,
      removeBrandFromProduct: removeBrandFromProduct,
      isProductExistCheckByProductNumber: isProductExistCheckByProductNumber,
      robotForAdminProductVerification: robotForAdminProductVerification,
      fetchDuplicateCollectionsList: fetchDuplicateCollectionsList,
      fetchProductPrivateLableCollectionByShop: fetchProductPrivateLableCollectionByShop
    }
  }])
