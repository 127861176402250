prismanoteApp.service('$retailerFilters', ['$q', '$api', function ($q, $api) {
  function setCategoryFilter (categoryFilter) {
    // set in localStorage
    // console.log('Category Filter Current', categoryFilter)
    localStorage.setItem('categroryFilter', JSON.stringify(categoryFilter))
  }

  function getCategoryFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('categroryFilter'))
    })
  }

  function removeCategoryFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('categroryFilter')
      return resolve(true)
    })
  }

  function setGenderFilter (genderFilter) {
    // set in localStorage
    // console.log('file--- retailerfilters-service.js !!');
    // console.log('------ getGenderFilter!!');
    // console.log('Gender Filter Current')
    // console.log(JSON.stringify(genderFilter, null, 2));
    // console.log(JSON.stringify(localStorage, null, 2));
    localStorage.setItem('genderFilter', JSON.stringify(genderFilter))
    // console.log(JSON.stringify(localStorage, null, 2));
  }

  function getGenderFilter () {
    // console.log('file--- retailerfilters-service.js !!');
    // console.log('------ getGenderFilter!!');
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('genderFilter'))
    })
  }

  function removeGenderFilter () {
    // console.log('------------- removeGenderFilter!!!');
    return $q(function (resolve, reject) {
      // console.log(localStorage.getItem('genderFilter'));
      localStorage.removeItem('genderFilter')
      // console.log(localStorage.getItem('genderFilter'));
      return resolve(true)
    })
  }

  function setParticularsFilter (particularsFilter) {
    // set in localStorage
    // console.log('Particulars Filter Current', particularsFilter)
    localStorage.setItem('particularsFilter', JSON.stringify(particularsFilter))
  }

  function getParticularsFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('particularsFilter'))
    })
  }

  function removeParticularsFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('particularsFilter')
      return resolve(true)
    })
  }

  function setProductFilter (categoryFilter) {
    // set in localStorage
    localStorage.setItem('productFilter', JSON.stringify(categoryFilter))
  }

  function getProductFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('productFilter'))
    })
  }

  function removeProductFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('productFilter')
      return resolve(true)
    })
  }

  function setIndicationFilter (indicationFilter) {
    // set in localStorage
    // console.log('Indication Filter Current', indicationFilter)
    localStorage.setItem('indicationFilter', JSON.stringify(indicationFilter))
  }

  function getIndicationFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('indicationFilter'))
    })
  }

  function removeIndicationFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('indicationFilter')
      return resolve(true)
    })
  }

  function setStrapTypeFilter (strapTypeFilter) {
    // set in localStorage
    // console.log('Category Filter Current', strapTypeFilter)
    localStorage.setItem('strapTypeFilter', JSON.stringify(strapTypeFilter))
  }

  function getStrapTypeFilterFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('strapTypeFilter'))
    })
  }

  function removeStrapTypeFilterFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('strapTypeFilter')
      return resolve(true)
    })
  }

  function setSupplierFilter (supplierFilter) {
    // set in localStorage
    // console.log('Category Filter Current', supplierFilter)
    localStorage.setItem('supplierFilter', JSON.stringify(supplierFilter))
  }

  function getSupplierFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('supplierFilter'))
    })
  }

  function removeSupplierFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('supplierFilter')
      return resolve(true)
    })
  }

  function setPriceFilter (priceFilter) {
    // set in localStorage
    // console.log('Category Filter Current', priceFilter)
    localStorage.setItem('priceFilter', JSON.stringify(priceFilter))
  }

  function getPriceFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('priceFilter'))
    })
  }

  function removePriceFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('priceFilter')
      return resolve(true)
    })
  }

  function setWidthOfStrapFilter (strapWidthFilter) {
    // set in localStorage
    localStorage.setItem('strapWidthFilter', JSON.stringify(strapWidthFilter))
  }

  function getWidthOfStrapFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('strapWidthFilter'))
    })
  }

  function removeWidthOfStrapFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('strapWidthFilter')
      return resolve(true)
    })
  }

  function setCollectionFilter (strapWidthFilter) {
    // set in localStorage
    localStorage.setItem('collectionFilter', JSON.stringify(strapWidthFilter))
  }

  function getCollectionFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('collectionFilter'))
    })
  }

  function removeCollectionFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('collectionFilter')
      return resolve(true)
    })
  }

  function setBrandFilter (brandFilter) {
    // set in localStorage
    localStorage.setItem('brandFilter', JSON.stringify(brandFilter))
  }

  function getBrandFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('brandFilter'))
    })
  }

  function removeBrandFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('brandFilter')
      return resolve(true)
    })
  }

  function setClientCollectionFilter (collectionFilter) {
    // set in localStorage
    localStorage.setItem('clientGroupCollectionFilter', JSON.stringify(collectionFilter))
  }

  function getClientCollectionFilter () {
    // have to get from localStorage
    return $q(function (resolve, reject) {
      return resolve(localStorage.getItem('clientGroupCollectionFilter'))
    })
  }

  function removeClientCollectionFilter () {
    return $q(function (resolve, reject) {
      localStorage.removeItem('clientGroupCollectionFilter')
      return resolve(true)
    })
  }

  this.setClientCollectionFilter = setClientCollectionFilter
  this.getClientCollectionFilter = getClientCollectionFilter
  this.removeClientCollectionFilter = removeClientCollectionFilter

  this.setCategoryFilter = setCategoryFilter
  this.getCategoryFilter = getCategoryFilter
  this.removeCategoryFilter = removeCategoryFilter

  this.setGenderFilter = setGenderFilter
  this.getGenderFilter = getGenderFilter
  this.removeGenderFilter = removeGenderFilter

  this.setParticularsFilter = setParticularsFilter
  this.getParticularsFilter = getParticularsFilter
  this.removeParticularsFilter = removeParticularsFilter

  this.setProductFilter = setProductFilter
  this.getProductFilter = getProductFilter
  this.removeProductFilter = removeProductFilter

  this.setIndicationFilter = setIndicationFilter
  this.getIndicationFilter = getIndicationFilter
  this.removeIndicationFilter = removeIndicationFilter

  this.setStrapTypeFilter = setStrapTypeFilter
  this.getStrapTypeFilterFilter = getStrapTypeFilterFilter
  this.removeStrapTypeFilterFilter = removeStrapTypeFilterFilter

  this.setSupplierFilter = setSupplierFilter
  this.getSupplierFilter = getSupplierFilter
  this.removeSupplierFilter = removeSupplierFilter

  this.setPriceFilter = setPriceFilter
  this.getPriceFilter = getPriceFilter
  this.removePriceFilter = removePriceFilter

  this.setWidthOfStrapFilter = setWidthOfStrapFilter
  this.getWidthOfStrapFilter = getWidthOfStrapFilter
  this.removeWidthOfStrapFilter = removeWidthOfStrapFilter

  this.setCollectionFilter = setCollectionFilter
  this.getCollectionFilter = getCollectionFilter
  this.removeCollectionFilter = removeCollectionFilter

  this.setBrandFilter = setBrandFilter
  this.getBrandFilter = getBrandFilter
  this.removeBrandFilter = removeBrandFilter
}])
