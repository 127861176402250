prismanoteApp.service('$brandShopSettings', ['$api', '$rootScope', 'countryService',
    function ($api, $rootScope, countryService) {

        // Seraching the brands
        function searchBrands(brands, str) {
            var matches = [];
            brands.forEach(function (brand) {
                if ((brand[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (brand[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
                    matches.push(brand);
                }
            })
            return matches;
        }

        // Searching the companies
        function searchSuppliersOrRepairer(companies, str) {
            var matches = [];
			var addNewCollectionItem = {
				en: { 
					name: " ➕ Add New Collection"
				},
				nl: {
					name: " ➕ Voeg nieuwe collectie toe"
				},
				es: {
					name: " ➕ AÃ±adir colecciÃ³n"
				},
				fr: {
					name: " ➕ Ajouter une collection"
				},
				de: {
					name: " ➕ Kollektion hinzufÃ¼gen"
				}
			};

			matches.push(addNewCollectionItem[$rootScope.language]);
            
            if (companies != undefined && companies.length > 0) {
                companies.forEach(function (item) {
                    if ((item.name && item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                        (item.nameSlug && item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
                        matches.push(item);
                    }
                });
            }
            return matches;
        }

        // Here are we are getting useLanguage companies first always then as it is
        // And also getting country name using the countryCode of company.address.countryCode
        function getCompanyWithCountryName(companyCountries) {
            if (!companyCountries || !companyCountries.length) return [];
            var companiesLength = companyCountries.length;
            var currentUserLanguage = ($rootScope.language || 'EN').toUpperCase();
            // console.log('currentUserLanguage: ', currentUserLanguage, companyCountries);
            var countries = countryService.getTranslatedCountryList(currentUserLanguage);
            // console.log('countries: ', countries);
            var userlangCompany;
            for (var i = 0; i < companiesLength; i++) {
                // console.log(i, companyCountries[i]);
                var countryCode = companyCountries[i]._id || '';
                companyCountries[i]['country'] = getCountryName(countries, countryCode);
                // console.log(companyCountries[i]['country'], 1, countryCode, 2, currentUserLanguage)
                if (countryCode.toUpperCase() === currentUserLanguage) {
                    userlangCompany = {
                        company: companyCountries[i],
                        index: i
                    };
                }
            }
            if (userlangCompany && userlangCompany.company) {
                companyCountries.splice(userlangCompany.index, 1);
                companyCountries.unshift(userlangCompany.company);
            }
            return companyCountries;
        }

        // Getting the contry name using the country code
        function getCountryName(countries, countryCode) {
            var countryName = '';
            // console.log('getCountryName: ', countries, countryCode);
            for (var i in countries) {
                // console.log(i, countryCode);
                if (i === countryCode) {
                    countryName = countries[i];
                    break;
                }
            }

            // console.log('ccountryName: ', countryName);
            return countryName;
        }

        // Add selected Company in the view and default selected as fav supplier
        function addSelectedCompany(companies, selectedCompany) {
            //console.log('addSelectedCompany: ', companies, selectedCompany);
            var selectedCompanyCountryCode = selectedCompany.address && selectedCompany.address.country ? selectedCompany.address.country : null;
            //console.log('selectedCompanyCountryCode ', selectedCompanyCountryCode);
            var isCountryCodeExist = false;
            var isCompanyAlreadyExist = false;
            for (var i = 0; i < companies.length; i++) {
                var companiesPerLang = companies[i].companies;
                var companiesPerLangLength = companiesPerLang.length;
                for (var j = 0; j < companiesPerLangLength; j++) {
                    companiesPerLang[j].isFavouriteSupplier = false;
                    if (companiesPerLang[j]._id === selectedCompany._id) {
                        //console.log('Already Exist');
                        isCompanyAlreadyExist = true;
                        isCountryCodeExist = true;
                        companiesPerLang[j].isFavouriteSupplier = true;
                    }
                }
                if (companies[i]._id == selectedCompanyCountryCode && !isCompanyAlreadyExist) {
                    //console.log('YOu here really?');
                    isCountryCodeExist = true;
                    companies[i].companies.push(selectedCompany);
                }
                isCompanyAlreadyExist = false;
            }

            //console.log('isCountryCodeExist: ', isCountryCodeExist);
            if (!isCountryCodeExist) {
                var currentUserLanguage = ($rootScope.language || 'EN').toUpperCase();
                var countries = countryService.getTranslatedCountryList(currentUserLanguage);
                var company = {
                    _id: selectedCompanyCountryCode,
                    country: getCountryName(countries, selectedCompanyCountryCode),
                    companies: [selectedCompany]
                }
                companies.push(company);
            }

            // console.log('companies: ', companies);
            return companies;
        }

        function getBrands() {
            return $api.get('brands');
        }

        function getShopBrandsByShopId(body) {
            return $api.get('v2/retailer/shop-brands', body);
        }

        function deleteBrandInShop(body) {
            return $api.post('remove-brand-from-shop', body)
        }

        this.getBrands = getBrands;
        this.searchBrands = searchBrands;
        this.getShopBrandsByShopId = getShopBrandsByShopId;
        this.getCompanyWithCountryName = getCompanyWithCountryName;
        this.searchSuppliersOrRepairer = searchSuppliersOrRepairer;
        this.addSelectedCompany = addSelectedCompany;
        this.deleteBrandInShop = deleteBrandInShop;
    }
]);