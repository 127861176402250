prismanoteApp.service('$diamond', ['$q', '$api', function ($q, $api) {
  var getGemKinds = function () {
    return [{
      abbreviation: 'AG',
      key: 'agate',
      value: 'agate'
    }, {
      abbreviation: 'AZ',
      key: 'amazone',
      value: 'amazone'
    }, {
      abbreviation: 'AZN',
      key: 'amazonite',
      value: 'amazonite'
    }, {
      abbreviation: 'AB',
      key: 'amber',
      value: 'amber'
    }, {
      abbreviation: 'AM',
      key: 'amethyst',
      value: 'amethyst'
    }, {
      abbreviation: 'AQ',
      key: 'aquamarine',
      value: 'aquamarine'
    }, {
      abbreviation: 'AV',
      key: 'aventurine',
      value: 'aventurine'
    }, {
      abbreviation: 'BR',
      key: 'beryll',
      value: 'beryll'
    }, {
      key: 'bloodcoral',
      value: 'bloodcoral'
    }, {
      abbreviation: 'CC',
      key: 'calcite',
      value: 'calcite'
    }, {
      abbreviation: 'CM',
      key: 'cameo',
      value: 'cameo'
    }, {
      abbreviation: 'CA',
      key: 'carnelean',
      value: 'carnelean'
    }, {
      key: 'cateye',
      value: 'cateye'
    }, {
      abbreviation: 'CS',
      key: 'ceramics',
      value: 'ceramics'
    }, {
      abbreviation: 'CH',
      key: 'chalcedony',
      value: 'chalcedony'
    }, {
      abbreviation: 'CI',
      key: 'citrine',
      value: 'citrine'
    }, {
      abbreviation: 'CD',
      key: 'corundum',
      value: 'corundum'
    }, {
      abbreviation: 'CR',
      key: 'crystal',
      value: 'crystal'
    }, {
      abbreviation: 'DI',
      key: 'diamond',
      value: 'diamond'
    }, {
      abbreviation: 'EM',
      key: 'emerald',
      value: 'emerald'
    }, {
      abbreviation: 'EN',
      key: 'enamel',
      value: 'enamel'
    }, {
      abbreviation: 'EP',
      key: 'epidote',
      value: 'epidote'
    }, {
      key: 'firepost',
      value: 'firepost'
    }, {
      abbreviation: 'FL',
      key: 'fluorite',
      value: 'fluorite'
    }, {
      abbreviation: 'GR',
      key: 'garnet',
      value: 'garnet'
    }, {
      abbreviation: 'GL',
      key: 'glass',
      value: 'glass'
    }, {
      abbreviation: 'HT',
      key: 'heliotrope',
      value: 'heliotrope'
    }, {
      abbreviation: 'HE',
      key: 'hematite',
      value: 'hematite'
    }, {
      key: 'irisquartz',
      value: 'irisquartz'
    }, {
      abbreviation: 'JA',
      key: 'jade',
      value: 'jade'
    }, {
      abbreviation: 'JG',
      key: 'jargon',
      value: 'jargon'
    }, {
      abbreviation: 'JP',
      key: 'jasper',
      value: 'jasper'
    }, {
      abbreviation: 'LB',
      key: 'labradorite',
      value: 'labradorite'
    }, {
      abbreviation: 'LA',
      key: 'lapis',
      value: 'lapis'
    }, {
      abbreviation: 'LZ',
      key: 'lazuli',
      value: 'lazuli'
    }, {
      abbreviation: 'LT',
      key: 'lazurite',
      value: 'lazurite'
    }, {
      key: 'lemonquartz',
      value: 'lemonquartz'
    }, {
      key: 'madeiracitrine',
      value: 'madeiracitrine'
    }, {
      abbreviation: 'ML',
      key: 'malachite',
      value: 'malachite'
    }, {
      abbreviation: 'MS',
      key: 'moonstone',
      value: 'moonstone'
    }, {
      key: 'muranoglass',
      value: 'muranoglass'
    }, {
      key: 'naturalstone',
      value: 'naturalstone'
    }, {
      abbreviation: 'NP',
      key: 'nephrite',
      value: 'nephrite'
    }, {
      abbreviation: 'ON',
      key: 'onyx',
      value: 'onyx'
    }, {
      abbreviation: 'OP',
      key: 'opal',
      value: 'opal'
    }, {
      abbreviation: 'PL',
      key: 'pearl',
      value: 'pearl'
    }, {
      abbreviation: 'PA',
      key: 'pearl akoya',
      value: 'pearl akoya'
    }, {
      abbreviation: 'PT',
      key: 'pearl tahiti',
      value: 'pearl tahiti'
    }, {
      abbreviation: 'PW',
      key: 'pearl sweet water',
      value: 'pearl sweet water'
    }, {
      abbreviation: 'PS',
      key: 'pearl south sea',
      value: 'pearl south sea'
    }, {
      abbreviation: 'PY',
      key: 'pearl synthetical',
      value: 'pearl synthetical'
    }, {
      abbreviation: 'PC',
      key: 'pearl cultive',
      value: 'pearl cultive'
    }, {
      abbreviation: 'PE',
      key: 'peridote',
      value: 'peridote'
    }, {
      abbreviation: 'PH',
      key: 'phosphorite',
      value: 'phosphorite'
    }, {
      abbreviation: 'QU',
      key: 'quartz',
      value: 'quartz'
    }, {
      abbreviation: 'RS',
      key: 'rhinestone',
      value: 'rhinestone'
    }, {
      abbreviation: 'RC',
      key: 'rockcrystal',
      value: 'rockcrystal'
    }, {
      abbreviation: 'RQ',
      key: 'rosequartz',
      value: 'rosequartz'
    }, {
      abbreviation: 'RH',
      key: 'ruby',
      value: 'ruby'
    }, {
      abbreviation: 'SA',
      key: 'sapphire',
      value: 'sapphire'
    }, {
      abbreviation: 'SL',
      key: 'slate',
      value: 'slate'
    }, {
      abbreviation: 'SQ',
      key: 'smoky quartz',
      value: 'smoky quartz'
    }, {
      abbreviation: 'SD',
      key: 'sodalite',
      value: 'sodalite'
    }, {
      abbreviation: 'SP',
      key: 'spinel',
      value: 'spinel'
    }, {
      abbreviation: 'ST',
      key: 'stratumstone',
      value: 'stratum stone'
    }, {
      key: 'swarovskicrystal',
      value: 'swarovskicrystal'
    }, {
      key: 'syntheticstone',
      value: 'syntheticstone'
    }, {
      key: 'tigereye',
      value: 'tigereye'
    }, {
      abbreviation: 'TP',
      key: 'topaz',
      value: 'topaz'
    }, {
      abbreviation: 'TR',
      key: 'tourmaline',
      value: 'tourmaline'
    }, {
      abbreviation: 'TQ',
      key: 'turquoise',
      value: 'turquoise'
    }, {
      abbreviation: 'UV',
      key: 'uvarovite',
      value: 'uvarovite'
    }, {
      abbreviation: 'WV',
      key: 'wildvinite',
      value: 'wildvinite'
    }, {
      abbreviation: 'XS',
      key: 'xylopile',
      value: 'xylopile'
    }, {
      abbreviation: 'ZI',
      key: 'zirconia',
      value: 'zirconia'
    }]
  }

  var getGemPurities = function () {
    return [{ key: 'LC', value: 'LC' },
      { key: 'VVS1', value: 'VVS1' },
      { key: 'VVS2', value: 'VVS2' },
      { key: 'VS1', value: 'VS1' },
      { key: 'VS2', value: 'VS2' },
      { key: 'SI1', value: 'SI1' },
      { key: 'SI2', value: 'SI2' },
      { key: 'P1', value: 'P1' },
      { key: 'P2', value: 'P2' },
      { key: 'P3', value: 'P3' }]
  }

  var getGemColors = function () {
    return [{ value: 'D', key: 'D (Finest white+ Jager )' },
      { value: 'E', key: 'E (Finest white River )' },
      { value: 'F', key: 'F (Fine white+ River )' },
      { value: 'G', key: 'G (Fine white Top Wesselton )' },
      { value: 'H', key: 'H (White Wesselton )' },
      { value: 'I', key: 'I (Light tinted white+ Crystal)' },
      { value: 'J', key: 'J (Light tinted white )' },
      { value: 'K', key: 'K (Tinted white+ Top )' },
      { value: 'L', key: 'L (Tinted white Top Cape )' },
      { value: 'M', key: 'M (Tinted colour Cape )' },
      { value: 'N', key: 'N (Tinted colour Low Cape )' },
      { value: 'O', key: 'O (Tinted colour Very Light Yellow)' },
      { value: 'P-Z', key: 'P-Z (Tinted colour Light )' },
      { value: 'P', key: 'P (Tinted colour Very Light Yellow)' },
      { value: 'Q', key: 'Q (Tinted colour Very Light Yellow)' },
      { value: 'R', key: 'R (Tinted colour Very Light Yellow)' },
      { value: 'S', key: 'S (Light Yellow)' },
      { value: 'T', key: 'T (Light Yellow)' },
      { value: 'V', key: 'V (Light Yellow)' },
      { value: 'U', key: 'U (Light Yellow)' },
      { value: 'W', key: 'W (Light Yellow)' },
      { value: 'X', key: 'X (Light Yellow)' },
      { value: 'Y', key: 'Y (Light Yellow)' },
      { value: 'Z', key: 'Z (Light Yellow)' },
      { value: 'TRANSPARENT', key: 'TRANSPARENT' },
      { value: 'SILVER_COLORED', key: 'SILVER' },
      { value: 'GREY', key: 'GREY' },
      { value: 'BLUE', key: 'BLUE' },
      { value: 'WHITE', key: 'WHITE' },
      { value: 'RED', key: 'RED' },
      { value: 'BLACK', key: 'BLACK' },
      { value: 'ROSE_GOLD_COLORED', key: 'ROSE_GOLD_COLORED' },
      { value: 'SILVER_GOLD_COLORED', key: 'SILVER_GOLD_COLORED' },
      { value: 'GOLD_COLORED', key: 'GOLD_COLORED' },
      { value: 'MOTHER_OF_PEARL', key: 'MOTHER_OF_PEARL' },
      { value: 'BROWN', key: 'BROWN' },
      { value: 'BEIGE', key: 'BEIGE' },
      { value: 'MOTHER_OF_PEARL_COLOURED', key: 'MOTHER_OF_PEARL_COLOURED' },
      { value: 'GOLDEN', key: 'GOLDEN' },
      { value: 'GREEN', key: 'GREEN' },
      { value: 'PINK', key: 'PINK' },
      { value: 'PURPLE', key: 'PURPLE' },
      { value: 'ORANGE', key: 'ORANGE' },
      { value: 'YELLOW', key: 'YELLOW' },
      { value: 'CREME', key: 'CREME' },
      { value: 'TAUPE', key: 'TAUPE' },
      { value: 'BRASS', key: 'BRASS' }]
  }

  var getGemCuts = function () {
    return [{ key: 'asscher', value: 'asscher' },
      { key: 'baguette', value: 'baguette' },
      { key: 'briljant', value: 'briljant' },
      { key: 'buff-top', value: 'buff-top' },
      { key: 'cabochon', value: 'cabochon' },
      { key: 'carre', value: 'carre' },
      { key: 'emerald', value: 'emerald' },
      { key: 'fact', value: 'fact' },
      { key: 'heart', value: 'heart' },
      { key: 'marquise', value: 'marquise' },
      { key: 'mixed', value: 'mixed' },
      { key: 'octagon', value: 'octagon' },
      { key: 'oval fact', value: 'oval fact' },
      { key: 'oval', value: 'oval' },
      { key: 'pear', value: 'pear' },
      { key: 'trilliant', value: 'trilliant' },
      { key: 'princess', value: 'princess' },
      { key: 'single-cut', value: 'single-cut' },
      { key: 'radiant', value: 'radiant' },
      { key: 'round', value: 'round' },
      { key: 'radiant', value: 'radiant' }]
  }

  var getGemKindAbbreviation = function(value) {
    var result = ''
    var gemKinds = getGemKinds()
    for (var i = 0; i < gemKinds.length; i++) {
      if (gemKinds[i].value === value) {
        result = gemKinds[i].abbreviation
        break
      }
    }
    return result
  }

  this.getGemKinds = getGemKinds
  this.getGemPurities = getGemPurities
  this.getGemColors = getGemColors
  this.getGemCuts = getGemCuts
  this.getGemKindAbbreviation = getGemKindAbbreviation
}])
