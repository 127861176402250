prismanoteApp.service('$company', ['$rootScope', '$q', '$api', '$state',
  function ($rootScope, $q, $api, $state) {
    // create a named var for the service, because 'this' is problematic when using other vars or functions in this service.
    var companyService = this

    // Store currently active merchant and shop nameSlugs in the service. If set, this will be valid as long as the page is not refreshed. This is useful for running multiple AJAX calls in sequence, i.e. for lazy loading.
    companyService.company

    var validateNameSlug = function (nameSlug, type) {
      if (nameSlug == null || typeof nameSlug !== 'string') {
        // Try to use the saved merchant or shop (if it has been set before, the nameSlug parameter can be omitted).
        if (companyService[type] != null && typeof companyService[type] === 'string') {
          return companyService[type]
        } else {
          var companySlug = localStorage.getItem('currentCompany');
          if(companySlug){
            return companySlug;
          }else if ($rootScope.user.companies[0]) {
            return $rootScope.user.companies[0].nameSlug
          } else {
            return false
          }
        }
      } else {
        return nameSlug
      }
    }

    var validateParams = function (params) {
      if (params != null && typeof params === 'object') {
        return params
      } else {
        return {}
      }
    }

    var beforeApiRequest = function (options) {
      // Currently unused pre request hook.
    }

    var afterApiRequest = function (options) {
      if (options != null && typeof options === 'object') {
        if (options.redirect != null && typeof options.redirect === 'string') {
          $state.go(options.redirect)
        }
      }
    }

    companyService.addNewProductInDatabase = function (product) {
      return $q(function (resolve, reject) {
        $api.post('v2/company/product', product)
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    companyService.changeCompanyCollectionsShowOptions = function (companyId, collections) {
      return $q(function (resolve, reject) {
        $api.put('v2/company/collections', { companyId: companyId, collections: collections })
          .then(function (resopnse) {
            console.log('Get Success Resopnse changeShopCollectionsShowOptions', resopnse.data)
            return resolve(resopnse.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    companyService.deleteProductFromCompany = function (productId, companyId) {
      return $q(function (resolve, reject) {
        $api.post('v2/company/delete-product-from-company', {
          companyId: companyId,
          productId: productId
        }).then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
      })
    }

    companyService.updateCompanyProductStockAndShow = function (productId, companyId) {
      return $q(function (resolve, reject) {
        $api.put('v2/company/stock-show-update-in-company-product/', {
          companyId: companyId,
          productId: productId
        }).then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
      })
    }

    companyService.searchProductForCompany = function (data) {
      return $q(function (resolve, reject) {
        $api.post('v2/company/product/serarch', data)
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    companyService.searchProductForCompany2 = function (data) {
      return $q(function (resolve, reject) {
        $api.post('v2/company/product/serarch2', data)
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    companyService.updateProductByCompany = function (companyId, product, originalProduct, newCollections, oldCollections, companyProduct, suggester, autoGeneratedArticalNumber) {
      // @params {companyId} use for update specific product of this company
      // {product} have all the comman details
      // {originalProduct} Currently Stored in database details
      // {companyProduct} company specific details
      // {suggester} user who is changeing the details
      // {newCollections} newAssign newCollections
      // {oldCollections} before changes

      var data = {
        companyId: companyId,
        product: product,
        originalProduct: originalProduct,
        companyProduct: companyProduct,
        newCollections: newCollections,
        oldCollections: oldCollections,
        suggester: suggester,
        autoGeneratedArticalNumber: autoGeneratedArticalNumber
      }

      return $q(function (resolve, reject) {
        $api.put('v2/company/product', data)
          .then(function (response) {
            return resolve(response.data)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    companyService.getCompayProductStockChanges = function (companyId, productId) {
      return $q(function (resolve, reject) {
        $api.get('v2/company/product/stock-changes/' + companyId + '/' + productId + '?companyId=' + $rootScope.currentCompany._id)
          .then(function (response) {
            return resolve(response.data.data)
          })
          .catch(function (error) {
            return reject(error)
          })
      })
    }

    companyService.addProductInCompany = function (companyId, productId, companyProductDetails) {
      var data = {
        companyId: companyId,
        productId: productId,
        companyProductDetails: companyProductDetails,
        autoGeneratedArticalNumber: true
      }

      return $q(function (resolve, reject) {
        $api.post('v2/product/in-company/', data)
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    companyService.updateCompanyProductStock = function (compayId, productId, oldStock, newStock) {
      var data = {
        compayId: compayId,
        companyId: compayId, //security
        productId: productId,
        oldStock: oldStock,
        newStock: newStock
      }

      return $q(function (resolve, reject) {
        $api.put('v2/company/product/stock', data).then(function (response) {
          return resolve(response.data.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
      })
    }

    companyService.getCompanyBrandsForFilter = function (companyNameSlug) {
      return $q(function (resolve, reject) {
        $api.get('v2/company/own-brands-for-filter/' + companyNameSlug).then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
      })
    }

    companyService.getCompanyCollectionsForFilter = function (companyNameSlug) {
      return $q(function (resolve, reject) {
        $api.get('v2/company/own-collections-for-filter/' + companyNameSlug).then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
      })
    }

    companyService.getStockModeProductsCompanyWise = function (companyId, stockFilter, productFilter, orderBy) {
      return $q(function (resolve, reject) {
        $api.get('v2/company/stock-and-price-products-from-company/',
          {
            companyId: companyId,
            companyFilter: stockFilter,
            productFilter: productFilter,
            orderBy: orderBy
          })
          .then(function (response) {
            console.log('Get success resopnse', response.data)
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

     companyService.getOwnCompanyProductsForCSVWithoutFilters = function (companyNameSlug, stockFilter, productFilter, orderBy) {
      return $q(function (resolve, reject) {
        $api.get('v2/company/stock-and-price-products-from-company-for-csv-WithoutFilters', { companyNameSlug: companyNameSlug, shopFilter: stockFilter, productFilter: productFilter, orderBy: orderBy })
          .then(function (response) {
            console.log(response.data);
            return resolve(response.data)
          })
          .catch(function (reason) {
            console.log('----------------- errr!');
            console.log(reason);
            return reject(reason.data)
          })
      })
    }

    companyService.getOwnCompanyProductsForCSV = function (companyNameSlug, stockFilter, productFilter, orderBy, pageNumber, limitCount) {
      console.log('------------------- getOwnCompanyProductsForCSV!');
      return $q(function (resolve, reject) {
        $api.get('v2/company/stock-and-price-products-from-company-for-csv', { companyNameSlug: companyNameSlug, shopFilter: stockFilter, productFilter: productFilter, orderBy: orderBy, pageNumber: pageNumber, limitCount: limitCount})
          .then(function (response) {
            console.log('---------------------- company/stock-and-price-products-from-company-for-csv!');
            console.log(response.data);
            return resolve(response.data)
          })
          .catch(function (reason) {
            console.log('----------------- errr!');
            console.log(reason);
            return reject(reason.data)
          })
      })
    }

    companyService.searchCompanyProducts = function (companyNameSlug, searchValue) {
      return $q(function (resolve, reject) {
        $api.get('v2/company/products-search', {
          nameSlug: companyNameSlug,
          searchValue: searchValue
        }).then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
      })
    }

    companyService.getLatestArticleNumberForCompany = function (companyId) {
      return $q(function (resolve, reject) {
        $api.get('v2/company/product-article-number/' + companyId)
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    companyService.getCompanyProduct = function (companyId, productId) {
      return $q(function (resolve, reject) {
        $api.get('v2/company/product/' + companyId + '/' + productId)
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    companyService.getCompanyCollections = function (companyId, productId) {
      return $q(function (resolve, reject) {
        $api.get('v2/company/collections/', { companyId: companyId })
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }
    
    companyService.getCompanyDetail = function (nameSlug, params, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'company')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }
        var validParams = validateParams(params)

        beforeApiRequest(options)

        $api.get('companies-data/' + validatedNameSlug, validParams)

          .then(function (response) {
            // Settings like redirect URL
            afterApiRequest(options)

            // Store currently active shop nameSlug
            companyService.company = response.data.company.nameSlug
            $rootScope.unreadNotifications = response.data.company.unreadNotifications
            $rootScope.changeTitle()

            resolve(response.data.company)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }
    companyService.getCompany = function (nameSlug, params, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'company')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }
        var validParams = validateParams(params)

        beforeApiRequest(options)

        $api.get('companies/' + validatedNameSlug, validParams)

          .then(function (response) {
            // Settings like redirect URL
            afterApiRequest(options)

            // Store currently active shop nameSlug
            companyService.company = response.data.company.nameSlug
            $rootScope.unreadNotifications = response.data.company.unreadNotifications
            $rootScope.changeTitle()

            resolve(response.data.company)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    companyService.setCompany = function (nameSlug, shop, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'company')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        company._id = $rootScope.currentCompany._id

        $api.update('companies/' + validatedNameSlug, { company: company })

          .then(function (response) {
            // Settings like redirect URL
            afterApiRequest(options)

            // Store currently active shop nameSlug
            companyService.company = response.data.company.nameSlug

            $rootScope.companySaved = true

            resolve(response.data.company)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Wrapper for getShop, returns ONLY the shop brands instead of the default fields (which do not include brands).
    companyService.getCompanyBrands = function (nameSlug, params, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'company')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        companyService.getCompany(validatedNameSlug, { select: 'company' })

          .then(function (company) {
            // Settings like redirect URL
            afterApiRequest(options)

            resolve(company.brands)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Wrapper for setShop, updates and returns ONLY the shop brands instead of the default fields (which do not include brands).
    companyService.setCompanyBrands = function (nameSlug, brands, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'companies')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        companyService.setShop(validatedNameSlug, { brands: brands })

          .then(function (company) {
            // Settings like redirect URL
            afterApiRequest(options)

            $rootScope.companySaved = true

            resolve(company.brands)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    companyService.makeCompanyProudctShowTrue = function (body) {
      console.log('i m called body compId', body.companyId)
      console.log('i m called', body)

      return $q(function (resolve, reject) {

        $api.post('v2/company/make-company-product-show-true', 
          {
            companyId: body.companyId,
          })
          .then(function (response) {
            console.log('Get success resopnse', response.data)
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    companyService.getCompanyClientGroup = function (body) {
      return $api.get('v2/company/client-group', body)
    }



  }])



