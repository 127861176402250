prismanoteApp.factory('$language', ['$translate', '$sce', '$api', '$rootScope', function ($translate, $sce, $api, $rootScope) {
  // Note: This function only called from the controllers, so it isn't handling all translations!
  function trans (keyword, values) {
    var translation = $sce.valueOf($translate.instant(keyword, values))
    if (translation === '') {
      return keyword
    }
    return translation
  }

  return {
    translate: trans
  }
}])

