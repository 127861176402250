prismanoteApp.factory('cookieService', ['$q', '$injector',
    function ($q, $injector) {
        function setCookie(content, ttl, path) {
            // ttl is value in seconds how long cookie must live
            if (ttl) {
                var now = new Date()
                var time = now.getTime()
                time += ttl * 1000;
                now.setTime(time)
            }
            document.cookie = content + (ttl ? '; expires=' + now.toUTCString() + ';' : '') + (path ? '; path=' + path : '')
        }

        function getCookie(cname) {
            return $q(function (resolve, reject) {
                var name = cname + '='
                var decodedCookie = decodeURIComponent(document.cookie)
                var ca = decodedCookie.split(';')
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i]
                    while (c.charAt(0) === ' ') {
                        c = c.substring(1)
                    }
                    if (c.indexOf(name) === 0) {
                        return resolve(c.substring(name.length, c.length))

                    }
                }
                //No cookie found, just return empty string
                return resolve('')
            })
        }

        function eraseCookie(name) {
            document.cookie = name + '=; Max-Age=-99999999'
        }

        return {
            setCookie: setCookie,
            getCookie: getCookie,
            eraseCookie: eraseCookie
        }
    }])