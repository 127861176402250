prismanoteApp.service('$customerInform', ['$q', '$api',
  function ($q, $api) {
    function isTotalAppliedStockAvailable (transactionDetails) {
      console.log('transaciondetail: ', transactionDetails)
      var isTotalAppliedStock = false
      if (transactionDetails) {
        var transactionDetailsLength = transactionDetails.length
        for (var i = 0; i < transactionDetailsLength; i++) {
          if (transactionDetails[i].totalAppliedStock) {
            isTotalAppliedStock = true
            break
          }
        }
      }
      return isTotalAppliedStock
    }

    function fullDeliverFromPurchaseOrder (body) {
      return $q(function (resolve, reject) {
        $api.post('v2/shop/fulldeliver-transaction-from-purchaseorder', body).then(function (response) {
          resolve(response)
        }).catch(function (error) {
          reject(error)
        })
      })
    }

    function partialDeliverFromPurchaseOrder (body) {
      return $q(function (resolve, reject) {
        $api.post('v2/shop/partialdeliver-transaction-from-purchaseorder', body).then(function (response) {
          resolve(response)
        }).catch(function (error) {
          reject(error)
        })
      })
    }

    function cancelTransactionsFromPurchaseOrder (body) {
      return $q(function (resolve, reject) {
        $api.post('v2/shop/cancel-transaction-from-purchaseorder', body).then(function (response) {
          resolve(response)
        }).catch(function (error) {
          reject(error)
        })
      })
    }

    return {
      isTotalAppliedStockAvailable: isTotalAppliedStockAvailable,
      fullDeliverFromPurchaseOrder: fullDeliverFromPurchaseOrder,
      partialDeliverFromPurchaseOrder: partialDeliverFromPurchaseOrder,
      cancelTransactionsFromPurchaseOrder: cancelTransactionsFromPurchaseOrder
    }
  }])
