prismanoteApp.factory('$general', ['$api',
    function ($api) {

        function welcomeInvitationLink(body) {
            return $api.get('v2/welcome-invitation-link', body)
        }

        function isEmailExistForLoginUser(body) {
            return $api.get('v2/email-exist-for-login-user', body)
        }

        function companyLogo(body) {
            return $api.get('v2/company-logo', body)
        }
        
        function chooseShopAndLogin(body) {
            return $api.post('v2/chooseshop-and-login', body)
        }

        function login(body) {
            return $api.post('login', body)
        }

        function register(body) {
            return $api.post('register', body)
        }

        return {
            welcomeInvitationLink: welcomeInvitationLink,
            isEmailExistForLoginUser: isEmailExistForLoginUser,
            companyLogo: companyLogo,
            chooseShopAndLogin: chooseShopAndLogin,
            login: login,
            register: register
        }

    }])
