prismanoteApp.service('$notification', ['$q', '$api', '$rootScope', '$state', '$language',
  function ($q, $api, $rootScope, $state, $language) {
    var createShopNotification = function (notification) {
      return $q(function (resolve, reject) {
        $api.post('v2/shop-product/notification', notification)
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            return reject(reason.data)
          })
      })
    }

    var productEditPageNotification = function () {
      return {
        en: 'For your information, in the middle of the product page you can see what information is missing to show a product on the webshop. Also ask your supplier to provide product information.',
        de: 'Zu Ihrer Information können Sie in der Mitte der Produktseite sehen, welche Informationen fehlen, um ein Produkt im Webshop anzuzeigen. Bitten Sie auch Ihren Lieferanten, Produktinformationen bereitzustellen.',
        nl: 'Ter info, in het midden van de productpagina ziet u welke informatie ontbreekt om een product op de webshop te tonen. Vraag ook uw leverancier om product info aan te leveren.',
        fr: 'Pour votre information, au milieu de la page du produit, vous pouvez voir quelles informations manquent pour montrer un produit sur la boutique en ligne. Demandez également à votre fournisseur de fournir des informations sur le produit.',
        es: 'Para su información, en el medio de la página del producto puede ver qué información falta para mostrar un producto en la tienda web. También solicite a su proveedor que proporcione información sobre el producto.'
      }
    }

    var productComparePageNotification = function () {
      return {
        en: "Details of product information are important. Learn <a href='https://info.prismanote.com/product-wijziging-goedkeuren/'>how to approve</a> changes in 1 minute.",
        de: "Details der Produktinformationen sind wichtig. Lernen Sie <a href='https://info.prismanote.com/product-wijziging-goedkeuren/'>wie Sie Änderungen in 1 Minute genehmigen können.",
        nl: "Details van de producten zijn belangrijk. Lees <a href='https://info.prismanote.com/product-wijziging-goedkeuren/'>Hoe u wijzigingen binnen 1 minuut kunt goedkeuren</a>.",
        fr: "Les informations détaillées sur les produits sont importantes. Apprenez <a href='https://info.prismanote.com/product-wijziging-goedkeuren/'>comment approuver </a> changements en 1 minute.",
        es: "Los detalles de la información del producto son importantes. Aprenda <a href='https://info.prismanote.com/product-wijziging-goedkeuren/'>cómo aprobar </a> cambios en 1 minuto."
      }
    }

    var updateNotificationStatus = function (id) {
      return $q(function (resolve, reject) {
        $api.put('shop/' + $rootScope.currentShop._id + '/notification/' + id, null, null, 2)
          .then(function (result) {
            fetchNotifications()
            return reject(resolve)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    var updateNotificationStatusForCompany = function (id) {
      return $q(function (resolve, reject) {
        $api.put('company/' + $rootScope.currentCompany._id + '/notification/' + id, null, null, 2)
          .then(function (result) {
            fetchNotificationsForCompany()
            return reject(resolve)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    var postNotification = function (message, sender, date, data) {
      return $q(function (resolve, reject) {
        if (!message) {
          return reject('NO_MESSAGE_PROVIDED')
        }
        if (!sender) {
          sender = null
        }
        if (!date) {
          date = null
        }

        $api.post('shop/' + $rootScope.currentShop._id + '/notification/', {
          message: message,
          sender: sender,
          date: date,
          data: data
        }, null, 2)
          .then(function (response) {
            return resolve(response)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    var postNotificationForCompany = function (message, sender, date, data) {
      return $q(function (resolve, reject) {
        if (!message) {
          return reject('NO_MESSAGE_PROVIDED')
        }
        if (!sender) {
          sender = null
        }
        if (!date) {
          date = null
        }

        $api.post('company/' + $rootScope.currentCompany._id + '/notification/', {
          message: message,
          sender: sender,
          date: date,
          data: data
        }, null, 2)
          .then(function (response) {
            return resolve(response)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    var sendTestNotification = function () {
      var notification = {
        nl: 'Hallootjes! Dit is een test notificatie!',
        en: 'Hellooo there! This is an test notifcation!',
        de: 'Hallo, hallo! Dies ist eine Testmeldung!',
        fr: "Hellooo là ! Il s'agit d'une notification de test !",
        es: '¡Hola a todos! Esta es una notificación de prueba!'
      }
      return postNotification(notification, '5cfa5fb5850b2755d89574cc', null, { senderIsUser: true })
    }

    var sendTestNotificationToCompany = function () {
      var notification = {
        nl: 'Hallootjes! Dit is een test notificatie!',
        en: 'Hellooo there! This is an test notifcation!',
        de: 'Hallo, hallo! Dies ist eine Testmeldung!',
        fr: "Hellooo là ! Il s'agit d'une notification de test !",
        es: '¡Hola a todos! Esta es una notificación de prueba!'
      }
      return postNotificationForCompany(notification)
    }

    var deleteNotification = function (id) {
      $api.delete('shop/' + $rootScope.currentShop._id + '/notification/' + id, null, null, 2)
        .then(function () {
          fetchNotifications()
        })
        .catch(function (reason) {
          console.error('Error while deleting notification', reason)
        })
    }

    var deleteNotificationForCompany = function (id) {
      $api.delete('company/' + $rootScope.currentCompany._id + '/notification/' + id, null, null, 2)
        .then(function () {
          fetchNotificationsForCompany()
        })
        .catch(function (reason) {
          console.error('Error while deleting notification', reason)
        })
    }

    var deleteAllNotifications = function () {
      $api.delete('shop/' + $rootScope.currentShop._id + '/notification/delete', null, null, 2)
        .then(function () {
          fetchNotifications()
        })
        .catch(function (reason) {
          console.error('Error while deleting all notifications', reason)
        })
    }

    var deleteAllNotificationsForCompany = function () {
      $api.delete('company/' + $rootScope.currentCompany._id + '/notification/delete', null, null, 2)
        .then(function () {
          fetchNotificationsForCompany()
        })
        .catch(function (reason) {
          console.error('Error while deleting all notifications', reason)
        })
    }

    var fetchNotifications = function () {
      return $q(function (resolve, reject) {
        if (!$rootScope.currentShop) return reject('NO_SHOP')

        $api.get('shop/' + $rootScope.currentShop._id + '/notification', null, null, 2)
          .then(function (response) {
            $rootScope.unreadNotificationsCount = response.data.unread

            var title = 'PrismaNote'
            
            if ($state.$current.data && $state.$current.data.pageTitle) {
              title = title + ' | ' + $language.translate($state.$current.data.pageTitle)
            }

            // if ($rootScope.unreadNotifications > 0) {
            //   title = '(' + $rootScope.unreadNotifications + ') ' + title
            // }

            $rootScope.pageTitle = title
            $rootScope.currentShop.notifications = response.data.notifications

            return resolve(response.data)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    var fetchNotificationsForCompany = function () {
      return $q(function (resolve, reject) {
        if (!$rootScope.currentCompany) return reject('NO_COMPANY')

        $api.get('company/' + $rootScope.currentCompany._id + '/notification', null, null, 2)
          .then(function (response) {
            $rootScope.unreadNotificationsCount = response.data.unread

            var title = 'PrismaNote'
            if ($state.$current.data && $state.$current.data.pageTitle) {
              title = title + ' | ' + $language.translate($state.$current.data.pageTitle)
            }

            // if ($rootScope.unreadNotifications > 0) {
            //   title = '(' + $rootScope.unreadNotifications + ') ' + title
            // }

            $rootScope.pageTitle = title
            $rootScope.currentCompany.notifications = response.data.notifications

            return resolve(response.data)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    this.createShopNotification = createShopNotification
    this.productEditPageNotification = productEditPageNotification
    this.productComparePageNotification = productComparePageNotification
    this.updateNotificationStatus = updateNotificationStatus
    this.updateNotificationStatusForCompany = updateNotificationStatusForCompany
    this.sendTestNotification = sendTestNotification
    this.postNotification = postNotification
    this.deleteNotification = deleteNotification
    this.deleteNotificationForCompany = deleteNotificationForCompany
    this.deleteAllNotifications = deleteAllNotifications
    this.deleteAllNotificationsForCompany = deleteAllNotificationsForCompany
    this.fetchNotifications = fetchNotifications
    this.sendTestNotificationToCompany = sendTestNotificationToCompany
  }])
