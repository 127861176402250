prismanoteApp.factory('$employee', ['$q', '$rootScope', '$retailer', '$uibModal', '$state', '$api', '$uibModalStack',
  function ($q, $rootScope, $retailer, $uibModal, $state, $api, $uibModalStack) {
    function getEmployee () {
      return $q(function (resolve, reject) {
        if (!$rootScope.currentEmployee) {
          var lse = localStorage.getItem('currentEmployee')
          if (lse) {
            lse = JSON.parse(lse)
            $rootScope.currentEmployee = lse
            return resolve(lse)
          } else {
            return reject('NOT_UNLOCKED')
          }
        } else {
          return resolve($rootScope.currentEmployee)
        }
      })
    }

    function lockEmployee () {
      
      try {
        $rootScope.stopIdleTimer()
        localStorage.removeItem('currentEmployee')
      } catch (catcherr) {
        console.error('error: ', catcherr)
      }
    }

    function getEmployees(force) {
      return $q(function (resolve, reject) {
        if (!$rootScope.employees || $rootScope.employees.length === 0 || force === true) {
          var currentNameSlug = localStorage.getItem('currentShop');
          $api.get('shops/' + currentNameSlug).then(function (response) {
            if (response && response.data && response.data.shop && response.data.shop.employees && response.data.shop.employees.length) {
              $rootScope.employees = response.data.shop.employees
              return resolve($rootScope.employees) // Give message that Inactive employee
            } else {
              return reject('NO_EMPLOYEES')
            }
          }).catch(function (reason) {
            reject(reason)
          })
        } else {
          resolve($rootScope.employees)
        }
      })
    }

    function loginEmployee(ep) {
      return $q(function (resolve, reject) {
        getEmployees().then(function (employees) {
          if ($rootScope.user && $rootScope.user.initials === ep.initials && (($rootScope.user.hasRequiredPin && $rootScope.user.pincode === ep.pincode) || (!$rootScope.user.hasRequiredPin))) {
            setSession({}, false).then(function (sessionresponse) {
              $rootScope.startIdleTimer()
              saveEmployeeInLocalStorage($rootScope.user)
              resolve($rootScope.user)
            }).catch(function (sessioneror) {
              reject(sessioneror)
            })
          } else {
            var index = _.findIndex(employees, { _id: ep._id })
            if (index >= 0) {
              if (employees[index] && ((employees[index].hasRequiredPin && employees[index].pincode === ep.pincode) || !employees[index].hasRequiredPin)) {
                if (employees[index].enabled) {
                  setSession(employees[index], true).then(function (sessionresponse) {
                    $rootScope.startIdleTimer()
                    saveEmployeeInLocalStorage(employees[index])
                    resolve(employees[index])
                  }).catch(function (sessioneror) {
                    reject(sessioneror)
                  })
                } else {
                  reject('SELECTED_EMPLOYEE_IS_ACTIVE')
                }
              } else {
                reject('SELECTED_EMPLOYEE_IS_NOT_VALID')
              }
            } else {
              reject('NO_MATCHING_RECORD_FOUND')
            }
          }
        }).catch(function (reason) {
          reject(reason)
        })
      })
    }

    function saveEmployeeInLocalStorage (employee) {
      try {
        var getLastLoggedInEmployees = localStorage.getItem('lastLoggedInEmployees')
        if (getLastLoggedInEmployees) {
          getLastLoggedInEmployees = JSON.parse(getLastLoggedInEmployees)
        } else {
          getLastLoggedInEmployees = []
        }
        var isAlreadyExist = false
        var indexToUpdate
        for (var i = 0; i < getLastLoggedInEmployees.length; i++) {
          if ((getLastLoggedInEmployees[i]._id && employee._id && (getLastLoggedInEmployees[i]._id).toString() === (employee._id).toString()) ||
                        getLastLoggedInEmployees[i].initials === employee.initials) {
            isAlreadyExist = true
            indexToUpdate = i
          }
        }

        if (!isAlreadyExist) {
          var toPushEmp = {
            _id: employee._id,
            fullName: ((employee.firstName || '') + ' ') + ((employee.lastNamePrefix || '') + ' ') + ((employee.lastName || '') + ' '),
            initials: employee.initials,
            profileImage: employee.profileImage,
            hasRequiredPin: employee.hasRequiredPin
          }
          getLastLoggedInEmployees.push(toPushEmp)
        } else if (indexToUpdate === 0 || indexToUpdate) {
          getLastLoggedInEmployees[indexToUpdate] = {
            _id: employee._id,
            fullName: ((employee.firstName || '') + ' ') + ((employee.lastNamePrefix || '') + ' ') + ((employee.lastName || '') + ' '),
            initials: employee.initials,
            profileImage: employee.profileImage,
            hasRequiredPin: employee.hasRequiredPin
          }
        }
        localStorage.setItem('lastLoggedInEmployees', JSON.stringify(getLastLoggedInEmployees))
      } catch (catcherr) {
        console.error('catcherr: ', catcherr)
      }
    }

    function updateLocalStorage (employee) {
      try {
        var getLastLoggedInEmployees = localStorage.getItem('lastLoggedInEmployees')
        if (getLastLoggedInEmployees) getLastLoggedInEmployees = JSON.parse(getLastLoggedInEmployees)
        else getLastLoggedInEmployees = []
        var isAlreadyExist = false
        var indexToUpdate
        for (var i = 0; i < getLastLoggedInEmployees.length; i++) {
          if ((getLastLoggedInEmployees[i]._id && employee._id && (getLastLoggedInEmployees[i]._id).toString() === (employee._id).toString()) ||
                        getLastLoggedInEmployees[i].initials === employee.initials) {
            isAlreadyExist = true
            indexToUpdate = i
          }
        }

        if (isAlreadyExist && indexToUpdate === 0 || indexToUpdate) {
          getLastLoggedInEmployees[indexToUpdate] = {
            _id: employee._id,
            fullName: ((employee.firstName || '') + ' ') + ((employee.lastNamePrefix || '') + ' ') + ((employee.lastName || '') + ' '),
            initials: employee.initials,
            profileImage: employee.profileImage,
            hasRequiredPin: employee.hasRequiredPin
          }
        }
        localStorage.setItem('lastLoggedInEmployees', JSON.stringify(getLastLoggedInEmployees))
      } catch (catcherr) {
        console.error('catcherr: ', catcherr)
      }
    }

    function openUnlockModal () {
      // lockEmployee();
      // localStorage.setItem("isEnableEmployeeLogin", true);
      return $q(function (resolve, reject) {
      if ($rootScope.warningModal) {
        $rootScope.warningModal.close()
      }

      $rootScope.unlockModal = $uibModal.open({
        templateUrl: '../views/modal/employee-modal.html',
        controller: 'employeeLoginModalController',
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        backdropClass: 'unlock-backdrop',
        openedClass: 'unlock-open'
      })

      $rootScope.unlockModal.result.then(function (result) {
        $rootScope.currentEmployee = result
        var canAccess = $rootScope.hasAccessToViewPage()
        if (!canAccess) {
          $uibModalStack.dismissAll()
          $state.go('retailer.home')
        } else {
          $uibModalStack.dismissAll()
          if ($state.$current.name === 'retailer.no-rights') $state.go('retailer.home')
          return resolve(result)
        }
      }, function (err) {
        return reject('NOT_UNLOCKED')
      })
    })
    }

    function openWarningModal () {
      $rootScope.warningModal = $uibModal.open({
        templateUrl: '../views/modal/idle-warning-modal.html',
        controller: 'employeeLoginModalController'
      })
    }

    function setSession (employee, HaveToStoreInSession) {
      return $q(function (resolve, reject) {
        if (HaveToStoreInSession) localStorage.setItem('currentEmployee', JSON.stringify(employee))
        else localStorage.removeItem('currentEmployee')
        if (employee) employee.HaveToStoreInSession = HaveToStoreInSession
        $api.post('store-employee-in-session', { employee: employee, shopId: $rootScope.currentShop._id }).then(function (response) {
          resolve(response)
        }).catch(function (error) {
          console.error('error: ', error)
          reject(error)
        })
      })
    }

    function changeEmployeeLockingInAllDevice (body) {
      return $q(function (resolve, reject) {
        $api.put('change-status-of-employeelogin', body).then(function (response) {
          resolve(response.data)
        }).catch(function (error) {
          reject(error)
        })
      })
    }

    // If resolve() then employeeLogin Enable otherwise disable, For this particular device
    function EmployeeLoginIsEnableOrDisableForThisDevice () {
      return $q(function (resolve, reject) {
        var isEnableEmployeeLogin = localStorage.getItem('isEnableEmployeeLogin')
        isEnableEmployeeLogin = !!((isEnableEmployeeLogin == 'true' || isEnableEmployeeLogin == true))
        if (isEnableEmployeeLogin) {
          resolve()
        } else {
          reject()
        }
      })
    }

    // If resolve() then employeeLogin Enable otherwise disable, Whenever needs just we have to check it
    function getCurrentUserData () {
      return $q(function (resolve, reject) {
        if ($rootScope.currentEmployee) {
          resolve($rootScope.currentEmployee)
        } else {
          resolve($rootScope.user)
        }
      })
    }

    function openConfirmPinModal (pincodes) {
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/confirmation-admin-pin-profile.html',
          controller: 'confirmAdminPinModalController',
          keyboard: false,
          size: 'md',
          backdrop: 'static',
          // backdropClass: 'unlock-backdrop',
          openedClass: 'unlock-open',
          resolve: {
            pincodes: function () {
              return pincodes
            }
          }
        })

        modalInstance.result.then(function (res) {
          return resolve(res)
        }, function (reason) {
          if (!reason) reason = 'dismissed'
          return reject(reason)
        })
      })
    }

    function removeEmployeeFromLocalStorage (employee) {
      return $q(function (resolve, reject) {
        try {
          var getLastLoggedInEmployees = localStorage.getItem('lastLoggedInEmployees')
          if (getLastLoggedInEmployees) {
            getLastLoggedInEmployees = JSON.parse(getLastLoggedInEmployees)
          } else {
            getLastLoggedInEmployees = []
          }
          if (!getLastLoggedInEmployees.length) {
            return
          }
          var isAlreadyExist = false
          for (var i = 0; i < getLastLoggedInEmployees.length; i++) {
            if ((getLastLoggedInEmployees[i]._id && employee._id && (getLastLoggedInEmployees[i]._id).toString() === (employee._id).toString())) {
              isAlreadyExist = true
              getLastLoggedInEmployees.splice(i, 1)
              break
            }
          }
          if (isAlreadyExist) {
            localStorage.setItem('lastLoggedInEmployees', JSON.stringify(getLastLoggedInEmployees))
          }
        } catch (catcherr) {
          reject(catcherr)
        }
      })
    }

    function changeRootScopeUserDetailOREmployeeDetail (user) {
      try {
        if (user._id) {
          if ($rootScope.user && $rootScope.user._id && ($rootScope.user._id).toString() === (user._id).toString()) {
            $rootScope.user = user
            localStorage.setItem('currentUserName', $rootScope.user.firstName + ($rootScope.user.lastNamePrefix ? ' ' + $rootScope.user.lastNamePrefix : '') + ' ' + $rootScope.user.lastName)
          }

          if ($rootScope.currentEmployee && $rootScope.currentEmployee._id && ($rootScope.currentEmployee._id).toString() === (user._id).toString()) {
            $rootScope.currentEmployee = user
            localStorage.setItem('currentUserName', $rootScope.currentEmployee.firstName + ($rootScope.currentEmployee.lastNamePrefix ? ' ' + $rootScope.currentEmployee.lastNamePrefix : '') + ' ' + $rootScope.currentEmployee.lastName)
            localStorage.setItem('currentEmployee', JSON.stringify(user))
          }
        }
      } catch (catcherr) {
        console.error('catcherr: ', catcherr)
      }
    }

    function openEmployeeModal () {
      localStorage.setItem('isEnableEmployeeLogin', true)
      $rootScope.stopIdleTimer()
      localStorage.removeItem('currentEmployee')
      openUnlockModal()
    }

    function addEmployee (body) {
      return $q(function (resolve, reject) {
        $api.post('shop/employee', body).then(function (response) {
          resolve(response.data)
        }).catch(function (error) {
          reject(error)
        })
      })
    }

    function deleteRedoEmployee(body) {
      return $api.post('v2/user/delete-employee', body)
    }

    return {
      getEmployees: getEmployees,
      loginEmployee: loginEmployee,
      openUnlockModal: openUnlockModal,
      openWarningModal: openWarningModal,
      getEmployee: getEmployee,
      lockEmployee: lockEmployee,
      changeEmployeeLockingInAllDevice: changeEmployeeLockingInAllDevice,
      EmployeeLoginIsEnableOrDisableForThisDevice: EmployeeLoginIsEnableOrDisableForThisDevice,
      getCurrentUserData: getCurrentUserData,
      setSession: setSession,
      openConfirmPinModal: openConfirmPinModal,
      removeEmployeeFromLocalStorage: removeEmployeeFromLocalStorage,
      changeRootScopeUserDetailOREmployeeDetail: changeRootScopeUserDetailOREmployeeDetail,
      openEmployeeModal: openEmployeeModal,
      addEmployee: addEmployee,
      updateLocalStorage: updateLocalStorage,
      deleteRedoEmployee: deleteRedoEmployee
    }
  }])
