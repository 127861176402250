prismanoteApp.factory('$crypto', ['$log',
  function ($log) {
    var key = '2e35f242a46d67eeb74aabc37d5e5d05'

    function encrypt (data) {
      return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString(); // Encryption Part
    }

    function decrypt(data) {
      return CryptoJS.AES.decrypt(data, key)
    }

    return {
      encrypt: encrypt,
      decrypt: decrypt
    }
  }])
