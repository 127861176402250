prismanoteApp.service('$news', ['$q', '$api', function ($q, $api) {
  var newsValidation = function (news) {
    return $q(function (resolve, reject) {
      console.log('isValidNews: ', news)
      if (news.isAdd) news.creationDate = new Date()
      news.lastModified = new Date()
      try {
        if (news && news.name && news.name.length && (news.name.length < 4 || news.name.length > 60)) {
          reject({ message: 'Invalid news name. News name must be 4-60 characters long' })
        } else if (news && news.summary && news.summary.length && (news.summary.length < 5 || news.summary.length > 180)) {
          reject({ message: 'Invalid brief description. Brief description must be 3-180 characters long' })
        } else if (news.isPublished == undefined) {
          news.isPublished = false
        } else {
          resolve({ message: 'Valid news' })
        }
      } catch (error) {
        reject({ message: 'Internal Server Error', error: error })
      }
    })
  }

  this.newsValidation = newsValidation
}])
