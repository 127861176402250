prismanoteApp.factory('countryService', ['$q', '$injector',
  function ($q, $injector) {
    // Data copied and transformed from https://github.com/mjahn/Multilingual-Country-List/tree/master/data
    var countries_NL = function () {
      var countryList = []
      countryList.AF = 'Afghanistan'
      countryList.AX = 'Åland'
      countryList.AL = 'Albanië'
      countryList.DZ = 'Algerije'
      countryList.AS = 'Amerikaans-Samoa'
      countryList.VI = 'Amerikaanse Maagdeneilanden'
      countryList.AD = 'Andorra'
      countryList.AO = 'Angola'
      countryList.AI = 'Anguilla'
      countryList.AQ = 'Antarctica'
      countryList.AG = 'Antigua en Barbuda'
      countryList.AR = 'Argentinië'
      countryList.AM = 'Armenië'
      countryList.AW = 'Aruba'
      countryList.AC = 'Ascension'
      countryList.AU = 'Australië'
      countryList.AZ = 'Azerbeidzjan'
      countryList.BS = 'Bahama’s'
      countryList.BH = 'Bahrein'
      countryList.BD = 'Bangladesh'
      countryList.BB = 'Barbados'
      countryList.BY = 'Belarus'
      countryList.BE = 'België'
      countryList.BZ = 'Belize'
      countryList.BJ = 'Benin'
      countryList.BM = 'Bermuda'
      countryList.BT = 'Bhutan'
      countryList.BO = 'Bolivia'
      countryList.BA = 'Bosnië en Herzegovina'
      countryList.BW = 'Botswana'
      countryList.BR = 'Brazilië'
      countryList.IO = 'Britse Gebieden in de Indische Oceaan'
      countryList.VG = 'Britse Maagdeneilanden'
      countryList.BN = 'Brunei'
      countryList.BG = 'Bulgarije'
      countryList.BF = 'Burkina Faso'
      countryList.BI = 'Burundi'
      countryList.KH = 'Cambodja'
      countryList.CA = 'Canada'
      countryList.IC = 'Canarische Eilanden'
      countryList.BQ = 'Caribisch Nederland'
      countryList.CF = 'Centraal-Afrikaanse Republiek'
      countryList.EA = 'Ceuta en Melilla'
      countryList.CL = 'Chili'
      countryList.CN = 'China'
      countryList.CX = 'Christmaseiland'
      countryList.CC = 'Cocoseilanden'
      countryList.CO = 'Colombia'
      countryList.KM = 'Comoren'
      countryList.CG = 'Congo-Brazzaville'
      countryList.CD = 'Congo-Kinshasa'
      countryList.CK = 'Cookeilanden'
      countryList.CR = 'Costa Rica'
      countryList.CU = 'Cuba'
      countryList.CW = 'Curaçao'
      countryList.CY = 'Cyprus'
      countryList.DK = 'Denemarken'
      countryList.DG = 'Diego Garcia'
      countryList.DJ = 'Djibouti'
      countryList.DM = 'Dominica'
      countryList.DO = 'Dominicaanse Republiek'
      countryList.DE = 'Duitsland'
      countryList.EC = 'Ecuador'
      countryList.EG = 'Egypte'
      countryList.SV = 'El Salvador'
      countryList.GQ = 'Equatoriaal-Guinea'
      countryList.ER = 'Eritrea'
      countryList.EE = 'Estland'
      countryList.ET = 'Ethiopië'
      countryList.EZ = 'Eurozone'
      countryList.FO = 'Faeröer'
      countryList.FK = 'Falklandeilanden'
      countryList.FJ = 'Fiji'
      countryList.PH = 'Filipijnen'
      countryList.FI = 'Finland'
      countryList.FR = 'Frankrijk'
      countryList.GF = 'Frans-Guyana'
      countryList.PF = 'Frans-Polynesië'
      countryList.TF = 'Franse Gebieden in de zuidelijke Indische Oceaan'
      countryList.GA = 'Gabon'
      countryList.GM = 'Gambia'
      countryList.GE = 'Georgië'
      countryList.GH = 'Ghana'
      countryList.GI = 'Gibraltar'
      countryList.GD = 'Grenada'
      countryList.GR = 'Griekenland'
      countryList.GL = 'Groenland'
      countryList.GP = 'Guadeloupe'
      countryList.GU = 'Guam'
      countryList.GT = 'Guatemala'
      countryList.GG = 'Guernsey'
      countryList.GN = 'Guinee'
      countryList.GW = 'Guinee-Bissau'
      countryList.GY = 'Guyana'
      countryList.HT = 'Haïti'
      countryList.HN = 'Honduras'
      countryList.HU = 'Hongarije'
      countryList.HK = 'Hongkong SAR van China'
      countryList.IE = 'Ierland'
      countryList.IS = 'IJsland'
      countryList.IN = 'India'
      countryList.ID = 'Indonesië'
      countryList.IQ = 'Irak'
      countryList.IR = 'Iran'
      countryList.IM = 'Isle of Man'
      countryList.IL = 'Israël'
      countryList.IT = 'Italië'
      countryList.CI = 'Ivoorkust'
      countryList.JM = 'Jamaica'
      countryList.JP = 'Japan'
      countryList.YE = 'Jemen'
      countryList.JE = 'Jersey'
      countryList.JO = 'Jordanië'
      countryList.KY = 'Kaaimaneilanden'
      countryList.CV = 'Kaapverdië'
      countryList.CM = 'Kameroen'
      countryList.KZ = 'Kazachstan'
      countryList.KE = 'Kenia'
      countryList.KG = 'Kirgizië'
      countryList.KI = 'Kiribati'
      countryList.UM = 'Kleine afgelegen eilanden van de Verenigde Staten'
      countryList.KW = 'Koeweit'
      countryList.XK = 'Kosovo'
      countryList.HR = 'Kroatië'
      countryList.LA = 'Laos'
      countryList.LS = 'Lesotho'
      countryList.LV = 'Letland'
      countryList.LB = 'Libanon'
      countryList.LR = 'Liberia'
      countryList.LY = 'Libië'
      countryList.LI = 'Liechtenstein'
      countryList.LT = 'Litouwen'
      countryList.LU = 'Luxemburg'
      countryList.MO = 'Macau SAR van China'
      countryList.MK = 'Macedonië'
      countryList.MG = 'Madagaskar'
      countryList.MW = 'Malawi'
      countryList.MV = 'Maldiven'
      countryList.MY = 'Maleisië'
      countryList.ML = 'Mali'
      countryList.MT = 'Malta'
      countryList.MA = 'Marokko'
      countryList.MH = 'Marshalleilanden'
      countryList.MQ = 'Martinique'
      countryList.MR = 'Mauritanië'
      countryList.MU = 'Mauritius'
      countryList.YT = 'Mayotte'
      countryList.MX = 'Mexico'
      countryList.FM = 'Micronesia'
      countryList.MD = 'Moldavië'
      countryList.MC = 'Monaco'
      countryList.MN = 'Mongolië'
      countryList.ME = 'Montenegro'
      countryList.MS = 'Montserrat'
      countryList.MZ = 'Mozambique'
      countryList.MM = 'Myanmar (Birma)'
      countryList.NA = 'Namibië'
      countryList.NR = 'Nauru'
      countryList.NL = 'Nederland'
      countryList.NP = 'Nepal'
      countryList.NI = 'Nicaragua'
      countryList.NC = 'Nieuw-Caledonië'
      countryList.NZ = 'Nieuw-Zeeland'
      countryList.NE = 'Niger'
      countryList.NG = 'Nigeria'
      countryList.NU = 'Niue'
      countryList.KP = 'Noord-Korea'
      countryList.MP = 'Noordelijke Marianen'
      countryList.NO = 'Noorwegen'
      countryList.NF = 'Norfolk'
      countryList.UG = 'Oeganda'
      countryList.UA = 'Oekraïne'
      countryList.UZ = 'Oezbekistan'
      countryList.OM = 'Oman'
      countryList.TL = 'Oost-Timor'
      countryList.AT = 'Oostenrijk'
      countryList.PK = 'Pakistan'
      countryList.PW = 'Palau'
      countryList.PS = 'Palestijnse gebieden'
      countryList.PA = 'Panama'
      countryList.PG = 'Papoea-Nieuw-Guinea'
      countryList.PY = 'Paraguay'
      countryList.PE = 'Peru'
      countryList.PN = 'Pitcairneilanden'
      countryList.PL = 'Polen'
      countryList.PT = 'Portugal'
      countryList.PR = 'Puerto Rico'
      countryList.QA = 'Qatar'
      countryList.RE = 'Réunion'
      countryList.RO = 'Roemenië'
      countryList.RU = 'Rusland'
      countryList.RW = 'Rwanda'
      countryList.KN = 'Saint Kitts en Nevis'
      countryList.LC = 'Saint Lucia'
      countryList.VC = 'Saint Vincent en de Grenadines'
      countryList.BL = 'Saint-Barthélemy'
      countryList.MF = 'Saint-Martin'
      countryList.PM = 'Saint-Pierre en Miquelon'
      countryList.SB = 'Salomonseilanden'
      countryList.WS = 'Samoa'
      countryList.SM = 'San Marino'
      countryList.ST = 'Sao Tomé en Principe'
      countryList.SA = 'Saoedi-Arabië'
      countryList.SN = 'Senegal'
      countryList.RS = 'Servië'
      countryList.SC = 'Seychellen'
      countryList.SL = 'Sierra Leone'
      countryList.SG = 'Singapore'
      countryList.SH = 'Sint-Helena'
      countryList.SX = 'Sint-Maarten'
      countryList.SI = 'Slovenië'
      countryList.SK = 'Slowakije'
      countryList.SD = 'Soedan'
      countryList.SO = 'Somalië'
      countryList.ES = 'Spanje'
      countryList.SJ = 'Spitsbergen en Jan Mayen'
      countryList.LK = 'Sri Lanka'
      countryList.SR = 'Suriname'
      countryList.SZ = 'Swaziland'
      countryList.SY = 'Syrië'
      countryList.TJ = 'Tadzjikistan'
      countryList.TW = 'Taiwan'
      countryList.TZ = 'Tanzania'
      countryList.TH = 'Thailand'
      countryList.TG = 'Togo'
      countryList.TK = 'Tokelau'
      countryList.TO = 'Tonga'
      countryList.TT = 'Trinidad en Tobago'
      countryList.TA = 'Tristan da Cunha'
      countryList.TD = 'Tsjaad'
      countryList.CZ = 'Tsjechische Republiek'
      countryList.TN = 'Tunesië'
      countryList.TR = 'Turkije'
      countryList.TM = 'Turkmenistan'
      countryList.TC = 'Turks- en Caicoseilanden'
      countryList.TV = 'Tuvalu'
      countryList.UY = 'Uruguay'
      countryList.VU = 'Vanuatu'
      countryList.VA = 'Vaticaanstad'
      countryList.VE = 'Venezuela'
      countryList.GB = 'Verenigd Koninkrijk'
      countryList.AE = 'Verenigde Arabische Emiraten'
      countryList.UN = 'verenigde naties'
      countryList.US = 'Verenigde Staten'
      countryList.VN = 'Vietnam'
      countryList.WF = 'Wallis en Futuna'
      countryList.EH = 'Westelijke Sahara'
      countryList.ZM = 'Zambia'
      countryList.ZW = 'Zimbabwe'
      countryList.ZA = 'Zuid-Afrika'
      countryList.GS = 'Zuid-Georgia en Zuidelijke Sandwicheilanden'
      countryList.KR = 'Zuid-Korea'
      countryList.SS = 'Zuid-Soedan'
      countryList.SE = 'Zweden'
      countryList.CH = 'Zwitserland'

      return countryList
    }

    var countries_EN = function () {
      var countryList = []
      countryList.AF = 'Afghanistan'
      countryList.AX = 'Åland Islands'
      countryList.AL = 'Albania'
      countryList.DZ = 'Algeria'
      countryList.AS = 'American Samoa'
      countryList.AD = 'Andorra'
      countryList.AO = 'Angola'
      countryList.AI = 'Anguilla'
      countryList.AQ = 'Antarctica'
      countryList.AG = 'Antigua & Barbuda'
      countryList.AR = 'Argentina'
      countryList.AM = 'Armenia'
      countryList.AW = 'Aruba'
      countryList.AC = 'Ascension Island'
      countryList.AU = 'Australia'
      countryList.AT = 'Austria'
      countryList.AZ = 'Azerbaijan'
      countryList.BS = 'Bahamas'
      countryList.BH = 'Bahrain'
      countryList.BD = 'Bangladesh'
      countryList.BB = 'Barbados'
      countryList.BY = 'Belarus'
      countryList.BE = 'Belgium'
      countryList.BZ = 'Belize'
      countryList.BJ = 'Benin'
      countryList.BM = 'Bermuda'
      countryList.BT = 'Bhutan'
      countryList.BO = 'Bolivia'
      countryList.BA = 'Bosnia & Herzegovina'
      countryList.BW = 'Botswana'
      countryList.BR = 'Brazil'
      countryList.IO = 'British Indian Ocean Territory'
      countryList.VG = 'British Virgin Islands'
      countryList.BN = 'Brunei'
      countryList.BG = 'Bulgaria'
      countryList.BF = 'Burkina Faso'
      countryList.BI = 'Burundi'
      countryList.KH = 'Cambodia'
      countryList.CM = 'Cameroon'
      countryList.CA = 'Canada'
      countryList.IC = 'Canary Islands'
      countryList.CV = 'Cape Verde'
      countryList.BQ = 'Caribbean Netherlands'
      countryList.KY = 'Cayman Islands'
      countryList.CF = 'Central African Republic'
      countryList.EA = 'Ceuta & Melilla'
      countryList.TD = 'Chad'
      countryList.CL = 'Chile'
      countryList.CN = 'China'
      countryList.CX = 'Christmas Island'
      countryList.CC = 'Cocos (Keeling) Islands'
      countryList.CO = 'Colombia'
      countryList.KM = 'Comoros'
      countryList.CG = 'Congo - Brazzaville'
      countryList.CD = 'Congo - Kinshasa'
      countryList.CK = 'Cook Islands'
      countryList.CR = 'Costa Rica'
      countryList.CI = 'Côte d’Ivoire'
      countryList.HR = 'Croatia'
      countryList.CU = 'Cuba'
      countryList.CW = 'Curaçao'
      countryList.CY = 'Cyprus'
      countryList.CZ = 'Czechia'
      countryList.DK = 'Denmark'
      countryList.DG = 'Diego Garcia'
      countryList.DJ = 'Djibouti'
      countryList.DM = 'Dominica'
      countryList.DO = 'Dominican Republic'
      countryList.EC = 'Ecuador'
      countryList.EG = 'Egypt'
      countryList.SV = 'El Salvador'
      countryList.GQ = 'Equatorial Guinea'
      countryList.ER = 'Eritrea'
      countryList.EE = 'Estonia'
      countryList.ET = 'Ethiopia'
      countryList.EZ = 'Eurozone'
      countryList.FK = 'Falkland Islands'
      countryList.FO = 'Faroe Islands'
      countryList.FJ = 'Fiji'
      countryList.FI = 'Finland'
      countryList.FR = 'France'
      countryList.GF = 'French Guiana'
      countryList.PF = 'French Polynesia'
      countryList.TF = 'French Southern Territories'
      countryList.GA = 'Gabon'
      countryList.GM = 'Gambia'
      countryList.GE = 'Georgia'
      countryList.DE = 'Germany'
      countryList.GH = 'Ghana'
      countryList.GI = 'Gibraltar'
      countryList.GR = 'Greece'
      countryList.GL = 'Greenland'
      countryList.GD = 'Grenada'
      countryList.GP = 'Guadeloupe'
      countryList.GU = 'Guam'
      countryList.GT = 'Guatemala'
      countryList.GG = 'Guernsey'
      countryList.GN = 'Guinea'
      countryList.GW = 'Guinea-Bissau'
      countryList.GY = 'Guyana'
      countryList.HT = 'Haiti'
      countryList.HN = 'Honduras'
      countryList.HK = 'Hong Kong SAR China'
      countryList.HU = 'Hungary'
      countryList.IS = 'Iceland'
      countryList.IN = 'India'
      countryList.ID = 'Indonesia'
      countryList.IR = 'Iran'
      countryList.IQ = 'Iraq'
      countryList.IE = 'Ireland'
      countryList.IM = 'Isle of Man'
      countryList.IL = 'Israel'
      countryList.IT = 'Italy'
      countryList.JM = 'Jamaica'
      countryList.JP = 'Japan'
      countryList.JE = 'Jersey'
      countryList.JO = 'Jordan'
      countryList.KZ = 'Kazakhstan'
      countryList.KE = 'Kenya'
      countryList.KI = 'Kiribati'
      countryList.XK = 'Kosovo'
      countryList.KW = 'Kuwait'
      countryList.KG = 'Kyrgyzstan'
      countryList.LA = 'Laos'
      countryList.LV = 'Latvia'
      countryList.LB = 'Lebanon'
      countryList.LS = 'Lesotho'
      countryList.LR = 'Liberia'
      countryList.LY = 'Libya'
      countryList.LI = 'Liechtenstein'
      countryList.LT = 'Lithuania'
      countryList.LU = 'Luxembourg'
      countryList.MO = 'Macau SAR China'
      countryList.MK = 'Macedonia'
      countryList.MG = 'Madagascar'
      countryList.MW = 'Malawi'
      countryList.MY = 'Malaysia'
      countryList.MV = 'Maldives'
      countryList.ML = 'Mali'
      countryList.MT = 'Malta'
      countryList.MH = 'Marshall Islands'
      countryList.MQ = 'Martinique'
      countryList.MR = 'Mauritania'
      countryList.MU = 'Mauritius'
      countryList.YT = 'Mayotte'
      countryList.MX = 'Mexico'
      countryList.FM = 'Micronesia'
      countryList.MD = 'Moldova'
      countryList.MC = 'Monaco'
      countryList.MN = 'Mongolia'
      countryList.ME = 'Montenegro'
      countryList.MS = 'Montserrat'
      countryList.MA = 'Morocco'
      countryList.MZ = 'Mozambique'
      countryList.MM = 'Myanmar (Burma)'
      countryList.NA = 'Namibia'
      countryList.NR = 'Nauru'
      countryList.NP = 'Nepal'
      countryList.NL = 'Netherlands'
      countryList.NC = 'New Caledonia'
      countryList.NZ = 'New Zealand'
      countryList.NI = 'Nicaragua'
      countryList.NE = 'Niger'
      countryList.NG = 'Nigeria'
      countryList.NU = 'Niue'
      countryList.NF = 'Norfolk Island'
      countryList.KP = 'North Korea'
      countryList.MP = 'Northern Mariana Islands'
      countryList.NO = 'Norway'
      countryList.OM = 'Oman'
      countryList.PK = 'Pakistan'
      countryList.PW = 'Palau'
      countryList.PS = 'Palestinian Territories'
      countryList.PA = 'Panama'
      countryList.PG = 'Papua New Guinea'
      countryList.PY = 'Paraguay'
      countryList.PE = 'Peru'
      countryList.PH = 'Philippines'
      countryList.PN = 'Pitcairn Islands'
      countryList.PL = 'Poland'
      countryList.PT = 'Portugal'
      countryList.PR = 'Puerto Rico'
      countryList.QA = 'Qatar'
      countryList.RE = 'Réunion'
      countryList.RO = 'Romania'
      countryList.RU = 'Russia'
      countryList.RW = 'Rwanda'
      countryList.WS = 'Samoa'
      countryList.SM = 'San Marino'
      countryList.ST = 'São Tomé & Príncipe'
      countryList.SA = 'Saudi Arabia'
      countryList.SN = 'Senegal'
      countryList.RS = 'Serbia'
      countryList.SC = 'Seychelles'
      countryList.SL = 'Sierra Leone'
      countryList.SG = 'Singapore'
      countryList.SX = 'Sint Maarten'
      countryList.SK = 'Slovakia'
      countryList.SI = 'Slovenia'
      countryList.SB = 'Solomon Islands'
      countryList.SO = 'Somalia'
      countryList.ZA = 'South Africa'
      countryList.GS = 'South Georgia & South Sandwich Islands'
      countryList.KR = 'South Korea'
      countryList.SS = 'South Sudan'
      countryList.ES = 'Spain'
      countryList.LK = 'Sri Lanka'
      countryList.BL = 'St. Barthélemy'
      countryList.SH = 'St. Helena'
      countryList.KN = 'St. Kitts & Nevis'
      countryList.LC = 'St. Lucia'
      countryList.MF = 'St. Martin'
      countryList.PM = 'St. Pierre & Miquelon'
      countryList.VC = 'St. Vincent & Grenadines'
      countryList.SD = 'Sudan'
      countryList.SR = 'Suriname'
      countryList.SJ = 'Svalbard & Jan Mayen'
      countryList.SZ = 'Swaziland'
      countryList.SE = 'Sweden'
      countryList.CH = 'Switzerland'
      countryList.SY = 'Syria'
      countryList.TW = 'Taiwan'
      countryList.TJ = 'Tajikistan'
      countryList.TZ = 'Tanzania'
      countryList.TH = 'Thailand'
      countryList.TL = 'Timor-Leste'
      countryList.TG = 'Togo'
      countryList.TK = 'Tokelau'
      countryList.TO = 'Tonga'
      countryList.TT = 'Trinidad & Tobago'
      countryList.TA = 'Tristan da Cunha'
      countryList.TN = 'Tunisia'
      countryList.TR = 'Turkey'
      countryList.TM = 'Turkmenistan'
      countryList.TC = 'Turks & Caicos Islands'
      countryList.TV = 'Tuvalu'
      countryList.UM = 'U.S. Outlying Islands'
      countryList.VI = 'U.S. Virgin Islands'
      countryList.UG = 'Uganda'
      countryList.UA = 'Ukraine'
      countryList.AE = 'United Arab Emirates'
      countryList.GB = 'United Kingdom'
      countryList.UN = 'United Nations'
      countryList.US = 'United States'
      countryList.UY = 'Uruguay'
      countryList.UZ = 'Uzbekistan'
      countryList.VU = 'Vanuatu'
      countryList.VA = 'Vatican City'
      countryList.VE = 'Venezuela'
      countryList.VN = 'Vietnam'
      countryList.WF = 'Wallis & Futuna'
      countryList.EH = 'Western Sahara'
      countryList.YE = 'Yemen'
      countryList.ZM = 'Zambia'
      countryList.ZW = 'Zimbabwe'

      return countryList
    }

    var countries_DE = function () {
      var countryList = []
      countryList.AF = 'Afghanistan'
      countryList.EG = 'Ägypten'
      countryList.AX = 'Ålandinseln'
      countryList.AL = 'Albanien'
      countryList.DZ = 'Algerien'
      countryList.AS = 'Amerikanisch-Samoa'
      countryList.VI = 'Amerikanische Jungferninseln'
      countryList.UM = 'Amerikanische Überseeinseln'
      countryList.AD = 'Andorra'
      countryList.AO = 'Angola'
      countryList.AI = 'Anguilla'
      countryList.AQ = 'Antarktis'
      countryList.AG = 'Antigua und Barbuda'
      countryList.GQ = 'Äquatorialguinea'
      countryList.AR = 'Argentinien'
      countryList.AM = 'Armenien'
      countryList.AW = 'Aruba'
      countryList.AC = 'Ascension'
      countryList.AZ = 'Aserbaidschan'
      countryList.ET = 'Äthiopien'
      countryList.AU = 'Australien'
      countryList.BS = 'Bahamas'
      countryList.BH = 'Bahrain'
      countryList.BD = 'Bangladesch'
      countryList.BB = 'Barbados'
      countryList.BY = 'Belarus'
      countryList.BE = 'Belgien'
      countryList.BZ = 'Belize'
      countryList.BJ = 'Benin'
      countryList.BM = 'Bermuda'
      countryList.BT = 'Bhutan'
      countryList.BO = 'Bolivien'
      countryList.BQ = 'Bonaire; Sint Eustatius und Saba'
      countryList.BA = 'Bosnien und Herzegowina'
      countryList.BW = 'Botsuana'
      countryList.BR = 'Brasilien'
      countryList.VG = 'Britische Jungferninseln'
      countryList.IO = 'Britisches Territorium im Indischen Ozean'
      countryList.BN = 'Brunei Darussalam'
      countryList.BG = 'Bulgarien'
      countryList.BF = 'Burkina Faso'
      countryList.BI = 'Burundi'
      countryList.CV = 'Cabo Verde'
      countryList.EA = 'Ceuta und Melilla'
      countryList.CL = 'Chile'
      countryList.CN = 'China'
      countryList.CK = 'Cookinseln'
      countryList.CR = 'Costa Rica'
      countryList.CI = 'Côte d’Ivoire'
      countryList.CW = 'Curaçao'
      countryList.DK = 'Dänemark'
      countryList.DE = 'Deutschland'
      countryList.DG = 'Diego Garcia'
      countryList.DM = 'Dominica'
      countryList.DO = 'Dominikanische Republik'
      countryList.DJ = 'Dschibuti'
      countryList.EC = 'Ecuador'
      countryList.SV = 'El Salvador'
      countryList.ER = 'Eritrea'
      countryList.EE = 'Estland'
      countryList.EZ = 'Eurozone'
      countryList.FK = 'Falklandinseln'
      countryList.FO = 'Färöer'
      countryList.FJ = 'Fidschi'
      countryList.FI = 'Finnland'
      countryList.FR = 'Frankreich'
      countryList.GF = 'Französisch-Guayana'
      countryList.PF = 'Französisch-Polynesien'
      countryList.TF = 'Französische Süd- und Antarktisgebiete'
      countryList.GA = 'Gabun'
      countryList.GM = 'Gambia'
      countryList.GE = 'Georgien'
      countryList.GH = 'Ghana'
      countryList.GI = 'Gibraltar'
      countryList.GD = 'Grenada'
      countryList.GR = 'Griechenland'
      countryList.GL = 'Grönland'
      countryList.GP = 'Guadeloupe'
      countryList.GU = 'Guam'
      countryList.GT = 'Guatemala'
      countryList.GG = 'Guernsey'
      countryList.GN = 'Guinea'
      countryList.GW = 'Guinea-Bissau'
      countryList.GY = 'Guyana'
      countryList.HT = 'Haiti'
      countryList.HN = 'Honduras'
      countryList.IN = 'Indien'
      countryList.ID = 'Indonesien'
      countryList.IQ = 'Irak'
      countryList.IR = 'Iran'
      countryList.IE = 'Irland'
      countryList.IS = 'Island'
      countryList.IM = 'Isle of Man'
      countryList.IL = 'Israel'
      countryList.IT = 'Italien'
      countryList.JM = 'Jamaika'
      countryList.JP = 'Japan'
      countryList.YE = 'Jemen'
      countryList.JE = 'Jersey'
      countryList.JO = 'Jordanien'
      countryList.KY = 'Kaimaninseln'
      countryList.KH = 'Kambodscha'
      countryList.CM = 'Kamerun'
      countryList.CA = 'Kanada'
      countryList.IC = 'Kanarische Inseln'
      countryList.KZ = 'Kasachstan'
      countryList.QA = 'Katar'
      countryList.KE = 'Kenia'
      countryList.KG = 'Kirgisistan'
      countryList.KI = 'Kiribati'
      countryList.CC = 'Kokosinseln'
      countryList.CO = 'Kolumbien'
      countryList.KM = 'Komoren'
      countryList.CG = 'Kongo-Brazzaville'
      countryList.CD = 'Kongo-Kinshasa'
      countryList.XK = 'Kosovo'
      countryList.HR = 'Kroatien'
      countryList.CU = 'Kuba'
      countryList.KW = 'Kuwait'
      countryList.LA = 'Laos'
      countryList.LS = 'Lesotho'
      countryList.LV = 'Lettland'
      countryList.LB = 'Libanon'
      countryList.LR = 'Liberia'
      countryList.LY = 'Libyen'
      countryList.LI = 'Liechtenstein'
      countryList.LT = 'Litauen'
      countryList.LU = 'Luxemburg'
      countryList.MG = 'Madagaskar'
      countryList.MW = 'Malawi'
      countryList.MY = 'Malaysia'
      countryList.MV = 'Malediven'
      countryList.ML = 'Mali'
      countryList.MT = 'Malta'
      countryList.MA = 'Marokko'
      countryList.MH = 'Marshallinseln'
      countryList.MQ = 'Martinique'
      countryList.MR = 'Mauretanien'
      countryList.MU = 'Mauritius'
      countryList.YT = 'Mayotte'
      countryList.MK = 'Mazedonien'
      countryList.MX = 'Mexiko'
      countryList.FM = 'Mikronesien'
      countryList.MC = 'Monaco'
      countryList.MN = 'Mongolei'
      countryList.ME = 'Montenegro'
      countryList.MS = 'Montserrat'
      countryList.MZ = 'Mosambik'
      countryList.MM = 'Myanmar'
      countryList.NA = 'Namibia'
      countryList.NR = 'Nauru'
      countryList.NP = 'Nepal'
      countryList.NC = 'Neukaledonien'
      countryList.NZ = 'Neuseeland'
      countryList.NI = 'Nicaragua'
      countryList.NL = 'Niederlande'
      countryList.NE = 'Niger'
      countryList.NG = 'Nigeria'
      countryList.NU = 'Niue'
      countryList.KP = 'Nordkorea'
      countryList.MP = 'Nördliche Marianen'
      countryList.NF = 'Norfolkinsel'
      countryList.NO = 'Norwegen'
      countryList.OM = 'Oman'
      countryList.AT = 'Österreich'
      countryList.TL = 'Osttimor'
      countryList.PK = 'Pakistan'
      countryList.PS = 'Palästinensische Autonomiegebiete'
      countryList.PW = 'Palau'
      countryList.PA = 'Panama'
      countryList.PG = 'Papua-Neuguinea'
      countryList.PY = 'Paraguay'
      countryList.PE = 'Peru'
      countryList.PH = 'Philippinen'
      countryList.PN = 'Pitcairninseln'
      countryList.PL = 'Polen'
      countryList.PT = 'Portugal'
      countryList.PR = 'Puerto Rico'
      countryList.MD = 'Republik Moldau'
      countryList.RE = 'Réunion'
      countryList.RW = 'Ruanda'
      countryList.RO = 'Rumänien'
      countryList.RU = 'Russland'
      countryList.SB = 'Salomonen'
      countryList.ZM = 'Sambia'
      countryList.WS = 'Samoa'
      countryList.SM = 'San Marino'
      countryList.ST = 'São Tomé und Príncipe'
      countryList.SA = 'Saudi-Arabien'
      countryList.SE = 'Schweden'
      countryList.CH = 'Schweiz'
      countryList.SN = 'Senegal'
      countryList.RS = 'Serbien'
      countryList.SC = 'Seychellen'
      countryList.SL = 'Sierra Leone'
      countryList.ZW = 'Simbabwe'
      countryList.SG = 'Singapur'
      countryList.SX = 'Sint Maarten'
      countryList.SK = 'Slowakei'
      countryList.SI = 'Slowenien'
      countryList.SO = 'Somalia'
      countryList.MO = 'Sonderverwaltungsregion Macau'
      countryList.HK = 'Sonderverwaltungszone Hongkong'
      countryList.ES = 'Spanien'
      countryList.SJ = 'Spitzbergen'
      countryList.LK = 'Sri Lanka'
      countryList.BL = 'St. Barthélemy'
      countryList.SH = 'St. Helena'
      countryList.KN = 'St. Kitts und Nevis'
      countryList.LC = 'St. Lucia'
      countryList.MF = 'St. Martin'
      countryList.PM = 'St. Pierre und Miquelon'
      countryList.VC = 'St. Vincent und die Grenadinen'
      countryList.ZA = 'Südafrika'
      countryList.SD = 'Sudan'
      countryList.GS = 'Südgeorgien und die Südlichen Sandwichinseln'
      countryList.KR = 'Südkorea'
      countryList.SS = 'Südsudan'
      countryList.SR = 'Suriname'
      countryList.SZ = 'Swasiland'
      countryList.SY = 'Syrien'
      countryList.TJ = 'Tadschikistan'
      countryList.TW = 'Taiwan'
      countryList.TZ = 'Tansania'
      countryList.TH = 'Thailand'
      countryList.TG = 'Togo'
      countryList.TK = 'Tokelau'
      countryList.TO = 'Tonga'
      countryList.TT = 'Trinidad und Tobago'
      countryList.TA = 'Tristan da Cunha'
      countryList.TD = 'Tschad'
      countryList.CZ = 'Tschechien'
      countryList.TN = 'Tunesien'
      countryList.TR = 'Türkei'
      countryList.TM = 'Turkmenistan'
      countryList.TC = 'Turks- und Caicosinseln'
      countryList.TV = 'Tuvalu'
      countryList.UG = 'Uganda'
      countryList.UA = 'Ukraine'
      countryList.HU = 'Ungarn'
      countryList.UY = 'Uruguay'
      countryList.UZ = 'Usbekistan'
      countryList.VU = 'Vanuatu'
      countryList.VA = 'Vatikanstadt'
      countryList.VE = 'Venezuela'
      countryList.AE = 'Vereinigte Arabische Emirate'
      countryList.US = 'Vereinigte Staaten'
      countryList.GB = 'Vereinigtes Königreich'
      countryList.UN = 'Vereinte Nationen'
      countryList.VN = 'Vietnam'
      countryList.WF = 'Wallis und Futuna'
      countryList.CX = 'Weihnachtsinsel'
      countryList.EH = 'Westsahara'
      countryList.CF = 'Zentralafrikanische Republik'
      countryList.CY = 'Zypern'

      return countryList
    }

    var countries_FR = function () {
      var countryList = []
      countryList.AF = 'Afghanistan'
      countryList.ZA = 'Afrique du Sud'
      countryList.AL = 'Albanie'
      countryList.DZ = 'Algérie'
      countryList.DE = 'Allemagne'
      countryList.AD = 'Andorre'
      countryList.AO = 'Angola'
      countryList.AI = 'Anguilla'
      countryList.AQ = 'Antarctique'
      countryList.AG = 'Antigua-et-Barbuda'
      countryList.SA = 'Arabie saoudite'
      countryList.AR = 'Argentine'
      countryList.AM = 'Arménie'
      countryList.AW = 'Aruba'
      countryList.AU = 'Australie'
      countryList.AT = 'Autriche'
      countryList.AZ = 'Azerbaïdjan'
      countryList.BS = 'Bahamas'
      countryList.BH = 'Bahreïn'
      countryList.BD = 'Bangladesh'
      countryList.BB = 'Barbade'
      countryList.BE = 'Belgique'
      countryList.BZ = 'Belize'
      countryList.BJ = 'Bénin'
      countryList.BM = 'Bermudes'
      countryList.BT = 'Bhoutan'
      countryList.BY = 'Biélorussie'
      countryList.BO = 'Bolivie'
      countryList.BA = 'Bosnie-Herzégovine'
      countryList.BW = 'Botswana'
      countryList.BR = 'Brésil'
      countryList.BN = 'Brunéi Darussalam'
      countryList.BG = 'Bulgarie'
      countryList.BF = 'Burkina Faso'
      countryList.BI = 'Burundi'
      countryList.KH = 'Cambodge'
      countryList.CM = 'Cameroun'
      countryList.CA = 'Canada'
      countryList.CV = 'Cap-Vert'
      countryList.EA = 'Ceuta et Melilla'
      countryList.CL = 'Chili'
      countryList.CN = 'Chine'
      countryList.CY = 'Chypre'
      countryList.CO = 'Colombie'
      countryList.KM = 'Comores'
      countryList.CG = 'Congo-Brazzaville'
      countryList.CD = 'Congo-Kinshasa'
      countryList.KP = 'Corée du Nord'
      countryList.KR = 'Corée du Sud'
      countryList.CR = 'Costa Rica'
      countryList.CI = 'Côte d’Ivoire'
      countryList.HR = 'Croatie'
      countryList.CU = 'Cuba'
      countryList.CW = 'Curaçao'
      countryList.DK = 'Danemark'
      countryList.DG = 'Diego Garcia'
      countryList.DJ = 'Djibouti'
      countryList.DM = 'Dominique'
      countryList.EG = 'Égypte'
      countryList.SV = 'El Salvador'
      countryList.AE = 'Émirats arabes unis'
      countryList.EC = 'Équateur'
      countryList.ER = 'Érythrée'
      countryList.ES = 'Espagne'
      countryList.EE = 'Estonie'
      countryList.VA = 'État de la Cité du Vatican'
      countryList.FM = 'États fédérés de Micronésie'
      countryList.US = 'États-Unis'
      countryList.ET = 'Éthiopie'
      countryList.EZ = 'Eurozone'
      countryList.FJ = 'Fidji'
      countryList.FI = 'Finlande'
      countryList.FR = 'France'
      countryList.GA = 'Gabon'
      countryList.GM = 'Gambie'
      countryList.GE = 'Géorgie'
      countryList.GS = 'Géorgie du Sud et îles Sandwich du Sud'
      countryList.GH = 'Ghana'
      countryList.GI = 'Gibraltar'
      countryList.GR = 'Grèce'
      countryList.GD = 'Grenade'
      countryList.GL = 'Groenland'
      countryList.GP = 'Guadeloupe'
      countryList.GU = 'Guam'
      countryList.GT = 'Guatemala'
      countryList.GG = 'Guernesey'
      countryList.GN = 'Guinée'
      countryList.GQ = 'Guinée équatoriale'
      countryList.GW = 'Guinée-Bissau'
      countryList.GY = 'Guyana'
      countryList.GF = 'Guyane française'
      countryList.HT = 'Haïti'
      countryList.HN = 'Honduras'
      countryList.HU = 'Hongrie'
      countryList.CX = 'Île Christmas'
      countryList.AC = 'Île de l’Ascension'
      countryList.IM = 'Île de Man'
      countryList.NF = 'Île Norfolk'
      countryList.AX = 'Îles Åland'
      countryList.KY = 'Îles Caïmans'
      countryList.IC = 'Îles Canaries'
      countryList.CC = 'Îles Cocos'
      countryList.CK = 'Îles Cook'
      countryList.FO = 'Îles Féroé'
      countryList.FK = 'Îles Malouines'
      countryList.MP = 'Îles Mariannes du Nord'
      countryList.MH = 'Îles Marshall'
      countryList.UM = 'Îles mineures éloignées des États-Unis'
      countryList.PN = 'Îles Pitcairn'
      countryList.SB = 'Îles Salomon'
      countryList.TC = 'Îles Turques-et-Caïques'
      countryList.VG = 'Îles Vierges britanniques'
      countryList.VI = 'Îles Vierges des États-Unis'
      countryList.IN = 'Inde'
      countryList.ID = 'Indonésie'
      countryList.IQ = 'Irak'
      countryList.IR = 'Iran'
      countryList.IE = 'Irlande'
      countryList.IS = 'Islande'
      countryList.IL = 'Israël'
      countryList.IT = 'Italie'
      countryList.JM = 'Jamaïque'
      countryList.JP = 'Japon'
      countryList.JE = 'Jersey'
      countryList.JO = 'Jordanie'
      countryList.KZ = 'Kazakhstan'
      countryList.KE = 'Kenya'
      countryList.KG = 'Kirghizistan'
      countryList.KI = 'Kiribati'
      countryList.XK = 'Kosovo'
      countryList.KW = 'Koweït'
      countryList.RE = 'La Réunion'
      countryList.LA = 'Laos'
      countryList.LS = 'Lesotho'
      countryList.LV = 'Lettonie'
      countryList.LB = 'Liban'
      countryList.LR = 'Libéria'
      countryList.LY = 'Libye'
      countryList.LI = 'Liechtenstein'
      countryList.LT = 'Lituanie'
      countryList.LU = 'Luxembourg'
      countryList.MK = 'Macédoine'
      countryList.MG = 'Madagascar'
      countryList.MY = 'Malaisie'
      countryList.MW = 'Malawi'
      countryList.MV = 'Maldives'
      countryList.ML = 'Mali'
      countryList.MT = 'Malte'
      countryList.MA = 'Maroc'
      countryList.MQ = 'Martinique'
      countryList.MU = 'Maurice'
      countryList.MR = 'Mauritanie'
      countryList.YT = 'Mayotte'
      countryList.MX = 'Mexique'
      countryList.MD = 'Moldavie'
      countryList.MC = 'Monaco'
      countryList.MN = 'Mongolie'
      countryList.ME = 'Monténégro'
      countryList.MS = 'Montserrat'
      countryList.MZ = 'Mozambique'
      countryList.MM = 'Myanmar (Birmanie)'
      countryList.NA = 'Namibie'
      countryList.UN = 'Nations Unies'
      countryList.NR = 'Nauru'
      countryList.NP = 'Népal'
      countryList.NI = 'Nicaragua'
      countryList.NE = 'Niger'
      countryList.NG = 'Nigéria'
      countryList.NU = 'Niue'
      countryList.NO = 'Norvège'
      countryList.NC = 'Nouvelle-Calédonie'
      countryList.NZ = 'Nouvelle-Zélande'
      countryList.OM = 'Oman'
      countryList.UG = 'Ouganda'
      countryList.UZ = 'Ouzbékistan'
      countryList.PK = 'Pakistan'
      countryList.PW = 'Palaos'
      countryList.PA = 'Panama'
      countryList.PG = 'Papouasie-Nouvelle-Guinée'
      countryList.PY = 'Paraguay'
      countryList.NL = 'Pays-Bas'
      countryList.BQ = 'Pays-Bas caribéens'
      countryList.PE = 'Pérou'
      countryList.PH = 'Philippines'
      countryList.PL = 'Pologne'
      countryList.PF = 'Polynésie française'
      countryList.PR = 'Porto Rico'
      countryList.PT = 'Portugal'
      countryList.QA = 'Qatar'
      countryList.HK = 'R.A.S. chinoise de Hong Kong'
      countryList.MO = 'R.A.S. chinoise de Macao'
      countryList.CF = 'République centrafricaine'
      countryList.DO = 'République dominicaine'
      countryList.RO = 'Roumanie'
      countryList.GB = 'Royaume-Uni'
      countryList.RU = 'Russie'
      countryList.RW = 'Rwanda'
      countryList.EH = 'Sahara occidental'
      countryList.BL = 'Saint-Barthélemy'
      countryList.KN = 'Saint-Christophe-et-Niévès'
      countryList.SM = 'Saint-Marin'
      countryList.MF = 'Saint-Martin'
      countryList.SX = 'Saint-Martin (partie néerlandaise)'
      countryList.PM = 'Saint-Pierre-et-Miquelon'
      countryList.VC = 'Saint-Vincent-et-les-Grenadines'
      countryList.SH = 'Sainte-Hélène'
      countryList.LC = 'Sainte-Lucie'
      countryList.WS = 'Samoa'
      countryList.AS = 'Samoa américaines'
      countryList.ST = 'Sao Tomé-et-Principe'
      countryList.SN = 'Sénégal'
      countryList.RS = 'Serbie'
      countryList.SC = 'Seychelles'
      countryList.SL = 'Sierra Leone'
      countryList.SG = 'Singapour'
      countryList.SK = 'Slovaquie'
      countryList.SI = 'Slovénie'
      countryList.SO = 'Somalie'
      countryList.SD = 'Soudan'
      countryList.SS = 'Soudan du Sud'
      countryList.LK = 'Sri Lanka'
      countryList.SE = 'Suède'
      countryList.CH = 'Suisse'
      countryList.SR = 'Suriname'
      countryList.SJ = 'Svalbard et Jan Mayen'
      countryList.SZ = 'Swaziland'
      countryList.SY = 'Syrie'
      countryList.TJ = 'Tadjikistan'
      countryList.TW = 'Taïwan'
      countryList.TZ = 'Tanzanie'
      countryList.TD = 'Tchad'
      countryList.CZ = 'Tchéquie'
      countryList.TF = 'Terres australes françaises'
      countryList.IO = 'Territoire britannique de l’océan Indien'
      countryList.PS = 'Territoires palestiniens'
      countryList.TH = 'Thaïlande'
      countryList.TL = 'Timor oriental'
      countryList.TG = 'Togo'
      countryList.TK = 'Tokélaou'
      countryList.TO = 'Tonga'
      countryList.TT = 'Trinité-et-Tobago'
      countryList.TA = 'Tristan da Cunha'
      countryList.TN = 'Tunisie'
      countryList.TM = 'Turkménistan'
      countryList.TR = 'Turquie'
      countryList.TV = 'Tuvalu'
      countryList.UA = 'Ukraine'
      countryList.UY = 'Uruguay'
      countryList.VU = 'Vanuatu'
      countryList.VE = 'Venezuela'
      countryList.VN = 'Vietnam'
      countryList.WF = 'Wallis-et-Futuna'
      countryList.YE = 'Yémen'
      countryList.ZM = 'Zambie'
      countryList.ZW = 'Zimbabwe'

      return countryList
    }

    var countries_ES = function () {
      var countryList = []
      countryList.AF = 'Afganistán'
      countryList.AL = 'Albania'
      countryList.DE = 'Alemania'
      countryList.AD = 'Andorra'
      countryList.AO = 'Angola'
      countryList.AI = 'Anguila'
      countryList.AQ = 'Antártida'
      countryList.AG = 'Antigua y Barbuda'
      countryList.SA = 'Arabia Saudí'
      countryList.DZ = 'Argelia'
      countryList.AR = 'Argentina'
      countryList.AM = 'Armenia'
      countryList.AW = 'Aruba'
      countryList.AU = 'Australia'
      countryList.AT = 'Austria'
      countryList.AZ = 'Azerbaiyán'
      countryList.BS = 'Bahamas'
      countryList.BD = 'Bangladés'
      countryList.BB = 'Barbados'
      countryList.BH = 'Baréin'
      countryList.BE = 'Bélgica'
      countryList.BZ = 'Belice'
      countryList.BJ = 'Benín'
      countryList.BM = 'Bermudas'
      countryList.BY = 'Bielorrusia'
      countryList.BO = 'Bolivia'
      countryList.BA = 'Bosnia-Herzegovina'
      countryList.BW = 'Botsuana'
      countryList.BR = 'Brasil'
      countryList.BN = 'Brunéi'
      countryList.BG = 'Bulgaria'
      countryList.BF = 'Burkina Faso'
      countryList.BI = 'Burundi'
      countryList.BT = 'Bután'
      countryList.CV = 'Cabo Verde'
      countryList.KH = 'Camboya'
      countryList.CM = 'Camerún'
      countryList.CA = 'Canadá'
      countryList.IC = 'Canarias'
      countryList.BQ = 'Caribe neerlandés'
      countryList.QA = 'Catar'
      countryList.EA = 'Ceuta y Melilla'
      countryList.TD = 'Chad'
      countryList.CZ = 'Chequia'
      countryList.CL = 'Chile'
      countryList.CN = 'China'
      countryList.CY = 'Chipre'
      countryList.VA = 'Ciudad del Vaticano'
      countryList.CO = 'Colombia'
      countryList.KM = 'Comoras'
      countryList.KP = 'Corea del Norte'
      countryList.KR = 'Corea del Sur'
      countryList.CR = 'Costa Rica'
      countryList.CI = 'Côte d’Ivoire'
      countryList.HR = 'Croacia'
      countryList.CU = 'Cuba'
      countryList.CW = 'Curazao'
      countryList.DG = 'Diego García'
      countryList.DK = 'Dinamarca'
      countryList.DM = 'Dominica'
      countryList.EC = 'Ecuador'
      countryList.EG = 'Egipto'
      countryList.SV = 'El Salvador'
      countryList.AE = 'Emiratos Árabes Unidos'
      countryList.ER = 'Eritrea'
      countryList.SK = 'Eslovaquia'
      countryList.SI = 'Eslovenia'
      countryList.ES = 'España'
      countryList.US = 'Estados Unidos'
      countryList.EE = 'Estonia'
      countryList.ET = 'Etiopía'
      countryList.EZ = 'Eurozone'
      countryList.PH = 'Filipinas'
      countryList.FI = 'Finlandia'
      countryList.FJ = 'Fiyi'
      countryList.FR = 'Francia'
      countryList.GA = 'Gabón'
      countryList.GM = 'Gambia'
      countryList.GE = 'Georgia'
      countryList.GH = 'Ghana'
      countryList.GI = 'Gibraltar'
      countryList.GD = 'Granada'
      countryList.GR = 'Grecia'
      countryList.GL = 'Groenlandia'
      countryList.GP = 'Guadalupe'
      countryList.GU = 'Guam'
      countryList.GT = 'Guatemala'
      countryList.GF = 'Guayana Francesa'
      countryList.GG = 'Guernesey'
      countryList.GN = 'Guinea'
      countryList.GQ = 'Guinea Ecuatorial'
      countryList.GW = 'Guinea-Bisáu'
      countryList.GY = 'Guyana'
      countryList.HT = 'Haití'
      countryList.HN = 'Honduras'
      countryList.HU = 'Hungría'
      countryList.IN = 'India'
      countryList.ID = 'Indonesia'
      countryList.IQ = 'Irak'
      countryList.IR = 'Irán'
      countryList.IE = 'Irlanda'
      countryList.AC = 'Isla de la Ascensión'
      countryList.IM = 'Isla de Man'
      countryList.CX = 'Isla de Navidad'
      countryList.NF = 'Isla Norfolk'
      countryList.IS = 'Islandia'
      countryList.AX = 'Islas Åland'
      countryList.KY = 'Islas Caimán'
      countryList.CC = 'Islas Cocos'
      countryList.CK = 'Islas Cook'
      countryList.FO = 'Islas Feroe'
      countryList.GS = 'Islas Georgia del Sur y Sandwich del Sur'
      countryList.FK = 'Islas Malvinas'
      countryList.MP = 'Islas Marianas del Norte'
      countryList.MH = 'Islas Marshall'
      countryList.UM = 'Islas menores alejadas de EE. UU.'
      countryList.PN = 'Islas Pitcairn'
      countryList.SB = 'Islas Salomón'
      countryList.TC = 'Islas Turcas y Caicos'
      countryList.VG = 'Islas Vírgenes Británicas'
      countryList.VI = 'Islas Vírgenes de EE. UU.'
      countryList.IL = 'Israel'
      countryList.IT = 'Italia'
      countryList.JM = 'Jamaica'
      countryList.JP = 'Japón'
      countryList.JE = 'Jersey'
      countryList.JO = 'Jordania'
      countryList.KZ = 'Kazajistán'
      countryList.KE = 'Kenia'
      countryList.KG = 'Kirguistán'
      countryList.KI = 'Kiribati'
      countryList.XK = 'Kosovo'
      countryList.KW = 'Kuwait'
      countryList.LA = 'Laos'
      countryList.LS = 'Lesoto'
      countryList.LV = 'Letonia'
      countryList.LB = 'Líbano'
      countryList.LR = 'Liberia'
      countryList.LY = 'Libia'
      countryList.LI = 'Liechtenstein'
      countryList.LT = 'Lituania'
      countryList.LU = 'Luxemburgo'
      countryList.MK = 'Macedonia'
      countryList.MG = 'Madagascar'
      countryList.MY = 'Malasia'
      countryList.MW = 'Malaui'
      countryList.MV = 'Maldivas'
      countryList.ML = 'Mali'
      countryList.MT = 'Malta'
      countryList.MA = 'Marruecos'
      countryList.MQ = 'Martinica'
      countryList.MU = 'Mauricio'
      countryList.MR = 'Mauritania'
      countryList.YT = 'Mayotte'
      countryList.MX = 'México'
      countryList.FM = 'Micronesia'
      countryList.MD = 'Moldavia'
      countryList.MC = 'Mónaco'
      countryList.MN = 'Mongolia'
      countryList.ME = 'Montenegro'
      countryList.MS = 'Montserrat'
      countryList.MZ = 'Mozambique'
      countryList.MM = 'Myanmar (Birmania)'
      countryList.NA = 'Namibia'
      countryList.NR = 'Nauru'
      countryList.NP = 'Nepal'
      countryList.NI = 'Nicaragua'
      countryList.NE = 'Níger'
      countryList.NG = 'Nigeria'
      countryList.NU = 'Niue'
      countryList.NO = 'Noruega'
      countryList.NC = 'Nueva Caledonia'
      countryList.NZ = 'Nueva Zelanda'
      countryList.OM = 'Omán'
      countryList.NL = 'Países Bajos'
      countryList.PK = 'Pakistán'
      countryList.PW = 'Palaos'
      countryList.PA = 'Panamá'
      countryList.PG = 'Papúa Nueva Guinea'
      countryList.PY = 'Paraguay'
      countryList.PE = 'Perú'
      countryList.PF = 'Polinesia Francesa'
      countryList.PL = 'Polonia'
      countryList.PT = 'Portugal'
      countryList.PR = 'Puerto Rico'
      countryList.HK = 'RAE de Hong Kong (China)'
      countryList.MO = 'RAE de Macao (China)'
      countryList.GB = 'Reino Unido'
      countryList.CF = 'República Centroafricana'
      countryList.CG = 'República del Congo'
      countryList.CD = 'República Democrática del Congo'
      countryList.DO = 'República Dominicana'
      countryList.RE = 'Reunión'
      countryList.RW = 'Ruanda'
      countryList.RO = 'Rumanía'
      countryList.RU = 'Rusia'
      countryList.EH = 'Sáhara Occidental'
      countryList.WS = 'Samoa'
      countryList.AS = 'Samoa Americana'
      countryList.BL = 'San Bartolomé'
      countryList.KN = 'San Cristóbal y Nieves'
      countryList.SM = 'San Marino'
      countryList.MF = 'San Martín'
      countryList.PM = 'San Pedro y Miquelón'
      countryList.VC = 'San Vicente y las Granadinas'
      countryList.SH = 'Santa Elena'
      countryList.LC = 'Santa Lucía'
      countryList.ST = 'Santo Tomé y Príncipe'
      countryList.SN = 'Senegal'
      countryList.RS = 'Serbia'
      countryList.SC = 'Seychelles'
      countryList.SL = 'Sierra Leona'
      countryList.SG = 'Singapur'
      countryList.SX = 'Sint Maarten'
      countryList.SY = 'Siria'
      countryList.SO = 'Somalia'
      countryList.LK = 'Sri Lanka'
      countryList.SZ = 'Suazilandia'
      countryList.ZA = 'Sudáfrica'
      countryList.SD = 'Sudán'
      countryList.SS = 'Sudán del Sur'
      countryList.SE = 'Suecia'
      countryList.CH = 'Suiza'
      countryList.SR = 'Surinam'
      countryList.SJ = 'Svalbard y Jan Mayen'
      countryList.TH = 'Tailandia'
      countryList.TW = 'Taiwán'
      countryList.TZ = 'Tanzania'
      countryList.TJ = 'Tayikistán'
      countryList.IO = 'Territorio Británico del Océano Índico'
      countryList.TF = 'Territorios Australes Franceses'
      countryList.PS = 'Territorios Palestinos'
      countryList.TL = 'Timor-Leste'
      countryList.TG = 'Togo'
      countryList.TK = 'Tokelau'
      countryList.TO = 'Tonga'
      countryList.TT = 'Trinidad y Tobago'
      countryList.TA = 'Tristán de Acuña'
      countryList.TN = 'Túnez'
      countryList.TM = 'Turkmenistán'
      countryList.TR = 'Turquía'
      countryList.TV = 'Tuvalu'
      countryList.UA = 'Ucrania'
      countryList.UG = 'Uganda'
      countryList.UN = 'United Nations'
      countryList.UY = 'Uruguay'
      countryList.UZ = 'Uzbekistán'
      countryList.VU = 'Vanuatu'
      countryList.VE = 'Venezuela'
      countryList.VN = 'Vietnam'
      countryList.WF = 'Wallis y Futuna'
      countryList.YE = 'Yemen'
      countryList.DJ = 'Yibuti'
      countryList.ZM = 'Zambia'
      countryList.ZW = 'Zimbabue'

      return countryList
    }

    var getTranslatedCountryList = function (lang) {
      if (lang == 'NL') {
        return countries_NL()
      }
      if (lang == 'EN') {
        return countries_EN()
      }
      if (lang == 'DE') {
        return countries_DE()
      }
      if (lang == 'FR') {
        return countries_FR()
      }
      if (lang == 'ES') {
        return countries_ES()
      }
    }

    return {
      getTranslatedCountryList: getTranslatedCountryList
    }
  }])
