prismanoteApp.service('$admin', ['$q', '$api', function ($q, $api) {
  function saveNewProductInDatabase (data) {
    return $q(function (resolve, reject) {
      $api.post('v2/company/brands/', data)
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  function removeProductByAdmin (data) {
    return $q(function (resolve, reject) {
      $api.delete('v2/admin/product/' + data.productId)
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  function toggleEmployeeVisibility (data) {
    return $q(function (resolve, reject) {
      $api.post('v2/admin/toggle-visible-employee/', data)
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  function replaceTheShopPoolArticle (body) {
    return $api.post('v2/admin/replace-shop-pool-article', body)
  }

  function changeFavSupplierInAllShopBrands(body) {
    return $api.post('v2/admin/change-favSupp-of-allshopbrands', body)
  }

  return {
    saveNewProductInDatabase: saveNewProductInDatabase,
    removeProductByAdmin: removeProductByAdmin,
    toggleEmployeeVisibility: toggleEmployeeVisibility,
    replaceTheShopPoolArticle: replaceTheShopPoolArticle,
    changeFavSupplierInAllShopBrands: changeFavSupplierInAllShopBrands
  }
}])
