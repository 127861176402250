prismanoteApp.factory('dudaService', ['$api', '$rootScope',
    function ($api, $rootScope) {
        var data = {}
        function getCustomerDetails() {
            data = {shopId : $rootScope.currentShop._id, email: $rootScope.currentShop.email}
            return $api.get('duda/customer', data)
        }

        function postCreateCustomer() {
            return $api.post('duda/customer')
        }

        function getSSOLink(body) {
            data = {shopId : $rootScope.currentShop._id, email: $rootScope.currentShop.email}
            return $api.get('duda/sso', data)
        }

        function getTemplateList() {
            return $api.get('duda/template-list')
        }

        function postCreateSite(template) {
            data = {shopId : $rootScope.currentShop._id, email: $rootScope.currentShop.email, template: template}
            return $api.post('duda/create-site', data)
        }

        function getSiteList(shopId) {
            data = {shopId : $rootScope.currentShop._id, email: $rootScope.currentShop.email}
            return $api.get('duda/get-site-list', data)
        }
        
        function duplicateWebsite(site) {
            data = {shopId : $rootScope.currentShop._id, email: $rootScope.currentShop.email, site: site}
            return $api.post('duda/copy-site', data)
        }

        function removeWebsite(site) {
            return $api.post('duda/remove-site', site)
        }

        function addSEOCollection(site) {
            return $api.post('duda/add-SEO-site-collection', site)
        }
        
        function updateSiteData(data) {
            return $api.post('duda/update-site-data', data)
        }
        
        return {
            getCustomerDetails : getCustomerDetails,
            postCreateCustomer : postCreateCustomer,
            getSSOLink : getSSOLink,
            getTemplateList : getTemplateList,
            postCreateSite : postCreateSite,
            getSiteList : getSiteList,
            duplicateWebsite : duplicateWebsite,
            removeWebsite : removeWebsite,
            addSEOCollection: addSEOCollection,
            updateSiteData: updateSiteData
        }

    }])