prismanoteApp.factory('$prefillCalculationForPurchasOrderAndCashRegister', ['$rootScope',
  function ($rootScope) {
    /**
         *
         * @param {OBJECT} prefillData : All the required data like price, margin, factor, purchasePrice, entryMethod etc..
         * @param {NUMBER} priceVat : We are sending it like 21 but we need to calculate in percentage like (1.21 -> 1+ (21/100))
         * returns: prefillData with some changes
         */
    function calculateWhenBothPriceAvailableInCompanyProduct (prefillData, priceVat) {
      prefillData.price = prefillData.suggestedRetailPrice
      prefillData.purchasePrice = prefillData.companyProduct.price
      prefillData.entryMethodCustomerValue = prefillData.companyProduct.entryMethodCustomerValue
      prefillData.margin = (prefillData.suggestedRetailPrice * (1 + (priceVat / 100))) / prefillData.companyProduct.price
      prefillData.storageFactor = prefillData.suggestedRetailPrice / prefillData.companyProduct.price
      prefillData.priceIncVat = (prefillData.price * (1 + (priceVat / 100)))
      return prefillData
    }

    /**
         *
         * @param {OBJECT} prefillData : All the required data like price, margin, factor, purchasePrice, entryMethod etc..
         * @param {NUMBER} priceVat : We are sending it like 21 but we need to calculate in percentage like (1.21 -> 1+ (21/100))
         * @param {OBJECT} clientGroup : Object
         * returns: prefillData with some changes based on client group data
         */
    function calculateWhenClientGroupAvailable (prefillData, priceVat, clientGroup) {
      console.log('calculateWhenClientGroupAvailable', prefillData, priceVat, clientGroup)
      // If its true then do it with the margin
      if (clientGroup.entryMethodCustomerValue) {
        prefillData.entryMethodCustomerValue = true
        prefillData.margin = clientGroup.discount
        prefillData.price = prefillData.suggestedRetailPrice || 0
        // CALCULATION PURCHASE PRICE
        if (prefillData.suggestedRetailPrice && prefillData.margin) {
          prefillData.purchasePrice = prefillData.suggestedRetailPrice * (1 + (priceVat / 100)) / prefillData.margin
        } else {
          console.log('else1')
          prefillData.purchasePrice = calculatePurchasePriceUsingSuggestedRetailerPriceForClientGroup(prefillData, clientGroup)
        }
        prefillData.storageFactor = prefillData.price / prefillData.purchasePrice
      } else { // If its true then do it with the storageFactor
        console.log('else2')
        prefillData.entryMethodCustomerValue = false
        prefillData.storageFactor = clientGroup.storageFactor
        prefillData.purchasePrice = (prefillData.companyProduct && prefillData.companyProduct.price) ? prefillData.companyProduct.price : calculatePurchasePriceUsingSuggestedRetailerPriceForClientGroup(prefillData, clientGroup)
        prefillData.price = prefillData.purchasePrice * prefillData.storageFactor
        prefillData.margin = ((prefillData.price * (1 + (priceVat / 100))) / prefillData.purchasePrice) || clientGroup.discount
        prefillData.priceIncVat = (prefillData.price * (1 + (priceVat / 100)))
      }
      console.log('prefillData', prefillData)
      return prefillData
    }

    /**
         *
         * @param {OBJECT} prefillData : All the required data like price, margin, factor, purchasePrice, entryMethod etc..
         * @param {NUMBER} priceVat : We are sending it like 21 but we need to calculate in percentage like (1.21 -> 1+ (21/100))
         * returns: prefillData with some changes
         */
    function calculateWhenBothPriceAvailableInShopProduct (prefillData, priceVat) {
      prefillData.price = prefillData.shopProduct.price
      prefillData.purchasePrice = prefillData.shopProduct.purchasePrice
      prefillData.entryMethodCustomerValue = prefillData.shopProduct.entryMethodCustomerValue
      prefillData.margin = (prefillData.price * (1 + (priceVat / 100))) / prefillData.shopProduct.purchasePrice
      prefillData.storageFactor = prefillData.shopProduct.price / prefillData.shopProduct.purchasePrice
      prefillData.priceIncVat = (prefillData.price * (1 + (priceVat / 100)))
      return prefillData
    }

    /**
         *
         * @param {OBJECT} prefillData : All the required data like price, margin, factor, purchasePrice, entryMethod etc..
         * @param {OBJECT} selectedCompany : which we selected but that data not from the shop.companies
         * @param {OBJECT} shopCompanies We need to find that selectedCompany from the shop.companies as we need marign, price, factor, entryMethod etc...
         * returns: prefillData with some changes
         */
    function calculateUsingShopCompanyDetail (prefillData, selectedCompany, shopCompanies) {
      var shopCompaniesLength = shopCompanies.length
      for (var i = 0; i < shopCompaniesLength; i++) {
        if (
          shopCompanies[i]._id &&
                    selectedCompany._id &&
                    shopCompanies[i]._id.toString() === selectedCompany._id.toString()
        ) {
          // Price Calculation START
          if (
            prefillData.isShopProduct &&
                        prefillData.shopProduct &&
                        prefillData.shopProduct.price
          ) {
            prefillData.price = prefillData.shopProduct.price
          } else {
            prefillData.price = prefillData.suggestedRetailPrice || 0
          }
          // Price Calculation END

          prefillData.purchasePrice = calculatePurchasePriceUsingSuggestedRetailerPriceForShopCompany(prefillData, shopCompanies[i])
          prefillData.entryMethodCustomerValue = shopCompanies[i].entryMethodCustomerValue !== false
          prefillData.margin = shopCompanies[i].purchaseMargin || 0
          prefillData.storageFactor = shopCompanies[i].storageFactor || 0

          break
        }
      }

      return prefillData
    }

    /**
         *
         * @param {OBJECT} prefillData : All the required data like price, margin, factor, purchasePrice, entryMethod etc..
         * @param {OBJECT} selectedCompany : which we selected but that data not from the shop.companies
         * @param {OBJECT} shopCompanies We need to find that selectedCompany from the shop.companies as we need marign, price, factor, entryMethod etc...
         * returns: prefillData with some changes
         */
    function calculateUsingShopCompanyDetailForPurchseOrder (prefillData, shopCompanyDetail) {
      console.log('---------------------- calculateUsingShopCompanyDetailForPurchseOrder!!');
      console.log(prefillData);
      console.log(shopCompanyDetail);
      // Price Calculation START
      if (
        prefillData.isShopProduct &&
                prefillData.shopProduct &&
                prefillData.shopProduct.price
      ) {
        prefillData.price = prefillData.shopProduct.price
      } else {
        prefillData.price = prefillData.suggestedRetailPrice || 0
      }
      // Price Calculation END

      prefillData.purchasePrice = calculatePurchasePriceUsingSuggestedRetailerPriceForShopCompany(prefillData, shopCompanyDetail)
      prefillData.entryMethodCustomerValue = shopCompanyDetail.entryMethodCustomerValue !== false
      prefillData.margin = shopCompanyDetail.purchaseMargin || 0
      prefillData.storageFactor = shopCompanyDetail.storageFactor || 0
      prefillData.priceIncVat = (prefillData.price * (1 + (21 / 100)))
      console.log(prefillData);
      return prefillData
    }

    function calculateWithDefaultValue (prefillData) {
      prefillData.entryMethodCustomerValue = true
      prefillData.margin = 0
      prefillData.price = prefillData.suggestedRetailPrice || 0
      prefillData.purchasePrice = (prefillData.companyProduct && prefillData.companyProduct.price) ? prefillData.companyProduct.price : 0
      prefillData.priceIncVat = 0
      // prefillData.priceIncVat = (prefillData.price * (1 + (priceVat / 100)))

      return prefillData
    }

    // Calculating purchaseprice using the product.suggestedRetailerPrice and product.suggestedRetailerPriceVat if shopCompany.entryMethod is there
    function calculatePurchasePriceUsingSuggestedRetailerPriceForShopCompany (prefillData, shopCompanyDetail) {
      var purchasePrice = 0
      if (prefillData.suggestedRetailPrice && prefillData.suggestedRetailPriceVat) {
        if (shopCompanyDetail.entryMethodCustomerValue === true || shopCompanyDetail.entryMethodCustomerValue === false) {
          if (shopCompanyDetail.entryMethodCustomerValue && shopCompanyDetail.purchaseMargin) {
            purchasePrice = prefillData.suggestedRetailPrice * (1 + (prefillData.suggestedRetailPriceVat / 100)) / shopCompanyDetail.purchaseMargin
          } else if (shopCompanyDetail.storageFactor) {
            purchasePrice = prefillData.suggestedRetailPrice / shopCompanyDetail.storageFactor
          }
        }
      }
      return purchasePrice
    }

    // Calculating purchaseprice using the product.suggestedRetailerPrice and product.suggestedRetailerPriceVat if clientGroup.entryMethod is there
    function calculatePurchasePriceUsingSuggestedRetailerPriceForClientGroup (prefillData, clientGroup) {
      var purchasePrice = 0
      if (prefillData.suggestedRetailPrice && prefillData.suggestedRetailPriceVat && clientGroup) {
        if (clientGroup.entryMethodCustomerValue === true || clientGroup.entryMethodCustomerValue === false) {
          if (clientGroup.entryMethodCustomerValue && clientGroup.discount) {
            purchasePrice = prefillData.suggestedRetailPrice * (1 + (prefillData.suggestedRetailPriceVat / 100)) / clientGroup.discount
          } else if (clientGroup.storageFactor) {
            purchasePrice = prefillData.suggestedRetailPrice / clientGroup.storageFactor
          }
        }
      }
      return purchasePrice
    }

    /**
         * DEPRECATED
         * @param {*} prefillData : Object
         * @param {*} companies : Object
         * @param {*} selectedSupplier : Object
         * returns prefillData with some changes
         */
    function calculateWithShopCompanies (prefillData, companies, selectedSupplier) {
      if (companies && companies.length && selectedSupplier) {
        var needToCallCalculateUsingShopCompanyDetail = false
        var selectedCompany = {}

        for (var i = 0; i < companies.length; i++) {
          if (
            companies[i] &&
                        (
                          (
                            companies[i].name &&
                                companies[i].name === selectedSupplier.name
                          ) ||
                            (
                              companies[i]._id &&
                                selectedSupplier._id &&
                                (companies[i]._id).toString() === (selectedSupplier._id).toString()
                            )
                        )
          ) {
            selectedCompany = companies[i]
            needToCallCalculateUsingShopCompanyDetail = true
            break
          }
        }

        return needToCallCalculateUsingShopCompanyDetail ? calculateUsingShopCompanyDetail(prefillData, selectedCompany) : prefillData
      }
    }

    function calculateAllValueOnPriceVatChange (lastEntryMethodCustomerValue, newVat, lastPriceVat, item) {
      newVat = 1 + (newVat / 100)
      if (lastEntryMethodCustomerValue) {
        var updatedPriceExVat = (item.price * (1 + (lastPriceVat / 100))) / newVat // As we don't need to change priceIncVat but we are changing the priceExVat
        var updatedPrice = updatedPriceExVat * newVat // PriceInVat which same as before
        var updatedStorageFactor = updatedPrice / newVat / item.purchasePrice
        var updatedMargin = updatedPrice / item.purchasePrice
        return { updatedPrice: updatedPrice, updatedStorageFactor: updatedStorageFactor, updatedMargin: updatedMargin }
      } else {
        var updatedPriceIncVat = (item.price * (1 + (lastPriceVat / 100)))
        // var updatedPriceIncVat = item.price * newVat; // As we don't need to change purchase price but we are changing the priceExVat
        var updatedStorageFactor = updatedPriceIncVat / newVat / item.purchasePrice
        // var updatedPurchasePrice = updatedPriceIncVat / newVat / updatedStorageFactor;
        var updatedMargin = updatedPriceIncVat / item.purchasePrice
        return { updatedPriceIncVat: updatedPriceIncVat, updatedStorageFactor: updatedStorageFactor, updatedMargin: updatedMargin }
      }
    }

    return {
      // calculateWithShopCompanies: calculateWithShopCompanies,
      calculateUsingShopCompanyDetail: calculateUsingShopCompanyDetail,
      calculateWhenBothPriceAvailableInShopProduct: calculateWhenBothPriceAvailableInShopProduct,
      calculateWhenClientGroupAvailable: calculateWhenClientGroupAvailable,
      calculateWhenBothPriceAvailableInCompanyProduct: calculateWhenBothPriceAvailableInCompanyProduct,
      calculateWithDefaultValue: calculateWithDefaultValue,
      calculateUsingShopCompanyDetailForPurchseOrder: calculateUsingShopCompanyDetailForPurchseOrder,
      calculateAllValueOnPriceVatChange: calculateAllValueOnPriceVatChange
    }
  }
])
