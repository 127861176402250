prismanoteApp.factory('apiInterceptor', ['$q', '$injector',
  function ($q, $injector) {
    return {
      request: function (config) {
        return config
      },
      requestError: function (rejection) {
        return $q.reject(rejection)
      },
      response: function (response) {
        return response
      },
      responseError: function (rejection) {
        if (
          rejection.status === -1 ||
          (rejection.statusText === '' && (!rejection.data || !rejection.data.message)) ||
          (rejection.statusText !== '' && rejection.statusText !== 'OK' && rejection.statusText !== 'Internal Server Error' && rejection.status > 499)
        ) {
          var message = 'Unkown error occured. Please try again. If the problem persists, please contact support and send this data: Error code: ' + rejection.status + ' Status: ' + rejection.statusText + ' Page: ' + window.location.href + ' Request: ' + rejection.config.method + ' ' + rejection.config.url
          rejection.data = message
          rejection.statusText = 'Unkown error'
        }
        // TODO: Here we can the translate service to translate the error status from the server
        return $q.reject(rejection)
      }
    }
  }])
