prismanoteApp.factory('$freshdesk', ['$log',
  function ($log) {

  function openWindow(data) {
      if(window.fcWidget.isInitialized() === true) {
        window.fcWidget.open(data);
      }
  }

  function closeWindow() {
    if(window.fcWidget.isInitialized() === true) {
      window.fcWidget.close();
    }
  }

  return {
    openWindow: openWindow,
    closeWindow: closeWindow
  }


}])