prismanoteApp.factory('$transaction', ['$q', '$api', '$rootScope', '$data', '$state', '$language', '$uibModal',
  function ($q, $api, $rootScope, $data, $state, $language, $uibModal) {

    function getCurrentUser(id) {
      var user = $rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id
      if (id) {
        return user._id
      } else {
        return user
      }
    }

    function getTransaction (id, combine, returnFirst, currentShopId) {
      return $q(function (resolve, reject) {
        $api.get('transactions', {
          id: id,
          combine: combine,
          returnFirst: returnFirst,
          currentShopId: currentShopId,
          shopId: $rootScope.currentShop._id
        }, null, null, true)
          .then(function (response) {
            resolve(response.data.transaction)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    function createTransaction (transaction, files, copy, refresh) {
      return $q(function (resolve, reject) {
        $api.post('transactions', {
          transaction: transaction,
          files: files,
          language: $rootScope.language,
          copy: copy || false
        })
          .then(function (result) {
            // if (refresh) $data.getTransactions(true)
            return resolve(result.data.transaction)
          })
          .catch(function (reason) {
            console.error('Error while creating transaction: ', reason)
            return reject(reason.data.message || reason.data)
          })
      })
    }

    function updateTransaction (transaction, updateStock, refresh) {
      var beforeDate = new Date().getTime();
      console.log('IN SERVICE START');
      return $q(function (resolve, reject) {
        $api.put('transactions', {
          transaction: transaction,
          updateStock: updateStock || false
        })
          .then(function (response) {
            // if (refresh) {
            //   $data.getTransactions(true)
            //   $data.getCustomers(true)
            // }
            var afterDate = new Date().getTime();
            console.log('IN SERVICE ', (afterDate - beforeDate) / 1000);
            console.log('IN SERVICE END');
            return resolve(response.data.result)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function deleteTransaction (id, refresh, admin) {
      return $q(function (resolve, reject) {
        $api.delete('transactions/' + id + (!admin ? '/' + $rootScope.currentShop._id : ''))
          .then(function (response) {
            // if (refresh) {
            //   $data.getTransactions(true)
            // }
            resolve(response.data.message)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    function cancelTransaction(id) {
      return $q(function (resolve, reject) {
        var user = getCurrentUser(false)
        return updateTransactionStatus(id, 'cancelled', {user: user})
          .then(function(result){
            return resolve(result)
          })
          .catch(function (reason){
            return reject(reason)
          })
      })
    }

    function findTransactions (number, types) {
      return $q(function (resolve, reject) {
        var results = []
        $data.getTransactions(false)
          .then(function (transactions) {
            transactions.forEach(function (item) {
              if ((item.number.toString().indexOf(number) >= 0) || (types && types.length > 0 && types.indexOf(item.type) >= 0)) {
                if (item.type === 'repair' || item.type === 'special') {
                  if (item[item.type].actualCosts) {
                    item.price = item[item.type].actualCosts
                  } else {
                    item.price = item[item.type].estimatedPrice
                  }
                } else {
                  // offer and shop-purchases
                  item.price = getTransactionPrice(item)
                }
                results.push(item)
              }
            })
            return resolve(results)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function getTransactionPrice (transaction) {
      var counter = 0; var totalAmount = 0
      for (var i = 0; i < transaction.details.length; i++) {
        totalAmount += transaction.details[i].total
        counter++
        if (counter === transaction.details.length) {
          return totalAmount
        }
      }
    }

    function updateTransactionStatus (transactionId, status, data) {
      return $q(function (resolve, reject) {
        $api.put('transaction/' + transactionId + '/status/' + status, data)
          .then(function (result) {
            return resolve(result.data.transaction)
          })
          .catch(function (reason) {
            console.error('Error while creating transaction: ', reason)
            return reject(reason.data.message || reason.data)
          })
      })
    }

    function updateTransactionPaymentTerm (transactionId, user, paymentTerm) {
      return $q(function (resolve, reject){
        $api.post('till/transaction/payment-term', {
          transactionId: transactionId,
          term: paymentTerm,
          user: user,
          shopId: $rootScope.currentShop._id
        }, null, 2)
          .then(function(result) {
            toastr.success($language.translate('PAYMENT_TERM_UPDATED'))
            return resolve()
          })
          .catch(function (reason) {
            console.error('Error while updating payment term', reason)
            toastr.error(reason)
            return reject(reason.message)
          })
      })
    }

    function updateTransactionCustomer (transactionId, customerId, user, term) {
      return $q(function (resolve, reject) {
        $api.post('till/transaction/customer', {
          transactionId: transactionId,
          customerId: customerId,
          user: user,
          term: term,
          shopId: $rootScope.currentShop._id,
        }, null, 2)
          .then(function (res) {
            toastr.success($language.translate(res.data.message))
            return resolve()
          })
          .catch(function (reason){
            console.error('Error while changing transaction customer', reason)
            toastr.error(reason.message)
            return reject(reason)
          })
      })
    }

    function updateTransactionToInvoice (transactionId, user, term) {
      return $q(function (resolve, reject){
        $api.post('till/transaction/invoice', {
          transactionId: transactionId,
          shopId: $rootScope.currentShop._id,
          user: user,
          term: term
        }, null, 2)
          .then(function(res) {
            toastr.success($language.translate(res.data.message))
            return resolve(res.data.transaction)
          })
          .catch(function(reason){
            console.error('Error while changing transaction to invoice', reason)
            toastr.error(reason.message)
            return reject(reason)
          })
      })
    }

    function createGiftCardTransaction (transactionData) {
      return $api.post('v2/transaction/giftcard', transactionData)
    }

    function createGoldPurchaseTransaction (transactionData) {
      return $api.post('v2/transaction/gold-purchase', transactionData)
    }

    function updateGiftCardStatus (status, transactionId, shopId) {
      return $api.put('v2/transaction/giftcard/status/' + transactionId, { status: status, shopId: shopId })
    }

    function updateGoldPurchaseTransaction (transactionId, transactionData) {
      var data = JSON.parse(JSON.stringify(transactionData))
      delete data._id
      return $api.put('v2/transaction/gold-purchase/' + transactionId, data)
    }

    function addComment (body) {
      return $api.post('add-comment-service-retailer-and-company', body)
    }

    function getStatus(status) {
      if (status === 'new' || status === 'offer' || status === 'offer-accepted' || status === 'to-repair') {
        return 0
      }

      if (status === 'processing' || status === 'ordered' || status === 'parts-ordered' || status === 'sent') {
        return 1
      }

      if (status === 'inspection') {
        return 2
      }

      if (status === 'completed') {
        return 3
      }

      if (status === 'delivered') {
        return 4
      }

      if (status === 'cancelled') {
        return 5
      }
    }

    function openTransaction (id, type, old) {
      if (old === true) {
        $state.go('retailer.service-item', {transactionId: id})
        return
      }

      if(type === 'webshop' || type === 'product-reserve') {
        $state.go('retailer.orderDetails', {
          transactionId: id
        })
        return
      }
      if (type === 'giftcard' || type === 'giftcard-sell') {
        $state.go('retailer.giftcard', {
          transactionId: id
        })
        return
      }
      if (type === 'refund' || type === 'shop-purchase') {
        type = 'shop'
      }
      if (type === 'order') {
        type = 'special'
      }
      if (type === 'gold-purchase' || type === 'gold-sell') {
        type  = 'gold'
      }

      $state.go('retailer.' + type, {
        transactionId: id
      })

    }

    function openUploadFilesModal (detail, hideSlider) {
      return $q(function (resolve, reject){
        if(!detail.photos) {
          detail.photos = []
        }

        var modalInstance = $uibModal.open({
          templateUrl: '../../views/modal/transaction-file-upload-modal.html',
          controller: 'transactionUploadFileModalController',
          size: 'lg',
          keyboard: false,
          backdrop: 'static',
          resolve: {
            files: function () {
              return detail.photos
            },
            hideSlider: function () {
              return hideSlider
            }
          }
        })

        modalInstance.result.then(function (res) {
          return resolve(res)
        }, function (reason) {
          return reject(reason)
        })
      })
    }

    function updateTransactionDetailItem (transactionId, detail, shopId) {
      return $q(function (resolve, reject) {
        $api.post('till/transaction/detail', {
          transactionId: transactionId,
          detail: detail,
          shopId: shopId
        }, null, 2).then(function(result) {
            return resolve(result.data)
        })
          .catch(function (reason){
            return reject(reason.message)
          })
      })
    }

    function addPayment (transactionId, payment) {
      return $q(function (resolve, reject){
        $api.post('till/transaction/payment/', {
          transactionId: transactionId,
          method: payment.method,
          paidAmount: payment.paidAmount,
          card: payment.cardName,
          bankConfirmed: payment.bankConfirmed,
          later: true,
          shopId: $rootScope.currentShop._id,
          user: getCurrentUser(true)
        }, null, 2)
          .then(function (result) {
            return resolve(result.data.message)
          })
          .catch(function (reason){
            console.error('Error while adding payment to transaction', reason)
            return reject(reason.data.message)
          })
      })
    }

    function removePayment (transactionId, paymentId) {
      return $q(function (resolve, reject) {
        $api.delete('till/transaction/payment/' + transactionId + '/' + paymentId + '/1?shopId=' + $rootScope.currentShop._id, null, null, 2)
          .then(function (result) {
            return resolve(result.data.message)
          })
          .catch(function (reason) {
            console.error('Error while removing payment by id from transaction', reason)
            return reject(reason.data.message)
          })
      })
    }

    function removePayLaterFromTransaction(transactionId, orderMode) {
      return $q(function (resolve, reject) {

        var user = ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id)
        $api.delete('till/transaction/paylater/' + transactionId + '/'+ orderMode + '/' + user + '/' + $rootScope.currentShop._id ,null, null, 2)
          .then(function (result) {
            return resolve(result)
          })
          .catch(function (reason){
            return reject(reason)
          })
      })
    }

    function getTransactionFromBarcode(scannerCode) {
      return $q(function (resolve, reject) {
        if(!scannerCode || scannerCode === '') {
          return reject('NO_VALID_BARCODE')
        }

        $api.get('transaction/find-for-barcode',  {
          transactionNumber: scannerCode,
          shopId: $rootScope.currentShop._id
        }).then(function (result) {
          if(result.data.transactionDetails) {
            return resolve(result.data.transactionDetails)
          } else {
            return reject('NO_TRANSACTION_FOUND')
          }
        })
          .catch(function (reason) {
            return reject(reason)
          })

      })
    }

    return {
      getTransaction: getTransaction,
      createTransaction: createTransaction,
      updateTransaction: updateTransaction,
      deleteTransaction: deleteTransaction,
      findTransactions: findTransactions,
      updateTransactionStatus: updateTransactionStatus,
      createGiftCardTransaction: createGiftCardTransaction,
      updateGiftCardStatus: updateGiftCardStatus,
      addComment: addComment,
      createGoldPurchaseTransaction: createGoldPurchaseTransaction,
      updateGoldPurchaseTransaction: updateGoldPurchaseTransaction,
      getStatus: getStatus,
      openTransaction: openTransaction,
      updateTransactionCustomer: updateTransactionCustomer,
      updateTransactionToInvoice: updateTransactionToInvoice,
      updateTransactionPaymentTerm: updateTransactionPaymentTerm,
      openUploadFilesModal: openUploadFilesModal,
      updateTransactionDetailItem: updateTransactionDetailItem,
      addPayment : addPayment,
      removePayment: removePayment,
      cancelTransaction: cancelTransaction,
      removePayLaterFromTransaction: removePayLaterFromTransaction,
      getTransactionFromBarcode: getTransactionFromBarcode
    }
  }])
