prismanoteApp.service('$collection', ['$q', '$api', function ($q, $api) {
  function getVariantCollections () {
    console.log('+++++++++++++++++getVariantCollections')
    return $q(function (resolve, reject) {
      $api.get('collection/variantbase-collections/')
        .then(function (response) {
          console.log('response________________________', response)
          return resolve(response.data)
        })
        .catch(function (reason) {
          console.log('reasonse------------------------', reason)
          return reject(reason)
        })
    })
  }

  function getBrandCollections (shopId) {
    return $q(function (resolve, reject) {
      $api.get('collection/brand-collection/', {shopId: $rootScope.currentShop._id})
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function addNewCollection (collection, currentShopId, currentCompanyId) {
    return $q(function (resolve, reject) {
      console.log('New Collection befor sendign api', collection)
      $api.post('collections', { collection: collection, shopId: currentShopId, companyId: currentCompanyId })
        .then(function (response) {
          console.log('response daata :', response.data);
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function deleteOwnPtivateLableCollection (shopId, collectionId) {
    return $q(function (resolve, reject) {
      console.log('Befor Sending Delete Request', shopId, collectionId)
      $api.delete('shop/private-collection/' + shopId + '/' + collectionId)
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function deleteOwnClientGroupCollection (companyId, collectionId) {
    return $q(function (resolve, reject) {
      $api.delete('V2/company/client-groups-collection/' + companyId + '/' + collectionId)
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function updateOwnPtivateLableCollection (shopId, collection) {
    return $q(function (resolve, reject) {
      $api.put('shop/private-collection/' + shopId + '/' + collection._id, { collection: collection })
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function updateOwnClientGroupCollection (companyId, collection) {
    return $q(function (resolve, reject) {
      $api.put('v2/company/client-groups-collection/' + companyId + '/' + collection._id, { collection: collection })
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function getOwnClientGroupsCollections (comapany_id) {
    return $q(function (resolve, reject) {
      $api.get('v2/comapany/client-group-collections/' + comapany_id)
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function addClientGroupsInClientGroupCollections (collectionId, clietGroupId, companyId) {
    return $q(function (resolve, reject) {
      $api.put('v2/client-groups/client-group-collections/' + collectionId + '/' + clietGroupId, { companyId: companyId })
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  // client-groups/client-group-collections
  function getClientGroupsWiseClientGroupCollections (clientGroupId, companyId) {
    return $q(function (resolve, reject) {
      $api.get('v2/client-groups/client-group-collections/' + clientGroupId + '/' + companyId)
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function checkClientGroupCollectionName (collection) {
    return $q(function (resolve, reject) {
      $api.post('v2/collections/client-groups/name-unique', collection)
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          console.log('reason Of the checkClientGroupCollectionName', reason)
          return reject(reason)
        })
    })
  }

  this.getVariantCollections = getVariantCollections
  this.getOwnClientGroupsCollections = getOwnClientGroupsCollections
  this.getBrandCollections = getBrandCollections
  this.addNewCollection = addNewCollection
  this.deleteOwnPtivateLableCollection = deleteOwnPtivateLableCollection
  this.updateOwnPtivateLableCollection = updateOwnPtivateLableCollection
  this.addClientGroupsInClientGroupCollections = addClientGroupsInClientGroupCollections
  this.getClientGroupsWiseClientGroupCollections = getClientGroupsWiseClientGroupCollections
  this.checkClientGroupCollectionName = checkClientGroupCollectionName
  this.updateOwnClientGroupCollection = updateOwnClientGroupCollection
  this.deleteOwnClientGroupCollection = deleteOwnClientGroupCollection
}])
