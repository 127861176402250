prismanoteApp.service('$retailer', ['$rootScope', '$q', '$api', '$state', '$uibModal',
  function ($rootScope, $q, $api, $state, $uibModal) {
    // create a named var for the service, because 'this' is problematic when using other vars or functions in this service.
    var retailerService = this

    // Store currently active merchant and shop nameSlugs in the service. If set, this will be valid as long as the page is not refreshed. This is useful for running multiple AJAX calls in sequence, i.e. for lazy loading.
    retailerService.merchant, retailerService.shop

    var validateNameSlug = function (nameSlug, type) {
      if (nameSlug == null || typeof nameSlug !== 'string') {
        // Try to use the saved merchant or shop (if it has been set before, the nameSlug parameter can be omitted).
        if (retailerService[type] != null && typeof retailerService[type] === 'string') {
          return retailerService[type]
        } else {
          if (type === 'shop') {
            var savedSlug = localStorage.getItem('currentShop')

            if (savedSlug) {
              return savedSlug
            } else if ($rootScope.user && $rootScope.user.shops[0]) {
              return $rootScope.user.shops[0].nameSlug
            }
          } else {
            return false
          }
        }
      } else {
        return nameSlug
      }
    }

    var validateParams = function (params) {
      if (params != null && typeof params === 'object') {
        return params
      } else {
        return {}
      }
    }

    var beforeApiRequest = function (options) {
      // Currently unused pre request hook.
    }

    var afterApiRequest = function (options) {
      if (options != null && typeof options === 'object') {
        if (options.redirect != null && typeof options.redirect === 'string') {
          $state.go(options.redirect)
        }
      }
    }

    retailerService.getMerchant = function (nameSlug, params, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'merchant')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        var validParams = validateParams(params)

        beforeApiRequest(options)

        $api.get('merchants/' + validatedNameSlug, validParams)

          .then(function (response) {
            // Settings like redirect URL
            afterApiRequest(options)

            // Store currently active merchant nameSlug
            retailerService.merchant = response.data.merchant.nameSlug

            resolve(response.data.merchant)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    retailerService.setMerchant = function (nameSlug, merchant, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'merchant')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        $api.update('merchants/' + validatedNameSlug, { merchant: merchant })

          .then(function (response) {
            // Settings like redirect URL
            afterApiRequest(options)

            // Store currently active merchant nameSlug
            retailerService.merchant = response.data.merchant.nameSlug

            $rootScope.shopSaved = true

            resolve(response.data.merchant)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    retailerService.getShop = function (nameSlug, params, options) {
      return $q(function (resolve, reject) {
        if ($rootScope.currentShop && Object.keys($rootScope.currentShop).length > 10) {
          return resolve($rootScope.currentShop)
        }
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          return reject('No nameSlug or invalid nameSlug provided')
        }

        var validParams = validateParams(params)
        beforeApiRequest(options)

        $api.get('shops/' + validatedNameSlug, validParams)

          .then(function (response) {
            // Settings like redirect URL
            afterApiRequest(options)

            // Store currently active shop nameSlug
            retailerService.shop = response.data.shop.nameSlug
            resolve(response.data.shop)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    retailerService.getShopForShopPage = function (nameSlug, params, options) {
      console.warn('Function not in use anymore')
      return
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        var validParams = validateParams(params)
        beforeApiRequest(options)
        $api.get('shops-for-shop-page/' + validatedNameSlug, validParams)

          .then(function (response) {
            // Settings like redirect URL
            afterApiRequest(options)

            // Store currently active shop nameSlug
            retailerService.shop = response.data.shop.nameSlug

            resolve(response.data.shop)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    retailerService.setShop = function (nameSlug, shop, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        shop._id = $rootScope.currentShop._id
        $rootScope.loading = true
        $api.update('shops/' + validatedNameSlug, { shop: shop })

          .then(function (response) {
            $rootScope.loading = false
            // Settings like redirect URL
            afterApiRequest(options)

            // Store currently active shop nameSlug
            retailerService.shop = response.data.shop.nameSlug

            $rootScope.shopSaved = true

            resolve(response.data.shop)
          })

          .catch(function (reason) {
            $rootScope.loading = false
            reject(reason)
          })
      })
    }

    // Wrapper for getShop, returns ONLY the shop products instead of the default fields (which do not include products).
    retailerService.getShopProducts = function (nameSlug, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        beforeApiRequest(options)

        retailerService.getShop(validatedNameSlug, { select: 'products' })

          .then(function (shop) {
            // Settings like redirect URL
            afterApiRequest(options)

            resolve(shop.products)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Wrapper for setShop, updates and returns ONLY the shop products instead of the default fields (which do not include products).
    retailerService.setShopProducts = function (nameSlug, products, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        retailerService.setShop(validatedNameSlug, { products: products })

          .then(function (shop) {
            // Settings like redirect URL
            afterApiRequest(options)

            $rootScope.shopSaved = true

            resolve(shop.products)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    retailerService.setShopProduct = function (nameSlug, product, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        // TODO update single shop product (either with a provided index from the controller or by adding a 'where' clause to the aggregation query).
      })
    }

    retailerService.deleteShopProduct = function (nameSlug, _id, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        // TODO delete a single shop product by ID. $pull does not work in aggregation. Find an equivalent operation or update the merchant (does not use aggregation, but it needs a shops array index for the current shop).
      })
    }

    // Wrapper for getShop, returns ONLY the shop brands instead of the default fields (which do not include brands).
    retailerService.getShopBrands = function (nameSlug, params, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        retailerService.getShop(validatedNameSlug, { select: 'brands' })

          .then(function (shop) {
            // Settings like redirect URL
            afterApiRequest(options)

            resolve(shop.brands)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Wrapper for setShop, updates and returns ONLY the shop brands instead of the default fields (which do not include brands).
    retailerService.setShopBrands = function (nameSlug, brands, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        retailerService.setShop(validatedNameSlug, { brands: brands })

          .then(function (shop) {
            // Settings like redirect URL
            afterApiRequest(options)

            $rootScope.shopSaved = true

            resolve(shop.brands)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Wrapper for getShop, returns ONLY the shop reviews instead of the default fields (which do not include reviews).
    retailerService.getShopReviews = function (nameSlug, params, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        retailerService.getShop(validatedNameSlug, { select: 'reviews' })

          .then(function (shop) {
            // Settings like redirect URL
            afterApiRequest(options)

            resolve(shop.reviews)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Wrapper for setShop, updates and returns ONLY the shop reviews instead of the default fields (which do not include reviews).
    retailerService.setShopReviews = function (nameSlug, reviews, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        retailerService.setShop(validatedNameSlug, { reviews: reviews })

          .then(function (shop) {
            // Settings like redirect URL
            afterApiRequest(options)

            $rootScope.shopSaved = true

            resolve(shop.reviews)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Wrapper for getShop, returns ONLY the shop tasks instead of the default fields (which do not include tasks).
    retailerService.getShopTasks = function (nameSlug, params, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        retailerService.getShop(validatedNameSlug, { select: 'tasks' })

          .then(function (shop) {
            // Settings like redirect URL
            afterApiRequest(options)

            resolve(shop.tasks)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Wrapper for setShop, updates and returns ONLY the shop tasks instead of the default fields (which do not include tasks).
    retailerService.setShopTasks = function (nameSlug, tasks, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'shop')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        beforeApiRequest(options)

        retailerService.setShop(validatedNameSlug, { tasks: tasks })

          .then(function (shop) {
            // Settings like redirect URL
            afterApiRequest(options)

            $rootScope.shopSaved = true

            resolve(shop.tasks)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    retailerService.openEmployeeModal = function (employee, isAddEmployee) {
      return $q(function (resolve, reject) {
        if (isAddEmployee === true) {
          employee = {
            shopRights: angular.copy($rootScope.userRights),
            enabled: true,
            phone: [
              {
                landLine: ''
              }
            ],
            address: [
              {
                state: '',
                type: ''
              }
            ]
          }
        }
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/retailer-employee-modal.html',
          controller: 'retailerEmployeeModalController',
          size: 'lg',
          resolve: {
            employee: function () {
              return employee
            },
            isAddEmployee: function () {
              return isAddEmployee
            }
          }
        })

        modalInstance.result.then(function (res) {
          return resolve(res)
        }, function (reason) {
          if (!reason) {
            reason = 'dismissed'
          }
          return reject(reason)
        })
      })
    }
  }])
