prismanoteApp.factory('$jspm', ['$q', '$api', '$rootScope', '$language', '$device', '$timeout',
  function ($q, $api, $rootScope, $language, $device, $timeout) {

    var JSPM_VERSION = 4
    var JSPM_PORT = 24443
    $rootScope.detectedJSPMVersion = null
    $rootScope.currentIp = null

    function checkScript(id) {
      var elem = document.getElementById(id)

      if(elem) {
        return true
      } else {
        return false
      }
    }

    function includeScript(fileName, mode, id) {
      if(!id || id === '' || id === undefined) {
        id = 'jspmScript'
      }
      if(checkScript(id) === false) {
        if (mode) { // Add the script reference in the body
          console.info('Adding script to head with src:', fileName)
          var head = document.getElementsByTagName('head')[0]

          var script = document.createElement('script')
          script.src = fileName
          script.type = 'text/javascript'
          script.id = id

          head.appendChild(script)
        } else { // remove the reference
          elem.parentNode.removeChild(elem)
        }
      }
    }

    function loadScriptBasedOnVersion(ip) {
      return $q(function (resolve, reject) {
        if(checkScript('jspmScript')) {
          if (JSPM && JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
            if ($rootScope.currentIp === ip) {
              console.info('JSPM Client already running continue')
              return reject('JSPM_ALREADY_RUNNING')
            } else {
              $rootScope.currentIp = ip
              console.info('IP address changed, we need to reconnect')
              //Script is already included but we need a new connection
              return resolve('RECONNECT')
            }
          }
        } else {
          if($rootScope.currentShop && ($rootScope.currentShop.printNode && $rootScope.currentShop.printNode.enabled === true)) {
            return resolve()
          } else {
            if($rootScope.currentCompany && ($rootScope.currentCompany.printNode && $rootScope.currentCompany.printNode.enabled === true)) {
              return resolve()
            }
          }
          
          if ($rootScope && (
            $rootScope.currentShop && ($rootScope.currentShop.jspmVersion === 2 || $rootScope.currentShop.jspmVersion === 2.0)) || (
            $rootScope.currentCompany && ($rootScope.currentCompany.jspmVersion === 2 || $rootScope.currentCompany.jspmVersion === '2.0'))) {
            console.info('%cShop setting is JSPM 2, please update asap!', 'font-size: 20px; color: red')
            JSPM_VERSION = 2
            JSPM_PORT = 22443
            includeScript('/inc/scripts/_JSPrintManager2.js', true)
          } else if ($rootScope && (
            $rootScope.currentShop && ($rootScope.currentShop.jspmVersion === 3 || $rootScope.currentShop.jspmVersion === 3.0)) || (
            $rootScope.currentCompany && ($rootScope.currentCompany.jspmVersion === 3 || $rootScope.currentCompany.jspmVersion === '3.0'))) {
            console.info('Shop setting is JSPM 3')
            JSPM_VERSION = 3
            JSPM_PORT = 23443
            includeScript('/inc/scripts/_JSPrintManager3.js', true)
          } else {
            console.info('Shop setting is JSPM 4')
            includeScript('/inc/scripts/_JSPrintManager4.js', true)
            includeScript('https://cdn.jsdelivr.net/gh/gildas-lormeau/zip.js/dist/zip-full.min.js', true, 'zipjs')
          }
          $timeout(function () {
            //Add a small timeout to let the browser load the scripts
            return resolve()
          }, 500)
        }
      })
    }

    function closeConnection () {
      return $q(function (resolve, reject){
        console.info('Closing connection')
        JSPM.JSPrintManager.stop()
        $rootScope.jspmActive = false
        return resolve()
      })
    }

    function connect(ip) {
      return $q(function (resolve, reject){
        console.info('Starting JSPM Client app')
        JSPM.JSPrintManager.auto_reconnect = false
        if (JSPM_VERSION === 2) {
          JSPM.JSPrintManager.license_url = window.location.origin + '/jspm2'
        }
        if (JSPM_VERSION === 3) {
          JSPM.JSPrintManager.license_url = window.location.origin + '/jspm3'
        }
        if (JSPM_VERSION === 4) {
          JSPM.JSPrintManager.license_url = window.location.origin + '/jspm4'
        }
        $rootScope.currentIp = ip
        JSPM.JSPrintManager.start(true, ip, JSPM_PORT)
          .then(function () {
            return resolve(ip)
          })
          .catch(function (reason) {
            console.error('4 Error while starting client', reason)
            toastr.error($language.translate('ERROR_CONNECTING_JSPM'))
            $rootScope.currentIp = null
            closeConnection()
            return reject(reason)
          })
      })
    }

    function startClientApp (ip) {
      return $q(function (resolve, reject) {
        if(!ip || ip === '') {
          ip = '127.0.0.1'
        }
        //First load the correct script version in the head based on a shop setting
        loadScriptBasedOnVersion(ip)
          .then(function (result) {
            if(result && result === 'RECONNECT') {
              console.info('Reconnecting because of ip change')
              closeConnection()
                .then( function() {
                  connect(ip)
                    .then(function (ip){
                      return resolve(ip)
                    })
                    .catch(function(reason){
                      return reject(reason)
                    })
                })
            } else {
              connect(ip)
                .then(function(ip) {
                  return resolve(ip)
                })
                .catch(function (reason){
                  return reject(reason)
                })
            }
          })
          .catch( function(reason) {
            if(reason === 'JSPM_ALREADY_RUNNING') {
              return resolve()
            } else {
              return reject()
            }
          })

      })
    }

    function detectClientApp (ip) {
      return $q(function (resolve, reject) {
        var obsoleteDate = new Date('2022-02-01')
        var today = new Date();
        if(today.getTime() > obsoleteDate.getTime()) {
          toastr.error($language.translate('THIS_PRINTMETHOD_IS_NOT_SUPPORTED_ANYMORE'), $language.translate('ERROR'), {showDuration: '1', hideDuration: '1', timeOut: 0, extendedTimeOut: 250, closeButton: true})
          return reject('THIS_PRINTMETHOD_IS_NOT_SUPPORTED_ANYMORE')
        } else {
          toastr.warning($language.translate('THIS_PRINTMETHOD_WILL_STOP_WORKING_SOON'))
        }
        $rootScope.jspmActive = true
        console.info('Detecting JSPM Client app on ip', ip)
        startClientApp(ip)
          .then(function (ip) {
            //Extra start() call to make sure onStatusChanged() is triggered
            JSPM.JSPrintManager.start(true, ip, JSPM_PORT)
              .then(function () {
              })

            JSPM.JSPrintManager.WS.onStatusChanged = function () {
              if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
                $rootScope.jspmError = null
                return resolve('OPEN')
              }
              if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
                console.error('JSPM Connection closed')
                $rootScope.jspmError = $language.translate('JSPRINT_APP_CLOSED_OR_NOT_INSTALLED')
                closeConnection()
                return reject('JSPRINT_APP_CLOSED_OR_NOT_INSTALLED')
              }

              if(JSPM_VERSION === 2) {
                if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.BlackListed) {
                  console.error('JSPM connection blacklisted')
                  $rootScope.jspmError = $language.translate('JSPRINT_APP_BLACKLISTED')
                  closeConnection()
                  return reject('JSPRINT_APP_BLACKLISTED')
                }
              } else {
                if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
                  console.error('JSPM connection blocked')
                  $rootScope.jspmError = $language.translate('JSPRINT_APP_BLACKLISTED')
                  closeConnection()
                  return reject('JSPRINT_APP_BLACKLISTED')
                }
              }
            }
          })
      })
    }

    function getInstalledVersion () {
      console.group('JSPM GET INSTALLED VERSION')
      return $q(function (resolve, reject) {
        if($rootScope.detectedJSPMVersion) {
          console.info('Installed version already known', $rootScope.detectedJSPMVersion)
          console.groupEnd()
          return resolve($rootScope.detectedJSPMVersion)
        }

        var ip = '127.0.0.1'
        if($rootScope.currentDevice.thermalPrinter.printDevice && $rootScope.currentDevice.thermalPrinter.printDevice.localIp && $rootScope.currentDevice.thermalPrinter.name) {
          console.log('getInstalledVersion', $rootScope.currentDevice.thermalPrinter)
          ip = $rootScope.currentDevice.thermalPrinter.printDevice.localIp
          console.info('IP is set to', ip)
        }

        detectClientApp(ip)
          .then(function () {
            JSPM.JSPrintManager.showAbout().then(function (version) {
              console.info('Installed version', version)
              console.groupEnd()
              $rootScope.detectedJSPMVersion = version
              return resolve(parseFloat(version))
            })
          })
          .catch(function (reason) {
            console.error('Error while starting JSPM', reason)
            $rootScope.jspmActive = false
            reject(reason)
          })
      })
    }

    function getInstalledPrinters (details, ip) {
      console.group('JSPM GET INSTALLED PRINTERS')
      return $q(function (resolve, reject) {
        if(!ip || ip === '') {
          ip = '127.0.0.1'
        }
        detectClientApp(ip)
          .then(function () {
            if (details) {
              console.info('Printers with details requested')
              JSPM.JSPrintManager.getPrintersInfo().then(function (printers) {
                console.info('printers', printers)
                console.groupEnd()
                $rootScope.jspmActive = false
                return resolve(printers)
              })
            } else {
              console.info('Only printernames requested')
              JSPM.JSPrintManager.getPrinters().then(function (printers) {
                console.groupEnd()
                $rootScope.jspmActive = false
                return resolve(printers)
              })
            }

          })
          .catch(function (reason) {
            console.error('Error while starting JSPM', reason)
            $rootScope.jspmActive = false
            reject(reason)
          })
      })
    }

    function getPageRotation (rotation) {
      if (!rotation || rotation === 0) {
        return JSPM.PrintRotation.None
      }
      if (rotation === 90) {
        return JSPM.PrintRotation.Rot90
      }
      if (rotation === 180) {
        return JSPM.PrintRotation.Rot180
      }
      if (rotation === 270) {
        return JSPM.PrintRotation.Rot270
      }
    }

    function printPDFFile (data) {
      console.group('JSPM PDF FILE PRINT')
      return $q(function (resolve, reject) {
        detectClientApp(data.ip)
          .then(function () {
            console.info('Creating JSPM Client Print Job')
            var printjob = new JSPM.ClientPrintJob()
            printjob.clientPrinter = new JSPM.InstalledPrinter(data.printerName, false, data.paperTray, data.printerPageFormat ? data.printerPageFormat : data.paperName ? data.paperName : '')
            var pdfFile = new JSPM.PrintFilePDF(data.content, JSPM.FileSourceType.BLOB, data.fileName, data.copies)
            pdfFile.printRotation = getPageRotation(data.rotation)
            printjob.files.push(pdfFile)
            printjob.sendToClient()
            console.info('Printjob sendend to JSPM client')
            console.info('pdfFile', pdfFile)
            console.info('printjob', printjob)
            console.groupEnd()
            $rootScope.jspmActive = false
            return resolve()
          })
          .catch(function (reason) {
            console.groupEnd()
            $rootScope.jspmActive = false
            reject(reason)
          })
      })
    }

    function printRawCommand (cmd, quantity, thermal) {
      console.group('JSPM RAW COMMAND PRINT')
      var printerName = ''
      var ip = '127.0.0.1'

      if (thermal) {
        if($rootScope.currentDevice && $rootScope.currentDevice.type === 'MOBILE') {
          if($rootScope.currentDevice.thermalPrinter.printDevice && $rootScope.currentDevice.thermalPrinter.printDevice.localIp && $rootScope.currentDevice.thermalPrinter.name) {
            printerName = $rootScope.currentDevice.thermalPrinter.name
            ip = $rootScope.currentDevice.thermalPrinter.printDevice.localIp
            console.info('Printer is set to thermal:', printerName, 'on device ' + $rootScope.currentDevice.thermalPrinter.printDevice.name)
          } else {
            return $q.reject('No printer set on remote device or no remote device known')
          }
        } else {
          printerName = $rootScope.currentDevice.thermalPrinter.name
          console.info('Printer is set to thermal:', printerName)
        }
      } else {
        if($rootScope.currentDevice && $rootScope.currentDevice.type === 'MOBILE') {
          if($rootScope.currentDevice.labelPrinterSettings && $rootScope.currentDevice.labelPrinterSettings.name && ($rootScope.currentDevice.labelPrinterSettings.printDevice && $rootScope.currentDevice.labelPrinterSettings.printDevice.localIp)) {
            printerName = $rootScope.currentDevice.labelPrinterSettings.name
            ip = $rootScope.currentDevice.labelPrinterSettings.printDevice.localIp
          } else {
            return $q.reject('No labelprinter set on remote device, or no remote device known')
          }
        } else {
          if ($rootScope.currentDevice && $rootScope.currentDevice.labelPrinter) {
            printerName = $rootScope.currentDevice.labelPrinter
            console.info('Setting printer from currentDevice:', printerName)
          }else if($rootScope.currentDevice && $rootScope.currentDevice.labelPrinterSettings && $rootScope.currentDevice.labelPrinterSettings.name) {
            //New printer setting
            printerName = $rootScope.currentDevice.labelPrinterSettings.name
            console.info('Setting printer from currentDevice (labelPrinterSettings):', printerName)
          } else if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
            printerName = $rootScope.currentCompany.labelPrinter
            console.info('Setting printer from currentCompany:', $rootScope.currentCompany.labelPrinter)
          } else {
            $rootScope.jspmActive = false
            console.error('No printer name set or known')
            return $q.reject('No printer name set or known')
          }
        }
      }
      return $q(function (resolve, reject) {
        detectClientApp(ip)
          .then(function (){
            console.info('Creating JSPM Client Print Job')
            var printjob = new JSPM.ClientPrintJob()
            printjob.clientPrinter = new JSPM.InstalledPrinter(printerName)
            printjob.printerCommands = cmd
            if (JSPM_VERSION !== 2) {
              printjob.printerCommandsCodePage = JSPM.Encoding.Windows_Greek
            }
            printjob.printerCommandsCopies = quantity

            printjob.onUpdated = function (data) {
              console.info('PrintJob updated', data)
            }
            printjob.onFinished = function(data) {
              console.info('PrintJob finished', data)
            }
            printjob.sendToClient()
            console.info('Printjob sendend to JSPM client')
            console.groupEnd()
            $rootScope.jspmActive = false
            return resolve()
          })
          .catch(function (reason){
            console.error('Error in printing raw command', reason)
            console.groupEnd()
            $rootScope.jspmActive = false
            return reject(reason)
          })
      })
    }

    function bulkRawPrint(commands, thermal) {
      console.group('JSPM RAW BULK PRINT')
      return $q(function (resolve, reject) {
        var printerName = ''
        var ip = '127.0.0.1'
        if (thermal) {
          if($rootScope.currentDevice && $rootScope.currentDevice.type === 'MOBILE') {
            if($rootScope.currentDevice.thermalPrinter && $rootScope.currentDevice.thermalPrinter.printDevice && $rootScope.currentDevice.thermalPrinter.printDevice.localIp && $rootScope.currentDevice.thermalPrinter.name) {
              printerName = $rootScope.currentDevice.thermalPrinter.name
              ip = $rootScope.currentDevice.thermalPrinter.printDevice.localIp
              console.info('Printer is set to thermal:', printerName, 'on device ' + $rootScope.currentDevice.thermalPrinter.printDevice.name)
            } else {
              return $q.reject('No printer set on remote device or no remote device known')
            }
          } else {
            printerName = $rootScope.currentDevice.thermalPrinter.name
            console.info('Printer is set to thermal:', printerName)
          }
        } else {
          if($rootScope.currentDevice && $rootScope.currentDevice.type === 'MOBILE') {
            if($rootScope.currentDevice.labelPrinterSettings && $rootScope.currentDevice.labelPrinterSettings.name && ($rootScope.currentDevice.labelPrinterSettings.printDevice && $rootScope.currentDevice.labelPrinterSettings.printDevice.localIp)) {
              printerName = $rootScope.currentDevice.labelPrinterSettings.name
              ip = $rootScope.currentDevice.labelPrinterSettings.printDevice.localIp
            } else {
              return $q.reject('No labelprinter set on remote device, or no remote device known')
            }
          } else {
            if ($rootScope.currentDevice && $rootScope.currentDevice.labelPrinter) {
              printerName = $rootScope.currentDevice.labelPrinter
              console.info('Setting printer from currentDevice:', printerName)
            }else if($rootScope.currentDevice && $rootScope.currentDevice.labelPrinterSettings && $rootScope.currentDevice.labelPrinterSettings.name) {
              //New printer setting
              printerName = $rootScope.currentDevice.labelPrinterSettings.name
              console.info('Setting printer from currentDevice (labelPrinterSettings):', printerName)
            } else if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
              printerName = $rootScope.currentCompany.labelPrinter
              console.info('Setting printer from currentCompany:', $rootScope.currentCompany.labelPrinter)
            } else {
              $rootScope.jspmActive = false
              console.error('No printer name set or known')
              return $q.reject('No printer name set or known')
            }
          }
        }
        detectClientApp(ip)
          .then(function() {
            if(!commands || Object.keys(commands).length === 0) {
              console.error('Commands array is not existing or is empty')
              $rootScope.jspmActive = false
              return reject('NOTHING_TO_PROCESS')
            }
            console.info('Going to bulk print ' + commands.length + ' documents')
            var counter = 0
            var printJobsGroup = new JSPM.ClientPrintJobGroup()

            for(var i = 0; i < commands.length; i++) {
              var printjob = new JSPM.ClientPrintJob()

              printjob.clientPrinter = new JSPM.InstalledPrinter(printerName)
              printjob.printerCommands = commands[i].command
              if (JSPM_VERSION !== 2) {
                printjob.printerCommandsCodePage = JSPM.Encoding.Windows_Greek
              }
              printjob.printerCommandsCopies = commands[i].quantity
              printJobsGroup.jobs.push(printjob)
              counter++
              if(counter === commands.length){
                printJobsGroup.sendToClient()
                console.info('All commands processed, sending to printer')
                console.groupEnd()
                $rootScope.jspmActive = false
                return resolve()
              }
            }
          })
          .catch(function (reason){
            console.error('Error in bulk printing raw', reason)
            console.groupEnd()
            $rootScope.jspmActive = false
            return reject(reason)
          })
      })
    }

    function bulkPrintPDF (files, definition) {
      console.group('JSPM BULK PDF PRINT')
      return $q(function (resolve, reject){
        var ip = '127.0.0.1'
        if(definition.printDevice && definition.printDevice.localIp) {
          ip = definition.printDevice.localIp
        }
        detectClientApp(ip)
          .then(function() {
            if(!files || Object.keys(files).length === 0) {
              console.error('Commands array is not existing or is empty')
              $rootScope.jspmActive = false
              return reject('NOTHING_TO_PROCESS')
            }
            console.info('Going to bulk print ' + files.length + ' documents')
            var counter = 0
            var printJobsGroup = new JSPM.ClientPrintJobGroup()
            var printjob = new JSPM.ClientPrintJob()

            printjob.clientPrinter = new JSPM.InstalledPrinter(definition.printerName, false, definition.paperTray, definition.paperName ? definition.paperName : "")

            for(var i = 0; i < files.length; i++) {

              var pdfFile = new JSPM.PrintFilePDF(files[i], JSPM.FileSourceType.BLOB, i+'.pdf', 1)
              pdfFile.printRotation = getPageRotation(definition.rotation)
              console.info('pdfFile', pdfFile)

              printjob.files.push(pdfFile)

              counter++
              if(counter === files.length){
                printJobsGroup.jobs.push(printjob)

                printJobsGroup.sendToClient()
                console.info('All commands processed, sending to printer')
                console.info('printJobsGroup', printJobsGroup)
                console.groupEnd()
                $rootScope.jspmActive = false
                return resolve()
              }
            }
          })
          .catch(function (reason){
            console.error('Error in bulk printing raw', reason)
            console.groupEnd()
            $rootScope.jspmActive = false
            return reject(reason)
          })
      })
    }

    return {
      startClientApp: startClientApp,
      closeConnection: closeConnection,
      detectClientApp: detectClientApp,
      getInstalledPrinters: getInstalledPrinters,
      printPDFFile: printPDFFile,
      printRawCommand: printRawCommand,
      bulkRawPrint: bulkRawPrint,
      bulkPrintPDF: bulkPrintPDF,
      getInstalledVersion: getInstalledVersion
    }
  }])
