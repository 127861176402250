prismanoteApp.factory('$customer', ['$q', '$api', '$rootScope', '$data', '$uibModal', '$retailer', '$language', '$state',
  function ($q, $api, $rootScope, $data, $uibModal, $retailer, $language, $state) {
    function getShop () {
      return $q(function (resolve, reject) {
        if (!$rootScope.currentShop) {
          $retailer.getShop()
            .then(function (shop) {
              $rootScope.currentShop = shop
              return resolve($rootScope.currentShop)
            })
        } else {
          return resolve($rootScope.currentShop)
        }
      })
    }

    function getCustomer (id, details) {
      return $q(function (resolve, reject) {
        getShop()
          .then(function (shop) {
            $api.get('customers', {
              id: id,
              details: details,
              currentShopId: shop._id
            })
              .then(function (response) {
                resolve(response.data)
              })
              .catch(function (reason) {
                reject(reason)
              })
          })
      })
    }

    function getCustomerTransaction (id, details) {
      return $q(function (resolve, reject) {
            $api.get('customerTransactions', {
              id: id,
              details: details,
              currentShopId: $rootScope.currentShop._id
            })
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (reason) {
              reject(reason)
            })
          })
    }

    function createCustomer (customer, refresh) {
      customer.shopId = $rootScope.currentShop._id
      return $q(function (resolve, reject) {
        $api.put('customers', {
          customer: customer
        })
          .then(function (response) {
            // if (refresh) {
            //   $data.getCustomers(true)
            // }
            resolve(response.data.result)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    function updateCustomer (customer, refresh) {
      return $q(function (resolve, reject) {
        $api.post('customers', {
          customer: customer
        })
          .then(function (response) {
            // if (refresh) {
            //   $data.getCustomers(true)
            // }
            resolve(response.data.message)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    function deleteCustomer (id, refresh) {
      return $q(function (resolve, reject) {
        $api.delete('customers/' + id + '/' + $rootScope.currentShop._id)
          .then(function (response) {
            if (refresh) {
              $data.getCustomers(true)
            }
            resolve(response.data.customer)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    function openCustomerModal (customer, add, serviceApp) {
      return $q(function (resolve, reject) {
        if (add === true) {
          customer = {}
        }

        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/customer-modal.html',
          controller: 'customerModalController',
          size: 'lg',
          keyboard: false,
          backdrop: 'static',
          resolve: {
            customer: function () {
              return customer
            },
            add: function () {
              return add
            },
            serviceApp: function () {
              return serviceApp
            }
          }
        })

        modalInstance.result.then(function (res) {
          if(res.action === 'merge') {
            $state.go('retailer.customer', {
              id: res.customer._id
            })
            return
          }
          return resolve(res)
        }, function (reason) {
          if (!reason) {
            reason = 'dismissed'
          }
          return reject(reason)
        })
      })
    }

    function openSearchCustomerModal (allowOpen, serviceApp) {
      if (allowOpen === undefined) {
        allowOpen = true
      }
      if (serviceApp === undefined) {
        serviceApp = false
      }
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/search-customer-modal.html',
          controller: 'searchCustomerModalController',
          keyboard: false,
          backdrop: 'static',
          size: 'lg',
          resolve: {
            allowOpen: function () {
              return allowOpen
            },
            serviceApp: function () {
              return serviceApp
            }
          }
        })

        modalInstance.result.then(function (res) {
          return resolve(res)
        }, function (reason) {
          if (!reason) {
            reason = 'dismissed'
          }
          return reject(reason)
        })
      })
    }

    function getUniqueEmail (customer) {
      return $q(function (resolve, reject) {
        $api.post('customers/check-email', {
          customer: customer,
          shopSlug: $rootScope.currentShop.nameSlug,
          shopId: $rootScope.currentShop._id
        })
          .then(function (email) {
            resolve(email.data.email)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    function makeCustomerName (customer) {
      if(!customer) {
        return
      }
      var result = ''
      if(customer.salutation && customer.salutation !== "") {
        result += $language.translate(customer.salutation) + ' '
      }

      if (customer.firstName) {
        result += customer.firstName + ' '
      }
      if (customer.prefix) {
        result += customer.prefix + ' '
      }
      if (customer.lastName) {
        result += customer.lastName
      }

      return result
    }

    function getCustomerPhone(customer, number) {
      var result = ''
      if (customer.phone) {
        if (number && customer.phone[number]) {
          result = customer.phone[number]
        } else {
          if (customer.phone.landLine) {
            result = customer.phone.landLine
          } else if (customer.phone.mobilePhone) {
            result = customer.phone.mobilePhone
          }
        }
      }
      return result
    }

    function getCustomerPoints(customer, shopId) {
      return $q(function (resolve, reject){
        $api.get('customers/points?customerId=' + customer._id + '&shopId=' + shopId)
          .then(function(res) {
            return resolve(res.data.spendable)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function openCustomerEmailModal(customer) {
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/customer-send-email-modal.html',
          controller: 'customerSendEmailModalController',
          keyboard: false,
          backdrop: 'static',
          size: 'lg',
          resolve: {
            customer: function () {
              return customer
            }, 
            email: function() {
              return {}
            }
          }
        })
        modalInstance.result.then(function () {
          return resolve()
        }, function () {
          return reject()
        })
      })
    }

    function getPayLaterTransactionForCustomer(customerId) {
      return $q(function (resolve, reject) {
        $api.get('customers/transactions', {
          customerId: customerId,
          shopId: $rootScope.currentShop._id
        })
        .then(function (transactions) {
          return resolve(transactions)
        })
        .catch(function (reason){
          return reject(reason)
        })
      })
    }

    function getPrefixes() {
      return ["","de","'s","'t","a","aan","aan 't","aan de","aan den","aan der","aan het","af","al","am","am de", 
        "auf","auf dem","auf den","auf der","auf ter","aus","aus 'm","aus dem","aus den","aus der","aus m","ben",
        "bij","bij 't","bij de","bij den","bij het","bij t","bin","boven d","boven d'","d","d'","da","dal","dalla",
        "das","de","de die","de die le","de l","de la","de las","de le","deca","degli","dei","del",
        "della","den","der","des","di","die le","do","don","dos","du","el","het","i","im","in","in 't","in de",
        "in den","in der","in het","in t","l","la","las","le","les","lo","los","of","onder","onder 't","onder de",
        "onder den","onder het","onder t","op","op 't","op de","op den","op der","op gen","op het","op t","op ten",
        "over","over 't","over de","over den","over het","over t","s","t","te","ten","ter","tho","thoe","thor","to",
        "toe","tot","uijt","uijt 't","uijt de","uijt den","uijt te de","uijt ten","uit","uit 't","uit de","uit den",
        "uit het","uit t","uit te de","uit ten","unter","van","van 't ","van de","van de l'","van den","van der",
        "van gen","van het","van la","van t","van ter","van van de","ver","vom","von","von 't","von dem","von den",
        "von der","von t","voor","voor 't","voor de","voor den","voor in 't","vor","vor der","zu","zum","zur"]
    }

    function openWhatsapp(customer) {
      if(!customer.phone || !customer.phone.mobilePhone || !customer.phone.whatsappMobile) {
        return
      }
      var country = $rootScope.language
      if(customer.invoiceAddress && customer.invoiceAddress.country) {
        country = customer.invoiceAddress.country
      } else if(customer.shippingAddress && customer.shippingAddress.country) {
        country = customer.shippingAddress.country
      }
      var phone = new libphonenumber.parsePhoneNumber(customer.phone.mobilePhone, country)
      if(phone && phone.number) {
        var win = window.open('https://api.whatsapp.com/send/?phone='+ phone.number.replace('+', '') + '&text&type=phone_number&app_absent=1')
        win.focus()
      }
    }

    return {
      getCustomer: getCustomer,
      createCustomer: createCustomer,
      updateCustomer: updateCustomer,
      deleteCustomer: deleteCustomer,
      openCustomerModal: openCustomerModal,
      openSearchCustomerModal: openSearchCustomerModal,
      getUniqueEmail: getUniqueEmail,
      makeCustomerName: makeCustomerName,
      getCustomerPhone: getCustomerPhone,
      getCustomerPoints: getCustomerPoints,
      openCustomerEmailModal: openCustomerEmailModal,
      getPayLaterTransactionForCustomer: getPayLaterTransactionForCustomer,
      getCustomerTransaction: getCustomerTransaction,
      getPrefixes: getPrefixes,
      openWhatsapp: openWhatsapp
    }
  }])
