prismanoteApp.factory('$upload', ['$q', '$api', '$http', '$rootScope', '$data', '$state', '$language', '$uibModal',
  function ($q, $api, $http, $rootScope, $data, $state, $language, $uibModal) {

    function uploadBase64Image (data) {

    }

    return {
      uploadBase64Image: uploadBase64Image
    }
  }])
