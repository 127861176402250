prismanoteApp.service('$shop', ['$q', '$api', '$rootScope', '$cashRegistryService', '$language', function ($q, $api, $rootScope, $cashRegistryService, $language) {
  function getOwnShopsPrivateLableCollections (id) {
    return $q(function (resolve, reject) {
      $api.get('shop/own-private-collection/' + id)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getBrandsByShop (shopNameSlug) {
    return $q(function (resolve, reject) {
      $api.get('brands/getshopbrands/' + shopNameSlug)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getshopbrandsForProduct (shopNameSlug) {
    return $q(function (resolve, reject) {
      $api.get('brands/getshopbrandsForProduct/' + shopNameSlug)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function findArticleNumberExistsOrNot (shopNameSlug, articleNumber) {
    return $q(function (resolve, reject) {
      $api.get('shop/artical-number-exists-or-not/' + shopNameSlug + '/' + articleNumber)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getAllCompanyByShop (shopId) {
    return $q(function (resolve, reject) {
      $api.get('shop-companies', {
          shopId: shopId
        })
        .then(function (response) {
          resolve(response.data.companies)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getSupplierDetaisFromCurrentShop (shopNameSlug, supplierId) {
    return $q(function (resolve, reject) {
      $api.get('shop/supplier-from-shop', {
          shopNameSlug: shopNameSlug,
          supplierId: supplierId
        })
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getPurchaseOrdersForSpecificProduct (productId, shopId) {
    return $q(function (resolve, reject) {
      $api.get('purchase-orders/product/' + productId + '?shopId=' + shopId)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          console.error('Inside error!');
          console.error(reason);
          reject(reason)
        })
    });

    // var str = 'purchase-orders/product/' + productId;
    // return $api.get(str)
  }

  function addProductInCurrentShop(shopNameSlug, shopProduct, newCollections, sPurchaseOrderNumber) {
    return $q(function (resolve, reject) {
      $api.post('shop/add-product-in-shop/', {
        shopNameSlug: shopNameSlug,
        product: shopProduct,
        collections: newCollections,
        sPurchaseOrderNumber: sPurchaseOrderNumber
      })
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getStockModeProductsShopWise (shopId, stockFilter, productFilter1, orderBy, pageNumber) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy,
        pageNumber : pageNumber ? pageNumber : 1
      }

     $api.get('shop/stock-and-price-products-from-shop-for-assortment/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getShopsCollections (shopNameSlug) {
    return $q(function (resolve, reject) {
      $api.get('shop-collections/' + shopNameSlug)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getShopsCollectionsAssortment (shopNameSlug) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop-collections-filter/' + shopNameSlug)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getShopsBrandsAssortment (shopNameSlug) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop-brands-filter/' + shopNameSlug)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getShopsSuppliersAssortment (shopNameSlug) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop-suppliers-filter/' + shopNameSlug)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function changeShopCollectionsShowOptions (shopId, collections) {
    return $q(function (resolve, reject) {
      $api.post('shops/change-collections-show-options', {
          shopId: shopId,
          collections: collections
        })
        .then(function (resopnse) {
          return resolve(resopnse.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getLatestArticalNumberForShop (shopNameSlug) {
    return $q(function (resolve, reject) {
      $api.get('shops/latest-articalNumber-shop/' + shopNameSlug)
        .then(function (resopnse) {
          return resolve(resopnse.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function deleteProductFromShop (productId, shopId) {
    return $q(function (resolve, reject) {
      $api.post('shops/delete-product-from-shop', {
        shop: shopId,
        product: productId
      }).then(function (response) {
        return resolve(response.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function updateShopProductStockAndShow (productId, shopId, stock) {

    var body = { shop: shopId, product: productId, stock: stock }
    
    return $q(function (resolve, reject) {
      $api.put('shop/stock-show-update-in-shop-product/', body).then(function (response) {
        return resolve(response.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function updateShopProductMinStock (productId, shopId, companyId, minStock) {

    var body = { shopId: shopId, product: productId, companyId: companyId, minStock: minStock, exportOption: 'ALL' }    
    return $q(function (resolve, reject) {
      $api.put('shop/update-minStock-in-shop-product/', body).then(function (response) {
        return resolve(response.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function updateStockProductShow (shopId, productId, show) {
    return $api.put('shop/products/' + shopId + '/' + productId, { show: show });
  }

  function getOwnShopProductsForCSV (shopId, stockFilter, productFilter, orderBy, pageNumber, limitCount, csvType) {
    return $q(function (resolve, reject) {
      $api.get('shop/stock-and-price-products-from-shop-for-csv', {
          shopId: shopId,
          shopFilter: stockFilter,
          productFilter: productFilter,
          orderBy: orderBy,
          pageNumber: pageNumber,
          limitCount: limitCount,
          csvType: csvType
        })
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function loadProductsForTheCSVWithoutFilters (shopId, stockFilter, productFilter, orderBy) {
    return $q(function (resolve, reject) {
      $api.get('shop/stock-and-price-products-from-shop-for-csv-without-filters', {
          shopId: shopId,
          shopFilter: stockFilter,
          productFilter: productFilter,
          orderBy: orderBy
        })
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function serialNumbersExport(shopId) {
    return $q(function (resolve, reject) {
      $api.get('shop/serial-numbers-export/' + shopId)
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function getOwnShopProduct (productNameSlug, shopNameSlug) {
    return $q(function (resolve, reject) {
      $api.get('shop/own-shop-product-by-nameSlug/' + productNameSlug + '/' + shopNameSlug)
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          console.error('-------------- inside error!!');
          console.error(reason);
          return reject(reason.data)
        })
    })
  }

  function updateProduct (suggester, product, original, shopProduct, oldCollections, newCollections, nameSlug, purchaseOrderId, generatedArticalNumber, requestFrom, shopId, modifieByUser, purchaseOrderItemId) {
    return $q(function (resolve, reject) {
      var newShopData = JSON.parse(JSON.stringify(shopProduct))
      var newProduct = JSON.parse(JSON.stringify(product))

      // has stock values has been reverted here before api call
      if (requestFrom !== 'compare-product') {
        newShopData.hasStock = (shopProduct.hasStock) ? JSON.parse(JSON.stringify(false)) : JSON.parse(JSON.stringify(true))
        newProduct.hasStock = JSON.parse(JSON.stringify(newShopData.hasStock))
      } else {
        newShopData.hasStock = JSON.parse(JSON.stringify(shopProduct.hasStock))
      }

      $api.post('shop-product/modified', {
          retailer: suggester,
          product: newProduct,
          originalProduct: original,
          shopProduct: newShopData,
          oldCollections: oldCollections,
          newCollections: newCollections,
          nameSlug: nameSlug,
          purchaseOrderId: purchaseOrderId,
          purchaseOrderItemId: purchaseOrderItemId,
          autoGenerateARNo: generatedArticalNumber,
          requestFrom: requestFrom,
          shopId: shopId,
          modifieByUser: modifieByUser
        })
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function getOwnShopCompanies (currentShopId, till) {
    return $q(function (resolve, reject) {
      if(!till) {
        till = false
      }
      $api.get('shop-retailer-companies', {
          till: till,
          shopId: currentShopId
        })
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          if(reason.status === 401) {
            toastr.error($language.translate('TEXT_NO_ACCESS'))
          } else {
            toastr.error(reason.data)
          }
          return reject(reason)
        })
    })
  }

  function getOwnShopUpdatedProducts (shopId) {
    return $q(function (resolve, reject) {
      $api.get('shop/products/with-suggestions/' + shopId)
        .then(function (response) {
          return resolve(response.data)
        })
        .catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function getTaxForShop (currentShopId) {
    return $q(function (resolve, reject) {
      $api.get('tax/shop', {
          shopId: currentShopId
        })
        .then(function (resopnse) {
          return resolve(resopnse.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function getEmployeesOfShop (currentShopId) {
    return $q(function (resolve, reject) {
      $api.get('shop/employees/' + currentShopId)
        .then(function (resopnse) {
          return resolve(resopnse.data)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function getAllShop () {
    return $q(function (resolve, reject) {
      $api.get('allshops/').then(function (response) {
        return resolve(response.data.data)
      }).catch(function (error) {
        return reject(error)
      })
    })
  }

  function getShopProductSerialNumbers (shopId, productId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/product/serialnumber/' + shopId + '/' + productId).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (error) {
        return reject(error)
      })
    })
  }

  function getShopProductStockRecord (shopId, productId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/product/stock-record/' + shopId + '/' + productId).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (error) {
        return reject(error)
      })
    })
  }

  function getShopProductStockChanges (shopId, productId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/product/stock-changes/' + shopId + '/' + productId).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (error) {
        return reject(error)
      })
    })
  }

  function isProductInShop (shopId, productIdA, productIdB) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/product-in-shop', {
          shopId: shopId,
          productA: productIdA,
          productB: productIdB
        })
        .then(function (response) {
          return resolve(response.data.data[0])
        }).catch(function (error) {
          return reject(error)
        })
    })
  }

  function transferProductData (shopId, productIdA, productIdB, newProduct, shopNameSlug) {
    return $q(function (resolve, reject) {
      $api.post('v2/shop/product/transfer', {
          shopId: shopId,
          productA: productIdA,
          productB: productIdB,
          newProduct: newProduct,
          shopNameSlug: shopNameSlug
        })
        .then(function (response) {
          return resolve(response.data.data[0])
        }).catch(function (error) {
          return reject(error)
        })
    })
  }

  function updateShopDetails (shopDetails) {
    return $q(function (resolve, reject) {
      if(!shopDetails.shopId && $rootScope.currentShop && $rootScope.currentShop._id) {
        shopDetails.shopId = $rootScope.currentShop._id
      }
      $api.put('v2/shop', shopDetails)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (error) {
          return reject(error)
        })
    })
  }

  function updateShopProductStock(shopId, productId, oldStock, newStock, reason, isEdit, sPurchaseOrderNumber, shopProductPurchasePrice) {
    return $q(function (resolve, reject) {
      $api.put('v2/shop/product/stock', {
        shopId: shopId,
        productId: productId,
        oldStock: oldStock,
        newStock: newStock,
        reason: reason,
        isEdit: isEdit,
        sPurchaseOrderNumber: sPurchaseOrderNumber,
        shopProductPurchasePrice: shopProductPurchasePrice
      }).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function searchStockProducts (currentShopId, searchValue, params, isFromPairing) {
    return $q(function (resolve, reject) {
      var data = {
        shopId: currentShopId,
        searchValue: searchValue,
        isFromPairing: isFromPairing
      }
      if(params && params.property){
        data['params'] = params
      }
      $api.post('v2/shop/products-search', data).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function searchStockProductsInventory (currentShopId, searchValue, isFromPairing) {
    return $q(function (resolve, reject) {
      $api.post('v2/shop/products-search/inventory', {
        shopId: currentShopId,
        searchValue: searchValue,
        isFromPairing: isFromPairing
      }).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function searchStockProductsCashRegistry (currentShopId, searchValue) {
    return $q(function (resolve, reject) {
      $api.put('v2/shop/products-search', {
        shopId: currentShopId,
        searchValue: searchValue
      }).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function getCompanyByShop (shopId, nameSlug) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/company', {
        shopId: shopId,
        nameSlug: nameSlug
      }).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function getSuggestionCompanyByShop (shopId, nameSlug) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/suggested-company', {
        shopId: shopId,
        nameSlug: nameSlug
      }).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function getPurchaseOrderProduct (shopId, productId, companyId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/product-purchase-order', {
        shopId: shopId,
        productId: productId,
        companyId: companyId
      }).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function getProductForCashRegistry (shopId, productId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/product-cash-registry', {
        shopId: shopId,
        productId: productId
      }).then(function (response) {
        return resolve(response.data.data)
      }).catch(function (reason) {
        return reject(reason.data)
      })
    })
  }

  function getShopLables (shopId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/labels', {
          shopId: shopId
        })
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCompanyForEditPage (nameSlug, shopId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/edit-company/' + nameSlug + '/' + shopId)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function canRemoveCompanyForShop (shopId, companyId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/can-delete-company/' + shopId + '/' + companyId)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function updateProductIdInTransaction (currentShopId, currentProductId, newProductId, newProductNumber) {
    var data = {
      newProductNumber: newProductNumber,
      currentProductId: currentProductId,
      newProductId: newProductId,
      currentShopId: currentShopId
    }

    return $q(function (resolve, reject) {
      $api.post('v2/translation/product-merge', angular.copy(data))
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getShopsAndCompaniesForSearchForDropShipper (shopId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/shops-companies-drop-shipper/' + shopId)
        .then(function (resopnse) {
          resolve(resopnse.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getBrandsWithDropShipperFromShops (currentShopId, shopId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/shop-brands-drop-shipper', {
          currentShopId: currentShopId,
          shopId: shopId
        })
        .then(function (resopnse) {
          resolve(resopnse.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getBrandsWithDropShipperFormShopAndCompany (currentShopId, companyId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/company-brands-drop-shipper', {
          currentShopId: currentShopId,
          companyId: companyId
        })
        .then(function (resopnse) {
          resolve(resopnse.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function updateDropShippersInShop (dropshippers, shopId) {
    return $q(function (resolve, reject) {
      var data = {
        currentShopId: shopId,
        dropshippers: dropshippers
      }
      $api.post('v2/shop/drop-shippers', data)
        .then(function (resopnse) {
          resolve(resopnse.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function updateShopMultiShopsAndDropShippers (data) {
    return $q(function (resolve, reject) {
      $api.put('v2/shop/drop-shippers-partners-and-multi-shops', data)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function getCollectionsForMergeCollections (collectionId, currentShopId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/can-merge-collections/', {
          collectionId: collectionId,
          currentShopId: currentShopId
        })
        .then(function (resopnse) {
          resolve(resopnse.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function mergeTwoShopCollections (keepCollectionId, replaceCollectionId, shopId) {
    return $q(function (resolve, reject) {
      $api.put('v2/shop/can-merge-collections/', {
          keepCollectionId: keepCollectionId,
          replaceCollectionId: replaceCollectionId,
          currentShopId: shopId
        })
        .then(function (resopnse) {
          resolve(resopnse.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getMutiShops (currentShopId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/muti-shops', {
          currentShopId: currentShopId
        })
        .then(function (resopnse) {
          resolve(resopnse.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getDropShippers (currentShopId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/drop-shippers', {
          currentShopId: currentShopId
        })
        .then(function (resopnse) {
          resolve(resopnse.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function updateDropShippers (dropShippers, shopId) {
    return $q(function (resolve, reject) {
      $api.put('v2/shop/drop-shippers', {
          dropShippers: dropShippers,
          shopId: shopId
        })
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function getShopAnalysis (shopId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop-analysis', {
          shopId: shopId
        })
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function updateShopProductShow (shopId, productId, showStatus) {
    return $q(function (resolve, reject) {
      $api.put('v2/show-update-in-shop-product', {
          shopId: shopId,
          productId: productId,
          showStatus: showStatus
        })
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function getAllShopForPurchaseOrder (body) {
    return $api.get('v2/brand/shops-for-purchaseorder', body)
  }

  function getShopProducts (searchText, mode, object) {
    return $q(function (resolve, reject) {
      if (searchText && searchText.length < 3) return []
      if (mode === 'order' || mode === 'repair') {
        var data = {
          nameSlug: $rootScope.currentShop.nameSlug,
          searchMode: 'stock',
          searchText: searchText,
          shopId: $rootScope.currentShop._id,
          shopProducts: true,
          useFor: 'service-app',
          language: 'nl',
          isOnlyByBrand: false,
        }
        var isNewApprocah = false;
        if (object) {
          if (object.language) {
            data.language = object.language;
          }
          if (object.isOnlyByBrand) {
            data.isOnlyByBrand = true;
          }
          if (object.isNewApprocah) {
            isNewApprocah = true;
          }
          if (object.offset) {
            data.offset = object.offset;
          }
          if (object.shopProductOffSet) {
            data.shopProductOffSet = object.shopProductOffSet;
          }
        }

        $cashRegistryService
          .fetchProductsFromWholeDb(data)
          .then(function (response) {
            if (isNewApprocah) return resolve(response.data);
            if (response && response.data && response.data.products && response.data.products.length) {
              return resolve(response.data.products)
            } else {
              return reject('NO_PRODUCTS_FOUND')
            }
          })
          .catch(function (reason) {
            console.error('Error while searching products', reason)
            return reject(reason)
          })
      } else {
        searchStockProductsCashRegistry($rootScope.currentShop._id, searchText)
          .then(function (response) {
            if (response) {
              return resolve(response)
            } else {
              return reject('NO_PRODUCTS_FOUND')
            }
          })
          .catch(function (reason) {
            console.error('Error while searching products', reason)
            return reject(reason)
          })
      }
    })
  }

  function getAddressInfo(body) {
    return;
    // return $api.post('get-address-info', body)
  }

  function getShopCollectionWiseProduct(shopId, collectionId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop-private-lable-collections-products', {
          shopId: shopId,
          collectionId: collectionId
        })
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function applyNewStockInShopBasedOnCollection(data) {
    return $q(function (resolve, reject) {
      $api.post('v2/shop-private-lable-collections-products', data)
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
    })
  }


  function resetAllCollectionUnChecked(shopId) {
    return $q(function (resolve, reject) {
      $api.put('v2/shop-private-lable-collections-update-false', {
          shopId: shopId
        })
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function addNewCollectionInShop(shopId, collectionId) {
    return $q(function (resolve, reject) {
      $api.post('v2/add-new-collection-in-shop', {
          shopId: shopId,
          collectionId: collectionId
        })
        .then(function (response) {
          return resolve(response.data)
        }).catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function updateShopDetailsByKey(body) {
    return $api.put('v2/retailer/update-shop-detail', body);
  }

  function getShopCompaniesByInvitationDate(body) {
    return $api.get('v2/retailer/shop-companies-by-invitation', body);
  }

  function isAnyShopProduct(body) {
    return $api.get('v2/admin/isAnyShopProduct', body);
  }

  function getFilterCounts (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForCategoryFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/category-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForBrandFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/brands-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForSupplierFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/supplier-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForCollectionsFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/collections-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForGenderFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/gender-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForIndicationFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/indication-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForStrapModelFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/strapType-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForGlassMaterialFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/typesOfGlass-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForCaseMaterialFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/caseMaterial-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForDialIndexFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/dialIndex-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForCaseShapeFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/caseShape-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForStrapMaterialFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/strapMaterial-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForJewelleryTypeFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/jewelleryType-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForJewelMaterialFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/jewelleryMaterial-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })  
    })
  }

  function getCountForColorFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/color-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCountForParticularsFilter (shopId, stockFilter, productFilter1, orderBy) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy
      }

      $api.get('shop/particulars-filter-counts-for-assortment-page/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getTotalCountForPagination (shopId, stockFilter, productFilter1, orderBy, pageNumber) {
    return $q(function (resolve, reject) {
      var temp
      temp = angular.copy(productFilter1)

      var newReq = {}

      newReq = {
        shopId: shopId,
        shopFilter: stockFilter,
        productFilter: temp,
        orderBy: orderBy,
        pageNumber : pageNumber ? pageNumber : 1
      }

      $api.get('shop/total-count-for-pagination/' + shopId, newReq)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function fetchTheProductWithCorruptCollections(body) {
    return $api.get('v2/admin/fetch-product-empty-collection', body)
  }

  function fetchedShopDeletedProducts(body) {
    return $api.get('v2/admin/fetch-shop-deleted-products', body)
  }

  function makeShopProudctShowTrue(body) {
    return $api.post('v2/retailer/make-shop-product-show-true', body)
  }

  function fetchAdminShopCollectionsAndShopProductCollections(body) {
    return $api.get('v2/admin/shop-collectoins-and-shopproduct-collections', body)
  }

  function fetchMultiShopDetail(body) {
    return $api.post('v2/retailer/fetch-multi-shop-detail', body);
  }

  function fetchProductCategoryByShopId(body) {
    return $api.post('v2/retailer/fetch-dynamic-product-category', body);
  }

  function addDynamicProductCategory(body) {
    return $api.post('v2/retailer/add-dynamic-product-category', body);
  }

  function isArticleNumberExistInShop(body) {
    return $api.post('v2/retailer/article-number-exist', body);
  }

  function getTheShopProduct(body) {
    return $api.get('v2/shop-product-detail', body);
  }

  function getTheShopProductsPurchasePrice(body) {
    return $api.post('v2/shop-products-purchaseprice', body);
  }

  function AddCompaniesAndBrandInShop(body) {
    return $api.post('v2/retailer/add-company-brand-in-shop', body);
  }

  function productIsForUpdateOrAddNewSuggestion(body) {
    return $api.get('product/updatemian-product-or-suggestion', body);
  }

  function getBusinessProducts(body) { // We are fetching it from new application
    return $api.get('v2/business/products/list', body);
  }

  function getBusinessProduct(body) { // We are fetching it from new application
    return $api.get('v2/business/products/' + body._id, body);
  }

  this.addNewCollectionInShop = addNewCollectionInShop
  this.resetAllCollectionUnChecked = resetAllCollectionUnChecked;
  this.applyNewStockInShopBasedOnCollection = applyNewStockInShopBasedOnCollection;
  this.getShopCollectionWiseProduct = getShopCollectionWiseProduct;
  this.updateShopProductShow = updateShopProductShow;
  this.getShopAnalysis = getShopAnalysis
  this.getDropShippers = getDropShippers
  this.updateDropShippers = updateDropShippers
  this.getMutiShops = getMutiShops
  this.mergeTwoShopCollections = mergeTwoShopCollections
  this.getCollectionsForMergeCollections = getCollectionsForMergeCollections
  this.updateShopMultiShopsAndDropShippers = updateShopMultiShopsAndDropShippers
  this.updateDropShippersInShop = updateDropShippersInShop
  this.getBrandsWithDropShipperFromShops = getBrandsWithDropShipperFromShops
  this.getBrandsWithDropShipperFormShopAndCompany = getBrandsWithDropShipperFormShopAndCompany
  this.getBrandsByShop = getBrandsByShop
  this.getOwnShopsPrivateLableCollections = getOwnShopsPrivateLableCollections
  this.findArticleNumberExistsOrNot = findArticleNumberExistsOrNot
  this.getAllCompanyByShop = getAllCompanyByShop
  this.getSupplierDetaisFromCurrentShop = getSupplierDetaisFromCurrentShop
  this.addProductInCurrentShop = addProductInCurrentShop
  this.getStockModeProductsShopWise = getStockModeProductsShopWise
  this.getFilterCounts = getFilterCounts
  this.getShopsCollections = getShopsCollections
  this.changeShopCollectionsShowOptions = changeShopCollectionsShowOptions
  this.getLatestArticalNumberForShop = getLatestArticalNumberForShop
  this.deleteProductFromShop = deleteProductFromShop
  this.updateShopProductStockAndShow = updateShopProductStockAndShow
  this.getOwnShopProduct = getOwnShopProduct
  this.updateProduct = updateProduct
  this.getOwnShopCompanies = getOwnShopCompanies
  this.getshopbrandsForProduct = getshopbrandsForProduct
  this.getOwnShopUpdatedProducts = getOwnShopUpdatedProducts
  this.getTaxForShop = getTaxForShop
  this.getAllShop = getAllShop
  this.getEmployeesOfShop = getEmployeesOfShop
  this.getShopProductSerialNumbers = getShopProductSerialNumbers
  this.getShopProductStockRecord = getShopProductStockRecord
  this.getShopProductStockChanges = getShopProductStockChanges
  this.updateShopProductStock = updateShopProductStock
  this.isProductInShop = isProductInShop
  this.transferProductData = transferProductData
  this.getOwnShopProductsForCSV = getOwnShopProductsForCSV
  this.updateShopDetails = updateShopDetails
  this.searchStockProducts = searchStockProducts
  this.searchStockProductsCashRegistry = searchStockProductsCashRegistry
  this.getCompanyByShop = getCompanyByShop
  this.getSuggestionCompanyByShop = getSuggestionCompanyByShop
  this.getPurchaseOrderProduct = getPurchaseOrderProduct
  this.getProductForCashRegistry = getProductForCashRegistry
  this.getShopLables = getShopLables
  this.getCompanyForEditPage = getCompanyForEditPage
  this.updateProductIdInTransaction = updateProductIdInTransaction
  this.getShopsCollectionsAssortment = getShopsCollectionsAssortment
  this.getShopsBrandsAssortment = getShopsBrandsAssortment
  this.getShopsSuppliersAssortment = getShopsSuppliersAssortment
  this.canRemoveCompanyForShop = canRemoveCompanyForShop
  this.getShopsAndCompaniesForSearchForDropShipper = getShopsAndCompaniesForSearchForDropShipper
  this.getAllShopForPurchaseOrder = getAllShopForPurchaseOrder
  this.getShopProducts = getShopProducts
  this.getAddressInfo = getAddressInfo
  this.getPurchaseOrdersForSpecificProduct = getPurchaseOrdersForSpecificProduct
  this.updateStockProductShow = updateStockProductShow
  this.updateShopDetailsByKey = updateShopDetailsByKey
  this.getShopCompaniesByInvitationDate = getShopCompaniesByInvitationDate
  this.getCountForCategoryFilter = getCountForCategoryFilter
  this.getCountForBrandFilter = getCountForBrandFilter
  this.getCountForSupplierFilter = getCountForSupplierFilter
  this.getCountForCollectionsFilter = getCountForCollectionsFilter
  this.getCountForGenderFilter = getCountForGenderFilter
  this.getCountForIndicationFilter = getCountForIndicationFilter
  this.getCountForStrapModelFilter = getCountForStrapModelFilter
  this.getCountForGlassMaterialFilter = getCountForGlassMaterialFilter
  this.getCountForCaseMaterialFilter = getCountForCaseMaterialFilter
  this.getCountForDialIndexFilter = getCountForDialIndexFilter
  this.getCountForCaseShapeFilter = getCountForCaseShapeFilter
  this.getCountForStrapMaterialFilter = getCountForStrapMaterialFilter
  this.getCountForJewelleryTypeFilter = getCountForJewelleryTypeFilter
  this.getCountForJewelMaterialFilter = getCountForJewelMaterialFilter
  this.getCountForColorFilter = getCountForColorFilter
  this.getCountForParticularsFilter = getCountForParticularsFilter
  this.getTotalCountForPagination = getTotalCountForPagination
  this.fetchTheProductWithCorruptCollections = fetchTheProductWithCorruptCollections;
  this.fetchedShopDeletedProducts = fetchedShopDeletedProducts;
  this.makeShopProudctShowTrue = makeShopProudctShowTrue;
  this.fetchAdminShopCollectionsAndShopProductCollections = fetchAdminShopCollectionsAndShopProductCollections;
  this.isAnyShopProduct = isAnyShopProduct;
  this.searchStockProductsInventory = searchStockProductsInventory;
  this.fetchMultiShopDetail = fetchMultiShopDetail;
  this.fetchProductCategoryByShopId = fetchProductCategoryByShopId;
  this.addDynamicProductCategory = addDynamicProductCategory;
  this.isArticleNumberExistInShop = isArticleNumberExistInShop;
  this.loadProductsForTheCSVWithoutFilters = loadProductsForTheCSVWithoutFilters;
  this.getTheShopProduct = getTheShopProduct;
  this.getTheShopProductsPurchasePrice = getTheShopProductsPurchasePrice;
  this.updateShopProductMinStock = updateShopProductMinStock;
  this.AddCompaniesAndBrandInShop = AddCompaniesAndBrandInShop;
  this.productIsForUpdateOrAddNewSuggestion = productIsForUpdateOrAddNewSuggestion;
  this.getBusinessProducts = getBusinessProducts;
  this.getBusinessProduct = getBusinessProduct;
  this.serialNumbersExport = serialNumbersExport;
}])
