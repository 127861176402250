prismanoteApp.service('$cashRegistryService', ['$q', '$api', 'Upload', '$uibModal',
  function ($q, $api) {
    // Fetch all the products from whole database based on search string
    function fetchProductsFromWholeDb (requestBody) {
      return $q(function (resolve, reject) {
        $api.put('purchase-order-products-search', requestBody)
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    };

    // function getMoreProductDetailsAfterSelectFromSuggestion = ()
    return {
      fetchProductsFromWholeDb: fetchProductsFromWholeDb
    }
  }])
