prismanoteApp.service('$purchaseOrder', ['$q', '$api', '$rootScope', 'Upload', '$uibModal', '$sce',
  function ($q, $api, $rootScope, Upload, $uibModal, $sce) {

    function isNumber (input) {
      console.log('input ', input);
      if(typeof input === 'number' && input >= 0 && isFinite(input)){
          return true
      }else{ return false }
    }

    function isValidEAN(ean, items) {
      if ((ean.length == 8 || ean.length == 11 || ean.length == 13) && !isNaN(ean)) {
        var isAlreadyExist = false;
        for (var i = 0; i < items.length; i++) {
          if (items[i].ean && ean && items[i].ean === ean) {
            isAlreadyExist = true;
            break;
          }
        }
        if (isAlreadyExist) return true;
        return true;
      } else {
        return false;
      }
    }

    function isLikeEANNumber(ean) {
      if (ean && (ean.length == 8 || ean.length == 11 || ean.length == 13) && !isNaN(ean)) {
        return true;
      } else {
        return false;
      }
    }

    function getBodyOfChangePrice(body) {
      console.log('----------- getBodyOfChangePrice!!');
      console.log('body ', body);
      var index = body.index
      var purchase_order = body.purchase_order
      var item = purchase_order.items[index]
      var eType = body.eType
      var isChangingFromViewPage = body.isChangingFromViewPage ? body.isChangingFromViewPage : false

      var priceIncVat = body.priceIncVat
      var lastPrice = body.lastPrice // Ex vat
      // Need to confirm this
      // item.priceVat = item.priceVat ? item.priceVat : 21;
      console.log('item.priceVat ', item.priceVat);
      var price = priceIncVat / ((item.priceVat / 100) + 1) // To save price ex vat

      var bodyPrice = {
        purchaseOrderId: purchase_order._id,
        purchaseOrderItemId: item._id,
        productId: item.product_id ? item.product_id._id : undefined,
        shopId: (purchase_order && purchase_order.shop && purchase_order.shop._id ? purchase_order.shop._id : undefined),
        lastAddedPrice: lastPrice || 0,
        addedPrice: price,
        priceIncVat: priceIncVat,
        lastPriceIncVat: lastPrice * ((item.lastPriceVat / 100) + 1),
        companyId: purchase_order.company._id,
        calculation: 'price',
        isShow: true,
        action: (!lastPrice ? 'Added' : 'Edited'),
        status: purchase_order.status[0].status,
        eType: eType,
        userId: $rootScope.user._id,
        isChangingFromViewPage: isChangingFromViewPage
      }
      console.log('bodyPrice ', bodyPrice);
      return bodyPrice
    }

    function getBodyOfChangePurchasePrice(body) {
      console.log('----------------------- getBodyOfChangePurchasePrice!!');
      console.log('body  ', body);
      var index = body.index
      var purchase_order = body.purchase_order
      var item = purchase_order.items[index]
      var addedPurchasePrice = parseFloat(body.addedPurchasePrice)
      var lastPurchasePrice = body.lastPurchasePrice
      var eType = body.eType
      var isChangingFromViewPage = body.isChangingFromViewPage ? body.isChangingFromViewPage : false

      console.log('addedPurchasePrice ', addedPurchasePrice);
      // console.log('changePrice Called: ', index, addedPurchasePrice, lastPurchasePrice, item);
      // addedPurchasePrice = addedPurchasePrice / ((item.priceVat / 100) + 1); // To save addedPurchasePrice ex vat
      var bodyPurchaePrice = {
        purchaseOrderId: purchase_order._id,
        purchaseOrderItemId: item._id,
        productId: item.product_id ? item.product_id._id : undefined,
        shopId: (purchase_order && purchase_order.shop && purchase_order.shop._id ? purchase_order.shop._id : undefined),
        lastPurchasePrice: lastPurchasePrice || 0,
        addedPurchasePrice: isNumber(addedPurchasePrice) ? addedPurchasePrice : 0,
        companyId: purchase_order.company._id,
        calculation: 'purchaseprice',
        userId: $rootScope.user._id,
        isShow: true,
        action: (!lastPurchasePrice ? 'Added' : 'Edited'),
        status: purchase_order.status[0].status,
        eType: eType,
        isChangingFromViewPage: isChangingFromViewPage
      }
      console.log('bodyPurchaePrice ', bodyPurchaePrice);
      return bodyPurchaePrice
    }

    function getBodyOfChangeMargin(body) {
      var index = body.index
      var purchase_order = body.purchase_order
      var item = purchase_order.items[index]
      var AddedDiscount = body.AddedDiscount
      var lastAddedDiscount = body.lastAddedDiscount
      var eType = body.eType
      var isChangingFromViewPage = body.isChangingFromViewPage ? body.isChangingFromViewPage : false

      var bodyMargin = {
        purchaseOrderId: purchase_order._id,
        purchaseOrderItemId: item._id,
        productId: item.product_id ? item.product_id._id : undefined,
        shopId: (purchase_order && purchase_order.shop && purchase_order.shop._id ? purchase_order.shop._id : undefined),
        companyId: purchase_order.company._id,
        calculation: 'discount',
        userId: $rootScope.user._id,
        isShow: true,
        action: 'Edited',
        status: purchase_order.status[0].status,
        eType: eType,
        isChangingFromViewPage: isChangingFromViewPage,
        lastAddedDiscount: {
          isPercentage: false,
          value: lastAddedDiscount || 0
        },
        AddedDiscount: {
          isPercentage: false,
          value: isNumber(AddedDiscount) ? AddedDiscount : 0
        }
      }
      return bodyMargin
    }

    function getBodyOfChangeStorageFactor(body) {
      var index = body.index
      var purchase_order = body.purchase_order
      var addedStorageFactor = body.addedStorageFactor || 0;
      var lastStorageFactor = body.lastStorageFactor
      var eType = body.eType
      var item = purchase_order.items[index]
      var isChangingFromViewPage = body.isChangingFromViewPage ? body.isChangingFromViewPage : false
      // console.log('changeStorageFactor Called: ', index, addedStorageFactor, lastStorageFactor, item);
      var bodyStorageFactor = {
        purchaseOrderId: purchase_order._id,
        purchaseOrderItemId: item._id,
        productId: item.product_id ? item.product_id._id : undefined,
        shopId: (purchase_order && purchase_order.shop && purchase_order.shop._id ? purchase_order.shop._id : undefined),
        lastStorageFactor: lastStorageFactor || 0,
        addedStorageFactor: isNumber(addedStorageFactor) ? addedStorageFactor : 0,
        companyId: purchase_order.company._id,
        calculation: 'storagefactor',
        userId: $rootScope.user._id,
        isShow: true,
        action: (!lastStorageFactor ? 'Added' : 'Edited'),
        status: purchase_order.status[0].status,
        eType: eType,
        isChangingFromViewPage: isChangingFromViewPage
      }
      return bodyStorageFactor
    }

    function getBodyOfChangePriceVat(body) {
      console.debug('----------- getBodyOfChangePriceVat!!');
      var index = body.index
      var purchase_order = body.purchase_order
      var item = purchase_order.items[index]
      var eType = body.eType
      var isChangingFromViewPage = body.isChangingFromViewPage ? body.isChangingFromViewPage : false
      var addedPriceVat = body.addedPriceVat
      var lastPriceVat = body.lastPriceVat

      var bodyPriceVat = {
        purchaseOrderId: purchase_order._id,
        purchaseOrderItemId: item._id,
        productId: item.product_id ? item.product_id._id : undefined,
        shopId: (purchase_order && purchase_order.shop && purchase_order.shop._id ? purchase_order.shop._id : undefined),
        lastPriceVat: lastPriceVat || 0,
        addedPriceVat: addedPriceVat,
        companyId: purchase_order.company._id,
        calculation: 'pricevat',
        userId: $rootScope.user._id,
        isShow: true,
        action: (!lastPriceVat ? 'Added' : 'Edited'),
        status: purchase_order.status[0].status,
        eType: eType,
        isChangingFromViewPage: isChangingFromViewPage
      }
      // console.log('bodyPriceVat ', bodyPriceVat);
      return bodyPriceVat
    }

    function getBodyOfChangeSendQuantity(body) {
      // console.log(body.index, body.purchase_order);
      var index = body.index
      var purchase_order = body.purchase_order
      var item = purchase_order.items[index]
      var isSendQuantityChangedAfterFocusingPrice = !!body.isSendQuantityChangedAfterFocusingPrice // Its about whenever we are changing sendQnt(Company) or RecievedQnt(Retailer) from the Purchase order
      // At that time we giving focus directly to the Price Input, so if price changes then we need to change Send or Recieced Qnt As well

      var bodySendQuantity = {
        isSendQuantityChangedAfterFocusingPrice: isSendQuantityChangedAfterFocusingPrice
      }
      if (isSendQuantityChangedAfterFocusingPrice) {
        bodySendQuantity = {
          _id: purchase_order._id,
          status: purchase_order.status[0].status,
          companyStatus: purchase_order.companyRemarks.status,
          isChangeTheStatus: !!purchase_order.isChangeTheStatus,
          item: {
            _id: item._id,
            oldSendQuantity: item.oldSendQuantity,
            addSendQuantity: item.addSendQuantity,
            TotalQuantity: item.sendQuantity,
            product_id: item.product_id && item.product_id._id ? item.product_id._id : undefined,
            action: 'Edited'
          },
          isSendQuantityChangedAfterFocusingPrice: isSendQuantityChangedAfterFocusingPrice
        }
      }

      return bodySendQuantity
    }

    function getProductDetailsFromPurchaseOrder(orderNumber, itemId, shopCompanyId) {
      console.log('order number', orderNumber, 'itemId', itemId)
      return $q(function (resolve, reject) {
        $api.get('purchase-order/purchase-order-product-by-item-id/' + orderNumber + '/' + itemId, { shopId: shopCompanyId, companyId: shopCompanyId})
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error while getting product details for new product', reason)
            return reject(reason.data)
          })
      })
    }

    function productIsAlredayInPurchaseOrder(number, itemId, productId, companyId) {
      return $q(function (resolve, reject) {
        $api.get('v2/purchase-order/product/exist', {
          number: number,
          itemId: itemId,
          productId: productId,
          companyId: companyId
        })
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error while getting product details for new product', reason)
            return reject(reason.data)
          })
      })
    }

    function updateProductDetailsInPurchaseOrder(number, itemId, productId, productNumber, purchaseOrder, productData, companyId) {
      return $q(function (resolve, reject) {
        $api.put('v2/purchase-order/product/exist', {
          number: number,
          itemId: itemId,
          productId: productId,
          productNumber: productNumber,
          purchaseOrder: purchaseOrder,
          productData: productData,
          companyId: companyId
        })
          .then(function (res) {
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.error('Error while getting product details for new product', reason)
            return reject(reason.data)
          })
      })
    }

    function getPurchaseOrderDetailsFormOrderNumber(orderNumber, product_id, localItemId) {
      return $q(function (resolve, reject) {
        console.log(' --------- getPurchaseOrderDetailsFormOrderNumber!! service!!');
        console.log('purchase-order/product/' + orderNumber + '/' + product_id);
        var body = {
          itemId: localItemId
        }
        $api.get('purchase-order/product/' + orderNumber + '/' + product_id, body)
          .then(function (res) {
            console.log('----- inside result !!');
            console.log(res.data);
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.log('----- inside reason !!');
            console.log(reason);
            console.error('Error while getting product details for new product', reason)
            return reject(reason.data)
          })
      })
    }

    function updateStepThreeFlag(orderNumber, product_id, flag) {
      return $q(function (resolve, reject) {
        console.log(' --------- updateStepThreeFlag!! service!!');
        var body = { flag: flag }
        $api.put('purchase-order-update-stepThree-flag/' + orderNumber + '/' + product_id, body)
          .then(function (res) {
            console.log('----- inside result !!');
            console.log(res.data);
            return resolve(res.data)
          })
          .catch(function (reason) {
            console.log('----- inside reason !!');
            console.log(reason);
            console.error('Error while getting product details for new product', reason)
            return reject(reason.data)
          })
      })
    }

    function getInernalSupplier(shopId) {
      return $q(function (resolve, reject) {
        $api.get('v2/shop/internal-supplier/' + shopId).then(function (res) {
          return resolve(res.data)
        }).catch(function (reason) {
          console.error('Error while getting product details for new product', reason)
          return reject(reason)
        })
      })
    }

    function getPurchaseOrderNumber(shopId) {
      console.log('hello')
      return $q(function (resolve, reject) {
        $api.get('getDefaultPurchaseOrderNo?shopId='+ shopId)
          .then(function (response) {
            resolve(response.data.purchaseOrderNumber)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    function uploadFileAndCreatePurchaseOrder(file, shop, language) {
      console.log('shop', shop)
      return $q(function (resolve, reject) {
        Upload.upload({
          url: 'api/upload-file-and-create-purchase-order', 
          data: {
            files: file, // file input field,
            shop: shop,
            language: language || 'en'
          }
        }).then(function (response) {
          resolve(response)
        }).catch(function (error) {
          reject(error)
        })
      })
    }

    function updateCompanyProduct(companyId, companyProductDetails) {
      return $q(function (resolve, reject) {
        var body = {
          companyId: companyId,
          companyProductDetails: companyProductDetails
        }
        $api.post('v2/company/update-company-product', body)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    /**
         * Modal for the searching the supplier in purchase order
         */
    function openSearchSupplierModal(companies) {
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/search-supplier-modal.html',
          controller: 'searchSupplierModalController',
          keyboard: false,
          size: 'lg',
          resolve: {
            companies: function () {
              return companies
            }
          }
        })

        modalInstance.result.then(function (res) {
          return resolve(res)
        }, function (reason) {
          if (!reason) {
            reason = 'dismissed'
          }
          return reject(reason)
        })
      })
    }

    function acceptTheRetailerChanges(body) {
      return $q(function (resolve, reject) {
        $api.post('v2/company/accept-the-retailer-change', body)
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    function getShopDetailForRetailerPurchaseOrder(body) {
      return $q(function (resolve, reject) {
        $api.get('v2/shop/shop-detail-for-retailer-purchase-order', body)
          .then(function (response) {
            resolve(response.data.data)
          }).catch(function (reason) {
            reject(reason)
          })
      })
    }

    function calculateAllValueOnPriceChange(lastEntryMethodCustomerValue, newPrice, item) {
      console.log('calculateAllValueOnPurchasePriceChange: ', lastEntryMethodCustomerValue, newPrice, item)
      if (lastEntryMethodCustomerValue) {
        var updatedPurchasePrice = (item && item.discount && item.discount.value) ? (newPrice / item.discount.value) : (item.purchasePrice || 0)
        var updatedStorageFactor = (updatedPurchasePrice ? ((newPrice / (1 + (item.priceVat / 100)) / updatedPurchasePrice) || 0) : 0)
        var updatedMargin = updatedPurchasePrice ? (newPrice / updatedPurchasePrice) || 0 : 0
        return { updatedPurchasePrice: updatedPurchasePrice, updatedStorageFactor: updatedStorageFactor, updatedMargin: updatedMargin }
      } else {
        // var updatedPurchasePrice = 0;
        // if (!item.storageFactor) {
        //     updatedPurchasePrice = item.purchasePrice;
        // } else {
        //     updatedPurchasePrice = newPrice / (1 + (item.priceVat / 100)) / item.storageFactor;
        // }
        var updatedStorageFactor = newPrice / (1 + (item.priceVat / 100)) / item.purchasePrice
        var updatedMargin = newPrice / item.purchasePrice
        return { updatedPurchasePrice: item.purchasePrice, updatedStorageFactor: updatedStorageFactor, updatedMargin: updatedMargin }
      }
    }

    function calculateAllValueOnPurchasePriceChange(lastEntryMethodCustomerValue, newPurchasePrice, item) {
      console.log('--------------- calculateAllValueOnPurchasePriceChange!!');
      // console.log('calculateAllValueOnPurchasePriceChange: ', lastEntryMethodCustomerValue, newPurchasePrice, item)
      if (lastEntryMethodCustomerValue) {
        console.log('IF -----------------> ')
        var updatedStorageFactor = item.price / newPurchasePrice
        var updatedMargin = item.discount && item.discount.value ? item.discount.value : 0
        var updatedPrice = parseFloat(parseFloat(item.price * ((1 + (item.priceVat / 100)))).toFixed(3));
        if (!item.price && newPurchasePrice && updatedMargin) {
          // console.log('CALCUATIONS: ', updatedMargin, newPurchasePrice, ((1 + (item.priceVat / 100))));
          updatedPrice = parseFloat(parseFloat(updatedMargin * newPurchasePrice).toFixed(3));
          // console.log('INSIDE: ', item.price);
        } else if (item.price && newPurchasePrice && item.priceVat) {
          updatedMargin = item.price * ((1 + (item.priceVat / 100))) / newPurchasePrice
        }
        console.log('------------------ updatedPrice!');
        console.log('updatedPrice ', updatedPrice);
        console.log('parseFloat(updatedPrice) ', parseFloat(updatedPrice));
        console.log('parseFloat(updatedPrice).toFixed(3) ', parseFloat(updatedPrice).toFixed(3));
        console.log('parseFloat(parseFloat(updatedPrice).toFixed(3)); ', parseFloat(parseFloat(updatedPrice).toFixed(3)));

        updatedPrice = parseFloat(parseFloat(updatedPrice).toFixed(3));
        console.log('updatedPrice ', updatedPrice);
        console.log('------------------ updatedStorageFactor!');
        console.log(updatedStorageFactor);
        console.log('------------------ updatedMargin!');
        console.log(updatedMargin);
        return { updatedPrice: updatedPrice, updatedStorageFactor: updatedStorageFactor, updatedMargin: updatedMargin }
      } else {
        console.log('ELSE -----------------> ')
        var updatedPrice = 0
        if (!item.storageFactor) {
          updatedPrice = (item.price || 0) * (1 + (item.priceVat / 100))
        } else {
          updatedPrice = newPurchasePrice * item.storageFactor * (1 + (item.priceVat / 100))
        }
        var updatedStorageFactor = item.storageFactor || 0
        if (updatedPrice && newPurchasePrice && item.priceVat) {
          updatedStorageFactor = updatedPrice / (1 + (item.priceVat / 100)) / newPurchasePrice
        }

        var updatedMargin = updatedPrice / newPurchasePrice
        var updatedMargin = item.discount.value
        console.log('------------------ updatedPrice!');
        console.log(updatedPrice);
        console.log('------------------ updatedStorageFactor!');
        console.log(updatedStorageFactor);
        console.log('------------------ updatedMargin!');
        console.log(updatedMargin);
        return { updatedPrice: updatedPrice, updatedStorageFactor: updatedStorageFactor, updatedMargin: updatedMargin }
      }
    }

    function calculateAllValueOnMarginChange(lastEntryMethodCustomerValue, newMargin, item) {
      console.log('inside calculateAllValueOnMarginChange: ', lastEntryMethodCustomerValue, newMargin, item)
      if (lastEntryMethodCustomerValue) {
        console.log('item.price: ', item.price, item.priceVat, newMargin)
        var updatedPurchasePrice = item.price * (1 + (item.priceVat / 100)) / newMargin // price is ex vat
        console.log('updatedPurchasePrice: ', updatedPurchasePrice)
        var updatedStorageFactor = item.price / updatedPurchasePrice
        console.log('updatedStorageFactor: ', updatedStorageFactor)
        // var updatedMargin = (item.price * (item.priceVat/100))) / updatedPurchasePrice;
        return { updatedPurchasePrice: updatedPurchasePrice, updatedStorageFactor: updatedStorageFactor }
      } else {
        var updatedPrice = (1 + (item.priceVat / 100)) * item.purchasePrice * item.storageFactor
        var updatedStorageFactor = updatedPrice / item.purchasePrice
        // var updatedMargin = updatedPrice / newPurchasePrice;
        return { updatedPrice: updatedPrice, updatedStorageFactor: updatedStorageFactor }
      }
    }

    function calculateAllValueOnPriceVatChange(lastEntryMethodCustomerValue, newVat, lastPriceVat, item) {
      newVat = 1 + (newVat / 100)
      console.log('hello there lastEntryMethodCustomerValue: ', newVat, item, lastEntryMethodCustomerValue)
      if (lastEntryMethodCustomerValue) {
        var updatedPriceExVat = (item.price * (1 + (lastPriceVat / 100))) / newVat // As we don't need to change priceIncVat but we are changing the priceExVat
        var updatedPrice = updatedPriceExVat * newVat // PriceInVat which same as before
        var updatedStorageFactor = updatedPrice / newVat / item.purchasePrice
        var updatedMargin = updatedPrice / item.purchasePrice
        return { updatedPrice: updatedPrice, updatedStorageFactor: updatedStorageFactor, updatedMargin: updatedMargin }
      } else {
        var updatedPriceIncVat = (item.price * (1 + (lastPriceVat / 100)))
        // var updatedPriceIncVat = item.price * newVat; // As we don't need to change purchase price but we are changing the priceExVat
        var updatedStorageFactor = updatedPriceIncVat / newVat / item.purchasePrice
        // var updatedPurchasePrice = updatedPriceIncVat / newVat / updatedStorageFactor;
        var updatedMargin = updatedPriceIncVat / item.purchasePrice
        // console.log('hhhhh: ', updatedPriceIncVat, updatedStorageFactor, updatedPriceIncVat / newVat, updatedPurchasePrice);
        return { updatedPriceIncVat: updatedPriceIncVat, updatedStorageFactor: updatedStorageFactor, updatedMargin: updatedMargin }
      }
    }

    function calculateAllValueOnStorageFactorChange(lastEntryMethodCustomerValue, newStorageFactor, item) {
      if (lastEntryMethodCustomerValue) {
        var updatedPurchasePrice = item.price * (1 + (item.priceVat / 100)) / item.margin // price is ex vat
        var updatedMargin = (item.price * (1 + (item.priceVat / 100))) / updatedPurchasePrice
        // var updatedStorageFactor = item.price / updatedPurchasePrice;
        return { updatedPurchasePrice: updatedPurchasePrice, updatedMargin: updatedMargin }
      } else {
        var updatedPrice = item.purchasePrice * newStorageFactor * (1 + (item.priceVat / 100))
        var updatedMargin = (updatedPrice * (1 + (item.priceVat / 100))) / item.purchasePrice
        // var updatedStorageFactor = updatedPrice / item.purchasePrice;
        // var updatedPurchasePrice = item.purchasePrice;
        return { updatedPrice: updatedPrice, updatedMargin: updatedMargin }
      }
    }

    function changeInvoiceNumber(body, shopId, companyId) {
      return $q(function (resolve, reject) {
        var url;
        if(shopId){ url = 'v2/change-invoice-number?shopId=' + shopId; }
        else if(companyId){ url = 'v2/change-invoice-number?companyId=' + companyId; }
        $api.put(url, body)
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    function changeSendQuantity(purchaseOrder, index) {
      return $q(function (resolve, reject) {
        var body = {
          _id: purchaseOrder._id,
          status: purchaseOrder.status[0].status,
          companyStatus: purchaseOrder.companyRemarks.status,
          isChangeTheStatus: !!purchaseOrder.isChangeTheStatus,
          item: {
            _id: purchaseOrder.items[index]._id,
            oldSendQuantity: purchaseOrder.items[index].oldSendQuantity,
            addSendQuantity: purchaseOrder.items[index].addSendQuantity,
            TotalQuantity: purchaseOrder.items[index].sendQuantity,
            product_id: purchaseOrder.items[index].product_id && purchaseOrder.items[index].product_id._id ? purchaseOrder.items[index].product_id._id : undefined,
            action: 'Edited'
          },
          companyId: $rootScope.currentCompany._id
        }
        $api.put('v2/company/change-send-quantity', body)
          .then(function (response) {
            resolve(response.data.purchaseOrderNumber)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    function changePurchasePrice(body) {
      return $q(function (resolve, reject) {
        try {
          // var index = body.index
          // var purchase_order = body.purchase_order
          // var item = purchase_order.items[index]
          // var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
          // var isChangingFromViewPage = body.isChangingFromViewPage
          // var addedPurchasePrice = body.addedPurchasePrice
          // var lastPurchasePrice = body.lastPurchasePrice
          // var eType = body.eType
          // // console.log('changePrice Called: ', index, addedPurchasePrice, lastPurchasePrice, purchase_order.items[index]);
          // // addedPurchasePrice = addedPurchasePrice / ((purchase_order.items[index].priceVat / 100) + 1); // To save addedPurchasePrice ex vat
          // if (addedPurchasePrice != undefined || addedPurchasePrice != null && addedPurchasePrice != lastPurchasePrice) {
          //   var bodyPurchaePrice = getBodyOfChangePurchasePrice(body)

          //   if (isChangingFromViewPage) {
          //     body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
          //     var objectPurchasePriceChange = calculateAllValueOnPurchasePriceChange(lastEntryMethodCustomerValue, addedPurchasePrice, item) // calculate all changes
          //     console.log('hello objectPrice Change: ', objectPurchasePriceChange)
          //     body.priceIncVat = objectPurchasePriceChange.updatedPrice
          //     body.lastPrice = item.price
          //     var bodyPrice = getBodyOfChangePrice(body)
          //     body.AddedDiscount = objectPurchasePriceChange.updatedMargin
          //     body.lastAddedDiscount = item.discount.value
          //     var bodyMargin = getBodyOfChangeMargin(body)
          //     body.addedStorageFactor = objectPurchasePriceChange.updatedStorageFactor
          //     body.lastStorageFactor = item.storageFactor
          //     var bodyStorageFactor = getBodyOfChangeStorageFactor(body)
          //   }

          //   var finalbody = {
          //     bodyPurchaePrice: bodyPurchaePrice,
          //     bodyPrice: bodyPrice,
          //     bodyMargin: bodyMargin,
          //     bodyStorageFactor: bodyStorageFactor
          //   }

            console.log('change PURCHASE PRICE called: ', body)
            $api.post('v2/change-purchaseprice-of-purchaseorder', body)
              .then(function (response) {
                resolve(response.data)
              })
              .catch(function (reason) {
                reject(reason)
              })
          // } else {
          //   reject({ message: 'Please enter correct purchase price or try with different one' })
          // }
        } catch (error) {
          console.log('Change PURCHASE Price  error: ', error)
          reject({ message: 'Internal Server Error', error: error })
        }
      })
    }

    function changePurchasePriceForBrandPortal(body) {
      return $q(function (resolve, reject) {
        try {
          // var index = body.index
          // var purchase_order = body.purchase_order
          // var item = purchase_order.items[index]
          // var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
          // var isChangingFromViewPage = body.isChangingFromViewPage
          // var addedPurchasePrice = body.addedPurchasePrice
          // var lastPurchasePrice = body.lastPurchasePrice
          // var eType = body.eType
          // // console.log('changePrice Called: ', index, addedPurchasePrice, lastPurchasePrice, purchase_order.items[index]);
          // // addedPurchasePrice = addedPurchasePrice / ((purchase_order.items[index].priceVat / 100) + 1); // To save addedPurchasePrice ex vat
          // if (addedPurchasePrice != undefined || addedPurchasePrice != null && addedPurchasePrice != lastPurchasePrice) {
          //   var bodyPurchaePrice = getBodyOfChangePurchasePrice(body)

          //   if (isChangingFromViewPage) {
          //     body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
          //     var objectPurchasePriceChange = calculateAllValueOnPurchasePriceChange(lastEntryMethodCustomerValue, addedPurchasePrice, item) // calculate all changes
          //     console.log('hello objectPrice Change: ', objectPurchasePriceChange)
          //     body.priceIncVat = objectPurchasePriceChange.updatedPrice
          //     body.lastPrice = item.price
          //     var bodyPrice = getBodyOfChangePrice(body)
          //     body.AddedDiscount = objectPurchasePriceChange.updatedMargin
          //     body.lastAddedDiscount = item.discount.value
          //     var bodyMargin = getBodyOfChangeMargin(body)
          //     body.addedStorageFactor = objectPurchasePriceChange.updatedStorageFactor
          //     body.lastStorageFactor = item.storageFactor
          //     var bodyStorageFactor = getBodyOfChangeStorageFactor(body)
          //   }

          //   var finalbody = {
          //     bodyPurchaePrice: bodyPurchaePrice,
          //     bodyPrice: bodyPrice,
          //     bodyMargin: bodyMargin,
          //     bodyStorageFactor: bodyStorageFactor
          //   }

          console.log('change PURCHASE PRICE called: ', body)
          $api.post('v2/change-purchaseprice-of-purchaseorder', body)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (reason) {
              reject(reason)
            })
          // } else {
          //   reject({ message: 'Please enter correct purchase price or try with different one' })
          // }
        } catch (error) {
          console.log('Change PURCHASE Price  error: ', error)
          reject({ message: 'Internal Server Error', error: error })
        }
      })
    }

    function changeStorageFactor(body) {
      return $q(function (resolve, reject) {
        try {
          // var index = body.index
          // var purchase_order = body.purchase_order
          // var item = purchase_order.items[index]
          // var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
          // var isChangingFromViewPage = body.isChangingFromViewPage
          // var addedStorageFactor = body.addedStorageFactor
          // var lastStorageFactor = body.lastStorageFactor
          // var eType = body.eType

          // if (addedStorageFactor != undefined || addedStorageFactor != null && addedStorageFactor != lastStorageFactor) {
          //   var bodyStorageFactor = getBodyOfChangeStorageFactor(body)

          //   if (isChangingFromViewPage) {
          //     body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
          //     var objectStorageFactorChange = calculateAllValueOnStorageFactorChange(lastEntryMethodCustomerValue, addedStorageFactor, item) // calculate all changes

          //     if (lastEntryMethodCustomerValue) {
          //       body.addedPurchasePrice = objectStorageFactorChange.updatedPurchasePrice
          //       body.lastPurchasePrice = item.purchasePrice
          //       var bodyPurchaePrice = getBodyOfChangePurchasePrice(body)
          //     } else {
          //       body.priceIncVat = objectStorageFactorChange.updatedPrice
          //       body.lastPrice = item.price
          //       var bodyPrice = getBodyOfChangePrice(body)
          //     }

          //     body.AddedDiscount = objectStorageFactorChange.updatedMargin
          //     body.lastAddedDiscount = item.discount.value
          //     var bodyMargin = getBodyOfChangeMargin(body)
          //   }

          //   var finalbody = {
          //     bodyStorageFactor: bodyStorageFactor,
          //     bodyMargin: bodyMargin
          //   }
          //   if (lastEntryMethodCustomerValue) {
          //     finalbody.bodyPurchaePrice = bodyPurchaePrice
          //     finalbody.bodyPrice = { lastEntryMethodCustomerValueFromStorageFactor: false }
          //   } else {
          //     finalbody.bodyPrice = bodyPrice
          //     finalbody.bodyPurchaePrice = { lastEntryMethodCustomerValueFromStorageFactor: false }
          //   }

            // console.log('storage factor chagned finalboyd: ', finalbody)
            $api.post('v2/change-storagefactor-of-purchaseorder', body)
              .then(function (response) {
                resolve(response.data)
              })
              .catch(function (reason) {
                reject(reason)
              })
          // } 
          // else {
          //   reject({ message: 'Please enter correct storage factor or try with different one' })
          // }
        } catch (error) {
          console.log('Change FACTOR  error: ', error)
          reject({ message: 'Internal Server Error', error: error })
        }
      })
    }

    function changePrice(body) {
      console.log('change Price called 1')
      return $q(function (resolve, reject) {
        try {
          // var index = body.index
          // var purchase_order = body.purchase_order
          // var item = purchase_order.items[index]
          // var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
          // var priceIncVat = body.priceIncVat
          // var lastPrice = body.lastPrice // Ex Vat
          // var isChangingFromViewPage = body.isChangingFromViewPage
          // var price = priceIncVat / ((item.priceVat / 100) + 1) // To save price ex vat

          // if (price != undefined || price != null && price != lastPrice) {
          //   var bodyPrice = getBodyOfChangePrice(body)
          //   var bodySendQuantity = getBodyOfChangeSendQuantity(body) // For the SEND QNT FROM THE COMPANY
          //   if (isChangingFromViewPage) {
          //     body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
          //     var objectPriceChange = calculateAllValueOnPriceChange(lastEntryMethodCustomerValue, priceIncVat, item) // calculate all changes
          //     // console.log('hello objectPrice Change: ', objectPriceChange);
          //     body.addedPurchasePrice = objectPriceChange.updatedPurchasePrice
          //     body.lastPurchasePrice = item.purchasePrice
          //     var bodyPurchaePrice = getBodyOfChangePurchasePrice(body)

          //     body.AddedDiscount = objectPriceChange.updatedMargin
          //     body.lastAddedDiscount = item.discount.value
          //     var bodyMargin = getBodyOfChangeMargin(body)

          //     body.addedStorageFactor = objectPriceChange.updatedStorageFactor
          //     body.lastStorageFactor = item.storageFactor
          //     var bodyStorageFactor = getBodyOfChangeStorageFactor(body)
          //   }

          //   var bodyReceivedQuantity = {
          //     isReceivedQuantityChangedAfterFocusingPrice: false
          //   }
          //   if (body.bodyReceivedQuantity) bodyReceivedQuantity = body.bodyReceivedQuantity

          //   var finalbody = {
          //     bodyPrice: bodyPrice,
          //     bodyPurchaePrice: bodyPurchaePrice,
          //     bodyMargin: bodyMargin,
          //     bodyStorageFactor: bodyStorageFactor,
          //     bodySendQuantity: bodySendQuantity, // For Focus price updatd from company(DEFAULT FALSE)
          //     bodyReceivedQuantity: bodyReceivedQuantity // For Focus price updatd from Retailer(DEFAULT FALSE)
          //   }
          //   console.log('change Price final body: ', finalbody)
            $api.post('v2/shop/change-price-of-purchaseorder', body)
              .then(function (res) {
                resolve(res)
              })
              .catch(function (reason) {
                reject(reason)
              })
          } 
          // else {
          //   reject({ message: 'Please enter correct price or try with different one' })
          // }
        // } 
        catch (error) {
          console.log('Change Price  error: ', error)
          reject({ message: 'Internal Server Error', error: error })
        }
      })
    }

    function changePriceForBrandPortal(body) {
      console.log('change Price called')
      return $q(function (resolve, reject) {
        try {
          // var index = body.index
          // var purchase_order = body.purchase_order
          // var item = purchase_order.items[index]
          // var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
          // var priceIncVat = body.priceIncVat
          // var lastPrice = body.lastPrice // Ex Vat
          // var isChangingFromViewPage = body.isChangingFromViewPage
          // var price = priceIncVat / ((item.priceVat / 100) + 1) // To save price ex vat

          // if (price != undefined || price != null && price != lastPrice) {
          //   var bodyPrice = getBodyOfChangePrice(body)
          //   var bodySendQuantity = getBodyOfChangeSendQuantity(body) // For the SEND QNT FROM THE COMPANY
          //   if (isChangingFromViewPage) {
          //     body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
          //     var objectPriceChange = calculateAllValueOnPriceChange(lastEntryMethodCustomerValue, priceIncVat, item) // calculate all changes
          //     // console.log('hello objectPrice Change: ', objectPriceChange);
          //     body.addedPurchasePrice = objectPriceChange.updatedPurchasePrice
          //     body.lastPurchasePrice = item.purchasePrice
          //     var bodyPurchaePrice = getBodyOfChangePurchasePrice(body)

          //     body.AddedDiscount = objectPriceChange.updatedMargin
          //     body.lastAddedDiscount = item.discount.value
          //     var bodyMargin = getBodyOfChangeMargin(body)

          //     body.addedStorageFactor = objectPriceChange.updatedStorageFactor
          //     body.lastStorageFactor = item.storageFactor
          //     var bodyStorageFactor = getBodyOfChangeStorageFactor(body)
          //   }

          //   var bodyReceivedQuantity = {
          //     isReceivedQuantityChangedAfterFocusingPrice: false
          //   }
          //   if (body.bodyReceivedQuantity) bodyReceivedQuantity = body.bodyReceivedQuantity

          //   var finalbody = {
          //     bodyPrice: bodyPrice,
          //     bodyPurchaePrice: bodyPurchaePrice,
          //     bodyMargin: bodyMargin,
          //     bodyStorageFactor: bodyStorageFactor,
          //     bodySendQuantity: bodySendQuantity, // For Focus price updatd from company(DEFAULT FALSE)
          //     bodyReceivedQuantity: bodyReceivedQuantity // For Focus price updatd from Retailer(DEFAULT FALSE)
          //   }
          //   console.log('change Price final body: ', finalbody)
            $api.post('v2/shop/change-price-of-purchaseorder', body)
              .then(function (res) {
                resolve(res)
              })
              .catch(function (reason) {
                reject(reason)
              })
          // } 
          // else {
          //   reject({ message: 'Please enter correct price or try with different one' })
          // }
        } 
        catch (error) {
          console.log('Change Price  error: ', error)
          reject({ message: 'Internal Server Error', error: error })
        }
      })
    }

    function changeDiscount(body) {
      return $q(function (resolve, reject) {
        try {
          // var index = body.index
          // var purchase_order = body.purchase_order
          // var item = purchase_order.items[index]
          // var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
          // var isChangingFromViewPage = body.isChangingFromViewPage
          // var eType = body.eType
          // var AddedDiscount = body.AddedDiscount
          // var lastAddedDiscount = body.lastAddedDiscount

          // if (AddedDiscount != undefined || AddedDiscount != null && AddedDiscount != lastAddedDiscount) {
          //   var bodyMargin = getBodyOfChangeMargin(body)
          //   if (isChangingFromViewPage) {
          //     body.isChangingFromViewPage = false // as only MARGIN WILL CHANGE SO OTHER ITS NEEDS TO BE FALSE
          //     var objectDiscountChange = calculateAllValueOnMarginChange(lastEntryMethodCustomerValue, AddedDiscount, item) // calculate all changes

          //     if (lastEntryMethodCustomerValue) {
          //       body.addedPurchasePrice = objectDiscountChange.updatedPurchasePrice
          //       body.lastPurchasePrice = item.purchasePrice
          //       var bodyPurchaePrice = getBodyOfChangePurchasePrice(body)
          //     } else {
          //       body.priceIncVat = objectDiscountChange.updatedPrice
          //       body.lastPrice = item.price
          //       var bodyPrice = getBodyOfChangePrice(body)
          //     }

          //     body.addedStorageFactor = objectDiscountChange.updatedStorageFactor
          //     body.lastStorageFactor = item.storageFactor
          //     var bodyStorageFactor = getBodyOfChangeStorageFactor(body)
          //   }

          //   var finalbody = {
          //     bodyMargin: bodyMargin,
          //     bodyStorageFactor: bodyStorageFactor
          //   }
          //   if (lastEntryMethodCustomerValue) {
          //     finalbody.bodyPurchaePrice = bodyPurchaePrice
          //     finalbody.bodyPrice = { lastEntryMethodCustomerValueFromDiscount: false }
          //   } else {
          //     finalbody.bodyPrice = bodyPrice
          //     finalbody.bodyPurchaePrice = { lastEntryMethodCustomerValueFromDiscount: false }
          //   }
          //   console.log('change DISCOUNT final body: ', finalbody)
            
          // } else {
          //   reject({ message: 'Please enter correct margin or try with different one' })
          // }

          $api.post('v2/shop/change-discount-of-purchaseorder', body)
              .then(function (res) {
                resolve(res)
              })
              .catch(function (reason) {
                reject(reason)
              })

        } catch (error) {
          console.log('Change margin  error: ', error)
          reject({ message: 'Internal Server Error', error: error })
        }
      })
    }

    function changeDiscountForBrandPortal(body) {
      return $q(function (resolve, reject) {
        try {
          // var index = body.index
          // var purchase_order = body.purchase_order
          // var item = purchase_order.items[index]
          // var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
          // var isChangingFromViewPage = body.isChangingFromViewPage
          // var eType = body.eType
          // var AddedDiscount = body.AddedDiscount
          // var lastAddedDiscount = body.lastAddedDiscount

          // if (AddedDiscount != undefined || AddedDiscount != null && AddedDiscount != lastAddedDiscount) {
          //   var bodyMargin = getBodyOfChangeMargin(body)
          //   if (isChangingFromViewPage) {
          //     body.isChangingFromViewPage = false // as only MARGIN WILL CHANGE SO OTHER ITS NEEDS TO BE FALSE
          //     var objectDiscountChange = calculateAllValueOnMarginChange(lastEntryMethodCustomerValue, AddedDiscount, item) // calculate all changes

          //     if (lastEntryMethodCustomerValue) {
          //       body.addedPurchasePrice = objectDiscountChange.updatedPurchasePrice
          //       body.lastPurchasePrice = item.purchasePrice
          //       var bodyPurchaePrice = getBodyOfChangePurchasePrice(body)
          //     } else {
          //       body.priceIncVat = objectDiscountChange.updatedPrice
          //       body.lastPrice = item.price
          //       var bodyPrice = getBodyOfChangePrice(body)
          //     }

          //     body.addedStorageFactor = objectDiscountChange.updatedStorageFactor
          //     body.lastStorageFactor = item.storageFactor
          //     var bodyStorageFactor = getBodyOfChangeStorageFactor(body)
          //   }

          //   var finalbody = {
          //     bodyMargin: bodyMargin,
          //     bodyStorageFactor: bodyStorageFactor
          //   }
          //   if (lastEntryMethodCustomerValue) {
          //     finalbody.bodyPurchaePrice = bodyPurchaePrice
          //     finalbody.bodyPrice = { lastEntryMethodCustomerValueFromDiscount: false }
          //   } else {
          //     finalbody.bodyPrice = bodyPrice
          //     finalbody.bodyPurchaePrice = { lastEntryMethodCustomerValueFromDiscount: false }
          //   }
            console.log('change DISCOUNT final body: ', body)
            $api.post('v2/shop/change-discount-of-purchaseorder', body)
            .then(function (res) {
              resolve(res)
            })
            .catch(function (reason) {
              reject(reason)
            })
          // } else {
          //   reject({ message: 'Please enter correct margin or try with different one' })
          // }
        } catch (error) {
          console.log('Change margin  error: ', error)
          reject({ message: 'Internal Server Error', error: error })
        }
      })
    }

    function changePriceVat(body) {
      // console.log('hey body: ', body.purchase_order.items[body.index])
      return $q(function (resolve, reject) {
        try {
          // var index = body.index
          // var purchase_order = body.purchase_order
          // var item = purchase_order.items[index]
          // var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
          // var isChangingFromViewPage = body.isChangingFromViewPage
          // var eType = body.eType
          // var lastPriceVat = body.lastPriceVat || 0
          // var addedPriceVat = body.addedPriceVat

          // if (addedPriceVat != undefined || addedPriceVat != null && addedPriceVat != lastPriceVat) {
          //   var bodyPriceVat = getBodyOfChangePriceVat(body)

          //   if (isChangingFromViewPage) {
          //     body.isChangingFromViewPage = false // as only PRICEVAT WILL CHANGE SO OTHER ITS NEEDS TO BE FALSE
          //     var objectPriceVatChange = calculateAllValueOnPriceVatChange(lastEntryMethodCustomerValue, addedPriceVat, lastPriceVat, item) // calculate all changes

          //     if (lastEntryMethodCustomerValue) {
          //       body.priceIncVat = objectPriceVatChange.updatedPrice
          //       body.lastPrice = item.price
          //       var bodyPrice = getBodyOfChangePrice(body)

          //       body.addedStorageFactor = objectPriceVatChange.updatedStorageFactor || 0;
          //       body.lastStorageFactor = item.storageFactor || 0;
          //       var bodyStorageFactor = getBodyOfChangeStorageFactor(body)
          //     } else {
          //       body.AddedDiscount = objectPriceVatChange.updatedMargin
          //       body.lastAddedDiscount = item.discount.value
          //       var bodyMargin = getBodyOfChangeMargin(body)

          //       body.priceIncVat = objectPriceVatChange.updatedPriceIncVat
          //       body.lastPrice = item.price
          //       var bodyPrice = getBodyOfChangePrice(body)

          //       body.addedStorageFactor = objectPriceVatChange.updatedStorageFactor
          //       body.lastStorageFactor = item.storageFactor
          //       var bodyStorageFactor = getBodyOfChangeStorageFactor(body)
          //     }
          //   }

          //   var finalbody = {
          //     bodyPriceVat: bodyPriceVat
          //   }
          //   if (lastEntryMethodCustomerValue) {
          //     finalbody.bodyPrice = bodyPrice
          //     finalbody.bodyStorageFactor = bodyStorageFactor
          //     finalbody.bodyMargin = { lastEntryMethodCustomerValueFromPriceVat: false }
          //   } else {
          //     finalbody.bodyMargin = bodyMargin
          //     // finalbody.bodyPrice = { lastEntryMethodCustomerValueFromPriceVat: false }
          //     // finalbody.bodyStorageFactor = { lastEntryMethodCustomerValueFromPriceVat: false }
          //     finalbody.bodyPrice = bodyPrice
          //     finalbody.bodyStorageFactor = bodyStorageFactor
          //   }
          //   console.log('change PRICEVAT final body: ', finalbody)
            // return;
            $api.post('v2/shop/change-pricevat-of-purchaseorder', body)
              .then(function (res) {
                resolve(res)
              })
              .catch(function (reason) {
                reject(reason)
              })
      //     } else {
      //       reject({ message: 'Please enter correct tax or try with different one' })
      //     }
          } catch (error) {
            console.log('Change tax  error: ', error)
            reject({ message: 'Internal Server Error', error: error })
          }
        })
      }

    function purchaseOrderPaid(body) {
      return $q(function (resolve, reject) {
        try {
          $api.post('v2/purchase-order-paid', body)
            .then(function (res) {
              resolve(res)
            }).catch(function (reason) {
              reject(reason)
            })
        } catch (catcherr) {
          reject(catcherr)
        }
      })
    }

    function getPurchseOrderListByShopOfCompanyShopDetail(body) {
      return $q(function (resolve, reject) {
        try {
          $api.get('v2/company/purchase-order-list-of-compnay-shopdetail', body)
            .then(function (res) {
              resolve(res.data)
            }).catch(function (reason) {
              reject(reason)
            })
        } catch (catcherr) {
          reject(catcherr)
        }
      })
    }

    // Using this function we can update any date so just need to pass and key to update and its value and type of company/retailer
    function updatePurchaseOrderDate(body) {
      return $q(function (resolve, reject) {
        try {
          $api.put('v2/update-date-of-purchaseorder', body)
            .then(function (res) {
              resolve(res.data)
            }).catch(function (reason) {
              reject(reason)
            })
        } catch (catcherr) {
          reject(catcherr)
        }
      })
    }

    function convertAllIntegerToDecimal(item, type) {
      if (type === 'pricechange') {
        console.log('convertAllIntegerToDecimal   pricechange');
        item.purchasePrice = item.lastPurchasePrice
        item.storageFactor = item.lastStorageFactor
        if (item.discount && item.discount) {
          console.log('item.discount ', item.discount);
          item.discount.value = (item.discount && item.discount.lastValue ? item.discount.lastValue : 0) || 0
        }

      } else if (type === 'purchasepricechange') {
        // item.price = item.lastPrice
        // item.storageFactor = item.lastStorageFactor
        // if (item.discount && item.discount) item.discount.value = (item.discount && item.discount.lastValue ? item.discount.lastValue : 0) || 0
      } else if (type === 'storagefactorchange') {
        // item.price = item.lastPrice
        // item.purchasePrice = item.lastPurchasePrice
        //if (item.discount && item.discount) item.discount.value = (item.discount && item.discount.lastValue ? item.discount.lastValue : 0) || 0
      } else if (type === 'marginchange') {
        // item.price = item.lastPrice
        // item.purchasePrice = item.lastPurchasePrice
        // item.storageFactor = item.lastStorageFactor
      } else if (type === 'pricevatchange') {
        // item.price = item.lastPrice
        // item.purchasePrice = item.lastPurchasePrice
        // item.storageFactor = item.lastStorageFactor
        //if (item.discount && item.discount) item.discount.value = (item.discount && item.discount.lastValue ? item.discount.lastValue : 0) || 0
      }

      return item
    }

    function createPurchaseOrderAndShopProductsWithPoolArtileProducts(body) {
      return $q(function (resolve, reject) {
        try {
          $api.post('v2/admin/create-pool-article-purchase-order-shop-products', body)
            .then(function (res) {
              resolve(res.data)
            }).catch(function (reason) {
              reject(reason)
            })
        } catch (catcherr) {
          reject(catcherr)
        }
      })
    }

    function sendEmailToShopAboutSavedOrder(body) {
      return $q(function (resolve, reject) {
        try {
          $api.post('v2/company/send-email-to-shop', body)
            .then(function (res) {
              resolve(res.data)
            }).catch(function (reason) {
              reject(reason)
            })
        } catch (catcherr) {
          reject(catcherr)
        }
      })
    }


    // here hits means firstAPI call and data means seconds API call
    function removeDuplicateProduct(hits, data) {
      try {
        // console.log('removeDuplicateProduct: ', hits, data);

        var finalData = [];
        if (data && data.length) {
          for (var i = 0; i < data.length; i++) {
            // console.log(i, data[i]);
            if (!finalData.length) finalData.push(data[i]);
            for (var k = 0; k < finalData.length; k++) {
              if (finalData[k]._id == data[i]._id) break;
              if (k == (finalData.length - 1)) {
                finalData.push(data[i]);
              }
            }
          }
        }

        //console.log('removeDuplicateProduct: ', finalData.length, hits.length);

        if (hits && hits.length && finalData && finalData.length) {
          for (var j = 0; j < finalData.length; j++) {
            for (var l = 0; l < hits.length; l++) {
              if (hits[l]._id && finalData[j]._id && hits[l]._id == finalData[j]._id) {
                hits[l].isDuplicated = true;
              }
            }
          }
        }


        var temphits = hits.filter(function (el) {
          return !el.isDuplicated;
        });
        //console.log('temphits', JSON.parse(JSON.stringify(temphits, null, 2), finalData.length));

        return finalData.concat(temphits);
      } catch (e) {
        console.log('removeDuplicateProduct called: ', e);
        return [];
      }
    }

    function addComment(body) {
      return $api.post('add-comment-retailer-and-company', body)
    }

    function addCollectionFromThePurchaseOrder(body) {
      return $api.post('v2/retailer/add-collection-from-purchaseorder', body)
    }

    function updateAllProductWithCollectionInPurchaseOrder(body) {
      return $api.post('v2/retailer/update-all-products-with-collectoin-of-purchaseorder', body)
    }

    function fetchCompanyBrandsForPurchaseOrder(body) {
      return $api.get('v2/retailer/fetch-company-brands-for-purchase-order', body)
    }

    function getCollectionStringForPurchaseOrderProduct(body) {
      return $api.get('v2/retailer/collectionstring-for-purchaseorder-product', body)
    }

    function bookAllProductAsReceived(body) {
      return $api.post('v2/book-allproduct-as-received', body)
    }

    function createPurchaseOrderWithSupplierBrand(body) {
      return $api.post('v2/retailer/purchase-order-with-supplier-brand', body)
    }

    function getThePrintLabelDataOfPurchaseOrder(body) {
      return $api.get('v2/retailer/printlabel-data-for-purchaseorder', body)
    }

    function mailToSupplierForReqProdImages(body) {
      return $api.post('v2/retailer/purchaseorder-mail-req-prod-images', body)
    }

    function allProductsSentInBrandPurchaseOrder(body) {
      return $api.post('v2/brand/all-purchaseorder-quantity-sent', body)
    }

    function changeTheStatus(body) {
      return $api.post('v2/retailer/change-the-status', body)
    }

    function updatePurchaseOrderByKey(body) {
      return $api.post('v2/retailer/update-purchaseorder-by-key', body)
    }

    function getPurchaseOrderStockCorrections(body) {
      console.log('getPurchaseOrderStockCorrections ', body);
      var shopId = body.shopId;
      var orderNumber = body.orderNumber;
      var sortBy = body.sortBy;
      return $api.get('purchase-order-stock-corrections/' + orderNumber + '/' + shopId + '?sortBy=' + sortBy)
    }

    function getPurchaseOrderStockCorrectionsGridView(body) {
      var shopId = body.shopId;
      var orderNumber = body.orderNumber;
      return $api.get('purchase-order-stock-corrections-grid-view/' + orderNumber + '/' + shopId)
    }
    
    function updateExistingProductInPurchaseOrder(body) {
      return $api.post('v2/retailer/update-existing-prod-of-purchaseorder', body)
    }

    function addProducctToExistingPurchaseOrder(body) {
      return $api.post('v2/retailer/add-prod-in-purchaseorder', body)
    }

    function deleteProductFromPurchaseOrder(body) {
      return $api.post('v2/retailer/delete-prod-in-purchaseorder', body)
    }

    function updateQuantityOfPurchaseOrder(body) {
      return $api.post('v2/retailer/upd-qnt-in-purchaseorder', body)
    }

    function updateReceivedQuantityOfPurchaseOrder(body) {
      // console.log('-------------------------- updateReceivedQuantityOfPurchaseOrder!!');
      // console.log(body);
      return $api.post('v2/retailer/upd-recqnt-in-purchaseorder', body)
    }

    function deleteProductFromCompanyPurchaseOrder(body) {
      return $api.post('v2/brand/delete-prod-of-purchaseorder', body)
    }

    function changeTheQuantityFromCompanyPurchaseOrder(body) {
      return $api.post('v2/brand/change-qnt-of-company-purchaseorder', body)
    }

    function updateTheInvoicesDeatils(body) {
      return $api.post('v2/brand/update-compay-invoicesdetails', body)
    }

    function sharePurchaseOrderWithRetailer(body) {
      return $api.post('v2/brand/share-order-with-retailer', body)
    }

    function updateExistingProductInCompanyPurchaseOrder(body) {
      return $api.post('v2/brand/upd-prod-in-company-purchaseorder', body)
    }

    function addProductInCompanyPurchaseOrder(body) {
      return $api.post('v2/brand/add-prod-in-company-purchaseorder', body)
    }

    function increasedTheOrderQuantity(body) {
      return $api.post('v2/retailer/increased-order-quantity', body)
    }

    function isProductExistByProductNumber(body) {
      return $api.post('v2/retailer/isproduct-exist-by-productnumber', body)
    }

    function changeEAN(body) {
      return $api.post('v2/retailer/change-ean', body)
    }

    function changeProductIdOfPurchaseOrderItem(body) {
      return $api.post('v2/retailer/update-purchaseorderitem-productid', body)
    }

    function getLabelDescription(body) {
      return $api.get('v2/retailer/labeldescription', body)
    }

    function purchaseOrderStatistics(body) {
      return $api.post('v2/retailer/purchase-order-statistics', body)
    }

    function getBrandStatisticsOfAutomaticPurchaseOrder(body) {
      return $api.get('v2/statistics-purchaseorder-automatically', body)
    }

    function SortShopProductOfAutoMaticOrder(body) {
      return $api.get('sort-shop-product', body)
    }

    function toolTipRows(rows) {
      var table;
      if (rows) {
        table = "<html><body><table class='tooltips_table' cellpadding='20' border='1'>  <tr> <th class='custom_tooltips'>Action</th> <th >Status</th> <th>Time</th> <th>Person</th> </tr>  " + rows + "</table></body></html>";
      } else {
        table = "<html> <body> <p> <b> No history found </b> </p> </body> </html>";
      }

      return $sce.trustAsHtml(table);
    }

    function purchaserOrderHistoryUibToolTip(item) {
      var rows = '';
      if (item && item.quantitiesIdsDetail && item.quantitiesIdsDetail.length && Object.keys(item.quantitiesIdsDetail[0]).length) {
        for (var i = 0; i < item.quantitiesIdsDetail.length; i++) {
          rows += '<tr><td>' + item.quantitiesIdsDetail[i].action + ' (' + item.quantitiesIdsDetail[i].addQuantity + ') ' + '</td><td>' + item.quantitiesIdsDetail[i].status + '</td><td>' + moment(item.quantitiesIdsDetail[i].createdDate).format('DD-MM-YYYY HH:mm') + '</td><td>' + item.quantitiesIdsDetail[i].userDetail.fullName + '</td></tr>';
        }
      }

      return toolTipRows(rows);
    }

    function serialNumberUibToolTip(item) {
      var rows = '';
      if (item && item.serialNumbersDetail && item.serialNumbersDetail.length && Object.keys(item.serialNumbersDetail[0]).length) {
        for (var i = 0; i < item.serialNumbersDetail.length; i++) {
          if (item.serialNumbersDetail[i] && item.serialNumbersDetail[i].serilaNumberHistory && item.serialNumbersDetail[i].serilaNumberHistory.action && item.serialNumbersDetail[i].serilaNumberHistory.addedSerialNumber) {
            rows += '<tr><td>' + item.serialNumbersDetail[i].serilaNumberHistory.action + ' (' + item.serialNumbersDetail[i].serilaNumberHistory.addedSerialNumber + ') ' + '</td><td>' + item.serialNumbersDetail[i].serilaNumberHistory.status + '</td><td>' + moment(item.serialNumbersDetail[i].serilaNumberHistory.createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + item.serialNumbersDetail[i].serilaNumberHistory.userDetail.fullName + '</td></tr>';
          }
        }
      }

      return toolTipRows(rows);
    }

    function priceUibToolTip(item) {
      var rows = '';
      var arr = [];
      var priceArr = [];

      if(item && item.purchasePriceDetail && item.purchasePriceDetail.length){
        for (var i = 0; i < item.purchasePriceDetail.length; i++) {
          if(item.purchasePriceDetail[i].priceHistory.calculation === 'price'){
            priceArr.push(item.purchasePriceDetail[i]);
          }
        }

        if (priceArr.length) {
          for (var i = 0; i < priceArr.length; i++) {
            if(priceArr[i].priceHistory.calculation === 'price' && !arr.length){
              arr.push(priceArr[i]);
            }
            if(i && arr.length){
              if(arr[arr.length - 1].priceHistory.addedPrice != priceArr[i].priceHistory.addedPrice){
                arr.push(priceArr[i]);
              }
            }
          }
  
          for (var i = 0; i < arr.length; i++) {
            if (arr[i].priceHistory.calculation === 'price') {
              rows += '<tr><td>' + arr[i].priceHistory.action + ' (' + parseFloat(parseFloat(arr[i].priceHistory.addedPrice).toFixed(3)) + ') ' + '</td><td>' + arr[i].priceHistory.status + '</td><td>' + moment(arr[i].priceHistory.createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + arr[i].priceHistory.userDetail.fullName + '</td></tr>';
            }
          }
        }
      }

      return toolTipRows(rows);
    }

    // function priceUibToolTip(item) {
    //   var rows = '';
    //   if (item && item.purchasePriceDetail && item.purchasePriceDetail.length && Object.keys(item.purchasePriceDetail[0]).length) {
    //     for (var i = 0; i < item.purchasePriceDetail.length; i++) {
    //       if (item.purchasePriceDetail[i].priceHistory.calculation === 'price') {
    //         rows += '<tr><td>' + item.purchasePriceDetail[i].priceHistory.action + ' (' + item.purchasePriceDetail[i].priceHistory.addedPrice + ') ' + '</td><td>' + item.purchasePriceDetail[i].priceHistory.status + '</td><td>' + moment(item.purchasePriceDetail[i].priceHistory.createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + item.purchasePriceDetail[i].priceHistory.userDetail.fullName + '</td></tr>';
    //       }
    //     }
    //   }

    //   return toolTipRows(rows);
    // }

    function priceVatUibToolTip(item) {
      var rows = '';
      if (item && item.purchasePriceDetail && item.purchasePriceDetail.length && Object.keys(item.purchasePriceDetail[0]).length) {
        for (var i = 0; i < item.purchasePriceDetail.length; i++) {
          if (item.purchasePriceDetail[i].priceHistory.calculation === 'pricevat') {
            rows += '<tr><td>' + item.purchasePriceDetail[i].priceHistory.action + ' (' + item.purchasePriceDetail[i].priceHistory.addedPriceVat + ') ' + '</td><td>' + item.purchasePriceDetail[i].priceHistory.status + '</td><td>' + moment(item.purchasePriceDetail[i].priceHistory.createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + item.purchasePriceDetail[i].priceHistory.userDetail.fullName + '</td></tr>';
          }
        }
      }

      return toolTipRows(rows);
    }

    // function discountUibToolTip(item) {
    //   var rows = '';
    //   if (item && item.purchasePriceDetail && item.purchasePriceDetail.length && Object.keys(item.purchasePriceDetail[0]).length) {
    //     for (var i = 0; i < item.purchasePriceDetail.length; i++) {
    //       if (item.purchasePriceDetail[i].priceHistory.calculation === 'discount') {
    //         var changedDiscount = item.purchasePriceDetail[i].priceHistory.addedDiscount.value + '-' + (item.purchasePriceDetail[i].priceHistory.addedDiscount.isPercentage ? 'Enabled' : 'Disabled');
    //         rows += '<tr><td>' + item.purchasePriceDetail[i].priceHistory.action + ' (' + changedDiscount + ') ' + '</td><td>' + item.purchasePriceDetail[i].priceHistory.status + '</td><td>' + moment(item.purchasePriceDetail[i].priceHistory.createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + item.purchasePriceDetail[i].priceHistory.userDetail.fullName + '</td></tr>';
    //       }
    //     }
    //   }

    //   return toolTipRows(rows);
    // }

    function discountUibToolTip(item) {
      var rows = '';
      var arr = [];
      var priceArr = [];

      if(item && item.purchasePriceDetail && item.purchasePriceDetail.length){
        for (var i = 0; i < item.purchasePriceDetail.length; i++) {
          if(item.purchasePriceDetail[i].priceHistory.calculation === 'discount'){
            priceArr.push(item.purchasePriceDetail[i]);
          }
        }

        if (priceArr.length) {
          for (var i = 0; i < priceArr.length; i++) {
            if(priceArr[i].priceHistory.calculation === 'discount' && !arr.length){
              arr.push(priceArr[i]);
            }
            if(i && arr.length){
              if(arr[arr.length - 1].priceHistory.addedDiscount.value != priceArr[i].priceHistory.addedDiscount.value){
                arr.push(priceArr[i]);
              }
            }
          }
  
          for (var i = 0; i < arr.length; i++) {
            if (arr[i].priceHistory.calculation === 'discount') {
              var changedDiscount = arr[i].priceHistory.addedDiscount.value + '-' + (arr[i].priceHistory.addedDiscount.isPercentage ? 'Enabled' : 'Disabled');
              rows += '<tr><td>' + arr[i].priceHistory.action + ' (' +  parseFloat(parseFloat(changedDiscount).toFixed(3)) + ') ' + '</td><td>' + arr[i].priceHistory.status + '</td><td>' + moment(arr[i].priceHistory.createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + arr[i].priceHistory.userDetail.fullName + '</td></tr>';
            }
          }
        }
      }

      return toolTipRows(rows);
    }

    function invoiceNumberHistoryUibToolTip(invoiceHistory) {
      var rows = '';
      if (invoiceHistory && invoiceHistory.length && Object.keys(invoiceHistory[0]).length) {
        for (var i = 0; i < invoiceHistory.length; i++) {
          if (invoiceHistory[i] && invoiceHistory[i].isShow &&
            invoiceHistory[i].newInvoiceNumber && invoiceHistory[i].status && invoiceHistory[i].createdAt) {
            rows += '<tr><td>' + 'Edited' + ' (' + invoiceHistory[i].newInvoiceNumber + ') ' + '</td><td>' + invoiceHistory[i].status + '</td><td>' + moment(invoiceHistory[i].createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + invoiceHistory[i].userDetail.fullName + '</td></tr>';
          }
        }
      }
      return toolTipRows(rows);
    }

    // function purchasePriceUibToolTip(item) {
    //   var rows = '';
    //   if (item && item.purchasePriceDetail && item.purchasePriceDetail.length && Object.keys(item.purchasePriceDetail[0]).length) {
    //     for (var i = 0; i < item.purchasePriceDetail.length; i++) {
    //       // console.log(i, item.purchasePriceDetail[i].priceHistory);
    //       if (item.purchasePriceDetail[i].priceHistory.calculation === 'purchaseprice') {
    //         rows += '<tr><td>' + item.purchasePriceDetail[i].priceHistory.action + ' (' + item.purchasePriceDetail[i].priceHistory.addedPurchasePrice + ') ' + '</td><td>' + item.purchasePriceDetail[i].priceHistory.status + '</td><td>' + moment(item.purchasePriceDetail[i].priceHistory.createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + item.purchasePriceDetail[i].priceHistory.userDetail.fullName + '</td></tr>';
    //       }
    //     }
    //   }

    //   return toolTipRows(rows);
    // }

    function purchasePriceUibToolTip(item) {
      var rows = '';
      var arr = [];
      var priceArr = [];

      if(item && item.purchasePriceDetail && item.purchasePriceDetail.length){
        for (var i = 0; i < item.purchasePriceDetail.length; i++) {
          if(item.purchasePriceDetail[i].priceHistory.calculation === 'purchaseprice'){
            priceArr.push(item.purchasePriceDetail[i]);
          }
        }

        if (priceArr.length) {
          for (var i = 0; i < priceArr.length; i++) {
            if(priceArr[i].priceHistory.calculation === 'purchaseprice' && !arr.length){
              arr.push(priceArr[i]);
            }
            if(i && arr.length){
              if(arr[arr.length - 1].priceHistory.addedPurchasePrice != priceArr[i].priceHistory.addedPurchasePrice){
                arr.push(priceArr[i]);
              }
            }
          }
  
          for (var i = 0; i < arr.length; i++) {
            if (arr[i].priceHistory.calculation === 'purchaseprice') {
              rows += '<tr><td>' + arr[i].priceHistory.action + ' (' + parseFloat(parseFloat(arr[i].priceHistory.addedPurchasePrice).toFixed(3)) + ') ' + '</td><td>' + arr[i].priceHistory.status + '</td><td>' + moment(arr[i].priceHistory.createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + arr[i].priceHistory.userDetail.fullName + '</td></tr>';
            }
          }
        }
      }

      return toolTipRows(rows);
    }

    // function storageFactorUibToolTip(item) {
    //   var rows = '';
    //   if (item && item.purchasePriceDetail && item.purchasePriceDetail.length && Object.keys(item.purchasePriceDetail[0]).length) {
    //     for (var i = 0; i < item.purchasePriceDetail.length; i++) {
    //       if (item.purchasePriceDetail[i].priceHistory.calculation === 'storagefactor') {
    //         rows += '<tr><td>' + item.purchasePriceDetail[i].priceHistory.action + ' (' + item.purchasePriceDetail[i].priceHistory.addedStorageFactor + ') ' + '</td><td>' + item.purchasePriceDetail[i].priceHistory.status + '</td><td>' + moment(item.purchasePriceDetail[i].priceHistory.createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + item.purchasePriceDetail[i].priceHistory.userDetail.fullName + '</td></tr>';
    //       }
    //     }
    //   }

    //   return toolTipRows(rows);
    // }

    function storageFactorUibToolTip(item) {
      var rows = '';
      var arr = [];
      var priceArr = [];

      if(item && item.purchasePriceDetail && item.purchasePriceDetail.length){
        for (var i = 0; i < item.purchasePriceDetail.length; i++) {
          if(item.purchasePriceDetail[i].priceHistory.calculation === 'storagefactor'){
            priceArr.push(item.purchasePriceDetail[i]);
          }
        }

        if (priceArr.length) {
          for (var i = 0; i < priceArr.length; i++) {
            if(priceArr[i].priceHistory.calculation === 'storagefactor' && !arr.length){
              arr.push(priceArr[i]);
            }
            if(i && arr.length){
              if(arr[arr.length - 1].priceHistory.addedStorageFactor != priceArr[i].priceHistory.addedStorageFactor){
                arr.push(priceArr[i]);
              }
            }
          }
  
          for (var i = 0; i < arr.length; i++) {
            if (arr[i].priceHistory.calculation === 'storagefactor') {
              rows += '<tr><td>' + arr[i].priceHistory.action + ' (' + parseFloat(parseFloat(arr[i].priceHistory.addedStorageFactor).toFixed(3)) + ') ' + '</td><td>' + arr[i].priceHistory.status + '</td><td>' + moment(arr[i].priceHistory.createdAt).format('DD-MM-YYYY HH:mm') + '</td><td>' + arr[i].priceHistory.userDetail.fullName + '</td></tr>';
            }
          }
        }
      }

      return toolTipRows(rows);
    }

    function sendQuantityUibToolTip(item) {
      var rows = '';
      if (item && item.sendQuantitiesIdsDetail.length && Object.keys(item.sendQuantitiesIdsDetail[0]).length) {
        for (var i = 0; i < item.sendQuantitiesIdsDetail.length; i++) {
          if (item.sendQuantitiesIdsDetail && item.sendQuantitiesIdsDetail.length && item.sendQuantitiesIdsDetail[i] &&
            item.sendQuantitiesIdsDetail[i].isShow && item.sendQuantitiesIdsDetail[i].action &&
            item.sendQuantitiesIdsDetail[i].addSendQuantity && item.sendQuantitiesIdsDetail[i].status && item.sendQuantitiesIdsDetail[i].createdDate) {
            rows += '<tr><td>' + item.sendQuantitiesIdsDetail[i].action + ' (' + item.sendQuantitiesIdsDetail[i].addSendQuantity + ') ' + '</td><td>' + item.sendQuantitiesIdsDetail[i].status + '</td><td>' + moment(item.sendQuantitiesIdsDetail[i].createdDate).format('DD-MM-YYYY HH:mm') + '</td><td>' + item.sendQuantitiesIdsDetail[i].userDetail.fullName + '</td></tr>';
          }
        }
      }
      return toolTipRows(rows);
    }

    function isValidCompanyDetail(company) {
      var isValidCompany = true;
      var message = '';
      if (!company.address || !company.address.street || !company.address.houseNumber || !company.address.city ||
        !company.address.country || !company.address.postalCode) {
        isValidCompany = false;
        message = 'Company address is not proper. First fill it proper then you can edit it';
      }

      if (!company.phone || !company.phone.landLine) {
        isValidCompany = false;
        message = 'Company does not have a phone number (fixed/landline)';
      }

      if (!company.email) {
        isValidCompany = false;
        message = 'Company does not have proper email address';
      }

      return { isValidCompany: isValidCompany, message: message };
    }

    function fetchProductDiscountUsingClientGroup(body) {
      return $q(function (resolve, reject) {
        try {
          if (body.companyId && body.productId && body.clientGroupId && body.purchasePrice) {
            $api.get('v2/get-discount-by-clientgroup', body).then(function (response) {
              if (response && response.data && response.data.data > 0) {
                // console.log('response: return body.purchasePrice;: ', response.data.data);
                return resolve(response.data.data);
              } else {
                return resolve(body.purchasePrice);
              }
            }).catch(function (error) {
              return resolve(body.purchasePrice);
            })
          } else {
            return resolve(body.purchasePrice);
          }
        } catch (e) {
          return resolve(body.purchasePrice);
        }
      })
    }

    return {
      getProductDetailsFromPurchaseOrder: getProductDetailsFromPurchaseOrder,
      getPurchaseOrderDetailsFormOrderNumber: getPurchaseOrderDetailsFormOrderNumber,
      getInernalSupplier: getInernalSupplier,
      getPurchaseOrderNumber: getPurchaseOrderNumber,
      uploadFileAndCreatePurchaseOrder: uploadFileAndCreatePurchaseOrder,
      changeSendQuantity: changeSendQuantity,
      updateCompanyProduct: updateCompanyProduct,
      changeInvoiceNumber: changeInvoiceNumber,
      openSearchSupplierModal: openSearchSupplierModal,
      acceptTheRetailerChanges: acceptTheRetailerChanges,
      changePurchasePrice: changePurchasePrice,
      changeStorageFactor: changeStorageFactor,
      changePrice: changePrice,
      changeDiscount: changeDiscount,
      changePriceVat: changePriceVat,
      productIsAlredayInPurchaseOrder: productIsAlredayInPurchaseOrder,
      updateProductDetailsInPurchaseOrder: updateProductDetailsInPurchaseOrder,
      purchaseOrderPaid: purchaseOrderPaid,
      getPurchseOrderListByShopOfCompanyShopDetail: getPurchseOrderListByShopOfCompanyShopDetail,
      getShopDetailForRetailerPurchaseOrder: getShopDetailForRetailerPurchaseOrder,
      updatePurchaseOrderDate: updatePurchaseOrderDate,
      convertAllIntegerToDecimal: convertAllIntegerToDecimal,
      createPurchaseOrderAndShopProductsWithPoolArtileProducts: createPurchaseOrderAndShopProductsWithPoolArtileProducts,
      sendEmailToShopAboutSavedOrder: sendEmailToShopAboutSavedOrder,
      addComment: addComment,
      addCollectionFromThePurchaseOrder: addCollectionFromThePurchaseOrder,
      updateAllProductWithCollectionInPurchaseOrder: updateAllProductWithCollectionInPurchaseOrder,
      fetchCompanyBrandsForPurchaseOrder: fetchCompanyBrandsForPurchaseOrder,
      getCollectionStringForPurchaseOrderProduct: getCollectionStringForPurchaseOrderProduct,
      bookAllProductAsReceived: bookAllProductAsReceived,
      createPurchaseOrderWithSupplierBrand: createPurchaseOrderWithSupplierBrand,
      getThePrintLabelDataOfPurchaseOrder: getThePrintLabelDataOfPurchaseOrder,
      mailToSupplierForReqProdImages: mailToSupplierForReqProdImages,
      allProductsSentInBrandPurchaseOrder: allProductsSentInBrandPurchaseOrder,
      changeTheStatus: changeTheStatus,
      updatePurchaseOrderByKey: updatePurchaseOrderByKey,
      updateExistingProductInPurchaseOrder: updateExistingProductInPurchaseOrder,
      addProducctToExistingPurchaseOrder: addProducctToExistingPurchaseOrder,
      deleteProductFromPurchaseOrder: deleteProductFromPurchaseOrder,
      updateQuantityOfPurchaseOrder: updateQuantityOfPurchaseOrder,
      updateReceivedQuantityOfPurchaseOrder: updateReceivedQuantityOfPurchaseOrder,
      deleteProductFromCompanyPurchaseOrder: deleteProductFromCompanyPurchaseOrder,
      changeTheQuantityFromCompanyPurchaseOrder: changeTheQuantityFromCompanyPurchaseOrder,
      updateTheInvoicesDeatils: updateTheInvoicesDeatils,
      sharePurchaseOrderWithRetailer: sharePurchaseOrderWithRetailer,
      updateExistingProductInCompanyPurchaseOrder: updateExistingProductInCompanyPurchaseOrder,
      addProductInCompanyPurchaseOrder: addProductInCompanyPurchaseOrder,
      increasedTheOrderQuantity: increasedTheOrderQuantity,
      toolTipRows: toolTipRows,
      purchaserOrderHistoryUibToolTip: purchaserOrderHistoryUibToolTip,
      serialNumberUibToolTip: serialNumberUibToolTip,
      priceUibToolTip: priceUibToolTip,
      priceVatUibToolTip: priceVatUibToolTip,
      discountUibToolTip: discountUibToolTip,
      invoiceNumberHistoryUibToolTip: invoiceNumberHistoryUibToolTip,
      purchasePriceUibToolTip: purchasePriceUibToolTip,
      storageFactorUibToolTip: storageFactorUibToolTip,
      sendQuantityUibToolTip: sendQuantityUibToolTip,
      isValidCompanyDetail: isValidCompanyDetail,
      isProductExistByProductNumber: isProductExistByProductNumber,
      fetchProductDiscountUsingClientGroup: fetchProductDiscountUsingClientGroup,
      getBodyOfChangeMargin: getBodyOfChangeMargin,
      calculateAllValueOnMarginChange: calculateAllValueOnMarginChange,
      getBodyOfChangePurchasePrice: getBodyOfChangePurchasePrice,
      getBodyOfChangePrice: getBodyOfChangePrice,
      getBodyOfChangeStorageFactor: getBodyOfChangeStorageFactor,
      calculateAllValueOnPurchasePriceChange: calculateAllValueOnPurchasePriceChange,   
      getBodyOfChangeSendQuantity: getBodyOfChangeSendQuantity,
      calculateAllValueOnPriceChange: calculateAllValueOnPriceChange,
      calculateAllValueOnStorageFactorChange: calculateAllValueOnStorageFactorChange,
      getBodyOfChangePriceVat: getBodyOfChangePriceVat,
      calculateAllValueOnPriceVatChange: calculateAllValueOnPriceVatChange,
      updateStepThreeFlag: updateStepThreeFlag,
      changePurchasePriceForBrandPortal: changePurchasePriceForBrandPortal,
      changePriceForBrandPortal: changePriceForBrandPortal,
      changeDiscountForBrandPortal: changeDiscountForBrandPortal,
      changeEAN: changeEAN,
      isValidEAN: isValidEAN,
      isLikeEANNumber: isLikeEANNumber,
      changeProductIdOfPurchaseOrderItem: changeProductIdOfPurchaseOrderItem,
      getLabelDescription: getLabelDescription,
      purchaseOrderStatistics: purchaseOrderStatistics,
      getBrandStatisticsOfAutomaticPurchaseOrder: getBrandStatisticsOfAutomaticPurchaseOrder,
      SortShopProductOfAutoMaticOrder: SortShopProductOfAutoMaticOrder,
      removeDuplicateProduct: removeDuplicateProduct,
      getPurchaseOrderStockCorrections: getPurchaseOrderStockCorrections,
      getPurchaseOrderStockCorrectionsGridView: getPurchaseOrderStockCorrectionsGridView
    }
  }])
