prismanoteApp.service('$card', ['$q', '$api', '$uibModal', '$log', '$rootScope', '$language', function ($q, $api, $uibModal, $log, $rootScope, $language) {
  function getCards () {
    var cards = [
      { name: 'VISA', cardName: $language.translate('VISA') },
      { name: 'MASTERCARD' , cardName: $language.translate('MASTERCARD')},
      { name: 'AMEX', cardName: $language.translate('AMEX') },
      { name: 'MAESTRO', cardName: $language.translate('MAESTRO') },
      { name: 'VPAY', cardName: $language.translate('VPAY') },
      { name: 'SHOP_GIFTCARD', cardName: $language.translate('SHOP_GIFTCARD') },
      { name: 'VVV', cardName: $language.translate('VVV') },
      { name: 'FASHIONGIFTCARD', cardName: $language.translate('FASHIONGIFTCARD') },
      { name: 'FASHIONCHEQUE', cardName: $language.translate('FASHIONCHEQUE') },
      { name: 'OTHER', cardName: $language.translate('OTHER') },
    ]

    if($rootScope.currentShop) {
      if ($rootScope.currentShop.nameSlug === 'miga-gifts-helmond') {
        cards.push(
          {
            name: 'HELMOND_GIFTCARD',
            cardName: $language.translate('HELMOND_GIFTCARD')
          }
        )
      }

      if ($rootScope.currentShop.nameSlug === 'b-trendy') {
        var extraCards = [
          { name: 'BANCONTACT', cardName: $language.translate('BANCONTACT') },
          { name: 'PAYCONIQ', cardName: $language.translate('PAYCONIQ') },
          { name: 'GEZINSBOND', cardName: $language.translate('GEZINSBOND') },
          { name: 'RES_EUROTRADE', cardName: $language.translate('RES_EUROTRADE') },
          { name: 'STADSBON', cardName: $language.translate('STADSBON') }
        ]
        var tmpCards = cards.concat(extraCards)
        cards = tmpCards
      }
     
      if ($rootScope.currentShop.nameSlug === 'yolande-merksem' || $rootScope.currentShop.nameSlug === 'yolande-brasschaat') {
        cards = [
          { name: 'BC', cardName: 'BC' },
          { name: 'VISA', cardName: $language.translate('VISA') },
          { name: 'MASTERCARD' , cardName: $language.translate('MASTERCARD')},
          { name: 'MAESTRO', cardName: $language.translate('MAESTRO') },
          { cardName: 'Diners', name:'DINERS' },
          { cardName: 'Klantenkaart', name:'KLANTENKAART' },
          { cardName: 'Overschrijving', name:'OVERSCHIJVING' },
          { cardName: 'Paypal', name:'PAYPAL' },
          { cardName: 'Award Cheques', name:'AWARD_CHEQUES' },
          { cardName: 'Brasschaatse kadobon', name:'BRASSCHAATSE_KADOBON' },
          { cardName: 'Consumptiecheque', name:'CONSUMPTIE_CHEQUE' }
        ]
      }

      if($rootScope.currentShop.nameSlug === 'ellen-juwelier-roosendaal') {
        var extraCards = [
          { name: 'ROOSPAS', cardName: $language.translate('ROOSPAS') },
          { name: 'ZINZI_CARD', cardName: $language.translate('ZINZI_CARD') },
          { name: 'AVOORDBON', cardName: $language.translate('AVOORDBON') },
        ]
        var tmpCards = cards.concat(extraCards)
        cards = tmpCards
      }

      if($rootScope.currentShop.nameSlug === 'van-woerkom-juweliers') {
        var extraCards = [
          { name: 'TROLLBEADS_GIFTC', cardName: 'Trollbeads Cadeaukaart' },
          { name: 'JEWELCARD', cardName: 'JewelCard' },
          { name: 'OVD_KAART', cardName: 'OVD Kaart' },
        ]
        var tmpCards = cards.concat(extraCards)
        cards = tmpCards
      }
    }
    return cards
  }

  function openManualPinModal (transaction, cardAmount) {
    return $q(function (resolve, reject) {
      var pinModal = $uibModal.open({
        templateUrl: '../views/modal/payment-modal.html',
        controller: 'retailerPaymentModalController',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          transaction: function () {
            return transaction
          },
          payment: function () {
            return null
          },
          amount: function () {
            return cardAmount
          },
          manual: function () {
            return true
          }
        }
      })
      pinModal.result.then(function (result) {
        if (result.success) {
          return resolve(result)
        } else {
          return reject(result)
        }
      }, function (reason) {
        if (!reason) {
          reason = 'dismissed'
        }
        return reject(reason)
      })
    })
  }

  function openPayNlPinModal (transaction, cardAmount) {
    return $q(function (resolve, reject) {
      startPayTransaction(transaction, cardAmount)
        .then(function (payment) {
          $log.info('Now opening the payment modal')
          var paymentModal = $uibModal.open({
            templateUrl: '../views/modal/payment-modal.html',
            controller: 'retailerPaymentModalController',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              transaction: function () {
                return transaction
              },
              payment: function () {
                return payment
              },
              amount: function () {
                return cardAmount
              },
              manual: function () {
                return false
              }
            }
          })

          paymentModal.result.then(function (result) {
            $log.info('payment modal is closed with this as result', result)
            return resolve(result)
          }, function () {
            $log.warning('payment modal is dismissed')
          })
        })
        .catch(function (reason) {
          $log.error('Error while creating instore payment', reason)
          console.error('REASON', reason)
          return reject(reason)
        })
    })
  }

  function startPayTransaction (transaction, cardAmount) {
    return $q(function (resolve, reject) {
      $log.info('Starting Pay.nl instore transaction')

      var pinTest = window.localStorage.getItem('pinTestMode')
      $log.info('TestMode is set to', pinTest)
      var testMode = function () {
        if (!pinTest) {
          return false
        }
        if (pinTest) {
          return pinTest === 'false'
        } else {
          return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === 'goedenoot.nl' || window.location.hostname === 'prismanote.it'
        }
      }

      $api.post('pay/instore-transaction', {
        shopId: $rootScope.currentShop._id,
        deviceId: $rootScope.currentDevice._id,
        amount: cardAmount,
        transactionInfo: {
          useExchangeUrl: true,
          transactionId: transaction._id,
          description: $language.translate('TRANSACTION') + ' ' + transaction.number,
          enduser: transaction.customer && !transaction.customer.counter ? {
            lastName: (transaction.customer.prefix ? transaction.customer.prefix + ' ' : '') + (transaction.customer.lastName ? transaction.customer.lastName : 'Klant'),
            dob: transaction.customer.dateOfBirth ? transaction.customer.dateOfBirth : null,
            emailAddress: transaction.customer.email ? transaction.customer.email : null,
            gender: transaction.customer.gender ? transaction.customer.gender.charAt(0).toUpperCase() : null,
            phoneNumber: transaction.customer.phone && (transaction.customer.phone.mobilePhone || transaction.customer.phone.landLine) ? (transaction.customer.phone.mobilePhone ? transaction.customer.phone.mobilePhone : transaction.customer.phone.landLine) : null
          } : null,
          product: transaction.details.map(function (p) {
            return {
              id: p._id,
              name: p.name,
              price: p.price * 100,
              qty: p.quantity,
              tax: p.priceVat
            }
          }),
          testMode: testMode(),
          language: $rootScope.language
        }
      })
        .then(function (payment) {
          $log.info('payment which is returned from pay.nl', payment)
          return resolve(payment.data)
        })
        .catch(function (reason){
          return reject(reason)
        })
    })
  }

  function startCCVTransaction (transaction, cardAmount) {
    return $q(function (resolve, reject) {
      $log.info('Starting CCV instore transaction')

      $api.post('ccv/instore', {
        shopId: $rootScope.currentShop._id,
        transactionId: transaction._id,
        deviceId: $rootScope.currentDevice._id,
        data: {
          amount: cardAmount,
          reference: transaction.number,
          description: $language.translate('TRANSACTION') + ' ' + transaction.number,
          language: $rootScope.language
        }
      }, null, 2)
        .then(function (payment) {
          $log.info('Payment which is returned from CCV', payment)
          return resolve(payment.data.payment)
        })
        .catch(function (reason) {
          window.localStorage.setItem('err_card.startCCVTransaction',reason)
          return reject(reason)
        })
    })
  }

  function openCCVPinModal (transaction, amount) {
    return $q(function (resolve, reject) {
      startCCVTransaction(transaction, amount)
        .then(function (payment) {
          $log.info('Now opening CCV Payment modal')
          var paymentModal = $uibModal.open({
            templateUrl: '../views/modal/retailer-payment-modal-ccv.html',
            controller: 'retailerCCVPaymentModalController',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              transaction: function () {
                return transaction
              },
              payment: function () {
                return payment
              },
              amount: function () {
                return amount
              }
            }
          })

          paymentModal.result.then(function (result) {
            $log.info('CCV Payment modal is closed with this as result', result)
            return resolve(result)
          }, function () {
            $log.warning('payment modal is dismissed')
          })
        })
        .catch(function (reason) {
          $log.error('Error while creating instore payment', reason)
          return reject($language.translate('UNKOWN_ERROR_OCCURED'))
        })
    })
  }

  function restartPayNlPayment (transaction, cardAmount) {
    return startPayTransaction(transaction, cardAmount)
  }

  function restartCCVPayment (transaction, amount) {
    return startCCVTransaction(transaction, amount)
  }

  function startPinTransaction (transaction, cardAmount) {
    return $q(function (resolve, reject) {
      var terminal = $rootScope.currentDevice.pinTerminal
      var provider = $rootScope.currentDevice.pinTerminal.provider ? $rootScope.currentDevice.pinTerminal.provider : $rootScope.currentShop.cashRegister.terminal.provider

      if (provider === 'paynl' && terminal.serviceId && terminal.apiToken && terminal.apiCode && terminal.terminalId) {
        openPayNlPinModal(transaction, cardAmount)
          .then(function (result) {
            return resolve(result)
          })
          .catch(function (reason) {
            window.localStorage.setItem('err_card.openPayNlPinModal', JSON.stringify(reason))
            return reject(reason)
          })
      } else if (provider === 'ccv' && terminal.apiToken && terminal.apiCode && terminal.terminalId && terminal.ip) {
        openCCVPinModal(transaction, cardAmount)
          .then(function (result) {
            return resolve(result)
          })
          .catch(function (reason) {
            window.localStorage.setItem('err_card.openCCVPinModal', JSON.stringify(result))
            return reject(reason)
          })
      } else {
        openManualPinModal(transaction, cardAmount)
          .then(function (result) {
            return resolve(result)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      }
    })
  }

  return {
    getCards: getCards,
    startPinTransaction: startPinTransaction,
    openCCVPinModal: openCCVPinModal,
    openManualPinModal: openManualPinModal,
    openPayNlPinModal: openPayNlPinModal,
    restartPayNlPayment: restartPayNlPayment,
    restartCCVPayment: restartCCVPayment

  }
}])
