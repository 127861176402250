prismanoteApp.service('$user', ['$q', '$api', function ($q, $api) {
  function registerUserWhenComingFromPurchaseOrderInvitationLink (body) {
    return $q(function (resolve, reject) {
      $api.post('register-with-purchase-order-invitationlink', body).then(function (response) {
        resolve(response.data)
      }).catch(function (reason) {
        reject(reason)
      })
    })
  }

  function changePurchaseOrderShopWhenComingFromInvitationLink (body) {
    return $q(function (resolve, reject) {
      $api.post('v2/retailer/change-shop-of-purchaseorder', body).then(function (response) {
        resolve(response.data)
      }).catch(function (reason) {
        reject(reason)
      })
    })
  }

  function registerUserWhenComingFromRecommendationInvitationLink (body) {
    return $q(function (resolve, reject) {
      $api.post('register-with-recommendation-order-invitationlink', body).then(function (response) {
        resolve(response.data)
      }).catch(function (reason) {
        reject(reason)
      })
    })
  }

  function registerUserWhenComingFromCampaignInvitationLink (body) {
    return $q(function (resolve, reject) {
      $api.post('register-with-campaign-invitationlink', body).then(function (response) {
        resolve(response.data)
      }).catch(function (reason) {
        reject(reason)
      })
    })
  }

  // Here, we are checking that, user has completely new shop then purchaseOrder.shop and user has mutiple shop then we have to open modal
  function isHaveToOpenChooseShopModal (usershops, purchaseorderShop) {
    var currentShopSlug
    var isShopExist = false
    if (usershops && usershops.length && purchaseorderShop && purchaseorderShop._id) {
      var shopsLength = usershops.length
      for (var i = 0; i < shopsLength; i++) {
        if ((purchaseorderShop._id).toString() === (usershops[i]._id).toString()) {
          isShopExist = true
          currentShopSlug = purchaseorderShop.nameSlug
          break
        }
      }
    }

    if (isShopExist) {
      console.log('Yes shop exist: ', currentShopSlug)
      return { isOpen: false, currentShopSlug: currentShopSlug }
    } else if (!isShopExist && usershops.length === 1) {
      return { isOpen: false, currentShopSlug: usershops[0].nameSlug }
    } else {
      return { isOpen: true }
    }
  }

  function getCurrentUserShops (userId, shopId) {
    return $q(function (resolve, reject) {
      $api.get('v2/user-shops/' + userId + '/' + shopId).then(function (response) {
        resolve(response.data)
      }).catch(function (reason) {
        reject(reason)
      })
    })
  }

  function getViewProductDetails (data) {
    return $q(function (resolve, reject) {
      $api.get('v2/user/view-product-details', data)
        .then(function (response) {
          resolve(response.data)
        }).catch(function (reason) {
          reject(reason)
        })
    })
  }

  function updateViewProductDetails (body) {
    return $q(function (resolve, reject) {
      var userDetails = {
        userDetails: {
          _id: body._id,
          canViewProductDetails: body.canViewProductDetails
        }
      }

      $api.put('v2/user/view-product-details', userDetails).then(function (response) {
        resolve(response.data)
      }).catch(function (reason) {
        reject(reason)
      })
    })
  }

  function companyRegisterUser (body) {
    return $api.post('company-register', body);
  }

  function verifyActivationLink(body) {
    return $api.post('v2/user/verify-activation-link', body);
  }

  function activateUser(body) {
    return $api.post('activate-user', body);
  }

  function changeTheResetToken(body) {
    return $api.post('v2/user/change-the-reset-token', body);
  }

  function emailVerificationOTP(body) {
    return $api.post('email-verification', body);
  }

  this.getViewProductDetails = getViewProductDetails
  this.updateViewProductDetails = updateViewProductDetails
  this.getCurrentUserShops = getCurrentUserShops
  this.registerUserWhenComingFromPurchaseOrderInvitationLink = registerUserWhenComingFromPurchaseOrderInvitationLink
  this.changePurchaseOrderShopWhenComingFromInvitationLink = changePurchaseOrderShopWhenComingFromInvitationLink
  this.registerUserWhenComingFromCampaignInvitationLink = registerUserWhenComingFromCampaignInvitationLink
  this.registerUserWhenComingFromRecommendationInvitationLink = registerUserWhenComingFromRecommendationInvitationLink;
  this.isHaveToOpenChooseShopModal = isHaveToOpenChooseShopModal
  this.companyRegisterUser = companyRegisterUser;
  this.verifyActivationLink = verifyActivationLink;
  this.activateUser = activateUser;
  this.changeTheResetToken = changeTheResetToken;
  this.emailVerificationOTP = emailVerificationOTP;
}])
