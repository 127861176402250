prismanoteApp.factory('$api', ['$http', '$q', '$state', '$crypto',
  function ($http, $q, $state, $crypto) {
    // Default params for fetching new items: 12 items with featured items first, sorted by views.
    var defaultApiParams = {
      products: {
        sort: {
          isFeatured: 'desc',
          views: 'desc'
        },
        limit: 24,
        offset: 0
      },
      brands: {
        sort: { isFeatured: 'desc' },
        limit: 24,
        offset: 0
      },
      campaigns: {
      },
      news: {
      },
      cart: {
        cart: []
      }
    }

    // Initially set the API params to the default params. They're initialized like this because it makes easy resetting to the defaults possible.
    var apiParams = angular.copy(defaultApiParams)

    // General setter to change the Api HTTP request params. Some wrapper functions are available to make the code more intuitive.
    var setApiParams = function (type, params, options) {
      return $q(function (resolve, reject) {
        function mergeParams (type) {
          if (apiParams[type] == null) {
            apiParams[type] = {}
          }

          for (var item in params) {
            if (apiParams[type][item] == null) {
              if (item === 'limit' || item === 'offset') {
                apiParams[type][item] = 0
              } else {
                apiParams[type][item] = {}
              }
            }

            // Clear sorting params, it is not useful to accumulate sorting params.
            if (item === 'sort') {
              apiParams[type].sort = {}
            }

            if (params[item] != null) {
              if (typeof params[item] === 'object') {
                if (options != null && typeof options === 'object' && options.overwrite != null && options.overwrite === true) {
                  apiParams[type][item] = params[item]
                } else {
                  apiParams[type][item] = Object.assign(apiParams[type][item], params[item])
                }
              } else {
                apiParams[type][item] = params[item]
              }
            } else {
              delete apiParams[type][item]
            }
          }
        }

        if (params != null && typeof params === 'object') {
          if (type != null && typeof type === 'string') {
            if (type === 'products') {
              if (params.filter != null && typeof params.filter === 'object') {
                if (apiParams.products.filter !== null && apiParams.products.filter.$text !== null && (params.filter.$text === null || params.filter.$text.$search === null || params.filter.$text.$search === '')) {
                  delete apiParams.products.filter.$text
                }
                if (apiParams.products.filter != null && (params.filter.male || params.filter.female || params.filter.kids)) {
                  delete apiParams.products.filter.male
                  delete apiParams.products.filter.female
                  delete apiParams.products.filter.kids
                  if (apiParams.products.filter.$or != null) {
                    delete apiParams.products.filter.$or
                  }
                }
              }
            }

            mergeParams(type)

            resolve(apiParams[type])
          } else {
            reject('No API content type was provided or it was invalid.')
          }
        } else {
          reject('No API params were provided or they are invalid (i.e. not an object).')
        }
      })
    }

    // Wrapper function for setApiParams
    var resetApiParams = function (type) {
      return $q(function (resolve, reject) {
        // Type is an optional parameter. Reset everything if omitted.
        if (type != null && typeof type === 'string') {
          setApiParams(type, defaultApiParams[type], { overwrite: true })

            .then(function (params) {
              resolve(params)
            })

            .catch(function (reason) {
              reject(reason)
            })
        } else {
          apiParams = angular.copy(defaultApiParams)
        }
      })
    }

    // Wrapper function for setApiParams. Call this with no arguments to use it as a getter for the existing apiParams.
    var setProductParams = function (params) {
      return $q(function (resolve, reject) {
        setApiParams('products', params)

          .then(function (productParams) {
            resolve(productParams)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    var getCount = function (type) {
      return $q(function (resolve, reject) {
        if (type != null && typeof type === 'string') {
          api('GET', 'products/count')
            .then(function (response) {
              resolve(response.data.count)
            })

            .catch(function (reason) {
              reject(reason)
            })
        } else {
          reject('Type is necessary to get the count for a set of documents in the database (products, users, ...)')
        }
      })
    }

    // General API HTTP request function to be called from one of the given wrapper functions / aliases
    function api(method, route, params, type, version) {
      // console.log('Hey api');
      var module = '';
      if ($state && $state.$current && $state.$current.data && $state.$current.data.rights) {
        module = $state.$current.data.rights;
      }
      if (method === 'POST' || method === 'PUT') {
        var encrypted = $crypto.encrypt(params) // Encryption Part

        params = { encrypted: encrypted }
      }

      return $q(function (resolve, reject) {
        var sendApiRequest = function (params) {
          var base_url = '/api/'

          if(version && (typeof version === 'string' && version.indexOf('kassa') >= 0)) {
            if ((window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1')) {
              base_url = 'https://kassa.prismanote.com/api/'
            }
            var routes = version.split('_')
            if(routes && routes.length > 1) {
              version = routes[1]
            } else {
              version = null
            }
          }
          route = base_url + (version ? 'v' + version.toString() + '/' : '') + route

          // Make sure the method parameter is always in UPPERCASE
          method = method.toUpperCase()

          var headers = { 'module': module }
          if (localStorage.getItem('authorization_new')) headers.authorization_new = localStorage.getItem('authorization_new');

          // The actual Angular function for asynchronous HTTP requests
          $http({
            method: method,
            url: route,
            params: (method === 'GET' ? params : null),
            data: (method !== 'GET' ? params : null),
            headers: headers
          })
            .then(function (response) {
              resolve(response)
            })
            .catch(function (response) {
              console.error('Error while doing API Request', response)
              var message = '';
              if (response.status === 500 || response.status === 404) {
                if (response.data.message) {
                  message = response.data.message
                } else if (response.data) {
                  message = response.data
                } else {
                  message = 'An unkown error occurred while trying sending your request.'
                }
              } else {
                message = response
              }
              return reject(message)
            })
        }

        if (method != null && typeof method === 'string') {
          if (route != null && typeof route === 'string') {
            if (params != null && typeof params === 'object') {
              if (type == null || typeof type !== 'string') {
                var routeParts = route.split('/')

                if (routeParts[1] == null || routeParts[1].length === 0) {
                  type = routeParts[0]
                } else {
                  type = route
                }

                if (method === 'GET') {
                  setApiParams(type, params)

                    .then(function (updatedParams) {
                      sendApiRequest(updatedParams)
                    })

                    .catch(function (reason) {
                      reject(reason)
                    })
                } else {
                  sendApiRequest(params)
                }
              }
            } else {
              // No params provided, default to last saved (= last used) API params for this content type.
              sendApiRequest(apiParams[type])
            }
          } else {
            reject('Invalid API route provided, unable to fullfil the request.')
          }
        } else {
          reject('Invalid HTTP method provided, unable to fullfil the request.')
        }
      })
    };

    return {
      getCount: getCount,
      setApiParams: setApiParams,
      resetApiParams: resetApiParams,
      setProductParams: setProductParams, // Wrapper/setter functions for setApiParams (only products are sortable/filterable yet)
      // Wrapper function with the request method provided as a parameter
      http: function (method, route, params, type, version) {
        return $q(function (resolve, reject) {
          api(method, route, params, type, version)

            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        })
      },

      // GET wrapper function
      get: function (route, params, type, version) {
        return $q(function (resolve, reject) {
          api('GET', route, params, type, version)

            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        })
      },

      // POST wrapper functions / aliases
      add: function (route, params, type, version) {
        return $q(function (resolve, reject) {
          api('POST', route, params, type, version)

            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        })
      },

      create: function (route, params, type, version) {
        return $q(function (resolve, reject) {
          api('POST', route, params, type, version)

            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        })
      },

      post: function (route, params, type, version) {
        return $q(function (resolve, reject) {
          api('POST', route, params, type, version)
            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        })
      },

      // PUT wrapper functions / aliases
      update: function (route, params, type, version) {
        return $q(function (resolve, reject) {
          api('PUT', route, params, type, version)

            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        })
      },

      edit: function (route, params, type, version) {
        return $q(function (resolve, reject) {
          api('PUT', route, params, type, version)

            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        })
      },

      put: function (route, params, type, version) {
        return $q(function (resolve, reject) {
          api('PUT', route, params, type, version)

            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        })
      },

      // DELETE wrapper functions / aliases
      remove: function (route, params, type, version) {
        return $q(function (resolve, reject) {
          api('DELETE', route, params, type, version)

            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        })
      },

      delete: function (route, params, type, version) {
        return $q(function (resolve, reject) {
          api('DELETE', route, params, type, version)

            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        })
      }
    }
  }])
