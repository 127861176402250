prismanoteApp.service('$auth', ['$q', '$api', function ($q, $api) {
  function isEmailExistThenLoggedIn (body) {
    return $q(function (resolve, reject) {
      $api.post('activate-user-loggedin', body)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (reason) {
          reject({ showError: true, errorMessage: 'Something went wrong while creating campaign' })
        })
    })
  }

  this.isEmailExistThenLoggedIn = isEmailExistThenLoggedIn
}])
