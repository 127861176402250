prismanoteApp.service('$inventory', ['$q', '$api', '$rootScope', function ($q, $api, $rootScope) {
    function addToInventory(body) {
        return $api.post('v2/inventory/create-record', body)
      }

    function getInventoryRecords(shopId) {
      return $q(function (resolve, reject) {
        $api.get('v2/inventory/get-records', {
            shopId: shopId
          })
          .then(function (response) {
            return resolve(response.data)
          }).catch(function (reason) {
            return reject(reason.data)
          })
      })
    }

    function updateProductCount(shopId, collectionId, productId, product) {
      return $q(function (resolve, reject) {
        $api.put('v2/inventory/update-product-count', {
            shopId: shopId,
            collectionId: collectionId,
            productId: productId,
            product: product || undefined
          })
          .then(function (response) {
            return resolve(response.data)
          }).catch(function (reason) {
            return reject(reason.data)
          })
      })
    }

    function decreaseProductCount(shopId, collectionId, productId, product) {
      console.log('----------- decreaseProductCount!!');
      return $q(function (resolve, reject) {
        $api.put('v2/inventory/decrease-product-count', {
            shopId: shopId,
            collectionId: collectionId,
            productId: productId,
            product: product
          })
          .then(function (response) {
            return resolve(response.data)
          }).catch(function (reason) {
            return reject(reason.data)
          })
      })
    }

    function resetAllCollectionsUnchecked(shopId) {
      console.log('----------- resetAllCollectionsUnchecked!!');
      return $q(function (resolve, reject) {
        $api.put('v2/inventory/reset-all-collections-unchecked', { shopId: shopId })
          .then(function (response) {
            return resolve(response.data)
          }).catch(function (reason) {
            return reject(reason.data)
          })
      })
    }

    function deleteAllInventoryRecords(shopId) {
      console.log('----------- deleteAllInventoryRecords!!');
      console.log('shopId ', shopId);
      return $q(function (resolve, reject) {
        $api.put('v2/inventory/deleteAllInventoryRecords', { shopId: shopId })
          .then(function (response) {
            return resolve(response.data)
          }).catch(function (reason) {
            return reject(reason.data)
          })
      })
    }

    function applyNewStockInShopBasedOnCollection(data) { 
      console.log('------------ applyNewStockInShopBasedOnCollection!!')
      console.log(data);
      return $q(function (resolve, reject) {
        $api.post('v2/inventory/newStock-inShopBasedOnCollection', data)
          .then(function (response) {
            return resolve(response.data)
          }).catch(function (reason) {
            return reject(reason.data)
          })
      })
    }

    function removeInventoryRecords(data) { 
      console.log('------------ removeInventoryRecords!!')
      console.log(data);
      return $q(function (resolve, reject) {
        $api.delete('v2/inventory/removeInventoryRecords?shopId=' + data)
          .then(function (response) {
            return resolve(response.data)
          }).catch(function (reason) {
            return reject(reason.data)
          })
      })
    }

    // update-product-count
    this.addToInventory = addToInventory
    this.getInventoryRecords = getInventoryRecords
    this.updateProductCount = updateProductCount
    this.decreaseProductCount = decreaseProductCount
    this.resetAllCollectionsUnchecked = resetAllCollectionsUnchecked
    this.applyNewStockInShopBasedOnCollection = applyNewStockInShopBasedOnCollection
    this.removeInventoryRecords = removeInventoryRecords
    this.deleteAllInventoryRecords = deleteAllInventoryRecords
}])