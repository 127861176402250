prismanoteApp.factory('$printer', ['$q', '$api', '$rootScope', '$retailer', '$language', '$customer', '$device', '$log', '$thermalPrint', '$filter', '$CashRegistry', '$uibModal',
  function ($q, $api, $rootScope, $retailer, $language, $customer, $device, $log, $thermalPrint, $filter, $CashRegistry, $uibModal) {
    // Set global variables to use in the whole service
    var ePOSDev = new epson.ePOSDevice()
    // var printer = new epson.ePOSBuilder();
    var printer = null
    var thermalPrinter = null
    var pageFormats = null

    function getData () {
      return $q(function (resolve, reject) {
        $device.getCurrentDevice()
          .then(function() {
            $retailer.getShop()
            thermalPrinter = $rootScope.currentDevice.thermalPrinter
            return resolve()
          })
          .catch(function(reason){
            return reject(reason)
          })
      })
    }

    getData()
      .then(function() {

      })
      .catch(function(reason) {
        if (reason) {
          $log.error('error while fetching shopdata in printer-service', reason)
        }
      })

    function connect () {
      return $q(function (resolve, reject) {
        var obsoleteDate = new Date('2022-02-01')
        var today = new Date();
        if(today.getTime() > obsoleteDate.getTime()) {
          toastr.error($language.translate('THIS_PRINTMETHOD_IS_NOT_SUPPORTED_ANYMORE'), $language.translate('ERROR'), {showDuration: '1', hideDuration: '1', timeOut: 0, extendedTimeOut: 250, closeButton: true})
          return reject('THIS_PRINTMETHOD_IS_NOT_SUPPORTED_ANYMORE')
        } else {
          toastr.warning($language.translate('THIS_PRINTMETHOD_WILL_STOP_WORKING_SOON'))
        }

        if($rootScope.currentShop.newPrinter === true || ($rootScope.currentShop.printNode && $rootScope.currentShop.printNode.enabled === true)) {
          return resolve()
        }
        // var printer = new epson.ePOSBuilder();
        function printerConnect () {
          $log.info('Connecting to printer', thermalPrinter)
          getData()
            .then(function () {
              if (!thermalPrinter || !thermalPrinter.ipAddress || !thermalPrinter.port || !thermalPrinter.deviceId) {
                return reject('No printer set in shop settings or check thermalprinter settings!')
              } else {
                try {
                  ePOSDev.connect(thermalPrinter.ipAddress, thermalPrinter.port, connectCallback, { eposprint: true })
                } catch (error) {
                  $log.error('error connecting', error)
                }
              }
            }).catch(function(reason){
            reject(reason)
          })

        }

        function connectCallback (data) {
          if (data === 'OK' || data === 'SSL_CONNECT_OK') {
            var options = {
              crypto: false,
              buffer: false
            }
            try {
              ePOSDev.createDevice(thermalPrinter.deviceId, ePOSDev.DEVICE_TYPE_PRINTER, options, createCallback)
            } catch (error) {
              $log.error('Error creating', error)
            }
          } else {
            $log.info('Printer settings', $rootScope.currentShop.devices.thermalPrinter)
            $log.error('Error while connecting to printer', data)
            return reject('Error while connecting to printer')
          }
        }

        function createCallback (obj, code) {
          if (code === 'OK') {
            printer = obj
            $log.info('Printer succesfully connected!')
            return resolve('Printer connected!')
          } else {
            $log.error('Error while creating device', code)
            return reject('Error while creating device')
          }
        }

        return printerConnect()
      })
    }

    function disconnect () {
      return $q(function (resolve, reject) {
        return resolve(true)
      })
    }

    function price (price, round, replace) {
      if (round) {
        price = (Math.round(price * 100) / 100).toFixed(2)
      }
      if (replace) {
        return '€ ' + price.toString().replace('.', ',')
      } else {
        return price
      }
    }

    function getTotalPrice (details) {
      var total = 0
      for (var i = 0; i < details.length; i++) {
        total = total + details[i].total

        if (i + 1 === details.length) {
          return total
        }
      }
    }

    function wordbreak(str, maxWidth) {
      var newLineStr = "\n"; done = false; res = '';
      while (str.length > maxWidth) {
        found = false;
        // Inserts new line at first whitespace of the line
        for (i = maxWidth - 1; i >= 0; i--) {
          if (testWhite(str.charAt(i))) {
            res = res + [str.slice(0, i), newLineStr].join('');
            str = str.slice(i + 1);
            found = true;
            break;
          }
        }
        // Inserts new line at maxWidth position, the word is too long to wrap
        if (!found) {
          res += [str.slice(0, maxWidth), newLineStr].join('');
          str = str.slice(maxWidth);
        }

      }
      return res + str;
    }

    function testWhite(x) {
      var white = new RegExp(/^\s$/);
      return white.test(x.charAt(0));
    }

    function stringLength (name, length) {
      if (name.length < length) {
        return name + new Array((length - name.length) + 1).join(' ')
      } else {
        return name.substring(0, length - 3) + '...'
      }
    }

    function send (transactionNumber, override, payments, type) {
      return $q(function (resolve, reject) {
        printer.addTextAlign(printer.ALIGN_CENTER)
        if ($rootScope.currentShop.cashRegister.footerTextReceipt && $rootScope.currentShop.cashRegister.footerTextReceipt !== '') {
          printer.addFeedLine(1)
          printer.addText($rootScope.currentShop.cashRegister.footerTextReceipt + '\n')
        }
        if (transactionNumber) {
          $log.info('TransactionNumber is provided, printing barcode')
          printer.addFeedLine(1)
          printer.addBarcode('T-' + transactionNumber, printer.BARCODE_CODE93, printer.HRI_NONE, printer.FONT_A, 2, 64)
          printer.addFeedLine(2)
        }

        printer.addCut(printer.CUT_FEED)
        openDrawer(printer, override, payments, type)

        printer.send()

        disconnect()
          .then(function () {
            return resolve('Printed')
          })
      })
    }

    /**
     * Thermal print giftcard
     * @param amount
     * @param number
     * @param date
     * @param giftcardnumber
     * @returns {*}
     */
    function printGiftcard(amount, number, date, giftcardnumber) {
      return $q(function (resolve, reject) {
        $api.get('shop/print-settings/' + $rootScope.currentShop._id + '/' + $rootScope.currentDevice._id + '/giftcard')
          .then(function (document) {
            var extraText = document.data.document.footer
            var date = moment(date).format('DD-MM-YYYY')
            if ($rootScope.currentShop.newPrinter === true) {
              $log.info('Redirecting printjob to thermalprint with JSPM')
              return $thermalPrint.printGiftcard(amount, number, date, document.data.document, giftcardnumber)
            } else {
              connect()
                .then(function () {
                  printer.addTextSmooth(true)
                  printer.addTextAlign(printer.ALIGN_CENTER)
                  if ($rootScope.currentShop.logoDark && $rootScope.currentShop.logoDark.src) {
                    // The shoplogo is set, we assumed that the logo is set into the printer
                    printer.addLogo(32, 32)
                    printer.addFeedLine(1)
                  } else {
                    printer.addTextDouble(true, true)
                    printer.addText($rootScope.currentShop.name + '\n')
                    printer.addTextDouble(false, false)
                    printer.addFeedLine(1)
                  }
                  //Print shop info
                  printer.addText($rootScope.currentShop.address.street + ' ' + ($rootScope.currentShop.address.houseNumber ? $rootScope.currentShop.address.houseNumber : '') + ($rootScope.currentShop.address.houseNumberSuffix ? $rootScope.currentShop.address.houseNumberSuffix : '') + '\n')
                  printer.addText($filter('zipcode')($rootScope.currentShop.address.postalCode) + '\n')
                  printer.addText($rootScope.currentShop.phone.landLine + '\n' || $rootScope.phone.mobilePhone + '\n')
                  printer.addText($rootScope.currentShop.email + '\n')
                  printer.addTextDouble(true, true)
                  printer.addFeedLine(2)
                  printer.addText($language.translate('GIFTCARD') + '\n')
                  printer.addFeedLine(3)
                  printer.addText(price(amount, true, true))
                  printer.addTextDouble(false, false)
                  printer.addFeedLine(2)
                  printer.addFeedLine(1)
                  printer.addBarcode('G-' + number, printer.BARCODE_CODE93, printer.HRI_NONE, printer.FONT_A, 2, 64)
                  printer.addFeedLine(1)
                  printer.addText($language.translate('GIFTCARD_NUMBER') + ': ' + number + '\n')
                  printer.addText($language.translate('ISSUED_AT') + ': ' + date)
                  printer.addFeedLine(2)
                  if (extraText) {
                    printer.addText(extraText)
                    printer.addFeedLine(2)
                  }
                  printer.addCut(printer.CUT_FEED)
                  printer.send()
                  return resolve()
                })
            }
          })
          .catch(function (reason) {
            console.error('Error while getting document info', reason)
            return reject(reason)
          })
      })
    }

    function printShopReceipt (data) {
      console.group('PRINT SHOP RECEIPT ')
      var skipVat = data && data.skipVat && data.skipVat === true
      if(skipVat) {
        console.info('VAT calculation will be skipped for this print')
      }
      return $q(function (resolve, reject) {
        if($rootScope.currentShop.newPrinter === true) {
          $log.info('Redirecting printjob to thermalprint with JSPM')
          return $thermalPrint.printThermal('shop-receipt',data.quantity, data.transaction, data.skipDrawer)
        }
        connect()
          .then(function () {
            $log.info('Starting printjob')

            printer.addTextSmooth(true)
            printer.addTextAlign(printer.ALIGN_CENTER)

            if ((data.shop.logoDark && data.shop.logoDark.src) || (data.shop.logoLight && data.shop.logoLight.src)) {
              // THe shoplogo is set, we assumed that the logo is set into the printer
              printer.addLogo(32, 32)
              printer.addFeedLine(1)
            } else {
              printer.addTextDouble(true, true)
              printer.addText(data.shop.name + '\n')
              printer.addTextDouble(false, false)
            }

            printer.addText(data.shop.address.street + ' ' + (data.shop.address.houseNumber ? data.shop.address.houseNumber : '') + (data.shop.address.houseNumberSuffix ? data.shop.address.houseNumberSuffix : '') + '\n')
            printer.addText($filter('zipcode')(data.shop.address.postalCode) + ' ' + data.shop.address.city + '\n')
            printer.addText(data.shop.phone.landLine + '\n' || data.shop.phone.mobilePhone + '\n')
            printer.addText(data.shop.email + '\n')
            printer.addHLine(0, 1, printer.LINE_THICK_DOUBLE)
            printer.addFeedLine(1)

            printer.addTextAlign(printer.ALIGN_LEFT)
            if(data.transaction.isInvoice && data.transaction.invoiceNumber) {
              printer.addTextSize(1,2)
              printer.addText('Factuur: \t\t' + data.transaction.invoiceNumber + '\n')
              printer.addTextSize(1,1)
            }
            printer.addText('Datum: \t\t\t' + moment(data.transaction.dateCreated).format('DD-MM-YYYY HH:mm') + '\n')
            printer.addText('Transactie: \t\t' + data.transaction.number + '\n')
            if(!data.transaction.isInvoice) {
              printer.addText('Bonnummer: \t\t' + data.transaction.receipt.number + '\n')
            }
            if (data.transaction.customer && !data.transaction.customer.counter) {
              printer.addText('Bon voor: \t\t' + $customer.makeCustomerName(data.transaction.customer) + '\n')
              printer.addText('Klantnummer: \t\t' + data.transaction.customer.clientId + '\n')
            }
            if (data.transaction.createdBy) {
              printer.addText('Geadviseerd door: \t' + data.transaction.createdBy.name + '\n')
            }
            if($rootScope.currentShop.cashRegister.printDeviceNameThermal) {
              printer.addText('Kassa: \t' + $rootScope.currentDevice.name + '\n')
            }
            printer.addTextAlign(printer.ALIGN_CENTER)
            printer.addText('=====================================\n')
            printer.addTextAlign(printer.ALIGN_LEFT)
            var counter = 0
            var taxes = []
            for (var i = 0; i < data.transaction.details.length; i++) {
              var detail = data.transaction.details[i]

              printer.addTextAlign(printer.ALIGN_LEFT)

              if (detail.type === 'text' || detail.type === 'empty') {
                if (detail.type === 'text') {
                  printer.addText(detail.name)
                } else {
                  printer.addFeedLine(1)
                }
              } else {
                if ($rootScope.currentShop.cashRegister.printFullProductNameThermal === true) {
                  printer.addTextStyle(false, false, true, printer.COLOR_4)
                  printer.addText(detail.originalQuantity + ' x ' + detail.name + '\n')

                  var priceLength = detail.total.toFixed(2).length + 1
                  var productNumber = detail.productNumber ? detail.productNumber : detail.productId && detail.productId.variants && detail.productId.variants[0].productNumber ? detail.productId && detail.productId.variants && detail.productId.variants[0].productNumber : ''

                  printer.addText(stringLength(productNumber, 42 - priceLength) + price(detail.total, true, true) + '\n')
                  printer.addTextStyle(false, false, false, printer.COLOR_4)
                } else {
                  printer.addTextStyle(false, false, true, printer.COLOR_4)

                  var name = stringLength(detail.originalQuantity + ' x ' + detail.name, 35)
                  printer.addText(name)
                  printer.addTextStyle(false, false, false, printer.COLOR_4)
                  printer.addText('  ' + price(detail.total, true, true) + '\n')

                  if (detail.productNumber) {
                    printer.addText(detail.productNumber + '\n')
                  } else if (detail.productId && detail.productId.variants && detail.productId.variants[0].productNumber) {
                    printer.addText(detail.productId.variants[0].productNumber + '\n')
                  }
                }
              }

              if (detail.comment) {
                printer.addText(detail.comment + '\n')
              }

              if (detail.discount && detail.discountValue) {
                printer.addTextStyle(true, false, false, printer.COLOR_4)
                var text = ''
                var originalPrice = (detail.price * (skipVat === true ? 1 : (1 + (detail.priceVat / 100))) + detail.discountValue)  * detail.originalQuantity
                if (detail.discountPercent) {
                  var taxPrice = detail.price * (skipVat === true ? 1 : (1 + (detail.priceVat / 100)))
                  var givenDiscount = taxPrice * detail.discountValue / (100 - detail.discountValue)
                  text = detail.discountValue.toString() + '%'
                  originalPrice = taxPrice + givenDiscount
                } else {
                  text = price(detail.discountValue.toString(), true, true)
                }
                printer.addText('Uw korting ' + text)
                printer.addTextStyle(false, false, false, printer.COLOR_4)

                printer.addText('  Originele prijs: ' + price(originalPrice, true, true) + '\n')
              }

              if (!taxes[detail.priceVat]) {
                taxes[detail.priceVat] = detail.total
              } else {
                taxes[detail.priceVat] += detail.total
              }

              counter++
              if (counter === data.transaction.details.length) {
                printer.addTextAlign(printer.ALIGN_CENTER)
                printer.addText('\n=====================================\n')
                printer.addTextAlign(printer.ALIGN_LEFT)

                printer.addFeedLine(1)
                var totalPrice = getTotalPrice(data.transaction.details)
                printer.addText(stringLength('Totaal: ', 30) + price(totalPrice, true, true) + '\n')

                if (!data.transaction.receipt || !data.transaction.receipt.payments || data.transaction.receipt.payments.length === 0) {
                  return send(data.transaction.number, null, data.transaction.receipt.payments, data.transaction.type)
                    .then(function () {
                      return resolve('printed')
                    })
                    .catch(function (reason) {
                      $log.error(reason)
                    })
                }

                counter = 0
                var totalPaid = 0
                var hashes = []
                var tickets = []
                for (var i = 0; i < data.transaction.receipt.payments.length; i++) {
                  var payment = data.transaction.receipt.payments[i]
                  if (payment.paymentHash) {
                    if (payment.paymentHash.length > 60) {
                      //ccv tickets
                      tickets.push(payment.paymentHash)
                    } else {
                      hashes.push(payment.paymentHash)
                    }
                  }
                  if (payment.method) {
                    printer.addText(stringLength($language.translate(payment.method.toUpperCase()) + (payment.cardName ? ' (' + $language.translate(payment.cardName) + ')' : ''), 30) + price(payment.amount, true, true) + '\n')
                  }
                  if (payment.amount) {
                    totalPaid += payment.method.toUpperCase() === 'PAYLATER' ? 0 : payment.amount
                  }
                  counter++
                  if (counter === data.transaction.receipt.payments.length) {
                    var totalAmount = totalPrice < 0 ? totalPrice * -1 : totalPrice
                    totalPaid = totalPaid < 0 ? totalPaid * -1 : totalPaid
                    printer.addText(stringLength('Wisselgeld: ', 30) + price(totalPaid - totalAmount, true, true) + '\n')
                    printer.addFeedLine(1)
                    if (!taxes || taxes.length === 0) {
                      $log.error('TAX ERROR', taxes)
                      return
                    }
                    counter = 0
                    printer.addText('\t\t Netto \t BTW \t Totaal\n')

                    for (var i in taxes) {
                      var priceWithoutTax = taxes[i] / (1 + (i / 100))
                      var tax = taxes[i] - priceWithoutTax
                      printer.addText(i + ' % BTW van \t' + price(priceWithoutTax, true, true) + '\t' + price(tax, true, true) + '\t' + price(taxes[i], true, true) + '\n')

                      counter++
                      if (counter === Object.keys(taxes).length) {
                        printer.addFeedLine(1)

                        function getCustomerPoints (callback) {
                          if (data.transaction.customer && !data.transaction.customer.counter && $rootScope.currentShop.points && $rootScope.currentShop.points.enabled && data.transaction.customer.points && data.transaction.customer.points.spendable > 0) {
                            $api.get('customers/points', {
                              customerId: data.transaction.customer._id,
                              shopId: $rootScope.currentShop._id
                            }).then(function (res) {
                              var points = Math.floor($rootScope.currentShop.points.perEuro * data.transaction.transactionTotal)
                              return callback({
                                points: points,
                                spendable: res.data.spendable
                              })
                            }).catch(function (reason) {
                              $log.error('Errror while fetching customer for points on thermal receipt', reason)
                            })
                          } else {
                            return callback(null)
                          }
                        }

                        getCustomerPoints(function (points) {
                          printer.addTextAlign(printer.ALIGN_LEFT)
                          if (points) {
                            printer.addText('Spaarpunten\n')
                            if (!data.transaction.parentTransactionId || (data.transaction.parentTransactionId && data.transaction.parentTransactionId.type !== 'gold-purchase')) {
                              printer.addText('Punten nu gespaard: \t' + points.points + '\n')
                            }
                            printer.addText('Saldo: \t\t' + points.spendable + '\n')
                          }

                          if (hashes && hashes.length > 0) {
                            var counter2 = 0
                            for (var i = 0; i < hashes.length; i++) {
                              $api.get('pay/get-ticket', {
                                shopId: $rootScope.currentShop._id,
                                hash: hashes[i]
                              })
                                .then(function (ticket) {
                                  var parsedTicket = ticket.data.receipt.replace('\n', '').replace('\n', '').replace('\n', '').replace('\n', '')
                                  printer.addText(parsedTicket)
                                  return checkLoop()
                                })
                                .catch(function (reason) {
                                  $log.error('Error while fetching ticket', reason)
                                  return checkLoop()
                                })
                            }
                            function checkLoop () {
                              counter2++
                              if (counter2 === hashes.length) {
                                return print()
                              }
                            }
                          } else if (tickets && tickets.length > 0) {
                            var counter2 = 0
                            for (var i = 0; i < tickets.length; i++) {
                              var parsed = JSON.parse(tickets[i])

                              if (!parsed || parsed.length === 0) {
                                counter2++
                                continue
                              }
                              var counter3 = 0

                              for (var d = 0; d < parsed.length; d++) {
                                printer.addText(parsed[d] + '\n')
                                counter3++
                                if (counter3 === parsed.length) {
                                  counter2++
                                }
                              }
                              if (counter2 === tickets.length) {
                                return print()
                              }
                            }
                          } else {
                            return print()
                          }
                        })
                      }
                    }

                    function print () {
                      if ($rootScope.currentShop.cashRegister.printMerchantReceipt) {
                        if (data.payment) {
                          // Only when direct printing from transaction
                          printer.addText(data.payment.receipt.replace(/(?:\\[r]|[\r]+)+/g, '\n'))
                          printer.addFeedLine(2)
                          return send(data.transaction.number, null, data.transaction.receipt.payments, data.transaction.type)
                            .then(function() {
                              return resolve('printed')
                            })
                        } else {
                          // Get payment status to fetch receipt
                          // When multiple payments or print later
                          var counter3 = 0
                          if (!hashes || hashes.length === 0) {
                            return send(data.transaction.number, null, data.transaction.receipt.payments, data.transaction.type)
                              .then(function() {
                                return resolve('printed')
                              })
                          } else {
                            return getMerchantLoop()
                          }
                        }
                      } else {
                        send(data.transaction.number, null, data.transaction.receipt.payments, data.transaction.type)
                          .then(function(){
                            return resolve('printed')
                          })
                          .catch(function (reason){
                            $log.error(reason)
                          })
                      }
                      function getMerchantLoop () {
                        counter3++
                        if (counter3 === hashes.length) {
                          send(data.transaction.number, null, data.transaction.receipt.payments, data.transaction.type)
                            .then(function(){
                              return resolve('printed')
                            })
                            .catch(function (reason){
                              $log.error(reason)
                            })
                        }
                      }
                    }
                  }
                }
              } else {
                printer.addFeedLine(1)
              }
            }
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function openDrawer (printer, override, payments, type) {
      if(type && type !== 'shop-purchase') {
        return
      }
      if ($rootScope.currentShop.cashRegister.openDrawer || override) {
        if(payments) {
          var cashPayments = payments.filter(function(p) { return p.method.toUpperCase() === 'CASH'})
          if(!cashPayments || cashPayments.length === 0) {
            //Do nothing if there are no cash payments
            $log.info('There are no cash payments found, no need to open the cash drawer')
            return
          }
        }
        $log.info('Opening cash drawer at', $rootScope.currentShop.cashRegister.drawerConnector)
        if ($rootScope.currentShop.cashRegister.drawerConnector === 'PIN2') {
          printer.addPulse(printer.DRAWER_1, printer.PULSE_100)
        } else {
          printer.addPulse(printer.DRAWER_2, printer.PULSE_100)
        }
      }
    }

    function checkEnabled () {
      return $q(function (resolve, reject) {
        if( $rootScope.currentDevice && $rootScope.currentDevice.thermalPrinter && $rootScope.currentDevice.thermalPrinter.enabled
        ) {
          return resolve('PRINTER_ENABLED')
        } else {
          return reject('PRINTER_DISABLED_OR_NOT_SET')
        }
      })
    }

    function printDayClosure (state) {
      return $q(function (resolve, reject) {
        if($rootScope.currentShop.newPrinter === true) {
          $log.info('Redirecting printjob to thermalprint with JSPM')
          return $thermalPrint.printDayClosure(state)
        }
        connect()
          .then(function () {
            $log.info('Starting printjob')
            printer.addTextSmooth(true)
            printer.addTextAlign(printer.ALIGN_CENTER)
            printer.addTextDouble(true, true)
            printer.addText($language.translate('DAY_CLOSURE') + '\n' + moment(state.date).format('DD-MM-YYYY') + '\n')
            printer.addTextDouble(false, false)
            printer.addFeedLine(2)
            printer.addTextAlign(printer.ALIGN_LEFT)

            printer.addText(stringLength($language.translate('TILL_OPENEND'), 30))
            printer.addText(moment(state.date).format('DD-MM-YYYY HH:mm') + '\n')
            printer.addText(stringLength($language.translate('TILL_CLOSED'), 30))
            printer.addText(moment(state.dateClosed).format('DD-MM-YYYY HH:mm') + '\n')
            printer.addText(stringLength($language.translate('PRINTED_ON'), 30))
            printer.addText(moment().format('DD-MM-YYYY HH:mm') + '\n')

            printer.addFeedLine(2)

            printer.addTextStyle(false, false, true, printer.COLOR_1)
            printer.addText($language.translate('PAYMENTS') + '\n')
            printer.addTextStyle(false, false, false, printer.COLOR_1)
            var counter = 0; var totalTurnOver = 0

            function getTakenMoney () {
              return $q(function (resolve, reject) {
                if (!state.takenMoney || state.takenMoney.length === 0) return resolve(null)
                var totalTaken = 0; var takenMoney = []; var count = 0
                for (var i = 0; i < state.takenMoney.length; i++) {
                  totalTaken += state.takenMoney[i].amount
                  takenMoney.push(state.takenMoney[i])
                  count++
                  if (count === state.takenMoney.length) {
                    return resolve({
                      amount: totalTaken,
                      taken: takenMoney
                    })
                  }
                }
              })
            }

            for (var i = 0; i < state.payments.length; i++) {
              var payment = state.payments[i]
              if (payment.method) {
                printer.addText(stringLength($language.translate(payment.method.toUpperCase()), 30))
              }
              if (payment.amount) {
                printer.addText(price(payment.amount, true, true) + '\n')

                totalTurnOver += payment.method.toUpperCase() !== 'PAYLATER' ? payment.amount : 0
              }
              if(typeof payment.amountBk !== 'undefined' && payment.amountBk !== payment.amount) {
                printer.addText(stringLength($language.translate('ORIGINAL_AMOUNT'), 30) + price(payment.amountBk, true, true) + '\n')
              }

              if (payment.method && payment.method.toUpperCase() === 'CARD' && payment.cards && payment.cards.length > 0) {
                var counter2 = 0
                for (var c = 0; c < payment.cards.length; c++) {
                  printer.addText(stringLength('  ' + $language.translate(payment.cards[c].cardName), 30))
                  printer.addText(price(payment.cards[c].amount, true, true) + '\n')
                  if(typeof payment.cards[c].amountBk !== 'undefined' && payment.cards[c].amountBk !== payment.cards[c].amount) {
                    printer.addText(stringLength($language.translate('ORIGINAL_AMOUNT'), 30) + price(payment.cards[c].amountBk, true, true) + '\n')
                  }
                  counter2++
                  if (counter2 === payment.cards.length) {
                    counter++
                  }
                }
              } else {
                counter++
              }

              if (counter === state.payments.length) {
                printer.addFeedLine(2)
                printer.addText(stringLength($language.translate('DAY_TURNOVER'), 30))
                printer.addText(price(totalTurnOver, true, true) + '\n')
                getTakenMoney()
                  .then(function (result) {
                    if (result) {
                      printer.addText(stringLength($language.translate('TAKEN_MONEY') + ' *', 30))
                      printer.addText(price(result.amount, true, true) + '\n')
                    }

                    printer.addText(stringLength($language.translate('CASH_COUNTED'), 30))
                    printer.addText(price(state.cashCounted, true, true) + '\n')
                    printer.addText(stringLength($language.translate('TREASURY_DIFFERENCE'), 30))
                    printer.addText(price(state.cashDifference, true, true) + '\n')
                    printer.addText(stringLength($language.translate('SKIM'), 30))
                    printer.addText(price(state.skim, true, true) + '\n')

                    printer.addFeedLine(2)

                    if (result && result.taken && result.taken.length > 0) {
                      var count = 0
                      printer.addTextStyle(false, false, true, printer.COLOR_1)
                      printer.addText('*' + $language.translate('TAKEN_MONEY') + '\n')
                      printer.addTextStyle(false, false, false, printer.COLOR_1)
                      for (var t = 0; t < result.taken.length; t++) {
                        printer.addText(moment(result.taken[t].date).format('DD-MM-YYYY HH:mm') + ' - ' + price(result.taken[t].amount, true, true) + ' - ' + result.taken[t].reason + '\n')
                        count++
                        if (count === result.taken.length) {
                          printer.addFeedLine(2)
                          completePrint()
                        }
                      }
                    } else {
                      completePrint()
                    }

                    function completePrint () {
                      //Add tax overview
                      printer.addTextAlign(printer.ALIGN_CENTER)
                      printer.addTextDouble(true, true)
                      printer.addText($language.translate('TAX_OVERVIEW') + '\n')
                      printer.addTextDouble(false, false)
                      printer.addTextAlign(printer.ALIGN_LEFT)
                      printer.addTextStyle(false, false, true, printer.COLOR_1)
                      printer.addText(stringLength($language.translate('INCOME'), 30) + price(state.income.totalEarning, true, true) + '\n')
                      printer.addTextStyle(false, false, false, printer.COLOR_1)
                      printer.addText(stringLength($language.translate('ONLINE_REVENUE'), 30) + price(state.income.online, true, true) + '\n')
                      if(state.income.online > 0) {
                        printer.addText($language.translate('ONLINE_ORDER_EXPLAIN'))
                      }
                      printer.addFeedLine(2)

                      printer.addTextStyle(false, false, true, printer.COLOR_1)
                      printer.addText($language.translate('REVENUE_FROM_SELLS') + '\n')
                      printer.addTextStyle(false, false, false, printer.COLOR_1)
                      for(var key in state.income.revenue) {
                        printer.addText(stringLength($language.translate('REVENUE') + ' ' + state.income.revenue[key].taxRate + '%: ', 30) + price(state.income.revenue[key].amountWithVat, true, true) + '\n')
                        printer.addText('  ' + stringLength($language.translate('TAX'), 28) + price(state.income.revenue[key].tax, true, true)+ '\n')
                      }
                      printer.addFeedLine(1)
                      printer.addText(stringLength($language.translate('TAX_OWN'),30) + price(_.sumBy(state.income.revenue, 'tax'), true, true) + '\n')
                      printer.addFeedLine(2)

                      if(state.income.collections && state.income.collections.length > 0) {
                        printer.addTextStyle(false, false, true, printer.COLOR_1)
                        printer.addText($language.translate('SELLS_PER_COLLECTION') + '\n')
                        printer.addTextStyle(false, false, false, printer.COLOR_1)
                        for(var i = 0; i < state.income.collections.length; i++) {
                          printer.addText(stringLength($language.translate(state.income.collections[i].category.toUpperCase()), 30) + price(state.income.collections[i].amount, true, true) + '\n')
                        }

                        printer.addText(stringLength($language.translate('TOTAL'), 30) + price(_.sumBy(state.income.collections, 'amount'), true, true))
                        printer.addFeedLine(2)
                      }

                      printer.addTextStyle(false, false, true, printer.COLOR_1)
                      printer.addText($language.translate('EARNINGS_FROM_GIFTCARDS') + '\n')
                      printer.addTextStyle(false, false, false, printer.COLOR_1)
                      printer.addText(stringLength($language.translate('ISSUED'), 30) + price(state.income.giftcards.issued, true, true) + '\n')
                      printer.addText(stringLength($language.translate('TAKEN'), 30) + price(state.income.giftcards.taken, true, true) + '\n')
                      printer.addText(stringLength($language.translate('BALANCE'), 30) + price(state.income.giftcards.balance, true, true) + '\n')
                      printer.addFeedLine(2)

                      printer.addTextStyle(false, false, true, printer.COLOR_1)
                      printer.addText($language.translate('EARNINGS_FROM_GOLD') + '\n')
                      printer.addTextStyle(false, false, false, printer.COLOR_1)
                      printer.addText(stringLength($language.translate('INCOME_GOLD_SELL_PURCHASE'), 30) + price(state.income.gold.income, true, true) + '\n')
                      printer.addText(stringLength($language.translate('PURCHASED_GOLD'), 30) + price(state.income.gold.purchased, true, true) + '\n')
                      printer.addText(stringLength($language.translate('SOLD_GOLD'), 30) + price(state.income.gold.sold, true, true) + '\n')
                      printer.addFeedLine(2)

                      printer.addTextStyle(false, false, true, printer.COLOR_1)
                      printer.addText(stringLength($language.translate('TAX_OWN'), 30) + price(state.income.totalTax, true, true) + '\n')
                      printer.addText(stringLength($language.translate('TOTAL_REVENUE'), 30) + price(state.income.totalRevenue, true, true) + '\n')
                      printer.addTextStyle(false, false, false, printer.COLOR_1)

                      printer.addFeedLine(1)
                      printer.addTextDouble(true, true)
                      printer.addText($language.translate('DIFFERENCE') + ' ' + price(state.income.totalRevenue - totalTurnOver, true, true) + '\n')
                      printer.addTextDouble(false, false)
                      printer.addFeedLine(2)

                      printer.addCut(printer.CUT_FEED)
                      printer.send()
                      disconnect()
                        .then(function () {
                          return resolve('Printed')
                        })
                    }
                  })
              }
            }
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function printPinTicket (ticket) {
      return $q(function (resolve, reject) {
        if($rootScope.currentShop.newPrinter === true) {
          $log.info('Redirecting printjob to thermalprint with JSPM')
          return $thermalPrint.printPinTicket(ticket)
        }
        connect()
          .then(function () {
            $log.info('Starting printjob')
            printer.addTextSmooth(true)
            printer.addText('POI:' + ticket.terminal + '\n')
            printer.addText('MERCHANT:' + ticket.terminal + '\n')
            printer.addText('POI:' + ticket.terminal + '\n')
            printer.addText('POI:' + ticket.terminal + '\n')
            printer.addText('POI:' + ticket.terminal + '\n')
            printer.addFeedLine(2)
            printer.addCut(printer.CUT_FEED)

            printer.send()
            disconnect()
              .then(function () {
                return resolve('Printed')
              })
          })
          .catch(function (err) {
            return reject(err)
          })
      })
    }

    function printTakenMoneyReceipt (amount, reason) {
      var currentUser = $rootScope.currentEmployee ? $rootScope.currentEmployee : $rootScope.user
      return $q(function (resolve, reject) {
        if($rootScope.currentShop.newPrinter === true) {
          $log.info('Redirecting printjob to thermalprint with JSPM')
          return $thermalPrint.printTakenMoneyReceipt(amount, reason)
        }
        connect()
          .then(function () {
            $log.info('starting taken money receipt print')
            printer.addTextDouble(true, true)
            printer.addText($language.translate('TAKEN_MONEY') + '\n')
            printer.addTextDouble(false, false)
            printer.addFeedLine(1)
            printer.addText($language.translate('USER') + ': ' + currentUser.firstName + '\n')
            printer.addText($language.translate('AMOUNT') + ': ' + price(amount, true, true) + '\n')
            printer.addText($language.translate('DATE_TIME') + ': ' + moment().format('DD-MM-YYYY HH:mm') + '\n')
            printer.addText($language.translate('REASON') + ': ' + reason)
            printer.addFeedLine(2)
            printer.addCut(printer.CUT_FEED)
            openDrawer(printer)
            printer.send()
            disconnect()
              .then(function () {
                return resolve('Printed')
              })
              .catch(function (reason) {
                return reject(reason)
              })
          })
      })
    }

    function getPageDefinition (name) {
      return $q(function (resolve, reject) {
        getData()
          .then(function(){
            if (!name || !$rootScope.currentDevice.documents || $rootScope.currentDevice.documents.length === 0) {
              return reject('DOCUMENT_NAME_IS_NOT_PROVIDED_OR_SHOP_DOES_NOT_HAVE_DOCUMENTS')
            }
            var deviceIdx = _.findIndex($rootScope.currentDevice.documents, {type: name})
            var shopIdx = _.findIndex($rootScope.currentShop.pageFormats, {name: name})
            if (deviceIdx < 0) {
              toastr.error($language.translate('NO_DEVICE_PAGEDEFINITION_FOUND'))
              return reject('NO_DEVICE_PAGEDEFINITION_FOUND')
            }
            if (shopIdx < 0) {
              toastr.error($language.translate('NO_SHOP_PAGEDEFINITION_FOUND'))
              return reject('NO_SHOP_PAGEDEFINITION_FOUND')
            }
            var document = $rootScope.currentDevice.documents[deviceIdx]
            document.rotation = $rootScope.currentShop.pageFormats[shopIdx].rotation
            document.logoSizeOverride = $rootScope.currentShop.pageFormats[shopIdx].logoSizeOverride
            document.showVatByDefault = $rootScope.currentShop.pageFormats[shopIdx].showVatByDefault ? $rootScope.currentShop.pageFormats[shopIdx].showVatByDefault : false
            document.documentId = $rootScope.currentShop.pageFormats[shopIdx].documentId ? $rootScope.currentShop.pageFormats[shopIdx].documentId : null
            document.useRepairBagReceipt = $rootScope.currentShop.pageFormats[shopIdx].useRepairBagReceipt ? $rootScope.currentShop.pageFormats[shopIdx].useRepairBagReceipt : false
            return resolve(document)
          })
          .catch(function(reason){
            console.error(reason)
            return reject(reason)
          })

      })
    }

    function printRepairSpecial (transaction, repair, notCustomer) {
      $log.info('starting transaction print for: ', repair ? 'repair' : 'order', transaction.number)
      printer.addTextSmooth(true)
      printer.addTextAlign(printer.ALIGN_CENTER)
      if (($rootScope.currentShop.logoDark && $rootScope.currentShop.logoDark.src) || ($rootScope.currentShop.logoLight && $rootScope.currentShop.logoLight.src)) {
        // The shoplogo is set, we assumed that the logo is set into the printer
        printer.addLogo(32, 32)
        printer.addFeedLine(1)
      } else {
        printer.addTextDouble(true, true)
        printer.addText($rootScope.currentShop.name + '\n')
        printer.addTextDouble(false, false)
        printer.addFeedLine(1)
      }
      //Print shop info
      printer.addText($rootScope.currentShop.address.street + ' ' + ($rootScope.currentShop.address.houseNumber ? $rootScope.currentShop.address.houseNumber : '') + ($rootScope.currentShop.address.houseNumberSuffix ? $rootScope.currentShop.address.houseNumberSuffix : '') + '\n')
      printer.addText($filter('zipcode')($rootScope.currentShop.address.postalCode) + '\n')
      printer.addText($rootScope.currentShop.phone.landLine + '\n' || $rootScope.phone.mobilePhone + '\n')
      printer.addText($rootScope.currentShop.email + '\n')

      printer.addTextAlign(printer.ALIGN_LEFT)
      printer.addTextDouble(true, true)
      printer.addText($language.translate(repair ? 'REPAIR' : 'SPECIAL') + '\n')
      printer.addTextDouble(false, false)
      printer.addFeedLine(1)
      if (transaction.repair.repairNumber && transaction.repair.repairNumber !== '' && transaction.repair.repairNumber !== '0') {
        printer.addText($language.translate('REPAIR_NUMBER') + ': ' + transaction.repair.repairNumber + '\n')
      } else {
        printer.addText($language.translate('RECEIPT_NUMBER') + ': ' + transaction.number + '\n')
      }
      printer.addFeedLine(1)
      printer.addBarcode('R-' + transaction.number, printer.BARCODE_CODE93, printer.HRI_NONE, printer.FONT_A, 2, 64)
      printer.addFeedLine(1)

      if (notCustomer) {
        if (transaction.repair.repairer && transaction.repair.repairer.name) {

          var repairer = transaction.repair.repairer

          printer.addTextStyle(false, false, true, printer.COLOR_4)
          printer.addText($language.translate('PROCESSOR_REQUEST') + '\n')
          printer.addTextStyle(false, false, false, printer.COLOR_4)

          printer.addText(repairer.name + '\n')

          if (repairer.address.street) {
            printer.addText(repairer.address.street + (repairer.address.houseNumber ? (' ' + repairer.address.houseNumber) : '') + '\n')
          }

          if (repairer.address.city) {
            printer.addText((repairer.address.postalCode ? ($filter('zipcode')(repairer.address.postalCode) + ' ') : '') + repairer.address.city + '\n')
          }

          if (repairer.email) {
            printer.addText(repairer.email + '\n')
          }

          if (repairer.phone.landLine) {
            printer.addText(repairer.phone.landLine + '\n')
          }
        }
      }
      if (transaction.customer && transaction.customer.counter === false) {

        var customer = transaction.customer

        printer.addTextStyle(false, false, true, printer.COLOR_4)
        printer.addText($language.translate('CUSTOMER') + '\n')
        printer.addTextStyle(false, false, false, printer.COLOR_4)

        printer.addText($customer.makeCustomerName(customer) + '\n')

        if (customer.invoiceAddress && Object.keys(customer.invoiceAddress).length > 3) {
          var address = customer.invoiceAddress
          if (address.street) {
            printer.addText(address.street + (address.houseNumber ? ' ' + address.houseNumber + (address.houseNumberSuffix ? address.houseNumberSuffix : '') : '') + '\n')
          }
          if (address.postalCode || address.city) {
            printer.addText((address.postalCode ? $filter('zipcode')(address.postalCode) : '') + (address.city ? (address.postalCode ? ' ' : '') + address.city : '') + '\n')
          }
          var phone = $customer.getCustomerPhone(customer)
          if (phone) {
            printer.addText(phone + '\n')
          }
        }
      }

      printer.addTextStyle(false, false, true, printer.COLOR_4)
      printer.addText('\n')
      printer.addText($language.translate('PRODUCT') + '\n')
      printer.addTextStyle(false, false, false, printer.COLOR_4)
      printer.addText(transaction.details[0].name + '\n')
      if (transaction.details[0].productId
        && transaction.details[0].productId.variants
        && transaction.details[0].productId.variants[0]
        && transaction.details[0].productId.variants[0].productNumber) {
        printer.addText(transaction.details[0].productId.variants[0].productNumber + '\n')
      }
      if (repair) {
        if (transaction.repair.category) {
          printer.addText($language.translate('CATEGORY') + ': ' + $language.translate(transaction.repair.category) + (transaction.repair.material ? '\n' : ''))
        }
        if (transaction.repair.material) {
          printer.addText($language.translate('MATERIAL') + ': ' + $language.translate(transaction.repair.material))
        }
      }
      printer.addFeedLine(2)
      if (transaction.comment) {
        printer.addTextStyle(false, false, true, printer.COLOR_4)
        printer.addText($language.translate('REMARKS') + '\n')
        printer.addTextStyle(false, false, false, printer.COLOR_4)

        printer.addText(wordbreak(transaction.comment,47))

        printer.addFeedLine(2)
      }

      printer.addFeedLine(1)

      printer.addText($language.translate('INTAKE_DATE') + ': ' + moment(transaction.dateCreated).format('DD-MM-YYYY') + '\n')
      if (repair) {
        if (transaction.repair.estimatedDate) {
          printer.addText($language.translate('ESTIMATED_DATE') + ': ' + moment(transaction.repair.estimatedDate).format('DD-MM-YYYY') + '\n')
        }
        if (transaction.repair.estimatedDateString) {
          printer.addText($language.translate('CONTACT_WHEN_READY') + ': ' + $language.translate(transaction.repair.estimatedDateString) + '\n')
        }
        if (transaction.repair.estimatedPrice && transaction.repair.estimatedPrice > 0 && (!transaction.repair.actualCosts || transaction.repair.actualCosts <= 0)) {
          printer.addText($language.translate('ESTIMATED_PRICE') + ': €' + transaction.repair.estimatedPrice + '\n')
        }
        if (transaction.repair.actualCosts && transaction.repair.actualCosts > 0) {
          printer.addText($language.translate('TOTAL_AMOUNT_TO_PAY') + ': €' + transaction.repair.actualCosts + '\n')
        }
        if (transaction.repair && transaction.repair.repairNumber && transaction.repair.repairNumber !== '' && transaction.repair.repairNumber !== '0') {
          printer.addText($language.translate('RECEIPT_NUMBER') + ': ' + transaction.number + '\n')
        }
      } else {
        if (transaction.special.estimatedDate) {
          printer.addText($language.translate('ESTIMATED_DATE') + ': ' + moment(transaction.special.estimatedDate).format('DD-MM-YYYY') + '\n')
        }
        if (transaction.special.estimatedDateString) {
          printer.addText($language.translate('CONTACT_WHEN_READY') + ': ' + $language.translate(transaction.special.estimatedDateString) + '\n')
        }
        if (transaction.special.estimatedPrice && transaction.special.estimatedPrice > 0 && (!transaction.special.actualCosts || transaction.special.actualCosts <= 0)) {
          printer.addText($language.translate('ESTIMATED_PRICE_SPECIAL') + ': €' + transaction.special.estimatedPrice + '\n')
        }
        if (transaction.special.actualCosts && transaction.special.actualCosts > 0) {
          printer.addText($language.translate('TOTAL_AMOUNT_TO_PAY') + ': €' + transaction.special.actualCosts + '\n')
        }
        if (transaction.special && transaction.special.repairNumber && transaction.special.repairNumber !== '' && transaction.special.repairNumber !== '0') {
          printer.addText($language.translate('RECEIPT_NUMBER') + ': ' + transaction.number + '\n')
        }
      }
      printer.addText($language.translate('ASSISTED_BY') + ': ' + transaction.createdBy.name + '\n')

      printer.addFeedLine(2)
      printer.addCut(printer.CUT_FEED)
      openDrawer(printer, null, transaction.receipt.payments, transaction.type)
      printer.send()
    }

    function printRepairSpecialReceipt (transactions, repair, notCustomer) {
      return $q(function (resolve, reject) {
        connect()
          .then(function () {
            var transactionArray = Array.isArray(transactions)
            if(transactionArray === false) {
              if(transactions.type === 'giftcard') {
                printGiftcard(transactions.transactionTotal - transactions.giftCardReedemAmount, transactions.special.repairNumber, transactions.dateCreated)
              } else {
                if($rootScope.currentShop.newPrinter === true || ($rootScope.currentShop.printNode && $rootScope.currentShop.printNode.enabled === true)) {
                  var type = transactions.type === 'order' || transactions.type === 'special' ? 'special-receipt' : 'repair-receipt'
                  if(notCustomer === true && (type === 'special-receipt' || type === 'repair-receipt')) {
                    type += '-partner'
                  }
                  $thermalPrint.printThermal(type,1, transactions)
                } else {
                  printRepairSpecial(transactions, transactions.type === 'repair', notCustomer)
                }
              }
              disconnect()
                .then(function () {
                  return resolve((transactions.type === 'Giftcard' ? 'Giftcard' : 'Repair/Special') + ' printed')
                })
                .catch(function (reason) {
                  return reject(reason)
                })
            } else if(transactionArray === true && transactions.length > 0) {
              var counter = 0
              for(var i = 0; i < transactions.length; i++) {
                if(transactions[i].type === 'giftcard') {
                  printGiftcard(transactions[i].transactionTotal - transactions[i].giftCardReedemAmount, transactions[i].special.repairNumber, transactions[i].dateCreated)
                } else {
                  if($rootScope.currentShop.newPrinter === true || ($rootScope.currentShop.printNode && $rootScope.currentShop.printNode.enabled === true)) {
                    $log.info('Redirecting printjob to thermalprint with JSPM')
                    var type = transactions[i].type === 'order' || transactions[i].type === 'special' ? 'special-receipt' : 'repair-receipt'
                    if(notCustomer === true && (type === 'special-receipt' || type === 'repair-receipt')) {
                      type += '-partner'
                    }
                    $thermalPrint.printThermal(type,1, transactions[i])
                  } else {
                    printRepairSpecial(transactions[i], transactions[i].type === 'repair', transactions[i].notCustomer)
                  }
                }
                counter++
                if (counter === transactions.length) {
                  disconnect()
                    .then(function () {
                      return resolve('Printed')
                    })
                    .catch(function (reason) {
                      return reject(reason)
                    })
                }
              }
            }
          })
      })
    }

    function printRepairSpecialDouble (transaction, repair) {
      return $q(function (resolve, reject) {
        if($rootScope.currentShop.newPrinter === true) {
          $log.info('Redirecting printjob to thermalprint with JSPM')
          var type = repair ? 'repair-receipt' : 'order-receipt'

          return $thermalPrint.printThermal(type,1, transaction)
            .then(function () {
              if(repair) {
                $thermalPrint.printThermal(type+'-partner',1, transaction)
              }
            })
        } else {
          printRepairSpecialReceipt(transaction, repair)
            .then(function (res) {
              if (repair) {
                printRepairSpecialReceipt(transaction, repair, true)
                  .then(function (res) {
                    return resolve(res)
                  })
              } else {
                return resolve(res)
              }
            })
        }
      })
    }

    function drawerReason() {
      return $q(function (resolve, reject) {
        if($rootScope.currentShop.cashRegister.askReasonOpeningDrawer === true) {
          $CashRegistry.openDrawerReasonModal()
            .then(function  () {
              return resolve()
            })
            .catch( function (reason) {
              return reject(reason)
            })
        } else {
          return resolve()
        }
      })
    }

    function openCashDrawer(){
      return $q(function (resolve, reject) {
        // drawerReason()
        //   .then(function () {
        connect()
          .then(function () {
            $log.info('starting opening cashdrawer')
            openDrawer(printer, true)
            printer.send()
            disconnect()
              .then(function () {
                return resolve('Openend')
              })
              .catch(function (reason) {
                return reject(reason)
              })
          })
        // })
        // .catch(function (reason){
        //   return reject(reason)
        // })
      })
    }

    function bulkPrintTransactions (transactions, mode) {
      return $q(function (resolve, reject) {
        if(!transactions || transactions.length === 0) {
          return reject('NO_TRANSACTIONS_TO_PRINT')
        }
        if(!mode || mode  === '') {
          mode = 'both'
        }
        var notCustomerReceipts = []
        //copy the array and place an notCustomer variable on them for the repairer receipts
        if(mode === 'both' || mode === 'repairer') {
          notCustomerReceipts = angular.copy(transactions)
          notCustomerReceipts = notCustomerReceipts.filter(function (t) {
            return t.type !== 'giftcard'
          })
          notCustomerReceipts = notCustomerReceipts.map(function (t) {
            t.notCustomer = true
            return t
          })
        }
        if(mode === 'both') {
          transactions = transactions.concat(notCustomerReceipts)
        } else if(mode === 'repairer') {
          transactions = notCustomerReceipts
        }

        var ids = transactions.filter(function (t) {
          return typeof t === 'string'
        })

        if(ids.length > 0) {
          $api.post('transactions/multiple', {
            transactions: transactions,
            shopId: $rootScope.currentShop._id
          }, null, 2)
            .then(function (result) {
              sendTransactionsToPrinter(result.data.transactions, mode)
            })
            .catch(function (reason) {
              console.error('Error while fetching details for transactions', reason)
            })
        } else {
          sendTransactionsToPrinter(transactions, mode)
        }

        function sendTransactionsToPrinter(transactions, mode) {

          var groupedTransactions = _.groupBy(transactions, 'number')
          for(var key in groupedTransactions) {
            if(groupedTransactions[key].length > 1 || mode === 'repairer') {
              groupedTransactions[key][groupedTransactions[key].length -1].notCustomer = true
            }
          }

          if($rootScope.currentShop.newPrinter === true) {
            $log.info('Redirecting printjob to thermalprint with JSPM')
            return $thermalPrint.bulkPrintThermalTransactions(transactions)
          }else {
            return printRepairSpecialReceipt(transactions)
          }
        }
      })
    }

    function openSelectPrinterModal (data) {
      return $q(function (resolve, reject) {
        var printerModal = $uibModal.open({
          templateUrl: '../views/modal/select-printer-modal.html',
          controller: 'selectPrinterModalController',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            data: function () {
              return data;
            }
          }
        })
        printerModal.result.then(function (result) {
          return resolve(result)
        }, function (err) {
          //dismissed
          return reject(err)
        })
      })
    }

    return {
      connect: connect,
      disconnect: disconnect,
      checkEnabled: checkEnabled,
      printShopReceipt: printShopReceipt,
      printDayClosure: printDayClosure,
      printPinTicket: printPinTicket,
      printTakenMoneyReceipt: printTakenMoneyReceipt,
      getPageDefinition: getPageDefinition,
      printRepairSpecialReceipt: printRepairSpecialReceipt,
      printRepairSpecialDouble: printRepairSpecialDouble,
      openCashDrawer: openCashDrawer,
      printGiftcard: printGiftcard,
      bulkPrintTransactions: bulkPrintTransactions,
      openSelectPrinterModal: openSelectPrinterModal
    }
  }])
