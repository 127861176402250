prismanoteApp.service('$cart', ['$q', '$api', function ($q, $api) {
  var get = function (force) {
    return $q(function (resolve, reject) {
      if (!force) {
        force = false
      }
      $api.get('cart', { force: force })

        .then(function (response) {
          resolve(response.data.cart || {})
        })

        .catch(function (reason) {
          reject((reason || 'The saved shopping cart could not be retrieved from the server-side session for an unknown reason.'))
        })
    })
  }

  var save = function (cart) {
    return $q(function (resolve, reject) {
      $api.post('cart', { cart: cart })

        .then(function (response) {
          resolve(response.data.cart)
        })

        .catch(function (reason) {
          reject((reason || 'Saving the shopping cart to a server-side session has failed for an unknown reason.'))
        })
    })
  }

  var empty = function () {
    return $q(function (resolve, reject) {
      $api.get('empty-cart')
        .then(function (response) {
          resolve(response.data.cart)
        })
        .catch(function (reason) {
          console.error(reason)
          reject(reason)
        })
    })
  }

  var getShops = function (cart) {
    return $q(function (resolve, reject) {
      var shops = {}

      // internal method to group the cart items by their respective shops
      var groupByShop = function (cart) {
        // Create a new shop item for each unique shop and add the corresponding items to it
        for (var i = 0; i < cart.length; i++) {
          if (!shops[cart[i].shop.nameSlug]) {
            shops[cart[i].shop.nameSlug] = { name: cart[i].shop.name, items: [] }
          }

          if (shops[cart[i].shop.nameSlug].items.indexOf(cart[i]) == -1) {
            shops[cart[i].shop.nameSlug].items.push(cart[i])
          }
        }
        resolve(shops)
      }

      // Use optional param 'cart' if provided, otherwise try to get the saved cart from the server-side session.
      if (cart != null) {
        if (Array.isArray(cart)) {
          groupByShop(cart)
        } else {
          reject('Cart is not an array. Cart could not be updated (grouped by shop).')
        }
      } else {
        get()

          .then(function (cart) {
            groupByShop(cart)
          })

          .catch(function (reason) {
            reject(reason)
          })
      }
    })
  }

  var add = function (item, quantity, cart) {
    return $q(function (resolve, reject) {
      var cartContains = function (item) {
        var duplicates = cart.find(function (cartItem) {
          return item.shop.nameSlug == cartItem.shop.nameSlug && item.nameSlug == cartItem.nameSlug
        })

        if (duplicates == null) {
          return false
        } else {
          return true
        }
      }

      var cartFindIndex = function (item) {
        return cart.findIndex(function (cartItem) {
          return item.shop.nameSlug == cartItem.shop.nameSlug && item.nameSlug == cartItem.nameSlug
        })
      }

      // Internal method
      var addToCart = function (cart) {
        item.quantity = quantity
        item.tax = item.price * 0.21

        if (cartContains(item)) {
          increaseQuantity(cartFindIndex(item), quantity, cart)

            .catch(function (reason) {
              reject(reason)
            })
        } else {
          cart.push(item)
        }

        save(cart)

          .then(function (cart) {
            resolve(cart)
          })

          .catch(function (reason) {
            reject(reason)
          })
      }

      // Validate optional parameter 'quantity' and default to 1 if invalid or omitted.
      if (quantity == null || typeof quantity !== 'number') {
        quantity = 1
      }

      if (item != null) {
        if (typeof item === 'object') {
          // Use optional param 'cart' if provided, otherwise try to get the saved cart from the server-side session.
          if (cart != null && Array.isArray(cart)) {
            addToCart(cart)
          } else {
            get()

              .then(function (cart) {
                addToCart(cart)
              })

              .catch(function (reason) {
                reject(reason)
              })
          }
        } else {
          reject('The provided item param (product to be added to the shopping cart) is not an object.')
        }
      } else {
        reject('No item param (product to be added to the shopping cart) has been provided or it has no value.')
      }
    })
  }

  var remove = function (index, cart) {
    return $q(function (resolve, reject) {
      // Internal method
      var removeFromCart = function (index, cart) {
        if (cart[index]) {
          cart.splice(index, 1)

          save(cart)

            .then(function (cart) {
              resolve(cart)
            })

            .catch(function (reason) {
              reject('[remove] ' + reason)
            })
        }
      }

      if (index != null && typeof index === 'number') {
        // Use optional param 'cart' if provided, otherwise try to get the saved cart from the server-side session.
        if (cart != null && Array.isArray(cart)) {
          removeFromCart(index, cart)
        } else {
          get()

            .then(function (cart) {
              removeFromCart(index, cart)
            })

            .catch(function (reason) {
              reject(reason)
            })
        }
      } else {
        reject('Index of the item to be removed is invalid or has not been provided at all.')
      }
    })
  }

  var decreaseQuantity = function (index, quantity, cart) {
    return $q(function (resolve, reject) {
      // Internal method
      var decrease = function (cart) {
        if (cart[index] != null) {
          if (cart[index].quantity > quantity) {
            cart[index].quantity -= quantity
            cart[index].tax = (cart[index].price * cart[index].quantity) * 0.21

            save(cart)

              .then(function (cart) {
                resolve(cart)
              })

              .catch(function (reason) {
                reject(reason)
              })
          } else {
            remove(index, cart)

              .then(function (cart) {
                resolve(cart)
              })

              .catch(function (reason) {
                reject(reason)
              })
          }
        } else {
          reject('No such item found in the shopping cart.')
        }
      }

      // Validate optional parameter 'quantity' and default to 1 if invalid or omitted.
      if (quantity == null || typeof quantity !== 'number' || quantity < 1) {
        quantity = 1
      }

      if (index != null && typeof index === 'number') {
        // Use optional param 'cart' if provided, otherwise try to get the saved cart from the server-side session.
        if (cart != null && Array.isArray(cart)) {
          decrease(cart)
        } else {
          get()

            .then(function (cart) {
              decrease(cart)
            })

            .catch(function (reason) {
              reject(reason)
            })
        }
      } else {
        reject('Index of the item for which the quantity should be decreased is either invalid or has not been provided at all.')
      }
    })
  }

  var increaseQuantity = function (index, quantity, cart) {
    return $q(function (resolve, reject) {
      // Internal method
      var increase = function (index, cart) {
        if (cart[index] != null) {
          cart[index].quantity += quantity
          cart[index].tax = (cart[index].price * cart[index].quantity) * 0.21

          save(cart)

            .then(function (cart) {
              resolve(cart)
            })

            .catch(function (reason) {
              reject(reason)
            })
        } else {
          reject('No such item found in the shopping cart.')
        }
      }

      // Validate optional parameter 'quantity' and default to 1 if invalid or omitted.
      if (quantity == null || typeof quantity !== 'number' || quantity < 1) {
        quantity = 1
      }

      if (index != null && typeof index === 'number') {
        // Use optional param 'cart' if provided, otherwise try to get the saved cart from the server-side session.
        if (cart != null && Array.isArray(cart)) {
          increase(index, cart)
        } else {
          get()

            .then(function (cart) {
              increase(index, cart)
            })

            .catch(function (reason) {
              reject(reason)
            })
        }
      } else {
        reject('Index of the item for which the quantity should be increased is either invalid or has not been provided at all.')
      }
    })
  }

  var getTotal = function (cart, round) {
    if (round == null || typeof round === 'undefined') {
      round = true
    }

    return $q(function (resolve, reject) {
      // Internal method
      var calculateTotal = function (cart, round) {
        var total = 0
        var counter = 0

        cart.forEach(function (item) {
          console.log('price', item.price)
          console.log('quantity', item.quantity)
          console.log('tax', item.tax)
          total += (item.price * item.quantity) + item.tax
          counter++
        })

        if (counter == cart.length) {
          if (round) {
            var rounded = Math.round((total * 100) / 100)
            resolve(rounded)
          } else {
            resolve(total)
          }
        }
      }

      // Use optional param 'cart' if provided, otherwise try to get the saved cart from the server-side session.
      if (cart != null && Array.isArray(cart)) {
        calculateTotal(cart, round)
      } else {
        get()

          .then(function (cart) {
            calculateTotal(cart, round)
          })

          .catch(function (reason) {
            reject(reason)
          })
      }
    })
  }

  this.get = get
  this.save = save
  this.getShops = getShops
  this.add = add
  this.remove = remove
  this.decreaseQuantity = decreaseQuantity
  this.increaseQuantity = increaseQuantity
  this.getTotal = getTotal
  this.empty = empty
}])
