prismanoteApp.service('$wholesaler', ['$rootScope', '$q', '$api', '$state',
  function ($rootScope, $q, $api, $state) {
    // create a named var for the service, because 'this' is problematic when using other vars or functions in this service.
    var wholesalerService = this

    // Store currently active merchant and shop nameSlugs in the service. If set, this will be valid as long as the page is not refreshed. This is useful for running multiple AJAX calls in sequence, i.e. for lazy loading.
    wholesalerService.wholesaler

    var validateNameSlug = function (nameSlug, type) {
      if (nameSlug == null || typeof nameSlug !== 'string') {
        // Try to use the saved merchant or shop (if it has been set before, the nameSlug parameter can be omitted).
        if (wholesalerService[type] != null && typeof wholesalerService[type] === 'string') {
          return wholesalerService[type]
        } else {
          if ($rootScope.user.wholesaler[0]) {
            return $rootScope.user.wholesaler[0].nameSlug
          } else {
            return false
          }
        }
      } else {
        return nameSlug
      }
    }

    var validateParams = function (params) {
      if (params != null && typeof params === 'object') {
        return params
      } else {
        return {}
      }
    }

    var beforeApiRequest = function (options) {
      // Currently unused pre request hook.
    }

    var afterApiRequest = function (options) {
      if (options != null && typeof options === 'object') {
        if (options.redirect != null && typeof options.redirect === 'string') {
          $state.go(options.redirect)
        }
      }
    }

    wholesalerService.getWholesaler = function (nameSlug, params, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'wholesaler')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        var validParams = validateParams(params)

        beforeApiRequest(options)

        $api.get('wholesalers/' + validatedNameSlug, validParams)

          .then(function (response) {
            // Settings like redirect URL
            afterApiRequest(options)

            // Store currently active shop nameSlug
            wholesalerService.wholesaler = response.data.wholesaler.nameSlug

            resolve(response.data.wholesaler)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    wholesalerService.setWholesaler = function (nameSlug, shop, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'wholesaler')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        wholesaler._id = $rootScope.currentWholesaler._id

        $api.update('wholesalers/' + validatedNameSlug, { wholesaler: wholesaler })

          .then(function (response) {
            // Settings like redirect URL
            afterApiRequest(options)

            // Store currently active shop nameSlug
            wholesalerService.wholesaler = response.data.wholesaler.nameSlug

            $rootScope.wholesalerSaved = true

            resolve(response.data.wholesaler)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Wrapper for getShop, returns ONLY the shop brands instead of the default fields (which do not include brands).
    wholesalerService.getWholesalerBrands = function (nameSlug, params, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'wholesaler')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        wholesalerService.getWholesaler(validatedNameSlug, { select: 'wholesaler' })

          .then(function (wholesaler) {
            // Settings like redirect URL
            afterApiRequest(options)

            resolve(wholesaler.brands)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Wrapper for setShop, updates and returns ONLY the shop brands instead of the default fields (which do not include brands).
    wholesalerService.setWholesalerBrands = function (nameSlug, brands, options) {
      return $q(function (resolve, reject) {
        var validatedNameSlug = validateNameSlug(nameSlug, 'wholesalers')
        if (!validatedNameSlug) {
          reject('No nameSlug or invalid nameSlug provided. ')
        }

        wholesalerService.setShop(validatedNameSlug, { brands: brands })

          .then(function (wholesaler) {
            // Settings like redirect URL
            afterApiRequest(options)

            $rootScope.wholesalerSaved = true

            resolve(wholesaler.brands)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }
  }])
