prismanoteApp.service('scanner', ['$rootScope', '$api', '$log', '$q',
  function ($rootScope, $api, $log, $q) {

    // To make this work in your controller, just inject 'scanner' as dependency
    // then place one of the following codes in your controller to grab the data from this service / the scanner

    // Will be triggered when keycodes of the scanner are recognized and scanner is started scanning
    // $scope.$on('scanner-started', function (event, args) { })

    // Will be triggered when scanner is ready with scanning and the service has parsed the data, the parameters contains the parsed and scanned data
    // $scope.$on('scanner-ready', function (event, args) { })

    // Will be triggered when scanner has sent last keycode (after scanner-ready)
    // $scope.$on('scanner-ended', function (event, args) { })

    var barcode = false
    var mobileBarcode = false
    var scannedBarcode = ''

    function parseBarcode (code) {
      return $q(function (resolve, reject) {
        var result = {
          prefix: null,
          type: null,
          parsedCode: null,
          originalCode: code
        }
        var types = [
          {
            prefix: 'T',
            type: 'transaction'
          },
          {
            prefix: 'R',
            type: 'repair'
          },
          {
            prefix: 'G',
            type: 'giftcard'
          },
          {
            prefix: null,
            type: 'other'
          }
        ]
        var char = null
        var parsedBarcode = ''
        var manualCode = code.charAt(1) === '-'
        var charIndex = null

        //parsedBarcode = code.replace('\u0010', '') //old method, only removed first occurence..
        parsedBarcode = code.replace(/\u0010/g, '')

        if ((typeof parsedBarcode.charAt(0) === 'string' || typeof parsedBarcode.charAt(1) === 'string') && (isNaN(parseFloat(parsedBarcode.charAt(0))) || isNaN(parseFloat(parsedBarcode.charAt(1))))) {
          // parse the barcode by removing some chars (strange char, the T and the dash after it)
          var firstChar = 0
          var secondChar = 1
          var substr_start = 2

          // We found an string at the start of the code, now we should check if that is a known type
          // First check the second one, then the second char
          if (parsedBarcode.charAt(firstChar) && parsedBarcode.charAt(0) !== '\u0090') {
            char = parsedBarcode.charAt(firstChar).toUpperCase()
          } else {
            char = parsedBarcode.charAt(secondChar).toUpperCase()
            substr_start = 3
          }

          charIndex = _.findIndex(types, { prefix: char })

          if(charIndex >= 0) {
            parsedBarcode = parsedBarcode.substring(substr_start)
            if(types[charIndex].type === 'giftcard') {
              parsedBarcode.replace(/\W/g, '')
            }
          }
        }

        var index = charIndex ? charIndex : _.findIndex(types, { prefix: char })
        if (index >= 0) {
          var type = types[index]
          result.prefix = type.prefix
          result.type = type.type
          result.parsedCode = parsedBarcode.toString()
          if(!manualCode) {
            $rootScope.$broadcast('scanner-ready', result)
          }
          return resolve(result)
        } else {
          result.parsedCode = parsedBarcode.toString()
          result.type = 'other'
          if(!manualCode) {
            $rootScope.$broadcast('scanner-ready', result)
          }
          return reject(result)
        }
      })
    }

    function KeyPress (e) {
      var evtobj = e || window.event

      if ($rootScope.mobile || $rootScope.tablet) {
        if (evtobj.keyCode === 0 || evtobj.keyCode === 27) {
          $rootScope.$broadcast('scanner-started')
          scannedBarcode = ''
          mobileBarcode = true
          barcode = true
          return
        }
      }

      if (mobileBarcode && (evtobj.keyCode === 48 || evtobj.keyCode === 50 || evtobj.keyCode === 0 || evtobj.keyCode === 27)) {
        return
      }

      if ((evtobj.keyCode === 66 && evtobj.ctrlKey) || (evtobj.altKey && (evtobj.keyCode === 96 || evtobj.keyCode === 98))) {
        scannedBarcode = ''
        evtobj.stopPropagation()
        evtobj.preventDefault()
        evtobj.cancelBubble = true
        barcode = true
        $rootScope.$broadcast('scanner-started')
        return
      }

      if (barcode) {
        if (evtobj.keyCode === 13) {
          barcode = false
          evtobj.cancelBubble = false
          $rootScope.$broadcast('scanner-ended')

          parseBarcode(scannedBarcode)
            .then(function (result) {

            })
            .catch(function (result) {
              $log.warning('Error while parsing barcode, type not found. Result is:', result)
            })
        } else {
          var fromChar = ''
          if (evtobj.keyCode === 173 || evtobj.keyCode === 189) {
            fromChar = '-'
          } else {
            fromChar = String.fromCharCode(evtobj.keyCode)
          }
          scannedBarcode = scannedBarcode + fromChar
        }
      }
    }
    document.onkeydown = KeyPress

    return {
      parseBarcode: parseBarcode
    }
  }])
