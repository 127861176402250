prismanoteApp.service('$clientGroup', ['$q', '$api', 'Upload', function ($q, $api, Upload) {
  function getOwnClientGroups (companyId) {
    return $q(function (resolve, reject) {
      $api.get('v2/company/client-groups/' + companyId)
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  function saveNewClientGroup (newClientGroup) {
    return $q(function (resolve, reject) {
      $api.post('v2/company/client-groups/', newClientGroup)
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  function updateClientGroup (body) {
    return $q(function (resolve, reject) {
      $api.put('v2/company/client-groups/', body)
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  function addShopInClientGroup (clientGroupId, shopId, companyId) {
    return $q(function (resolve, reject) {
      var clientGroupBody = {
        clientGroupId: clientGroupId,
        shopId: shopId,
        companyId: companyId
      }
      $api.put('v2/company/client-groups/shop-blocked/' + clientGroupId + '/' + shopId, clientGroupBody)
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  function getShopClientGroups (shopId, companyId) {
    return $q(function (resolve, reject) {
      $api.get('v2/shop/client-groups/' + shopId + '/' + companyId)
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  function removeShopFromClientGroups (shopId, clientGroupId, companyId) {
    return $q(function (resolve, reject) {
      $api.delete('v2/company/client-groups/shop-blocked/' + clientGroupId + '/' + shopId, { companyId: companyId })
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  function getNoShopInClientGroupForBlock (companyId, shopId) {
    return $q(function (resolve, reject) {
      $api.get('v2/company/client-groups/shop-blocked/' + companyId + '/' + shopId)
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          console.error('Error While Getting Ean valid', reason)
          return reject(reason.data)
        })
    })
  }

  function removeClientGroups (clientGroupId, companyId) {
    return $q(function (resolve, reject) {
      $api.delete('v2/company/shop/client-groups/' + clientGroupId, { companyId: companyId })
        .then(function (res) {
          return resolve(res.data)
        })
        .catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function clientGroupsNameUnique (name, clientGroupId, companyId) {
    return $q(function (resolve, reject) {
      $api.get('v2/client-groups/name-unique/' + name + '/' + clientGroupId + '/' + companyId)
        .then(function (res) {
          console.log('%res&', res)
          return resolve(res.data.data)
        })
        .catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function getShopClientGroupsByCompany (shopId, companyId) {
    return $q(function (resolve, reject) {
      $api.get('v2/company/client-group-by-company', { shopId: shopId, companyId: companyId })
        .then(function (res) {
          // console.log("%res&", res);
          return resolve(res.data.data)
        })
        .catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function getShopClientGroupsByCompanyNameSlug (shopId, companyNameSlug) {
    return $q(function (resolve, reject) {
      $api.get('v2/client-group-by-company-nameslug', { shopId: shopId, companyNameSlug: companyNameSlug })
        .then(function (res) {
          // console.log("%res&", res);
          return resolve(res.data.data)
        })
        .catch(function (reason) {
          return reject(reason.data)
        })
    })
  }

  function fetchCompnayClientGroupCollection(body) {
    return $api.get('v2/wholesaler/company-clientgroup-collectioins', body)
  }

  function addProductIdToClientGroup(body) {
    return $api.post('v2/company/add-product-to-clientgroup', body)
  }

  return {
    getOwnClientGroups: getOwnClientGroups,
    saveNewClientGroup: saveNewClientGroup,
    addShopInClientGroup: addShopInClientGroup,
    getShopClientGroups: getShopClientGroups,
    removeShopFromClientGroups: removeShopFromClientGroups,
    getNoShopInClientGroupForBlock: getNoShopInClientGroupForBlock,
    removeClientGroups: removeClientGroups,
    clientGroupsNameUnique: clientGroupsNameUnique,
    getShopClientGroupsByCompany: getShopClientGroupsByCompany,
    updateClientGroup: updateClientGroup,
    getShopClientGroupsByCompanyNameSlug: getShopClientGroupsByCompanyNameSlug,
    fetchCompnayClientGroupCollection: fetchCompnayClientGroupCollection,
    addProductIdToClientGroup: addProductIdToClientGroup
  }
}])
