prismanoteApp.service('$recommendationOrder', ['$api',
  function ($api) {
    function getRetailerRecommendationPurchaseOrder(body) {
      return $api.get('v2/retailer/recommendation-purchase-order', body)
    }

    function getBrandRetailerRecommendationPurchaseOrder(body) {
      return $api.get('v2/brand/recommendation-purchase-order', body)
    }

    function deletePurchaseOrderProduct(body) {
      return $api.post('v2/delete-purchaseorder-product', body)
    }

    function changeQuantityOfPurchaseOrder(body) {
      return $api.post('v2/change-quantity-of-purchaseorder', body)
    }

    function addProductToPurchaseOrderFromRecommendation(body) {
      return $api.post('v2/retailer/recommendation-purchase-order-product', body)
    }

    function searchProductsForRetailerRecommnedation(body) {
      return $api.get('v2/retailer/recommendation-products-search', body)
    }

    function searchProductsForCompanyRecommnedation(body) {
      return $api.get('v2/brand/recommendation-products-search', body)
    }

    function getShopCompanies(body) {
      return $api.get('shop-companies-by-latestsuggestion', body)
    }

    function createNewPurchaseOrderUsingRecommendation(body) {
      console.log('-------------- createNewPurchaseOrderUsingRecommendation!!');
      console.log(body);
      return $api.post('v2/purchase-order-from-recommendation', body);
    }

    function fetchCompanyDetail(body) {
      return $api.get('v2/retailer/companydetail-for-recommendation', body)
    }

    function getCollectionsFilterForRecommendation(body) {
      return $api.get('v2/retailer/collectionfilter-for-recommendation', body)
    }

    function getCompanyProductsRecommendation(body) {
      return $api.get('v2/retailer/company-recommendation-product', body)
    }

    function getCompanyBRANDProductsRecommendation(body) {
      return $api.get('v2/retailer/brand-recommendation-product', body)
    }

    function getDynamicRecommendationFilter(body) {
      return $api.get('v2/retailer/recommendation-dynamic-filter', body)
    }

    function getPurchaseOrderDetail(body) {
      return $api.get('v2/retailer/recommendation-purchase-order-product', body)
    }

    function getDefaultFilterValue() {
      var filterVar = {
        category: {
          watch: false,
          jewel: false,
          strap: false,
          diamond: false,
          other: false
        },
        suppliers: [],
        gender: {
          male: false,
          female: false,
          boys: false,
          girls: false
        },
        brands: [],
        strapWidths: [
          {
            width: 6,
            isSelected: false
          }, {
            width: 7,
            isSelected: false
          }, {
            width: 8,
            isSelected: false
          }, {
            width: 9,
            isSelected: false
          }, {
            width: 10,
            isSelected: false
          }, {
            width: 11,
            isSelected: false
          },
          {
            width: 12,
            isSelected: false
          },
          {
            width: 13,
            isSelected: false
          },
          {
            width: 14,
            isSelected: false
          },
          {
            width: 15,
            isSelected: false
          },
          {
            width: 16,
            isSelected: false
          },
          {
            width: 17,
            isSelected: false
          },
          {
            width: 18,
            isSelected: false
          },
          {
            width: 19,
            isSelected: false
          },
          {
            width: 20,
            isSelected: false
          },
          {
            width: 21,
            isSelected: false
          }, {
            width: 22,
            isSelected: false
          },
          {
            width: 23,
            isSelected: false
          },
          {
            width: 24,
            isSelected: false
          },
          {
            width: 25,
            isSelected: false
          },
          {
            width: 26,
            isSelected: false
          },
          {
            width: 27,
            isSelected: false
          },
          {
            width: 28,
            isSelected: false
          },
          {
            width: 29,
            isSelected: false
          },
          {
            width: 30,
            isSelected: false
          },
          {
            width: 31,
            isSelected: false
          },
          {
            width: 32,
            isSelected: false
          },
          {
            width: 33,
            isSelected: false
          },
          {
            width: 34,
            isSelected: false
          },
          {
            width: 35,
            isSelected: false
          },
          {
            width: 36,
            isSelected: false
          },
          {
            width: 37,
            isSelected: false
          },
          {
            width: 38,
            isSelected: false
          },
        ],
        jewelTypes: [
          {
            type: 'BROOCH',
            isSelected: false
          },
          {
            type: 'CHOKER',
            isSelected: false
          },
          {
            type: 'PENDANT',
            isSelected: false
          },
          {
            type: 'NECKLACE',
            isSelected: false
          },
          {
            type: 'TENNIS_NECKLACE',
            isSelected: false
          },
          {
            type: 'STUD_EARRINGS',
            isSelected: false
          },
          {
            type: 'HOOP_EARRINGS',
            isSelected: false
          },
          {
            type: 'CREOLE_EARRINGS',
            isSelected: false
          },
          {
            type: 'RING',
            isSelected: false
          },
          {
            type: 'COMBINATION_RING',
            isSelected: false
          },
          {
            type: 'RING_WITH_PEARL',
            isSelected: false
          },
          {
            type: 'RING_WITH_GEM',
            isSelected: false
          },
          {
            type: 'SET',
            isSelected: false
          },
          {
            type: 'BRACELET',
            isSelected: false
          },
          {
            type: 'TENNIS_BRACELET',
            isSelected: false
          },
          {
            type: 'SLAVE_BRACELET',
            isSelected: false
          },
        ],
        jewelMaterials: [
          {
            type: 'BROOCH',
            isSelected: false
          },
          {
            type: 'CHOKER',
            isSelected: false
          },
          {
            type: 'PENDANT',
            isSelected: false
          },
          {
            type: 'STAINLESS_STEEL',
            isSelected: false
          },
          {
            type: 'REAL_LEATHER',
            isSelected: false
          },
          {
            type: 'GOLD',
            isSelected: false
          },
          {
            type: 'SILVER',
            isSelected: false
          },
          {
            type: 'TITANIUM',
            isSelected: false
          }
        ],
        diamondMaterials: [
          {
            type: 'diamond',
            isSelected: false
          },
          {
            type: 'moonstone',
            isSelected: false
          }
        ],
        watchMaterials: [
          {
            type: 'STAINLESS_STEEL',
            isSelected: false
          },
          {
            type: 'REAL_LEATHER',
            isSelected: false
          },
          {
            type: 'GOLD',
            isSelected: false
          },
          {
            type: 'SILVER',
            isSelected: false
          },
          {
            type: 'TITANIUM',
            isSelected: false
          }
        ],
        strapModels: [
          {
            model: 'STANDARD_MODEL',
            isSelected: false
          },
          {
            model: 'SELECT_BAND',
            isSelected: false
          },
          {
            model: 'MILANESE_MESH',
            isSelected: false
          },
          {
            model: 'NATO',
            isSelected: false
          },
          {
            model: 'CHAIN_OF_A_POCKET_WATCH',
            isSelected: false
          },
          {
            model: 'EXPANDABLE_STRETCH_STRAP',
            isSelected: false
          },
          {
            model: 'SELECT_BAND_FOR_POCKET_WATCH',
            isSelected: false
          },
        ],
        privateLabelCollections: [],
        clientGroupCollections: [],
        selectedPrivateLabelCollections: [],
        selectedclientGroupCollections: [],
        limit: 30, // Limit which we have to show
        offset: 0, // Page number
        selectedSupplierId: '',
      };

      return filterVar;
    }

    function getFiltersForCustomFilter() {
      var filter = {
        watchCaseMaterials: [
          {
            material: 'STAINLESS_STEEL',
            isSelected: false
          },
          {
            material: 'TITANIUM',
            isSelected: false
          }, {
            material: 'ALUMINIUM',
            isSelected: false
          }, {
            material: 'METAL',
            isSelected: false
          }, {
            material: 'PLASTIC_SILICONE',
            isSelected: false
          }, {
            material: 'CERAMICS',
            isSelected: false
          }, {
            material: 'WOOD',
            isSelected: false
          }, {
            material: 'GOLD',
            isSelected: false
          }, {
            material: 'PALLADIUM',
            isSelected: false
          },
        ],
        watchStrapMaterails: [
          {
            material: 'REAL_LEATHER',
            isSelected: false
          },
          {
            material: 'PU_LEATHER',
            isSelected: false
          }, {
            material: 'STAINLESS_STEEL',
            isSelected: false
          }, {
            material: 'TITANIUM',
            isSelected: false
          }, {
            material: 'ALUMINIUM',
            isSelected: false
          }, {
            material: 'METAL',
            isSelected: false
          }, {
            material: 'PLASTIC_SILICONE',
            isSelected: false
          }, {
            material: 'CANVAS',
            isSelected: false
          }, {
            material: 'CERAMICS',
            isSelected: false
          }, {
            material: 'GOLD',
            isSelected: false
          }, {
            material: 'PALLADIUM',
            isSelected: false
          },
        ],
        watchTypes: [
          {
            type: 'WRITS',
            isSelected: false
          },
          {
            type: 'POCKET',
            isSelected: false
          }, {
            type: 'NURSE',
            isSelected: false
          }, {
            type: 'SMARTWATCH',
            isSelected: false
          },
        ],
        watchIndications: [
          {
            indication: 'CHRONO_MULTI',
            isSelected: false
          },
          {
            indication: 'ANALOG',
            isSelected: false
          }, {
            indication: 'DIGITAL',
            isSelected: false
          }, {
            indication: 'ANALOG_DIGITAL',
            isSelected: false
          },
        ],
        companyProductProperties: {
          discount: false, // If discount is set to 0 (numeric, not null/empty), then this item is not on sale
          isBestseller: false,
          isFeatured: false
        }
      }

      return filter;
    }

    function searchCompanies(str, shopCompanies) {
      var matches = [];
      shopCompanies.forEach(function (item) {
        if (item && ((item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
          (item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
          matches.push(item);
        }
      });
      return matches;
    }

    function searchShops(str, shops) {
      var foundShops = [];
      shops.forEach(function (item) {
        if (item && (
          (item.companyShop && item.companyShop.name && item.companyShop.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
          (item.companyShop && item.companyShop.nameSlug && item.companyShop.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0) ||
          (item.companyShop && item.companyShop.email && item.companyShop.email.toLowerCase().indexOf(str.toString().toLowerCase()) > 0) ||

          (item.name && item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
          (item.nameSlug && item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0) ||
          (item.email && item.email.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
        )) {
          foundShops.push(item);
        }
      });

      return foundShops;
    }

    function removeFilter(scopefilter, filtertype, key, index) {
      if (filtertype === 'category') {
        scopefilter.category[key] = false;
      } else if (filtertype === 'gender') {
        scopefilter.gender[key] = false;
      } else if (filtertype === 'brands') {
        // console.log('scopefilter.brands: ', index, scopefilter.brands[index]);
        scopefilter.brands[index].isSelected = false;
      } else if (filtertype === 'jewelTypes') {
        scopefilter.jewelTypes[key] = false;
      } else if (filtertype === 'strapModels') {
        scopefilter.strapModels[key] = false;
      } else if (filtertype === 'watchCaseMaterials') {
        scopefilter.watchCaseMaterials[key] = false;
      } else if (filtertype === 'watchStrapMaterails') {
        scopefilter.watchStrapMaterails[key] = false;
      } else if (filtertype === 'watchTypes') {
        scopefilter.watchTypes[key] = false;
      } else if (filtertype === 'watchIndications') {
        scopefilter.watchIndications[key] = false;
      } else if (filtertype === 'companyProductProperties') {
        scopefilter.companyProductProperties[key] = false;
      } else if (filtertype === 'clientGroupCollections') {
        scopefilter.clientGroupCollections[index].isSelected = false;
        var selectedclientGroupCollections = scopefilter.clientGroupCollections.filter(function (el) {
          return el.isSelected;
        }).map(function (ele) {
          return ele._id;
        });
        scopefilter.selectedclientGroupCollections = selectedclientGroupCollections;
      } else if (filtertype === 'privateLabelCollections') {
        scopefilter.privateLabelCollections[index].isSelected = false;
        var selectedPrivateLabelCollections = scopefilter.privateLabelCollections.filter(function (el) {
          return el.isSelected;
        }).map(function (ele) {
          return ele._id;
        });
        scopefilter.selectedPrivateLabelCollections = selectedPrivateLabelCollections;
      }

      return scopefilter;
    }

    function brandRemoveFilter(scopefilter, filtertype, key, index) {
      console.log('brandRemoveFilter called: ', scopefilter, filtertype, index, key);
      if (filtertype === 'category') {
        console.log('brandRemoveFilter category: ', scopefilter.category[index]);
        scopefilter.category[index].isSelected = false;
      } else if (filtertype === 'gender') {
        scopefilter.gender[index].isSelected = false;
      } else if (filtertype === 'brands') {
        // console.log('scopefilter.brands: ', index, scopefilter.brands[index]);
        scopefilter.brands[index].isSelected = false;
      } else if (filtertype === 'jewelTypes') {
        scopefilter.jewelTypes[index].isSelected = false;
      } else if (filtertype === 'strapModels') {
        scopefilter.strapModels[index].isSelected = false;
      } else if (filtertype === 'strapWidths') {
        scopefilter.strapWidths[index].isSelected = false;
      } else if (filtertype === 'watchCaseMaterials') {
        scopefilter.watchCaseMaterials[index].isSelected = false;
      } else if (filtertype === 'watchStrapMaterails') {
        scopefilter.watchStrapMaterails[index].isSelected = false;
      } else if (filtertype === 'watchTypes') {
        scopefilter.watchTypes[index].isSelected = false;
      } else if (filtertype === 'watchIndications') {
        scopefilter.watchIndications[index].isSelected = false;
      } else if (filtertype === 'companyProductProperties') {
        scopefilter.companyProductProperties[index].isSelected = false;
      }

      return scopefilter;
    }

    function getSelectedClientGroupForCompanySettings(originalclientGroup, companyclientGroup) {
      for (var i = 0; i < companyclientGroup.length; i++) {
        for (var j = 0; j < originalclientGroup.length; j++) {
          if (companyclientGroup[i] === originalclientGroup[j]._id) {
            originalclientGroup[j].isSelected = true;
          }
        }
      }

      return originalclientGroup;
    }

    function getSelectedBrandsForCompanySettings(originalBrands, companyBrands) {
      for (var i = 0; i < companyBrands.length; i++) {
        for (var j = 0; j < originalBrands.length; j++) {
          if (companyBrands[i] && companyBrands[i]._id && companyBrands[i].isSelected && originalBrands[j] && originalBrands[j]._id &&
            companyBrands[i]._id === originalBrands[j]._id) {
            originalBrands[j].isSelected = true;
            // console.log(i, j, companyBrands[i], originalBrands[j]);
          }
        }
      }

      return originalBrands;
    }

    function getTheSelectedFilterForTheCompanySettings(originalFilter, companyFilter) {
      // console.log('getTheSelectedFilterForTheCompanySettings called: ', companyFilter);

      companyFilter.clientGroupCollections = getSelectedClientGroupForCompanySettings(originalFilter.clientGroupCollections, companyFilter.selectedclientGroupCollections);
      companyFilter.brands = getSelectedBrandsForCompanySettings(originalFilter.brands, companyFilter.brands);

      companyFilter.selectedclientGroupCollections = [];


      return companyFilter;
    }

    function getFilterForUpdateCompanySettings(filter) {
      if (filter.searchQuery.isSearch) {
        filter.filter = undefined;
      } else {
        filter.searchQuery.searchValue = undefined;

        var companyFilter = filter.filter;
        var selectedclientGroupCollections = companyFilter.clientGroupCollections.filter(function (el) {
          return el.isSelected;
        }).map(function (ele) {
          return ele._id;
        });
        companyFilter.clientGroupCollections = [];
        companyFilter.selectedclientGroupCollections = selectedclientGroupCollections;
        filter.filter = companyFilter;
      }
      return filter;
      // console.log('HI: companyFilter.selectedclientGroupCollections: ', companyFilter.selectedclientGroupCollections);
    }

    return {
      getRetailerRecommendationPurchaseOrder: getRetailerRecommendationPurchaseOrder,
      deletePurchaseOrderProduct: deletePurchaseOrderProduct,
      changeQuantityOfPurchaseOrder: changeQuantityOfPurchaseOrder,
      addProductToPurchaseOrderFromRecommendation: addProductToPurchaseOrderFromRecommendation,
      getBrandRetailerRecommendationPurchaseOrder: getBrandRetailerRecommendationPurchaseOrder,
      searchProductsForRetailerRecommnedation: searchProductsForRetailerRecommnedation,
      searchProductsForCompanyRecommnedation: searchProductsForCompanyRecommnedation,
      getDefaultFilterValue: getDefaultFilterValue,
      getShopCompanies: getShopCompanies,
      searchCompanies: searchCompanies,
      createNewPurchaseOrderUsingRecommendation: createNewPurchaseOrderUsingRecommendation,
      fetchCompanyDetail: fetchCompanyDetail,
      searchShops: searchShops,
      getCollectionsFilterForRecommendation: getCollectionsFilterForRecommendation,
      removeFilter: removeFilter,
      getTheSelectedFilterForTheCompanySettings: getTheSelectedFilterForTheCompanySettings,
      getFilterForUpdateCompanySettings: getFilterForUpdateCompanySettings,
      getFiltersForCustomFilter: getFiltersForCustomFilter,
      getCompanyProductsRecommendation: getCompanyProductsRecommendation,
      getPurchaseOrderDetail: getPurchaseOrderDetail,
      getCompanyBRANDProductsRecommendation: getCompanyBRANDProductsRecommendation,
      getDynamicRecommendationFilter: getDynamicRecommendationFilter,
      brandRemoveFilter: brandRemoveFilter
      // getOriginalFiltersForTheCompanySettings: getOriginalFiltersForTheCompanySettings,
      // getTheManipulatedFilterForCompanySettings: getTheManipulatedFilterForCompanySettings
    }
  }])



/*   function getOriginalFiltersForTheCompanySettings() {
    var originalFilters = getDefaultFilterValue();

    // Removing which are not required
    originalFilters.suppliers = undefined;
    originalFilters.privateLabelCollections = undefined;
    originalFilters.clientGroupCollections = undefined;
    originalFilters.limit = undefined;
    originalFilters.offset = undefined;
    originalFilters.selectedSupplierId = undefined;

    return originalFilters;
  }

  function getStrapWidth(originalStrapWidth, companyStrapWidth) {
    for (var i = 0; i < companyStrapWidth.length; i++) {
      for (var j = 0; j < originalStrapWidth.length; j++) {
        if (companyStrapWidth[i].width === originalStrapWidth[j].width) {
          originalStrapWidth[j].isSelected = true;
        }
      }
    }

    return originalStrapWidth;
  }

  function getjewelTypes(originaljewelTypes, companyjewelTypes) {
    for (var i = 0; i < companyjewelTypes.length; i++) {
      for (var j = 0; j < originaljewelTypes.length; j++) {
        if (companyjewelTypes[i].type === originaljewelTypes[j].type) {
          originaljewelTypes[j].isSelected = true;
        }
      }
    }

    return originaljewelTypes;
  }

  function getstrapModels(originalstrapModels, companystrapModels) {
    for (var i = 0; i < companystrapModels.length; i++) {
      for (var j = 0; j < originalstrapModels.length; j++) {
        if (companystrapModels[i].model === originalstrapModels[j].model) {
          originalstrapModels[j].isSelected = true;
        }
      }
    }

    return originalstrapModels;
  }

  function getTheManipulatedFilterForCompanySettings(filter) {
    // filter.strapWidths = filter.strapWidths.filter(function (el) { return el.isSelected })
    // filter.jewelTypes = filter.jewelTypes.filter(function (el) { return el.isSelected })
    // filter.strapModels = filter.strapModels.filter(function (el) { return el.isSelected })
    filter.brands = filter.brands.filter(function (el) { return el.isSelected })
    return filter;
  } */