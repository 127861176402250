prismanoteApp.service('$defaultEmail', ['$q', '$api', '$uibModal', '$log', '$rootScope', '$customer', '$language', function ($q, $api, $uibModal, $log, $rootScope, $customer, $language) {

  function getEmail(emailId) {
    return $q(function (resolve, reject) {
      $api.get('default-email/' + $rootScope.currentShop._id +  (emailId ? '/' + emailId : ''))
        .then( function (response) {
          return resolve(response.data.email)
        })
        .catch (function (reason) {
          return reject(reason)
        })
    })
  }

  function createOrUpdateEmail (email) {
    return $q(function(resolve, reject) {
      if(!email.shop) {
        email.shop = $rootScope.currentShop._id
      }
      $api.post('default-email/' + $rootScope.currentShop._id, {
        email: email
      })
        .then(function (res) {
          return resolve(res.message)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  function deleteEmail(id) {
    return $q(function (resolve, reject) {
      $api.delete('default-email/' + $rootScope.currentShop._id +'/' + id)
        .then(function(){
          return resolve('EMAIL_DELETED')
        })
        .catch(function (reason){
          return reject(reason)
        })
    })
  }

  function replacePlaceholder (email, customer) {
    if(!email || !customer) {
      return
    }

    var salutation = customer.salutation ? $language.translate(customer.salutation) : ''
    email = email.replace('[[salutation]]', salutation)
    var firstName = customer.firstName ? customer.firstName: ''
    email = email.replace('[[firstName]]', firstName)
    var prefix = customer.prefix ? customer.prefix : ''
    email = email.replace('[[prefix]]', prefix)
    var lastName = customer.lastName ? customer.lastName : ''
    email = email.replace('[[lastName]]', lastName)

    var fullName = $customer.makeCustomerName(customer)
    email = email.replace('[[fullName]]', fullName)
    var fullAddress = ''
    if(customer.invoiceAddress) {
      if(customer.invoiceAddress.street) {
        fullAddress += customer.invoiceAddress.street
      }
      if(customer.invoiceAddress.houseNumber) {
        fullAddress += ' ' + customer.invoiceAddress.houseNumber
      }
      if(customer.invoiceAddress.houseNumberSuffix) {
        fullAddress += customer.invoiceAddress.houseNumberSuffix
      }
      if(customer.invoiceAddress.postalCode) {
        fullAddress += '<br>'
        fullAddress += customer.invoiceAddress.postalCode
      }
      if(customer.invoiceAddress.city) {
        fullAddress += '<br>'
        fullAddress += customer.invoiceAddress.city
      }
      if(customer.invoiceAddress.country && customer.invoiceAddress.country !== $rootScope.currentShop.address.country) {
        fullAddress += '<br>'
        fullAddress += customer.invoiceAddress.country
      }
    }

    email = email.replace('[[fullAddress]]', fullAddress)

    return email
  }

  return {
    getEmail: getEmail,
    createOrUpdateEmail: createOrUpdateEmail,
    deleteEmail: deleteEmail,
    replacePlaceholder: replacePlaceholder
  }

}])
