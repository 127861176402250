prismanoteApp.factory('$thermalPrint', ['$q', '$api', '$log', '$jspm', '$rootScope', '$language', '$customer', '$CashRegistry', '$timeout', '$printnode',
  function ($q, $api, $log, $jspm, $rootScope, $language, $customer, $CashRegistry, $timeout, $printnode) {

    function getCustomerPoints(customer, transactionTotal) {
      return $q(function (resolve, reject) {
        if(customer && !customer.counter && $rootScope.currentShop.points && $rootScope.currentShop.points.enabled) {
          $customer.getCustomerPoints(customer, $rootScope.currentShop._id)
            .then(function(spendablePoints) {
              return resolve({
                points: Math.floor($rootScope.currentShop.points.perEuro * transactionTotal),
                spendable: spendablePoints
              })
            })
            .catch(function(reason){
              console.error('Error while fetching points for thermal receipt', reason)
              return reject(reason)
            })
        } else {
          return resolve({
            points: null,
            spendable: null
          })
        }
      })
    }

    function makeShopObject () {
      var shop = $rootScope.currentShop
      return {
        name: shop.name,
        phone: shop.phone.landLine ? shop.phone.landLine : shop.phone.mobilePhone ? shop.phone.mobilePhone : '',
        street: shop.address.street,
        houseNumber: shop.address.houseNumber,
        houseNumberSuffix: shop.address.houseNumberSuffix ? shop.address.houseNumberSuffix : '',
        postalCode: shop.address.postalCode,
        city: shop.address.city,
        country: shop.address.country,
        pvcNumber: shop.pvc,
        vatNumber: shop.vatNumber,
        email: shop.email,
        website: shop.website,
        footer: shop.cashRegister.footerTextReceipt,
        device: $rootScope.currentDevice.name
      }
    }

    function makeTransactionObject (transaction, skipVat) {
      return $q(function (resolve, reject) {
        if(skipVat) {
          console.info('VAT calculation will be skipped for this print')
        }
        var taxes = []

        if (transaction.details && transaction.details.length > 0) {
          for (var i = 0; i < transaction.details.length; i++) {
            if (!taxes[transaction.details[i].priceVat]) {
              taxes[transaction.details[i].priceVat] = transaction.details[i].total
            } else {
              taxes[transaction.details[i].priceVat] += transaction.details[i].total
            }
          }
        }

        var products = []
        if (transaction.details && transaction.details.length > 0) {
          products = transaction.details.map(function (p) {

            var originalPrice = (p.price * (skipVat === true ? 1 : (1 + (p.priceVat / 100))))
            var givenDiscount = p.discountValue
            var discountVal = p.discountValue

            if(p.discountPercent) {
              var taxPrice = p.price * (skipVat === true ? 1 : (1+ (p.priceVat / 100)))
              givenDiscount = taxPrice * p.discountValue / (100 - p.discountValue)

              originalPrice = taxPrice + givenDiscount
            }

            if(p.discount && !p.discountPercent) {
              discountVal = price(p.discountValue, true, true)
            }
  
            if(p.productCollection && p.productCollection.length > 0 && p.productCollection[0].nl && p.productCollection[0].nl.name && p.productCollection[0].nl.name !== '') {
              var nameWithoutCollection = p.name.replace(p.productCollection[0].nl.name,'')
              nameWithoutCollection = nameWithoutCollection.trim()
            } else {
              var nameWithoutCollection = ""
            }

            return {
              quantity: p.originalQuantity,
              name: p.name,
              type: p.type ? p.type : 'unknown',
              name_without_collection: nameWithoutCollection ? nameWithoutCollection : p.name,
              comment: p.comment ? p.comment : '',
              articleNumber: p.productNumber ? p.productNumber : '',
              productNumber: p.productNumberSupplier ? p.productNumberSupplier : p.productNumber ? p.productNumber : '',
              //productNumber: p.productNumber ? p.productNumber ? p.productId && p.productId.variants && p.productId.variants[0].productNumber : p.productId.variants[0].productNumber : "",
              total: price(p.total, true, true),
              originalTotalWithGivenDiscount: price(p.originalTotal, true, true),
              outstanding: price(p.originalTotal - ( (p.originalDetailTotal ? p.originalDetailTotal : p.total) + p.paidLaterAmount), true, true),
              originalTotal: price(p.originalTotal + givenDiscount, true, true),
              discount: p.discount,
              discountPercent: p.discountPercent,
              discountValue: discountVal,
              discountValueRaw: p.discountValue,
              totalRaw: p.total,
              originalTotalWithDiscountRaw: p.originalTotal,
              outstandingRaw: p.originalTotal - ( (p.originalDetailTotal ? p.originalDetailTotal : p.total) + p.paidLaterAmount),
              originalTotalRaw: p.originalTotal + givenDiscount
            }
          })
        }

        getCustomerPoints(transaction.customer, transaction.transactionTotal)
          .then(function (points) {
            var data = {
              transaction: {
                number: transaction.number,
                createdBy: transaction.createdBy.name,
                dateCreated: moment(transaction.dateCreated).format('DD-MM-YYYY HH:mm'),
                receiptNumber: transaction.receipt.number,
                total: price(transaction.transactionTotal, true, true),
                comment: transaction.comment
              },
              shipping: transaction.customer ? {
                street: transaction.customer.shippingAddress && transaction.customer.shippingAddress.street ? transaction.customer.shippingAddress.street : '',
                houseNumber: transaction.customer.shippingAddress && transaction.customer.shippingAddress.houseNumber ? transaction.customer.shippingAddress.houseNumber : '',
                houseNumberSuffix: transaction.customer.shippingAddress && transaction.customer.shippingAddress.houseNumberSuffix ? transaction.customer.shippingAddress.houseNumberSuffix : '',
                postalCode: transaction.customer.shippingAddress && transaction.customer.shippingAddress.postalCode ? transaction.customer.shippingAddress.postalCode : '',
                city: transaction.customer.shippingAddress && transaction.customer.shippingAddress.city ? transaction.customer.shippingAddress.city : '',
                country: transaction.customer.shippingAddress && transaction.customer.shippingAddress.country ? transaction.customer.shippingAddress.country : '',
              } : {},
              invoice: transaction.customer ? {
                street: transaction.customer.invoiceAddress && transaction.customer.invoiceAddress.street ? transaction.customer.invoiceAddress.street : '',
                houseNumber: transaction.customer.invoiceAddress && transaction.customer.invoiceAddress.houseNumber ? transaction.customer.invoiceAddress.houseNumber : '',
                houseNumberSuffix: transaction.customer.invoiceAddress && transaction.customer.invoiceAddress.houseNumberSuffix ? transaction.customer.invoiceAddress.houseNumberSuffix : '',
                postalCode: transaction.customer.invoiceAddress && transaction.customer.invoiceAddress.postalCode ? transaction.customer.invoiceAddress.postalCode : '',
                city: transaction.customer.invoiceAddress && transaction.customer.invoiceAddress.city ? transaction.customer.invoiceAddress.city : '',
                country: transaction.customer.invoiceAddress && transaction.customer.invoiceAddress.country ? transaction.customer.invoiceAddress.country : '',
              } : {},
              customer: transaction.customer ? {
                name: $customer.makeCustomerName(transaction.customer),
                email: transaction.customer.email && !transaction.customer.email.includes('autocreated') ? transaction.customer.email : "",
                landLine: transaction.customer.phone && transaction.customer.phone.landLine ? transaction.customer.phone.landLine : '',
                mobilePhone: transaction.customer.phone && transaction.customer.phone.mobilePhone ? transaction.customer.phone.mobilePhone : '',
                number: transaction.customer.clientId,
                points: points && points.points ? points.points : null,
                spendable: points && points.spendable ? points.spendable : null,
                counter: transaction.customer.counter,
                companyName: transaction.customer.companyName ? transaction.customer.companyName : null,
                paymentTermDays: transaction.customer.paymentTermDays ? transaction.customer.paymentTermDays : null
              } : {},
              language: transaction.customer ? transaction.customer.invoiceAddress && transaction.customer.invoiceAddress.country ? transaction.customer.invoiceAddress.country.toLowerCase() : 'nl' : 'nl',
              taxes: parseTaxes(taxes),
              products: products,
              payments: transaction.receipt && transaction.receipt.payments && transaction.receipt.payments.length > 0 ? transaction.receipt.payments.map(function (p) {
                return {
                  name: $language.translate(p.method.toUpperCase()),
                  cardName: p.cardName && p.cardName !== "" ? $language.translate(p.cardName.toUpperCase()) : undefined,
                  amount: price(p.amount, true, true),
                }
              }) : [],
              totals: {
                quantity: transaction.details ? transaction.details.length : 0,
                paid: price(_.sumBy(transaction.receipt.payments, 'amount'), true, true),
                change: price(_.sumBy(transaction.receipt.payments, 'amount') - transaction.transactionTotal, true, true),
                caratweight: _.sumBy(transaction.details, 'totalCaratWeight')
              },
              paymentTickets: []
            }
            if(transaction.receipt.payments && transaction.receipt.payments.length > 0) {
              var result = []
              var counter1 = 0;
              for (var i = 0; i < transaction.receipt.payments.length; i++) {
                var payment = transaction.receipt.payments[i]
                if (payment.paymentHash) {
                  if (payment.paymentHash.length > 60) {
                    var parsed = JSON.parse(payment.paymentHash)
                    var ticket = parsed.join('\n')
                    result.push({ ticket: ticket })
                    checkLoop()
                  } else {
                    $api.get('pay/get-ticket', {
                      shopId: $rootScope.currentShop._id,
                      hash: payment.paymentHash
                    })
                      .then(function (ticket) {
                        var parsedTicket = ticket.data.receipt.replace('\n', '').replace('\n', '').replace('\n', '').replace('\n', '')
                        result.push({ ticket: parsedTicket })
                        checkLoop()
                      })
                      .catch(function (reason) {
                        console.error('Error fetching payment ticket for thermal receipt', reason)
                        checkLoop()
                      })
                  }

                  function checkLoop () {
                    counter1++
                    if (counter1 === transaction.receipt.payments.length) {
                      data.paymentTickets = result
                    }
                  }
                }
              }
            }

            data.shop = makeShopObject()

            if (transaction.type === 'repair' || transaction.type === 'special') {
              data[transaction.type] = transaction[transaction.type]
              if (data[transaction.type].estimatedDate) {
                data[transaction.type].estimatedDate = moment(data[transaction.type].estimatedDate).format('DD-MM-YYYY')
              }
              if (data[transaction.type].estimatedDateString && data[transaction.type].estimatedDateString !== '') {
                data[transaction.type].estimatedDateString = $language.translate(data[transaction.type].estimatedDateString)
              }
              if (data[transaction.type].actualFinishDate) {
                data[transaction.type].actualFinishDate = moment(data[transaction.type].actualFinishDate).format('DD-MM-YYYY')
              }

              data.transaction.internalComment = (transaction.internalComment ? transaction.internalComment : "")
              data.transaction.extraComment = (transaction.extraComment ? transaction.extraComment : "")

              if(data[transaction.type].material && data[transaction.type].material !== '') {
                if(data[transaction.type].material !== 'UNKNOWN') {
                  data[transaction.type].material = $language.translate(data[transaction.type].material)
                }
              }

              if(data[transaction.type].category && data[transaction.type].category !== '') {
                if(data[transaction.type].category !== 'UNKNOWN') {
                  data[transaction.type].category = $language.translate(data[transaction.type].category)
                }
              }

              data[transaction.type].productName = transaction.details && transaction.details.length > 0 ? transaction.details[0].name : $language.translate('SERVICE')
            }

            if (transaction.type === 'giftcard' && transaction.special) {
              data.giftCardNumber = transaction.special.repairNumber
              data.amount = price(transaction.transactionTotal, true, true)
              data.number = transaction.number
              data.date = moment(transaction.dateCreated).format('DD-MM-YYYY')
            }
            if(transaction.type === 'webshop') {
              data.webshop = {
                shippingMethod: transaction.shippingMethod,
                shippingCosts: price(transaction.shippingCosts, true, true)
              }
            }
            return resolve(data)
          })
          .catch(function (reason) {
            console.error('Error while making transaction object', reason)
            return reject(reason)
          })
      })
    }

    function getThermalPrint(shopId, printId, readOnly) {
      return $q(function (resolve, reject) {
        if(!readOnly) {
          readOnly = false
        } else {
          shopId = null
        }
        $api.get('thermalprint/'+ readOnly + (shopId ? '/' + shopId : '') + (printId ? '/' + printId : ''))
          .then(function (res) {
            return resolve(res.data.print)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function getThermalPrintForType(type) {
      return $q(function (resolve, reject) {
        $api.get('thermalprint/type', {
          type: type
        })
          .then(function (res) {
            return resolve(res.data.print)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function createThermalPrint (print) {
      return $q(function(resolve, reject) {
        $api.post('thermalprint', {print: print})
          .then(function (res) {
            return resolve(res.message)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    function deleteThermalPrint(id) {
      return $q(function (resolve, reject) {
        $api.delete('thermalPrint/false/null/' + id)
          .then(function(){
            return resolve('PRINT_DELETED')
          })
          .catch(function (reason){
            return reject(reason)
          })
      })
    }

    function getAndParseTemplate (type, transaction, version) {
      return $q(function (resolve, reject) {
        console.info('getting template for type', type)
        getThermalPrint($rootScope.currentShop._id)
          .then(function (prints) {
            var printnode = false
            var JSPM = false
            if(JSPM === false && !version) {
              printnode = true
              return findTemplate()
            }

            if(!version || version !== '') {
              $jspm.getInstalledVersion().then(function(installedVersion) {
                version = installedVersion
                findTemplate()
              }).catch(function (reason){
                return reject(reason)
              })
            } else {
              findTemplate()
            }

            function findTemplate() {
              var print = _.find(prints, {type: type})
              if(!print) {
                getThermalPrintForType(type)
                  .then(function (template) {
                    makeTemplate(template)
                  })
                  .catch(function () {
                    return reject('NO_TEMPLATE_FOUND')
                  })
              } else {
                makeTemplate(print)
              }
            }

            function makeTemplate(print) {
              if (type !== 'ccv-merchant-receipt' && Object.keys(print).length < 10 || !print.template || print.template.length === 0) {
                console.error('Template for ' + type + ' is not valid')
                return reject('NO_(VALID)_TEMPLATE')
              }

              var parameters = {
                debug: print.debug,
                default_spacing: print.default_spacing,
                hideifnull: print.hideifnull,
                divider_gutter: print.divider_gutter,
                drawerpin: $rootScope.currentDevice.thermalPrinter.drawerPort,
                linelength_n: $rootScope.currentDevice.thermalPrinter.linelength_n,
                linelength_l: $rootScope.currentDevice.thermalPrinter.linelength_l,
                encoding: print.encoding,
                syntax: $rootScope.currentDevice.thermalPrinter.starSyntax === true ? 'star' : 'epson',
                paper_cut: print.paper_cut,
                replace_symbols: (version < 3) || printnode,
                //replace_symbols: true,
                do_validate: print.do_validate ? print.do_validate : false,
                translations: print.translations ? print.translations : []
              }

              var elements = []
              var counter = 0
              for (var i = 0; i < print.template.length; i++) {
                var tElement = print.template[i]

                var element = {}

                if (tElement.foreach && tElement.foreach !== '') {

                  if(parameters.do_validate) {
                    Object.keys(tElement).forEach(function(key) {
                      element[key] = tElement[key];
                    });
                    console.info('Template validation on!')
                  } else {
                    element[Object.keys(tElement)[0]] = tElement[Object.keys(tElement)[0]]
                  }

                  element.template = tElement.template
                  element.columns = tElement.columns ? tElement.columns : [0, 0]
                  element.blankline = tElement.blankline ? tElement.blankline : false
                  elements.push(element)
                  counter++
                  returnTemplate()
                } else {
                  if(parameters.do_validate) {
                    Object.keys(tElement).forEach(function(key) {
                      element[key] = tElement[key];
                    });
                  } else {
                    element[Object.keys(tElement)[0]] = tElement[Object.keys(tElement)[0]]
                  }
                  elements.push(element)
                  counter++
                  returnTemplate()
                }

                function returnTemplate () {
                  if (counter === print.template.length) {
                    console.info('generated parameters', parameters)
                    console.info('generated elements', elements)
                    return resolve({
                      template: JSON.stringify(elements),
                      parameters: parameters,
                      transaction: transaction ? transaction : null
                    })
                  }
                }
              }
            }
          })
          .catch(function (reason){
            return reject(reason)
          })
      })
    }

    function set_nv_logo(params) {
      if($rootScope.currentDevice && $rootScope.currentDevice.thermalPrinter && $rootScope.currentDevice.thermalPrinter.nvLogo && $rootScope.currentDevice.thermalPrinter.nvLogo === true) {
        params.nv_logo = true
      }
      return params
    }

    function price(price, round, replace) {
      if(isNaN(price)) {
        return (replace) ? "€ 0,00" : "0,00"
      }

      if (round) {
        price = (Math.round(price * 100) / 100).toFixed(2)
      }
      if (replace) {
        return '€ ' + price.toString().replace('.', ',')
      } else {
        return price
      }
    }

    function parseTaxes(taxes) {
      if(!taxes || taxes.length === 0) {
        return
      }
      var result = []
      for(var i in taxes) {
        var priceWithoutTax = taxes[i] / (1 + (i / 100))
        var tax = taxes[i] - priceWithoutTax
        result.push({
          rate: i,
          total: price(taxes[i], true, true),
          tax: price(tax, true, true),
          withoutTax: price(priceWithoutTax, true, true)
        })
      }
      return result
    }

    function getTakenMoney(state) {
      return $q(function (resolve, reject) {
        if (!state.takenMoney || state.takenMoney.length === 0) {
          return resolve([])
        }
        var totalTaken = 0; var takenMoney = []; var count = 0
        for (var i = 0; i < state.takenMoney.length; i++) {
          totalTaken += state.takenMoney[i].amount
          state.takenMoney[i].date = moment(state.takenMoney[i].date).format('DD-MM-YYYY HH:mm')
          takenMoney.push(state.takenMoney[i])
          count++
          if (count === state.takenMoney.length) {
            return resolve({
              amount: totalTaken,
              taken: takenMoney
            })
          }
        }
      })
    }

    function bulkPrintThermalTransactions(transactions) {
      console.group('BULK PRINT TRANSACTIONS')
      return $q(function (resolve, reject) {

        var counter = 0
        var JSPM = false

        if (JSPM) {
          $jspm.getInstalledVersion()
            .then(function (version) {
              var commands = []
              for (var i = 0; i < transactions.length; i++) {
                var type = 'shop-receipt'

                if (transactions[i].type === 'repair') {
                  type = 'repair-receipt'
                } else if (transactions[i].type === 'special') {
                  type = 'special-receipt'
                } else if (transactions[i].type === 'giftcard') {
                  type = 'giftcard'
                }

                if (transactions[i].notCustomer === true && (type === 'special-receipt' || type === 'repair-receipt')) {
                  type += '-partner'
                }

                getAndParseTemplate(type, transactions[i], version)
                  .then(function (res) {
                    res.parameters = set_nv_logo(res.parameters)
                    var c = new pn2escpos(res.parameters)
                    makeTransactionObject(res.transaction)
                      .then(function (data) {
                        console.info('Combined data for print', data)
                        var command = c.generate(res.template, JSON.stringify(data))

                        commands.push({
                          command: command,
                          quantity: 1
                        })
                        counter++
                        if (counter === transactions.length) {
                          return $jspm.bulkRawPrint(commands, true)
                        }
                      })
                      .catch(function (reason) {
                        return reject(reason)
                      })
                  })
                  .catch(function (reason) {
                    toastr.error($language.translate(reason))
                    console.error('Error while getting and/or parsing template', reason)
                    console.groupEnd()
                    return reject(reason)
                  })
              }
            })
            .catch(function (reason) {
              console.error('Error getting installed version JSPM', reason)
              return reject(reason)
            })
        }
        else {
          var printCommand = ''
          for (var i = 0; i < transactions.length; i++) {
            var type = 'shop-receipt'

            if (transactions[i].type === 'repair') {
              type = 'repair-receipt'
            } else if (transactions[i].type === 'special') {
              type = 'special-receipt'
            } else if (transactions[i].type === 'giftcard') {
              type = 'giftcard'
            }

            if (transactions[i].notCustomer === true && (type === 'special-receipt' || type === 'repair-receipt')) {
              type += '-partner'
            }

            getAndParseTemplate(type, transactions[i])
              .then(function (res) {
                res.parameters = set_nv_logo(res.parameters)
                var c = new pn2escpos(res.parameters)
                makeTransactionObject(res.transaction)
                  .then(function (data) {
                    console.info('Combined data for print', data)
                    printCommand += c.generate(res.template, JSON.stringify(data))

                    counter++
                    if (counter === transactions.length) {
                     $printnode.printRawContent(printCommand, $rootScope.currentDevice.thermalPrinter.printNode.printerId, $rootScope.currentDevice.thermalPrinter.printNode.deviceId,  1, {
                       title: $language.translate('TRANSACTIONS')
                     })
                       .then(function (result) {
                         return resolve(result)
                       })
                       .catch(function (reason) {
                         return reject(reason)
                       })
                    }
                  })
                  .catch(function (reason) {
                    return reject(reason)
                  })
              })
              .catch(function (reason) {
                toastr.error($language.translate(reason))
                console.error('Error while getting and/or parsing template', reason)
                console.groupEnd()
                return reject(reason)
              })
          }
        }
      })
    }

    /**
     * Print a thermal receipt for a transaction
     * @param {String} type
     * @param quantity
     * @param {Object} transaction
     * @param {Boolean} skipDrawer
     * @param {Boolean} skipVat
     * @returns {*}
     */
    function printThermal (type, quantity, transaction, skipDrawer, skipVat) {
      console.group('PRINT THERMAL RECEIPT (SHOP, REPAIR OR SPECIAL)')
      return $q(function (resolve, reject){
        if(!type) {
          return reject('NO_TYPE_PROVIDED')
        }
console.info('printThermal quantity', quantity)
        if(!quantity) {
          quantity = 1
        }
        var JSPM = false

        getAndParseTemplate(type)
          .then(function (res) {
            res.parameters = set_nv_logo(res.parameters)
            var c = new pn2escpos(res.parameters)
            makeTransactionObject(transaction, skipVat)
              .then(function (data) {
                console.info('Combined data for print', data)
                var command = c.generate(res.template, JSON.stringify(data))
console.info('GENERATED COMMAND', command)
                if(JSPM) {
console.info('JSPM')
console.info('command', command)
console.info('quantity')
                  return $jspm.printRawCommand(command, quantity, true)
                    .then(function () {
                      console.info('Command sended to printer with JSPM')
                      var port = $rootScope.currentDevice.thermalPrinter.drawerPort
                      if(port !== '' && !skipDrawer) {
                        if(data.payments && data.payments.length > 0) {
                          var cashPayments = transaction.receipt.payments.filter(function (p) {
                            return p.method.toUpperCase() === 'CASH'
                          })
                          if(cashPayments && cashPayments.length > 0) {
                            console.info('Cash payments found, going to open the drawer')
                            console.groupEnd()
                            $timeout(function () {
                              return openDrawer(false, transaction.number)
                            }, 10)
                          }
                        } else {
                          console.groupEnd()
                          return resolve()
                        }
                      } else {
                        console.groupEnd()
                        return resolve()
                      }
                    })
                    .catch(function (reason){
                      console.error('Error while sending printjob to printer', reason)
                      toastr.error(reason)
                      console.groupEnd()
                      return reject(reason)
                    })
                } else {
                  //PrintNode
                  var port = $rootScope.currentDevice.thermalPrinter.drawerPort
                  if(!port || port === '' && !skipDrawer) {
                    return sendPrintJob()
                  } else {
                    port = parseFloat(port.replace('PIN', ''))
                    if(!port || port === 0) {
                      console.error('ERROR_PARSING_DRAWER_PORT')
                      return sendPrintJob()
                    }

                    if(data.payments && data.payments.length > 0) {
                      var totalCashAmount = 0
                      var cashPayments = transaction.receipt.payments.filter(function (p) {
                        if(p.method.toUpperCase() === 'CASH') {
                          totalCashAmount += p.amount
                          return true
                        } else {
                          return false
                        }
                      })

                      if(cashPayments && cashPayments.length > 0 && totalCashAmount > 0) {
                        console.info('Cash payments found, going to open the drawer')
                        console.groupEnd()
                        drawerReason(false, transaction.number)
                          .then(function () {
                            console.info('Drawer will be openend at PIN', port)
                            var c = new pn2escpos(port)
                            var cmd = c.epOpenDrawer()
                            command += cmd
                            sendPrintJob()
                          })
                      } else {
                        sendPrintJob()
                      }
                    } else {
                      sendPrintJob()
                    }
                  }

                  function sendPrintJob() {
console.info('sending printjob to printnode')
console.info('command', command)
console.info('currentDevice.thermalPrinter', $rootScope.currentDevice.thermalPrinter.printNode)
console.info('quantity', quantity)
                    return $printnode.printRawContent(command, $rootScope.currentDevice.thermalPrinter.printNode.printerId, $rootScope.currentDevice.thermalPrinter.printNode.deviceId, quantity, {
                      title: $language.translate('TRANSACTION') + ' ' + transaction.number
                    })
                      .then(function () {
                        console.info('Printjob sended to PrintNode')
                        console.groupEnd()
                        return resolve()
                      })
                      .catch(function (reason) {
                        return reject(reason)
                      })
                  }
                }
              })
              .catch(function (reason) {
                return reject(reason)
              })
          })
          .catch(function (reason) {
            toastr.error($language.translate(reason))
            console.error('Error while getting and/or parsing template', reason)
            console.groupEnd()
            return reject(reason)
          })
      })
    }

    /**
     * Prints a meaasuring receipt, to determine how many chars fits in a row. Also used as testprint.
     * @returns {*}
     */
    function printMeasuringReceipt () {
      var escPos = new pn2escpos({
        syntax: $rootScope.currentDevice.thermalPrinter.starSyntax === true ? 'star' : 'epson'
      })
      var command = escPos.printMeasuringReceipt()
      var JSPM = false

      if(JSPM) {
        return $jspm.printRawCommand(command, 1, true)
          .then(function () {
            console.info('Measuring receipt printed')
          })
          .catch(function (reason){
            console.error('error while printing thermal measuring receipt', reason)
          })
      } else {
        return $printnode.printRawContent(command, $rootScope.currentDevice.thermalPrinter.printNode.printerId, $rootScope.currentDevice.thermalPrinter.printNode.deviceId,1, {
          title: $language.translate('MEASURE_RECEIPT')
        })
          .then(function () {
            $log.info('Measuring receipt printed')
          })
          .catch(function (reason) {
            $log.error('Error when printing measuring receipt', reason)
            toastr.error(reason)
          })
      }

    }

    /**
     * Prints a thermal day state
     * @param {Object} state
     * @returns {*}
     */
    function printDayClosure(state) {
      console.group('PRINT DAY CLOSURE')
      return $q (function (resolve, reject) {
        getAndParseTemplate('dayclosure')
          .then(function (res) {
            res.parameters = set_nv_logo(res.parameters)
            var c = new pn2escpos(res.parameters)
            var totalTurnOver = 0

            state.payments.forEach(function (p){
              totalTurnOver += p.method.toUpperCase() === 'PAYLATER' ? 0 : p.amount
            })

            getTakenMoney(state)
              .then(function (taken) {
                state.dateHeader = moment(state.date).format('DD-MM-YYYY')
                state.date = moment(state.date).format('DD-MM-YYYY HH:mm')
                state.dateClosed = moment(state.dateClosed).format('DD-MM-YYYY HH:mm')
                state.printedOn = moment().format('DD-MM-YYYY HH:mm')

                var cards = []
                var cardIdx = state.payments.findIndex(function(p) {
                  return p.method.toUpperCase() === 'CARD'
                })
                if(cardIdx >= 0) {
                  cards = state.payments[cardIdx].cards.map(function(p) {
                    p.originalCardName = angular.copy(p.cardName)
                    p.cardName = $language.translate(p.cardName.toUpperCase())
                    p.amount = price(p.amount,true,true)
                    return p
                  })
                }

                state.income.amount = price(state.income.amount,true,true)
                state.income.totalEarning = price(state.income.totalEarning,true,true)
                state.income.online = price(state.income.online,true,true)
                state.income.totalRevenueCategories = price(state.income.totalRevenueCategories,true,true)
                state.income.totalCollections = price(state.income.totalCollections,true,true)
                state.income.giftcards.issued = price(state.income.giftcards.issued,true,true)
                state.income.giftcards.taken = price(state.income.giftcards.taken,true,true)
                state.income.giftcards.balance = price(state.income.giftcards.balance,true,true)
                state.income.gold.income = price(state.income.gold.income,true,true)
                state.income.gold.purchased = price(state.income.gold.purchased,true,true)
                state.income.gold.sold = price(state.income.gold.sold,true,true)
                state.income.totalTax = price(state.income.totalTax,true,true)
                state.income.totalRevenue = price(state.income.totalRevenue,true,true)
                state.difference = price(state.difference,true,true)

                taken.amount = price(taken.amount,true,true)
                state.cashCounted = price(state.cashCounted,true,true)
                state.cashDifference = price(state.cashDifference,true,true)
                state.skim = price(state.skim,true,true)

                var payments = state.payments.map(function (p) {
                  p.originalMethod = angular.copy(p.method)
                  p.method = $language.translate(p.method.toUpperCase())
                  p.amount = price(p.amount,true,true)
                  return p
                })

                var takenMoneyItems = []
                if(taken && taken.taken && taken.taken.length > 0) {
                  takenMoneyItems = taken.taken.map(function (p) {
                    p.amount = price(p.amount *-1, true, true)
                    return p
                  })
                }

                var incomeRevenue = state.income.revenue.map(function (p) {
                  p.amountWithVat  = price(p.amountWithVat,true,true)
                  p.tax  = price(p.tax,true,true)
                  return p
                })

                var incomeCollections = state.income.collections.map(function (p) {
                  p.amount  = price(p.amount,true,true)
                  return p
                })

                var data = {
                  state: state,
                  payments: payments,
                  takenMoney: taken,
                  takenMoneyItems: takenMoneyItems,
                  income: state.income,
                  incomeRevenue: incomeRevenue,
                  incomeCollections: incomeCollections,
                  incomeGiftcards: state.income.giftcards,
                  incomeGold: state.income.gold,
                  cards: cards,
                  difference: price(state.income.totalEarning - totalTurnOver, true, true),
                  totalTurnOver: price(totalTurnOver, true, true)
                }
                data.shop = makeShopObject()

                console.info('Combined data for print', data)
                var command = c.generate(res.template, JSON.stringify(data))
                var JSPM = false

                if(JSPM) {
                  return $jspm.printRawCommand(command, 1, true)
                    .then(function () {
                      console.info('Command sended to printer with JSPM')
                      console.groupEnd()
                      return resolve()
                    })
                    .catch(function (reason) {
                      console.error('Error while sending printjob to printer', reason)
                      console.groupEnd()
                      return reject(reason)
                    })
                } else {
                  return $printnode.printRawContent(command, $rootScope.currentDevice.thermalPrinter.printNode.printerId, $rootScope.currentDevice.thermalPrinter.printNode.deviceId,1, {
                    title: $language.translate('DAY_CLOSURE') + ' ' + state.printedOn
                  })
                    .then(function () {
                      console.info('Dayclosure sended to PrintNode')
                      console.groupEnd()
                      return resolve()
                    })
                    .catch(function (reason) {
                      console.error('Error sending dayclosure print to PrintNode', reason)
                      console.groupEnd()
                      return reject(reason)
                    })
                }
              })
            })
            .catch(function (reason) {
              return reject('Error when calculating taken money')
            })
          .catch(function (reason){
            toastr.error($language.translate(reason))
            console.error('Error while getting and/or parsing template', reason)
            console.groupEnd()
            return reject(reason)
          })
      })
    }

    /**
     * Prints a receipt for a pin transaction
     * @param {Object} ticket
     * @returns {*}
     */
    function printPinTicket(ticket) {
      console.group('PRINT PIN TICKET')
      return $q(function (resolve, reject) {
        getAndParseTemplate('pin-receipt')
          .then(function(res) {
            res.parameters = set_nv_logo(res.parameters)
            var c = new pn2escpos(res.parameters)
            var data = ticket
            data.shop = makeShopObject()
            console.info('Combinbed data for print', data)
            var command = c.generate(res.template, JSON.stringify(data))
            var JSPM = false

            if(JSPM) {
              return $jspm.printRawCommand(command, 1, true)
                .then(function () {
                  console.info('Command sendend to printer with JSPM')
                  console.groupEnd()
                  return resolve()
                })
                .catch(function (reason) {
                  console.error('Error while sending printjob to printer', reason)
                  console.groupEnd()
                  return reject(reason)
                })
            } else {
              return $printnode.printRawContent(command, $rootScope.currentDevice.thermalPrinter.printNode.printerId, $rootScope.currentDevice.thermalPrinter.printNode.deviceId,1, {
                title: $language.translate('PINTICKET')
              })
                .then(function() {
                  console.info('Pinticket print send to PrintNode')
                  console.groupEnd()
                  return resolve()
                })
                .catch(function (reason) {
                  console.error('Error sending pinticket to PrintNode', reason)
                  console.groupEnd()
                  return reject(reason)
                })
            }
          })
          .catch(function (reason){
            toastr.error($language.translate(reason))
            console.error('Error while getting and/or parsing template', reason)
            console.groupEnd()
            return reject(reason)
          })
      })
    }

    /**
     * Prints a taken money from drawer receipt
     * @param {any} amount
     * @param {String} reason
     * @returns {*}
     */
    function printTakenMoneyReceipt (amount, reason) {
      console.group('PRINT TAKEN MONEY RECEIPT')
      return $q(function (resolve, reject) {
        getAndParseTemplate('taken-money')
          .then(function (res) {
            res.parameters = set_nv_logo(res.parameters)
            var c = new pn2escpos(res.parameters)

            var data = {
              currentUser: $rootScope.currentEmployee ? $rootScope.currentEmployee : $rootScope.user,
              amount: price(amount, true, true),
              reason: reason,
              dateTime: moment().format('DD-MM-YYYY HH:mm')
            }
            data.shop = makeShopObject()

            console.info('Combined data for print', data)
            var command = c.generate(res.template, JSON.stringify(data))
            var JSPM = false

            if(JSPM) {
              return $jspm.printRawCommand(command, 1, true)
                .then(function () {
                  console.info('Command sended to printer with JSPM')
                  console.groupEnd()
                  return resolve()
                })
                .catch(function (reason) {
                  console.error('Error while sending printjob to printer', reason)
                  console.groupEnd()
                  return reject(reason)
                })
            } else {
              $printnode.printRawContent(command, $rootScope.currentDevice.thermalPrinter.printNode.printerId, $rootScope.currentDevice.thermalPrinter.printNode.deviceId,1, {
                title: $language.translate('TAKEN_MONEY')
              })
                .then(function () {
                  console.info('Taken money receipt sended to PrintNode')
                  console.groupEnd()
                  return resolve()
                })
                .catch(function (reason){
                  console.error('Error sending taken money receipt', reason)
                  console.groupEnd()
                  return reject(reason)
                })
            }
          })
          .catch(function (reason) {
            toastr.error($language.translate(reason))
            console.error('Error while getting and/or parsing template', reason)
            console.groupEnd()
            return reject(reason)
          })

      })
    }

    /**
     * Print a thermal giftcard
     * @param {any} amount
     * @param {String} number
     * @param {Date} date
     * @param {Object} document
     * @param {String} giftcardnumber
     * @returns {*}
     */
    function printGiftcard (amount, number, date, document, giftcardnumber) {
      console.group('PRINT GIFTCARD')
      return $q(function (resolve, reject){
        getAndParseTemplate('giftcard')
          .then(function(res) {
            res.parameters = set_nv_logo(res.parameters)
            var c = new pn2escpos(res.parameters)
            var data = {
              amount: price(amount, true, true),
              number: number,
              date: date,
              footer: document.footer,
              giftCardNumber:giftcardnumber
            }
            data.shop = makeShopObject()
            console.info('Combined data for print', data)
            var command = c.generate(res.template, JSON.stringify(data))
            var JSPM = false

            if(JSPM) {
              return $jspm.printRawCommand(command, 1, true)
                .then(function () {
                  console.info('Command sended to printer with JSPM')
                  console.groupEnd()
                  return resolve()
                })
                .catch(function (reason) {
                  console.error('Error while sending printjob to printer', reason)
                  console.groupEnd()
                  return reject(reason)
                })
            } else {
              $printnode.printRawContent(command, $rootScope.currentDevice.thermalPrinter.printNode.printerId, $rootScope.currentDevice.thermalPrinter.printNode.deviceId, 1, {
                title: $language.translate('GIFTCARD') + ' ' + data.number
              })
                .then(function () {
                  console.info('Giftcard is sended to PrintNode')
                  console.groupEnd()
                  return resolve()
                })
                .catch(function (reason){
                  console.error('Error sending giftcard to PrintNode', reason)
                  console.groupEnd()
                  return reject(reason)
                })
            }
          })
          .catch(function (reason){
            toastr.error($language.translate(reason))
            console.error('Error while getting and/or parsing template', reason)
            console.groupEnd()
            return reject(reason)
          })
      })
    }

    function drawerReason(needReason, transactionNumber) {
      return $q(function (resolve, reject) {
        if($rootScope.currentShop.cashRegister.askReasonOpeningDrawer) {
          if(needReason === true) {
            $CashRegistry.openDrawerReasonModal()
              .then(function  () {
                return resolve()
              })
              .catch( function (reason) {
                return reject(reason)
              })
          } else if(transactionNumber) {
            $CashRegistry.saveDrawerReason($language.translate('OPENING_FOR_TRANSACTION') + ' ' + transactionNumber)
              .then(function () {
                return resolve()
              })
              .catch(function(reason){
                return reject(reason)
              })
          }
        } else {
          return resolve()
        }
      })
    }

    function openDrawer(needReason, transactionNumber) {
      console.group('OPEN DRAWER')
      return $q(function(resolve, reject) {

        var port = $rootScope.currentDevice.thermalPrinter.drawerPort

        if(!port || port === '') {
          return reject('NO_DRAWER_PORT_SET')
        } else {
          port = parseFloat(port.replace('PIN', ''))
          if(!port || port === 0) {
            return reject('ERROR_PARSING_DRAWER_PORT')
          }
          drawerReason(needReason, transactionNumber)
          .then( function () {
            console.info('Opening drawer at PIN: ' + port)

            var c = new pn2escpos(port)
            var command = c.epOpenDrawer()
            return $printnode.openDrawer(command, $rootScope.currentDevice.thermalPrinter.printNode.printerId, $rootScope.currentDevice.thermalPrinter.printNode.deviceId)
              .then(function() {
                console.info('Command sended to printer through PrintNode')
                console.groupEnd()
                return resolve()
            })
            .catch(function (reason) {
              console.error('Error while sending command to printer with PrintNode', reason)
              console.groupEnd()
              return reject(reason)
            })
            
          })
          .catch(function (reason){
            return reject(reason)
          })
        }
      })
    }

    function printMerchantPinReceipt (receipt) {
      var parsed = JSON.parse(receipt)
      var data = {ticket: parsed.join('\n')}

      getAndParseTemplate('ccv-merchant-receipt')
        .then( function (res) {
          res.parameters = set_nv_logo(res.parameters)
          var c = new pn2escpos(res.parameters)
          console.info('Combined data for print', data)
          var command = c.generate(res.template, JSON.stringify(data))

          $printnode.printRawContent(command, $rootScope.currentDevice.thermalPrinter.printNode.printerId, $rootScope.currentDevice.thermalPrinter.printNode.deviceId, 1, {
            title: 'Merchant PIN receipt'
          })
            .then(function () {
              console.info('Merchant receipt is sended to PrintNode')
            })
            .catch(function (reason){
              console.error('Error sending merchant receipt to PrintNode', reason)
            })
        })
        .catch(function (reason) {
          toastr.error($language.translate(reason))
          console.error('Error while getting and/or parsing template', reason)
        })
    }

    return {
      getThermalPrint: getThermalPrint,
      createThermalPrint: createThermalPrint,
      deleteThermalPrint: deleteThermalPrint,
      printThermal: printThermal,
      printMeasuringReceipt: printMeasuringReceipt,
      printDayClosure: printDayClosure,
      printPinTicket: printPinTicket,
      printTakenMoneyReceipt: printTakenMoneyReceipt,
      printGiftcard: printGiftcard,
      bulkPrintThermalTransactions: bulkPrintThermalTransactions,
      openDrawer: openDrawer,
      getThermalPrintForType: getThermalPrintForType,
      printMerchantPinReceipt: printMerchantPinReceipt
    }
  }])
