prismanoteApp.factory('$pdf', ['$http', '$q', 'dialogs', '$language', 'FileSaver', '$timeout', '$jspm', '$log', '$printer', '$crypto', '$rootScope', '$printnode',
  function ($http, $q, dialogs, $language, FileSaver, $timeout, $jspm, $log, $printer, $crypto, $rootScope, $printnode) {
    function downloadPdf (url, data, fileName, responseType, skipPrint, skipSave, timeOut) {
    if (!responseType) {
        responseType = 'arraybuffer'
      }
      var deffered = $q.defer()

      var JSPM = false

      var url = '/api/' + url
      if(data && data.documentId) {
        //New PDF generation TEST
        url = 'api/v2/pdf/generate'
      }

      var encryptedData = $crypto.encrypt(data)

      return $q(function (resolve, reject) {
        $http({
          method: 'POST',
          url: url,
          data: {encrypted: encryptedData},
          responseType: responseType,
         // timeout: deffered.promise
        })
          .then(function (response) {
            var file = new Blob([response.data], { type: 'application/pdf' })
            if (responseType === 'arraybuffer') {
              if (!data.documentName) {
                if( !skipSave) {
                  console.log('here too!!', fileName);
                  console.log(file);
                  saveAs(file, fileName)
                }
                return resolve(file)
              }
              $printer.getPageDefinition(data.documentName)
                .then(function (definition) {
                  if (definition && ( (JSPM && definition.printerName && definition.printerName !== '') || (JSPM === false && definition.printNode && definition.printNode.printerId) )&& !skipPrint) {
                    console.info('PDF file generated, sending document to printer')
                    var ip = '127.0.0.1'
                    if(definition.printDevice && definition.printDevice.localIp) {
                      ip = definition.printDevice.localIp
                    }

                    if(JSPM === false) {
                      if(file) {
                        var reader = new FileReader()
                        reader.readAsDataURL(file)
                        reader.onloadend = function () {
                          var base64Data = reader.result
                          var options = {}

                          options = {
                            title: fileName,
                            rotate: definition.rotation,
                          }
                          
                          if(definition.printerPageFormat) {
                            options.paper = definition.printerPageFormat
                          }

                          if(definition.printerPageFormat) {
                            options.paper = definition.printerPageFormat
                          }
                          if(definition.paperTray) {
                            options.tray = definition.paperTray
                          }

                          if(definition.printNodeOptions) {
                            try {
                              var extraOptions = JSON.parse(definition.printNodeOptions)
                              if(Object.keys(extraOptions).length > 0) {
                                var tmpOptions = Object.assign(options, extraOptions)
                                options = tmpOptions
                              }
                            } catch(e) {
                              console.error('Error parsing extra PrintNode Options', e)
                            }
                          }

                          console.info('PrintJob sended to PrintNode with the following options', options)

                          $printnode.printPdfFile(base64Data, definition.printNode.printerId, definition.printNode.deviceId, data.copies ? data.copies : 1, options )
                            .then(function(result) {
                              $log.info('pdf-service')
                              $log.info('Document sended to PrintNode', result)
                              return resolve()
                            })
                            .catch( function(reason){
                              $log.error('Error sending document to Printnode', reason)
                            })
                        }
                      } else {
                        $log.error('No file')
                      }
                    } else {
                      $jspm.printPDFFile({
                        printerName: definition.printerName,
                        ip: ip,
                        paperTray: definition.paperTray,
                        vertical: definition.vertical,
                        rotation: definition.rotation,
                        content: file,
                        fileName: fileName,
                        copies: data.copies ? data.copies : 1,
                        paperName: definition.pageFormat,
                        printerPageFormat: definition.printerPageFormat
                      })
                        .then(function () {
                          $log.info('Document sent to printer')
                          return resolve()
                        })
                        .catch(function (reason) {
                          $log.error('Error while sending document to printer', reason)
                        })
                    }
                  } else {
                    if( !skipSave) {
                      saveAs(file, fileName)
                    }
                    return resolve(file)
                  }
                })
                .catch(function (reason) {
                  return reject(reason)
                })
            } else {
              if( !skipSave) {
                saveAs(file, fileName)
              }
              return resolve(file)
            }

          }, function (response) {
            if (responseType !== 'arraybuffer') {
              if (response.data.message === 'NOT_VERIFIED') {
                dialogs.error($language.translate('EMAILADDRESS_NOT_VERIFIED'), $language.translate('YOU_CANT_SEND_EMAILS_BEFORE_VERIFYING_YOUR_ADDRESS'), { size: 'sm' })
              } else {
                dialogs.error(undefined, $language.translate('ERROR_WHILE_DOWNLOADING_DOCUMENT'), { size: 'sm' })
              }
            }
            return reject(response.data.message)
          })

        $timeout(function () {
          deffered.resolve()
        }, timeOut || 10000)
      })
    }

    return {
      downloadPdf: downloadPdf
    }
  }])
