prismanoteApp.service('$tax', ['$q', '$api', function ($q, $api) {
  function getTaxForCompany (companyId) {
    return $q(function (resolve, reject) {
      $api.get('company/tax/', { companyId: companyId })
        .then(function (response) {
          console.log('Get success resopnse', response.data)
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getTaxForAdmin () {
    return $q(function (resolve, reject) {
      $api.get('V2/admin/tax')
        .then(function (response) {
          console.log('Get success resopnse', response.data)
          resolve(response.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  this.getTaxForCompany = getTaxForCompany
  this.getTaxForAdmin = getTaxForAdmin
}])
