prismanoteApp.factory('$giftcard', ['$q', '$api', '$rootScope', '$data', '$uibModal', '$retailer',
  function ($q, $api, $rootScope, $data, $uibModal, $retailer) {

    function openExternalGiftcardsModal (totalAmount) {
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/retailer-other-giftcard-modal.html',
          controller: 'retailerOtherGiftcardModalController',
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            totalAmount: function () {
              return Math.round(totalAmount * 100) / 100
            }
          }
        })

        modalInstance.result.then(function (res) {
          return resolve(res)
        }, function (reason) {
          if (!reason) {
            reason = 'dismissed'
          }
          return reject(reason)
        })
      })
    }

    return {
     openExternalGiftcardsModal: openExternalGiftcardsModal
    }
  }])
