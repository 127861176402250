prismanoteApp.factory('$toast', ['$q', 'ngToast',
    function ($q, ngToast) {

        function warningToast(message) {
            ngToast.warning({
                content: message
            });
        }

        function successToast(message) {
            ngToast.success({
                content: message
            });
        }

        function primaryToast(message) {
            ngToast.primary({
                content: message
            });
        }

        function infoToast(message) {
            ngToast.info({
                content: message
            });
        }

        function dangerToast(message) {
            ngToast.danger({
                content: message
            });
        }

        return {
            warningToast: warningToast,
            successToast: successToast,
            infoToast: infoToast,
            primaryToast: primaryToast,
            dangerToast: dangerToast
        }
    }
])