prismanoteApp.service('$CashRegistry', ['$rootScope', '$q', '$api', '$language', '$transaction', '$uibModal',
  function ($rootScope, $q, $api, $language, $transaction, $uibModal) {
  // Get diamond info from product
  function getDiamondInfo (product) {
    if (product.diamonds && product.diamonds.length > 0) {
      var diamond = product.diamonds[0]
      return (diamond.gemKind ? diamond.gemKind : '') + ', ' +
                (diamond.gemPurity && diamond.gemPurity.code ? diamond.gemPurity.code : '') + ', ' +
                (diamond.gemColor && diamond.gemColor.code ? diamond.gemColor.code : '') + ', ' +
                (diamond.caratWeight ? diamond.caratWeight : '') +
                (product.weight ? ' | ' + product.weight.toString() + 'g' : '')
    } else {
      return null
    }
  }

  function makeProductTextAndGetStock (product, transactionItems) {
    return $q(function (resolve, reject) {
      try {
        var privateLabel = ''
        var labelFound = false
        if (!product.collections || product.collections.length === 0) {
          return checkLabelAndCallback()
        }

        var counter = 0
        for (var i = 0; i < product.collections.length; i++) {
          var collection = product.collections[i]
          if (
            (collection._id && collection._id.privateLabel && collection._id.privateLabel === true) ||
            ( typeof collection._id === 'string' && collection.privateLabel === true) ||
            ( product.brand && product.brand.isPoolArticleBrand )
          ) {
            labelFound = true
            if (typeof collection._id !== 'string') {
              privateLabel = (product.brand && product.brand.isPoolArticleBrand ? $language.translate('SERVICES') + ' ' : '') + collection._id[$rootScope.language].name
            } else {
              privateLabel = collection[$rootScope.language].name
            }
          }
          counter++
          if (counter === product.collections.length) {
            return checkLabelAndCallback()
          }
        }

        function checkLabelAndCallback () {
          var diamondInfo = getDiamondInfo(product)
          var labelDescription = product.labelDescription ? product.labelDescription : ''
          var productNumber = product.variants && product.variants.length > 0 && !product.variants[0].productNumberAuto && product.variants[0].productNumber ? product.variants[0].productNumber : null

          var counter = 0
          if (!labelFound) {
            if (product.suggestions && product.suggestions.length > 0) {
              for (var i = 0; i < product.suggestions.length; i++) {
                var suggestion = product.suggestions[i]
                if (suggestion.privateLabel && suggestion.privateLabel === true) {
                  privateLabel = suggestion._id[$rootScope.language].name
                }
                counter++
                if (counter === product.suggestions.length) {
                  return complete()
                }
              }
            } else {
              return complete()
            }
          } else {
            return complete()
          }

          function complete () {
            var stock = product.stock ? product.stock : 0
            // Try to find the same product in the list to calculate the right stock
            if (transactionItems && transactionItems.length > 0) {
              var counter = 0
              for (var i = 0; i < transactionItems.length; i++) {
                var item = transactionItems[i]
                if (item.productNumber === product.articleNumber) {
                  stock -= item.quantity
                }
                counter++
                if (counter === transactionItems.length) {
                  complete2()
                }
              }
            } else {
              complete2()
            }

            function complete2 () {
              var productName = ''
              if (privateLabel && labelDescription) {
                if (privateLabel.toLowerCase() !== 'kasius') {
                  productName = productName + privateLabel + ' '
                }

                if (labelDescription !== '') {
                  productName = productName + labelDescription + ' '
                } else if (product.brand && product.brand.alias && product.brand.alias !== '') {
                  productName = productName + product.brand.alias + ' '
                }

                if (product.brand && product.brand.alias && product.brand.alias === '') {
                  productName = productName + productNumber
                }
              } else {
                if (product[$rootScope.language] && product[$rootScope.language].name) {
                  productName = product[$rootScope.language].name
                } else {
                  productName = $language.translate('ARTICLE')
                }
              }
              return resolve({
                name: productName,
                diamondInfo: diamondInfo,
                stock: stock
              })
            }
          }
        }
      } catch (e) {
        return reject(e)
      }
    })
  }

  function getOriginalTransaction (id) {
    return $q(function (resolve, reject) {
      if (!id || id === '') {
        return resolve()
      } else {
        $transaction.getTransaction(id, false, true, $rootScope.currentShop.id)
          .then(function (transaction) {
            return resolve(transaction)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      }
    })
  }

  function getPoolArticlesSortedOnSells () {
    return $q(function (resolve, reject) {
      $api.get('retailer/fetch-pool-article-from-shop-and-sort', { shopId: $rootScope.currentShop._id }, null, 2)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (reason) {
          reject(reason)
        })
    })
  }

  function getCashMutationReasons(drawer) {
    var reasons = [
      {
        value: 'CASH_ON_DELIVERY',
        reason: $language.translate('CASH_ON_DELIVERY'),
        ledger: 7901
      },
      {
        value: 'SHIPPING_COSTS',
        reason: $language.translate('SHIPPING_COSTS'),
        ledger: 7902
      },
      {
        value: 'CAR_COSTS',
        reason: $language.translate('CAR_COSTS'),
        ledger: 7903
      },
      {
        value: 'FOOD_DRINK',
        reason: $language.translate('FOOD_DRINK'),
        ledger: 7904
      },
      {
        value: 'TOOLS_SMALL_MATERIAL',
        reason: $language.translate('TOOLS_SMALL_MATERIAL'),
        ledger: 7905
      },
      {
        value: 'OFFICE_SUPPLIES',
        reason: $language.translate('OFFICE_SUPPLIES'),
        ledger: 7906
      },
      {
        value: 'HOUSING_COSTS',
        reason: $language.translate('HOUSING_COSTS'),
        ledger: 7907
      },
      {
        value: 'MAINTENANCE_COSTS',
        reason: $language.translate('MAINTENANCE_COSTS'),
        ledger: 7908
      },
      {
        value: 'CASH_DEPOSIT_TO_BANK',
        reason: $language.translate('CASH_DEPOSIT_TO_BANK'),
        ledger: 7909
      },
      {
        value: 'OTHER',
        reason: $language.translate('OTHER'),
        ledger: 7910
      }
    ]
    if(drawer) {
      reasons.push({
        value: 'CASH_CHECK',
        reason: $language.translate('CASH_CHECK'),
      })
    }

    return reasons
  }

  function getPaymentMethods () {
    return [
      {
        value: 'CASH',
        name: $language.translate('CASH'),
        ledger: 2401
      },
      {
        value: 'CARD',
        name: $language.translate('CARD'),
        ledger: 2410
      },
      {
        value: 'MAESTRO',
        name: $language.translate('MEASTRO'),
        ledger: 2411
      },
      {
        value: 'VPAY',
        name: $language.translate('VPAY'),
        ledger: 2412
      },
      {
        value: 'MASTERCARD',
        name: $language.translate('MASTERCARD'),
        ledger: 2413
      },
      {
        value: 'VISA',
        name: $language.translate('VISA'),
        ledger: 2414
      },
      {
        value: 'AMEX',
        name: $language.translate('AMEX'),
        ledger: 2415
      },
      {
        value: 'FASHIONCHEQUE',
        name: $language.translate('FASHIONCHEQUE'),
        ledger: 2420
      },
      {
        value: 'FASHIONGIFTCARD',
        name: $language.translate('FASHIONGIFTCARD'),
        ledger: 2421
      },
      {
        value: 'VVV',
        name: $language.translate('VVV'),
        ledger: 2422
      },
      {
        value: 'GIFTCARD',
        name: $language.translate('GIFTCARD'),
        ledger: 2423
      }
    ]
  }

  function getRepairBagNumber (type) {
    return $q(function (resolve, reject) {
      if(!$rootScope.currentShop || !$rootScope.currentShop._id || !$rootScope.currentShop.cashRegister.autoRepairBagNumber) {
        return resolve()
      }

      $api.get('till/transaction/bagnumber', {shopId: $rootScope.currentShop._id, type: type}, null, 2)
        .then(function (response) {
          return resolve(response.data.bagNumber)
        })
        .catch(function (reason){
          console.error('Error while determing repair bag number', reason)
          return reject(reason)
        })
    })
  }

  function openDrawerReasonModal() {
    //Opens modal and saves result
    return $q(function (resolve, reject) {
      var takeMoneyModal = $uibModal.open({
        templateUrl: '../views/modal/retailer-take-money-modal.html',
        controller: 'retailerTakeMoneyModalController',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          stateId: function () {
            return null
          },
          drawer: function () {
            return true
          }
        }
      })

      takeMoneyModal.result.then(function (result) {
        saveDrawerReason(result.reason)
          .then(function() {
            return resolve()
          })
          .catch(function (reason){
            return reject(reason)
          })
      }, function () {
          return reject($language.translate('OPENING_CANCELLED'))
      })
    })
  }

  function saveDrawerReason(reason) {
    return $q(function (resolve, reject) {
      var user = $rootScope.currentEmployee ? $rootScope.currentEmployee : $rootScope.user
      var data = {
        shopId: $rootScope.currentShop._id,
        reason: reason,
        user: {
          _id: user._id,
          name: (user.firstName ? user.firstName + ' ' : '') + (user.lastNamePrefix ? user.lastNamePrefix + ' ' : '') + (user.lastName ? user.lastName  : '')
        }
      }
      $api.post('till/drawer', data, null, 2)
        .then(function (result) {
          return resolve()
        })
        .catch(function (reason){
          return reject(reason)
        })
    })
  }

  return {
    makeProductTextAndGetStock: makeProductTextAndGetStock,
    getOriginalTransaction: getOriginalTransaction,
    getPoolArticlesSortedOnSells: getPoolArticlesSortedOnSells,
    getCashMutationReasons: getCashMutationReasons,
    getPaymentMethods: getPaymentMethods,
    getRepairBagNumber: getRepairBagNumber,
    openDrawerReasonModal: openDrawerReasonModal,
    saveDrawerReason: saveDrawerReason
  }
}])
