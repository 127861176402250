prismanoteApp.factory('$printnode', ['$q', '$api', '$rootScope', '$language',
function ($q, $api, $rootScope, $language) {

  /**
   * Get all connected devices
   * @param{Boolean} company
   * @returns {*}
   */
  function getConnectedDevices (company) {
    return $q(function (resolve, reject) {
      company = typeof company !== 'undefined' ? company : false
      var id = ''
      if(company){
        id = $rootScope.currentCompany._id
      } else {
        id = $rootScope.currentShop._id
      }
      $api.get('print/computers?id=' + id + '&company=' + company, null, null, 2)
        .then(function (data) {
          return resolve(data.data)
      })
      .catch(function (reason){
        toastr.error($language.translate(reason))
        return reject(reason)
      })
    })
  }

  /**
   * Admin function to create child account
   * @param data
   * @returns {*}
   */
  function createChildAccount (data) {
    //This function is only usable from admin
    return $q(function (resolve, reject) {
      $api.post('print/account', data, null, 2)
      .then(function (result) {
        return resolve(result)
      })
      .catch(function (reason) {
        return reject(reason)
      })
    })
  }

  /**
   * Gets all connected printers
   * @param{String|Number} deviceId
   * @param{String|Number} printerId
   * @param{Boolean} company
   * @returns {*}
   */
  function getPrinters(deviceId, printerId, company) {
    return $q(function (resolve, reject) {
      company = typeof company !== 'undefined' ? company : false
      var query = ''
      if(deviceId) {
        query += '&deviceId=' + deviceId
      }
      if(printerId) {
        query += '&printerId=' + printerId
      }
      var id = ''
      if(company){
        id = $rootScope.currentCompany._id
      } else {
        id = $rootScope.currentShop._id
      }
      $api.get('print/printers?company='+company + '&id=' + id + query, null, null, 2)
      .then( function (printers) {
        return resolve(printers.data)
      })
      .catch( function (reason) {
        toastr.error(reason)
        return reject(reason)
      })
    })
  }

  /**
   * Creates a printjob for PDF files
   * @param doc - base64 decode files
   * @param printer - PrinterId
   * @param computer - ComputerId
   * @param qty - quantity
   * @param options - document options
   * @param company{Boolean} company
   * @returns {*}
   */
  function printPdfFile(doc, printer, computer, qty, options, company) {
    return $q(function (resolve, reject){
      company = typeof company !== 'undefined' ? company : false

      if(!doc || !printer || !options) {
        return reject($language.translate('NOT_ALL_DETAILS_PROVIDED'))
      }
      var id = ''
      if(company){
        id = $rootScope.currentCompany._id
      } else {
        id = $rootScope.currentShop._id
      }

      $api.post('print/printjobs', {
        content: doc,
        contentType: 'pdf_base64',
        printerId: printer,
        computerId: computer,
        options: options,
        quantity: qty,
        id: id
      }, null, 2)
      .then( function (result) {
        if(result.data.deviceStatus === 'disconnected') {
          toastr.warning($language.translate('PRINTJOB_SENDED_BUT_DEVICE_SEEMS_OFFLINE'), $language.translate('WARNING'), {showDuration: '1', hideDuration: '1', timeOut: 0, extendedTimeOut: 250, closeButton: true})
        } else {
          toastr.success($language.translate('SENDED_TO_PRINTER'))
        }
        return resolve(result)
      })
      .catch( function(reason){
        console.error(reason.data)
        toastr.error(reason)
        return reject(reason.data)
      })
    })
  }

  /**
   * Creates a printjob based on RAW content
   * @param doc
   * @param printer
   * @param computer
   * @param qty
   * @param options
   * @param {Boolean} company
   * @returns {*}
   */
  function printRawContent(doc, printer, computer, qty, options, company) {
    return $q(function (resolve, reject){
      company = typeof company !== 'undefined' ? company : false

      if(!doc || !printer) {
        return reject($language.translate('NOT_ALL_DETAILS_PROVIDED'))
      }

      var id = ''
      if(company){
        id = $rootScope.currentCompany._id
      } else {
        id = $rootScope.currentShop._id
      }

      $api.post('print/printjobs', {
        content: doc,
        contentType: 'raw_base64',
        printerId: printer,
        computerId: computer,
        options: options,
        quantity: qty,
        id: id,
        company: company
      }, null, 2)
      .then( function (result) {
        if(result.data.deviceStatus === 'disconnected') {
          toastr.warning($language.translate('PRINTJOB_SENDED_BUT_DEVICE_SEEMS_OFFLINE'), $language.translate('WARNING'), {showDuration: '1', hideDuration: '1', timeOut: 0, extendedTimeOut: 250, closeButton: true})
        } else {
          toastr.success($language.translate('SENDED_TO_PRINTER'))
        }
        return resolve(result)
      })
      .catch( function(reason){
        console.error(reason)
        toastr.error(reason)
        return reject(reason)
      })
    })
  }

  /**
   * Creates printjob to open the connected drawer
   * @param command
   * @param printerId
   * @returns {*}
   */
  function openDrawer(command, printerId, deviceId) {
    return $q(function (resolve, reject) {
      return printRawContent(command, printerId, deviceId, 1, {title: $language.translate('OPEN_DRAWER')})
        .then(function (result) {
          if(result.data.deviceStatus === 'disconnected') {
            toastr.warning($language.translate('PRINTJOB_SENDED_BUT_DEVICE_SEEMS_OFFLINE'), $language.translate('WARNING'), {showDuration: '1', hideDuration: '1', timeOut: 0, extendedTimeOut: 250, closeButton: true})
          } else {
            toastr.success($language.translate('SENDED_TO_PRINTER'))
          }
          return resolve(result)
        })
        .catch(function (reason) {
          return reject(reason)
        })
    })
  }

  return {
    getConnectedDevices: getConnectedDevices,
    createChildAccount: createChildAccount,
    getPrinters: getPrinters,
    printPdfFile: printPdfFile,
    printRawContent: printRawContent,
    openDrawer: openDrawer
  }
}])
