
prismanoteApp.factory('grapeFactory', ['$http', '$q', '$api', 'Upload', function ($http, $q, $api, Upload) {
  return {
    uploadImages: function (websiteId, shopId, formData) {
      return $q(function (resolve, reject) {
        $http({
          method: 'put',
          url: 'api/v2/website/image-upload/' + websiteId + '/' + shopId,
          data: formData,
          headers: {
            'Content-Type': undefined
          }
        })
          .then(function (response) {
            return resolve(response)
          })
          .catch(function (error) {
            return reject(error)
          })
      })
    },
    createWebsite: function (shopId, data) {
      return Upload.upload({
        url: 'api/v2/website/' + shopId,
        data: data
      })
    },
    updateLanguagePreference: function (websiteId, shopId, requestData) {
      return $api.put('v2/website/language-preference/' + websiteId + '/' + shopId, requestData)
    },
    getWebsiteDetail: function (shopId, websiteId) {
      return $api.get('v2/website/' + websiteId + '/' + shopId)
    },
    getHtmlPageContent: function (shopId, websiteId, page) {
      return $api.get('v2/website/page/' + shopId + '/' + websiteId + '/' + page)
    },
    postTemplate: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/grape/template',
        method: 'POST',
        data: obj
      })
        .then(function (res) {
          return res
        }, function (err) {
          return err
        })
    },
    saveGrapeData: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/grape/save',
        method: 'POST',
        data: obj
      })
        .then(function (res) {
          return res
        }, function (err) {
          return err
        })
    },
    postCanPublish: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/grape/can-publish',
        method: 'POST',
        data: obj
      })
        .then(function (res) {
          return res
        }, function (err) {
          return err
        })
    },
    postPublish: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/grape/publish',
        method: 'POST',
        data: obj
      })
        .then(function (res) {
          return res
        }, function (err) {
          return err
        })
    },
    postSiteName: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/grape/site-name',
        method: 'POST',
        data: obj
      })
        .then(function (res) {
          return res
        }, function (err) {
          return err
        })
    },
    postCustomPage: function (obj) {
      return $http({
        headers: { 'Content-Type': 'application/json' },
        url: '/api/grape/custom-page',
        method: 'POST',
        data: obj
      })
        .then(function (res) {
          return res
        }, function (err) {
          return err
        })
    },
    templateSelection: function (shopId, websiteId, data) {
      return $api.post('v2/website/template-choose/' + shopId + '/' + websiteId, data)
    },
    getShopSpecificWebsites: function (shopId) {
      return $api.get('v2/websites/' + shopId)
    },
    updateWebsiteRemark: function (websiteId, shopId, data) {
      return $api.put('v2/website/remark/' + websiteId + '/' + shopId, data)
    },
    getAllNewImage: function () {
      return $http({
        url: '/api/grapes/getAllNewImage',
        method: 'GET'
      })
        .then(function (res) {
          return res
        }, function (err) {
          return err
        })
    },
    getAllWebsiteImagesByShopId: function (shopId) {
      return $http({
        url: '/api/v2/uploadedImages/' + shopId,
        method: 'GET'
      })
        .then(function (res) {
          return res
        }, function (err) {
          return err
        })
    },
    addNewDomain: function (data, shopId, websiteId) {
      return $api.put('v2/website/add-new-domain/' + websiteId + '/' + shopId, data)
    },
    removeDomain1: function (shopId, websiteName, domain) {
      return $api.delete('website/' + websiteName + '/domain/' + domain + '/' + shopId)
    },
    removeWebsite: function (shopId, websiteId) {
      return $api.delete('v2/website/' + shopId + '/' + websiteId)
    },
    copyWebsite: function (websiteId, shopId) {
      return $api.put('v2/copy/website/' + websiteId + '/' + shopId)
    },
    getTranslationUsingKeyword: function (websiteId, shopId, keyword) {
      return $api.get('v2/website/translation/keyword/' + websiteId + '/' + shopId + '/' + keyword)
    },
    updateKeywordTranslation: function (websiteId, shopId, keyword, request) {
      return $api.put('v2/website/translation/keyword/' + websiteId + '/' + shopId + '/' + keyword, request)
    },
    updateWebsiteName: function (websiteId, shopId, data) {
      return $api.put('v2/website/'+ websiteId + '/' + shopId, data);
    }
  }
}])

prismanoteApp.factory('factory', function () {
  var obj = {}
  return obj
})
