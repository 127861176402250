prismanoteApp.factory('$companyInvitation', ['$api',
    function ($api) {

        function fetchCompanyInvitationData(body) {
            return $api.get('v2/company-invitation', body)
        }

        function isUserAndCompanyAvailableByEmail(body) {
            return $api.get('v2/user-company-available-by-email', body)
        }

        function chooseCompanyAndLogin(body) {
            return $api.post('v2/choosecompany-and-login', body)
        }

        function addShopAndCompanyViceVersa(body) {
            return $api.post('v2/add-shop-company-viceversa', body)
        }

        return {
            fetchCompanyInvitationData: fetchCompanyInvitationData,
            isUserAndCompanyAvailableByEmail: isUserAndCompanyAvailableByEmail,
            chooseCompanyAndLogin: chooseCompanyAndLogin,
            addShopAndCompanyViceVersa: addShopAndCompanyViceVersa
        }

    }])
