prismanoteApp.constant('constantFact', {

  alertMsg: [{
    msg: 'Please select Facebook Page.'
  }, {
    msg: 'Please Select End date greater than start date!!!'
  }, {
    msg: 'please select start and End date'
  }, {
    msg: 'image width and height should be greater than 500 & 262'
  }, {
    msg: 'Please select Interest for Promotion'
  }, {
    msg: 'please Provide Image Description'
  }, {
    msg: 'image is not selected'
  }, {
    msg: 'Please Select Start And End Date From Today'
  }, {
    msg: 'Please provide a valid budget'
  }, {
    msg: 'campaign name or purpose is not Defined'
  }, {
    msg: 'instagram is not linked with this page'
  }, {
    msg: 'There is no Custom Location linked with your page. Please add your location in the next step, before executing the campaign.'
  }, {
    msg: 'target reach is very low so please Update your targeting like age , gender or location'
  }],
  Url: 'https://graph.facebook.com/v2.11/'

})
